import { getToken } from 'services/token';

export default class Upload {
    constructor(options) {
        this.options = options;
        this.data = this.options.data || {};
    }

    setFile(key, file) {
        this.key = key;
        this.file = file;
        return this;
    }

    send() {
        return new Promise((resolve, reject) => {
            if (!this.file) {
                reject(new Error('missing_file'));
            }

            const xhr = new window.XMLHttpRequest();
            const formData = new window.FormData();
            const fileName = encodeURI(this.file.name);

            xhr.open('POST', this.options.url);
            xhr.setRequestHeader('Accept', 'application/json, text/javascript', '*/*');

            const token = getToken();

            if (token) {
                xhr.setRequestHeader('Authorization', token, '*/*');
            }

            xhr.upload.onprogress = (event) => {
                const percent = Math.round(
                    100 * event.loaded / event.total
                );
                if (typeof this.options.onProgress === 'function') {

                    this.options.onProgress(percent);

                }
            };

            xhr.onloadend = () => {
                if (typeof this.options.onFileUploadEnded === 'function') {
                    this.options.onFileUploadEnded();
                }
            };

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    let responseJson;

                    try {
                        responseJson = JSON.parse(xhr.responseText || {});
                    } catch (e) { // eslint-disable-line
                        responseJson = xhr.responseText;
                    }

                    if (xhr.status.toString().substr(0, 1) === '2') {
                        resolve(responseJson);
                    } else {
                        reject(new Error(responseJson.message));
                    }
                }
            };

            // @TODO manage nested object
            if (Object.keys(this.data).length) {
                this.data.forEach((prop) => {
                    if (Object.prototype.hasOwnProperty.call(this.data, prop)) {
                        const isFileNameProp = prop === 'fileName';
                        const dataProp = isFileNameProp
                            ? this.data[prop]
                            : encodeURI(this.data[prop]);
                        formData.append(prop, dataProp);
                    }
                });
            }

            formData.append(this.key, this.file, fileName);
            xhr.send(formData);
        });
    }
}
