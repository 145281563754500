import * as types from 'types/drawer';
import { removeAllHashParameters } from 'utils/url';

const initialState = {
    [types.DRAWER_SIP]: {
        isVisible: false
    },
    [types.DRAWER_FEED_EDITOR]: {
        isVisible: false
    },
    [types.DRAWER_INVITE_EXTERNAL_EMAILS]: {
        isVisible: false
    },
    [types.DRAWER_SEARCH]: {
        isVisible: false
    },
    [types.DRAWER_CREATE_UNICAST]: {
        isVisible: false
    },
    [types.DRAWER_INVITE_GROUP]: {
        isVisible: false,
        _feed: null
    },
    [types.DRAWER_ADD_MEMBERS]: {
        isVisible: false,
        _feed: null
    },
    [types.DRAWER_EDIT_GROUP]: {
        isVisible: false,
        _feed: null
    }
};

const drawerReducer = (state = initialState, action) => {

    const { modalType, type, ...rest } = action || {}; // eslint-disable-line

    switch (action.type) {

        case types.OPEN_DRAWER:
            return {
                ...state,
                [action.drawerType]: {
                    isVisible: true,
                    ...rest
                }
            };

        case types.CLOSE_DRAWER:
            if (action.drawerType === types.DRAWER_SEARCH || action.drawerType === types.DRAWER_SIP)
                removeAllHashParameters();
            return {
                ...state,
                [action.drawerType]: {
                    ...state[types.DRAWER_SIP],
                    isVisible: false
                }
            };

        case types.CLOSE_ALL_DRAWERS:
            removeAllHashParameters();
            return initialState;

        default:
            return state;
    }
};

export default drawerReducer;
