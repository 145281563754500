import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from '@amplement/backend-connector';

import Button from 'components/Shared/Common/Button';
import { RECORDER_STATUS } from 'components/Shared/Recorder/constants';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import useMyStreamSelector from 'hooks/useMyStreamSelector';
import { useWebgl } from 'components/Room/Webgl/WebglContext';

const ExitButton = (props) => {
    const {
        onClick,
        recorderState = RECORDER_STATUS.STOPPED,
        isEnabled, // eslint-disable-line
        onToggleOwnVideo,
        _room,
        onToggleOwnScreen,
        videoStream,
        onStopRecording = () => {},
        handleChangeStatus = () => {},
        ...btnProps
    } = props;
    const {
        setIsScreenShare,
        setHasVideo,
    } = useWebgl();
    const timeoutRef = useRef(null);
    const { hasVideo, isSharingScreen, hasScreen } = useMyStreamSelector(_room) || {};
    const [stopCall, setStopCall] = useState(false);

    const handleClick = useCallback(() => {
        if (recorderState === RECORDER_STATUS.RECORDING) {
            onStopRecording();
            handleChangeStatus(RECORDER_STATUS.STOPPED);
        }

        setHasVideo(false);
        setIsScreenShare(false);
        onToggleOwnVideo?.(false);
        onToggleOwnScreen?.(false);
        setStopCall(true);
        // In case of error while destroying the video streams or if we dont receive the PC_CLOSE from websocket,
        // we will force the call to stop after 1 second as a fallback.
        // The video light will stay ON on the end room screen until the window is completely closed.
        timeoutRef.current = setTimeout(() => onClick(), 1000);
    }, [onClick, onStopRecording, setHasVideo, handleChangeStatus, recorderState]);

    useEffect(() => {
        if (stopCall && ((!hasVideo && !isSharingScreen && !hasScreen) || !videoStream)) {
            clearTimeout(timeoutRef.current);
            onClick();
        }
    }, [stopCall, hasVideo, isSharingScreen, hasScreen, videoStream]);

    useWhyDidYouUpdate('component:ExitButton', props);

    return (
        <Button
            iconName="PhoneOff"
            color="error"
            onClick={handleClick}
            isLoading={stopCall}
            {...btnProps}
        />
    );
}

ExitButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    recorderState: PropTypes.oneOf(Object.values(RECORDER_STATUS)),
    onStopRecording: PropTypes.func,
    setHasVideo: PropTypes.func,
    handleChangeStatus: PropTypes.func,
}

const Component = memo(ExitButton);

export default Component;

const mapDispatchToProps = (dispatch, { _room }) => ({
    onClick: () => dispatch(actions.rooms.requestPostRoomLeave(_room)),
});

export const container = connect(null, mapDispatchToProps)(Component);
