import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Button from 'components/Shared/Common/Button';
import { Component as NetworkIcon } from 'components/Room/Participant/NetworkPingInfo';

const ResponderError = ({ intl, onRetry, name = '' }) => (
    <>
        <div className="line">
            <div className="line-container">
                <NetworkIcon quality="down" />
            </div>
            <div className="line-container">
                <div className="name">
                    {name} a une erreur
                </div>
            </div>
        </div>

        {typeof onRetry === 'function' ? (
            <div className="centered-container">
                <div className="error">
                    <div className="text">
                        {intl.formatMessage({
                            id: 'room.player.retry',
                            defaultMessage: 'Réessayer'
                        })}
                    </div>
                    <Button
                        iconName="RefreshCw"
                        onClick={onRetry}
                        color="light-transparency"
                    />
                </div>
            </div>
        ) : null}
    </>
);


ResponderError.propTypes = {
    onRetry: PropTypes.func,
    name: PropTypes.string,
    intl: PropTypes.any.isRequired
};

export default memo(injectIntl(ResponderError));
