import React, { memo } from 'react';
import PropTypes from 'prop-types';

const VideoSliderThumbItem = ({ file }) => (
    <video src={(window.URL || window.webkitURL).createObjectURL(file)} />
);

VideoSliderThumbItem.propTypes = {
    file: PropTypes.object.isRequired
};

export default memo(VideoSliderThumbItem);
