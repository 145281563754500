import React, { useCallback, useState, useEffect } from 'react';
import { Typography } from 'antd';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import styled from 'styled-components';
import Tooltip from 'components/Shared/Common/Tooltip';
import ContactButton from 'components/Contacts/Contacts/ContactButton';
import { isNumber } from 'utils/sip';
import classNames from 'classnames';

const { Text } = Typography;

const StyledButton = styled.div`
    height: 42px;
    align-items: center;
    display: flex;
    border-radius: 30px 0 0px 30px;
    margin: -5px -12px -5px -16px;
    &.room-end{
        .ant-btn{
            border-radius: 30px;
        }
    }
    .ant-btn{
        border-radius: 30px 0 0px 30px;
        height: 100%!important;
        .anticon{
            font-size: 16px;
        }
    }
`;


type WrappedContactButtonProps = {
    roomName: string;
    isEndRoom?: boolean;
} & WrappedComponentProps;

const WrappedContactButton = ({ isEndRoom = false, roomName = '', intl }: WrappedContactButtonProps): JSX.Element => {
    const [name, setName] = useState(roomName);
    const handleSuccess = useCallback(response => {
        const {
            data: {
                user_name: {
                    first = '',
                    last = ''
                } = {}
            } = {}
        } = response;
        setName(`${first} ${last}`);
    }, []);

    useEffect(() => setName(roomName), [roomName]);

    if (isNumber(name)) {
        return (
            <Tooltip
                title={intl.formatMessage({ id: 'room.addContact' })}
                placement='bottom'
            >
                <StyledButton 
                    className={classNames({ 'room-end': isEndRoom })}
                >
                    <ContactButton
                        compact
                        isPrimary
                        phoneNumber={roomName}
                        onSuccess={handleSuccess}
                    />
                </StyledButton>
            </Tooltip>
        );
    }

    return <Text>{name}</Text>;
}

export default injectIntl(WrappedContactButton as React.FC<WrappedContactButtonProps>);
