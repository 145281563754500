import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ImagePreviewItem = ({ url }) => (
    // <div className="big-thumb" style={{ backgroundImage: `url(${url})` }} />
    <div className="img-container">
        <img alt="preview" className="preview-img" src={url} />
    </div>
);

ImagePreviewItem.propTypes = {
    url: PropTypes.string.isRequired
};

export default memo(ImagePreviewItem);
