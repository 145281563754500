import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Shared/Common/Button';

import MoreButton from './container';

const MoreButtonContainer = (props) => {
    const {
        _room,
        isInterfaceDisplayed = true
    } = props;

    const [isOpenMoreDropdown, setIsOpenMoreDropdown] = useState();
    const handleOnMoreClose = useCallback(() => setIsOpenMoreDropdown(false),
        [setIsOpenMoreDropdown]);

    const handleToggleMoreClose = useCallback(() => setIsOpenMoreDropdown(!isOpenMoreDropdown),
        [setIsOpenMoreDropdown, isOpenMoreDropdown]);

    useEffect(() => {
        if (!isInterfaceDisplayed) {
            setIsOpenMoreDropdown(false);
        }
    }, [isInterfaceDisplayed]);

    return (
        <>
            {isOpenMoreDropdown !== undefined ? (
                <MoreButton
                    isOpen={isOpenMoreDropdown}
                    onClose={handleOnMoreClose}
                    _room={_room}
                />
            ) : null}
            <Button
                iconName="MoreVertical"
                color="light-transparency"
                onClick={handleToggleMoreClose}
            />
        </>
    );
}

MoreButtonContainer.propTypes = {
    _room: PropTypes.string.isRequired,
    isInterfaceDisplayed: PropTypes.bool,
};

export default MoreButtonContainer;
