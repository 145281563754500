import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';

// function beforeUpload(file) {
//     const isImg = isImage();
//     if (!isImg) {
//         console.error('You can only upload JPG/PNG file!');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//         console.error('Image must smaller than 2MB!');
//     }
//     return isImg && isLt2M;
// }

export const MyUpload = ({ children, onAdd, disabled = false, ...otherProps }) => {
    const props = {
        disabled,
        multiple: true,
        showUploadList: false,
        beforeUpload: (file, fList) => {
            onAdd(fList);
            return false;
        },
        // onChange: (info) => console.log('onchangeee', info.file,
        // info.fileList.map(x => x.uid).indexOf(info.file.uid), info.file.length)
    };

    return (
        <Upload {...props} {...otherProps}>
            {children}
        </Upload>
    );
}

MyUpload.propTypes = {
    children: PropTypes.any.isRequired,
    onAdd: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default memo(MyUpload);
