import React, { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Form, Space } from 'antd';
import { useQueryClient } from 'react-query';

import ForwardSipUserSearch from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/ForwardSipUserSearch';
import {
    SearchBtn,
    ForwardToLabel,
    PhoneBookNumber,
    numberFilterPhoneType
} from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/Commons';

const isInternalNumber = (number) => number?.length <= 6

const ForwardContactInput = ({ value, onChange, intl }) => {
    const [meta, setMeta] = useState();
    const form = Form.useFormInstance();
    useEffect(() => form.setFieldValue('type', 'NUMBER'), []);
    const queryClient = useQueryClient();

    const handleSelectSipUser = useCallback((val) => {
        const number = (val?.phones ? val?.phones?.[0]?.callNumber : val?.id)?.replace(/^00/, '+');
        if (isInternalNumber(number)) {
            onChange(val.sipUsername);
            queryClient.setQueryData(['phonebook', { query: val.sipUsername, type: 'internal' }], [val]);
        } else {
            onChange(number);
        }
        form.setFieldValue('type', isInternalNumber(number) ? 'EXTENSION' : 'NUMBER');
        // onChange(number);
        setMeta(val);
    }, [onChange]);

    const handleReset = useCallback(() => {
        onChange();
        setMeta();
    }, [onChange]);

    return (
        <Space direction="vertical" size="small" style={{ display: 'flex' }}>
            {/* <div>
                <Button onClick={() => handleSelectSipUser({
                    firstname: "Charles",
                    hasStatus: false,
                    id: "222943-0",
                    lastname: "Aellig",
                    phones: [{ displayNumber: '0033649231469', callNumber: '0033649231469' }],
                    sipUsername: 'sipUsername-1'
                })}>00</Button>
                <Button onClick={() => handleSelectSipUser({
                    firstname: "Charles",
                    hasStatus: false,
                    id: "222943-0",
                    lastname: "Aellig",
                    phones: [{ displayNumber: '+33649231469', callNumber: '+33649231469' }],
                    sipUsername: 'sipUsername-2'
                })}>+</Button>
                <Button onClick={() => handleSelectSipUser({
                    firstname: "Charles",
                    hasStatus: false,
                    id: "500100903101",
                    lastname: "Aellig",
                    phones: [{ displayNumber: '3101', callNumber: '3101' }],
                    sipUsername: '500100903101'
                })}>3101</Button>
                <Button onClick={() => handleSelectSipUser({
                    firstname: "Charles",
                    hasStatus: false,
                    id: "222943-0",
                    lastname: "Aellig",
                    phones: [{ displayNumber: '+590690199167', callNumber: '+590690199167' }],
                    sipUsername: 'sipUsername-4'
                })}>GWD</Button>
                <Button onClick={() => handleSelectSipUser({
                    firstname: "Charles",
                    hasStatus: false,
                    id: "222943-0",
                    lastname: "Aellig",
                    phones: [{ displayNumber: '+5215549409329', callNumber: '+5215549409329' }],
                    sipUsername: 'sipUsername-5'
                })}>AFRICA</Button>
                <Button type="danger" onClick={() => handleSelectSipUser({
                    firstname: "Charles",
                    hasStatus: false,
                    id: "222943-0",
                    lastname: "Aellig",
                    phones: [{ displayNumber: '+52155494', callNumber: '+52155494' }],
                    sipUsername: 'sipUsername-6'
                })}>Error</Button>
            </div>
            <div>sipUsername : {value}</div> */}

            <div>
                <ForwardToLabel />
                <PhoneBookNumber
                    {...meta} 
                    emptyText={intl.formatMessage({ id: 'call.forward.selectSipUser' })} 
                />
                {!!meta && value && (
                    <Button 
                        type="link" 
                        danger 
                        onClick={handleReset} 
                        size="small"
                    >
                        x
                    </Button>
                )}
                {'  '}
                <ForwardSipUserSearch
                    TriggerComponent={SearchBtn}
                    onConfirm={handleSelectSipUser}
                    phoneFilterTypes={numberFilterPhoneType}
                />
            </div>
        </Space>
    );
};

ForwardContactInput.propTypes = {
    intl: PropTypes.any.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default injectIntl(memo(ForwardContactInput));
