import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Shared/Common/Button';
import { getUrl } from 'utils/url';
import usePhonenumber from 'hooks/usePhonenumber';

const StyledRecallButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RecallButton = forwardRef(({
    isPersistent = false,
    _room,
    _feed,
    children = null,
    isMulticast, // eslint-disable-line
    members = [],
    subTitleComponent = null,
    buttonClassName = 'item',
    windowTarget, // eslint-disable-line
    _user: userId,
    disabled = false,
    customPrefix,
    ...rest
}, ref) => {
    let href;
    let target;
    let isDisabled = disabled;

    const _user = useMemo(() => {
        if (userId) {
            return userId;
        }

        return members?.find(m => !m.isMyUser)?._user;
    }, [members, userId]);

    const phoneNumber = useMemo(() => {
        if (rest.phoneNumber) {
            return rest.phoneNumber;
        }

        return members?.find(m => !m.isMyUser)?.phonenumber;
    }, [members, rest.phoneNumber]);

    const { formatProps } = usePhonenumber({ prefix: customPrefix });
    const numberProps = formatProps(phoneNumber);

    if (members?.length <= 2 && phoneNumber) {
        href = numberProps.href;
        target = numberProps.target;
    } else if (isPersistent && !phoneNumber) {
        href = getUrl('room', { _room });
        target = `room-${_room}`;
    }
    else if (!isPersistent && !phoneNumber && !_feed && !_user && _room) {
        // The conference no longer exists, so we disable the call back button.
        // We could also create a new one.
        isDisabled = true;
    }
    else if (!isPersistent && !phoneNumber && _feed) {
        href = getUrl('roomByFeed', { _feed });
        target = `roomByFeed-${_feed}`;
    }
    else if (!isPersistent && members?.length <= 2 && _user && !phoneNumber) {
        href = getUrl('roomByUser', { _user });
        target = `roomByUser-${_user}`;
    }

    return (
        <StyledRecallButton ref={ref} className={rest.className || buttonClassName}>
            <Button
                {...rest}
                href={href}
                target={target}
                forceReload
                isDisabled={isDisabled}
            >
                {children}
            </Button>
            {subTitleComponent}
        </StyledRecallButton>
    );
});

RecallButton.propTypes = {
    isPersistent: PropTypes.bool,
    disabled: PropTypes.bool,
    buttonClassName: PropTypes.string,
    members: PropTypes.array,
    isMulticast: PropTypes.bool,
    _room: PropTypes.string,
    _feed: PropTypes.string,
    children: PropTypes.any,
    subTitleComponent: PropTypes.element,
    windowTarget: PropTypes.string,
};

export default RecallButton;
