import React, { memo } from 'react';
import PropTypes from 'prop-types';

import VoiceMailItemDetails from 'components/Shared/Drawers/Sip/VoiceMailItem/Details/container';
import VoiceMailItemSummary from 'components/Shared/Drawers/Sip/VoiceMailItem/Summary/container';
import { Collapse } from 'antd';
import styled from 'styled-components';

const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
    &:hover,
    &.ant-collapse-item-active{
        background-color: ${({theme}) => theme.lighten};
    }
`;

const VoiceMailItem = ({
    VoiceMailItemSummaryComponent = VoiceMailItemSummary,
    VoiceMailItemDetailsComponent = VoiceMailItemDetails,
    _voiceMail,
    isActive,
    selectable,
    onChange,
    setVoiceMails = () => {},
    checked,
    ...rest
}) => (
    <StyledPanel
        {...rest}
        header={(
            <VoiceMailItemSummaryComponent
                _voiceMail={_voiceMail}
                selectable={selectable}
                onChange={onChange}
                checked={checked}
            />)}
        showArrow={false}
        isActive={isActive}
    >
        <VoiceMailItemDetailsComponent
            setVoiceMails={setVoiceMails}
            _voiceMail={_voiceMail}
            isActive={isActive}
        />
    </StyledPanel>
);

VoiceMailItem.propTypes = {
    _voiceMail: PropTypes.number.isRequired,
    setVoiceMails: PropTypes.func,
    VoiceMailItemSummaryComponent: PropTypes.any,
    VoiceMailItemDetailsComponent: PropTypes.any,
};

export default memo(VoiceMailItem);
