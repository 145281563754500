import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { selectors, actions } from '@amplement/backend-connector';
import { Typography } from 'antd';

import Row from 'components/CallBuilder/Row/Row';
import Icon from 'components/Shared/Common/Icon';
import { RoomLabel, getIconName, getRoomLabelId } from 'components/Shared/Notification/Room';
import ButtonClick from 'components/Shared/Common/Button/ButtonClick';

import CallButton from 'components/CallBuilder/DefaultResults/style';
import { MAX, NAVIGATION }from 'components/CallBuilder/constants';

import { NOTIFICATION_CATEGORIES, NOTIFICATION_ROOM_TYPES } from 'const/notification';

const { Text } = Typography;

const Caller = injectIntl(({
    id,
    onAddParticipant,
    onRemoveParticipant,
    inputValue,
    isDisconnected,
    onCloseModal,
    intl,
    showDirectCall,
}) => {
    const {
        payload: {
            name,
            members = [],
            _feed,
            _sourceFeed,
            isPersistent,
            isMulticast,
            id: _room,
            caller: {
                isMyUser,
            } = {},
        } = {},
        type,
        createdAt: date,
    } = useSelector(state => selectors.notifications.getNotificationByIdSelector(state, id)) || {};

    const handleAddRoomParticipants = useCallback(() => {
        members.forEach(member => {
            const hasMember = inputValue.find((item) => {
                if (member?.phonenumber) {
                    return item?.phoneNumber === member?.phonenumber
                }

                return item?._user === member?._user
            });

            if (member.isMyUser || hasMember) {
                return;
            }

            onAddParticipant({
                resultType: NAVIGATION.RECENT_CALLS,
                phoneNumber: member.phonenumber,
                _feed,
                value: member.phonenumber || member._user,
                label: member.label || member.name || member.phonenumber || member.fullname,
                ...member
            });
        });
    }, [onAddParticipant, members, inputValue, _feed]);

    const handleRemoveRoomParticipants = useCallback(() => {
        members.forEach(member => {
            if (member.phonenumber) {
                onRemoveParticipant(member, 'phonenumber');
            } else {
                onRemoveParticipant(member, '_user');
            }
        });
    }, [members, onRemoveParticipant]);

    const hasParticipant = useMemo(() => {        
        if (!members.length || (members.length === 1 && members[0].isMyUser)) return false;

        return members.every(member => {
            if (member.isMyUser) {
                return true;
            }

            return inputValue.some(item => {
                if (item.phoneNumber) {
                    return item?.phoneNumber === member?.phonenumber;
                }

                return item?._user === member?._user;
            })
        });
    }, [members, inputValue]);

    const hasPhonenumberParticipants = useMemo(() => members.some(member => member.phonenumber), [members]);

    const status = useMemo(() => (
        <>
            <Icon iconName={getIconName(isMyUser, type, false, members)} />
            {intl.formatMessage({ id: getRoomLabelId(isMyUser, type, members) })}
        </>
    ), [isMyUser, type, members, intl]);

    const isMissed = useMemo(() => type === NOTIFICATION_ROOM_TYPES.MISSED, [type]);

    return (
        <Row
            label={(
                <ButtonClick onClick={onCloseModal}>
                    <RoomLabel
                        isPersistent={isPersistent}
                        members={members}
                        name={name}
                        className="s-recent-call-name"
                        isMulticast={isMulticast}
                        _sourceFeed={_sourceFeed}
                    />
                </ButtonClick>
            )}
            onAddParticipant={handleAddRoomParticipants}
            onRemoveParticipant={handleRemoveRoomParticipants}
            hasParticipant={hasParticipant}
            members={members}
            date={date}
            status={status}
            isMissed={isMissed}
            disablePlusButton={isDisconnected && hasPhonenumberParticipants}
        >
            {!inputValue.length && (!isDisconnected || !hasPhonenumberParticipants) && showDirectCall ? (
                <ButtonClick onClick={onCloseModal}>
                    <CallButton
                        iconName="Phone"
                        _room={_room}
                        _feed={_sourceFeed}
                        members={members}
                        isPersistent={isPersistent}
                    >
                        <p>{intl.formatMessage({ id: 'call.call' })}</p>
                    </CallButton>
                </ButtonClick>
            ) : null}
        </Row>
    );
});

const CallList = ({
    max = MAX,
    onAddParticipant = () => {},
    onRemoveParticipant = () => {},
    _notifications = [],
    getNotifications = () => {},
    isDisconnected = false,
    isLoading = false,
    inputValue,
    onCloseModal = () => {},
    intl,
    showDirectCall = false,
}) => {
    useEffect(() => {
        getNotifications(NOTIFICATION_CATEGORIES.ROOM, undefined, 50, 1, undefined, undefined);
    }, []);

    if (isLoading) {
        return <LoadingOutlined />;
    }

    if (!_notifications || !_notifications.length) {
        return <Text>{intl.formatMessage({ id: 'callbuilder.emptyList' })}</Text>;
    }

    return (
        _notifications.slice(0, max).map(_notification => (
            <Caller
                key={_notification}
                isDisconnected={isDisconnected}
                inputValue={inputValue}
                showDirectCall={showDirectCall}
                id={_notification}
                onAddParticipant={onAddParticipant}
                onRemoveParticipant={onRemoveParticipant}
                onCloseModal={onCloseModal}
            />
        ))
    );
};

CallList.propTypes = {
    onAddParticipant: PropTypes.func,
    onRemoveParticipant: PropTypes.func,
    showDirectCall: PropTypes.bool,
    isDisconnected: PropTypes.bool,
    getNotifications: PropTypes.func,
    _notifications: PropTypes.arrayOf(PropTypes.string),
    max: PropTypes.number,
    isLoading: PropTypes.bool,
    onCloseModal: PropTypes.func,
    intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    _notifications: selectors.notifications.notificationIdsSelector(state),
    isLoading: selectors.notifications.getLoadingNotificationByCategory(state, `${NOTIFICATION_CATEGORIES.ROOM}_all`)
})

const mapDispatchToProps = dispatch => ({
    getNotifications: (category, type, limit, page, ignored, read) =>
        dispatch(actions.notifications.requestGetNotifications(category, type, limit, page, ignored, read)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CallList));
