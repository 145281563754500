import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Button from 'components/Shared/Common/Button';
import buttonProps from 'components/Shared/Common/Button/Button.props';
import { CONTACT_STATUS } from 'const/user';

const formatMessages = intl => ({ contactStatus }) => ({
    buttonLabel: intl.formatMessage(
        { id: 'relation.action' },
        {
            contactStatus: contactStatus === null || contactStatus === undefined ?
                CONTACT_STATUS.NOT_CONNECTED : contactStatus
        }
    ),
    buttonLoading: intl.formatMessage({
        id: 'drawer.createGroup.multiSelect.loadingMessageText',
        defaultMessage: 'Chargement...'
    }),
    accept: intl.formatMessage({
        id: 'profile.relationButton.label.accept',
        defaultMessage: 'Accepter'
    }),
    dismiss: intl.formatMessage({
        id: 'profile.relationButton.label.dismiss',
        defaultMessage: 'Refuser'
    }),
    cancel: intl.formatMessage({
        id: 'profile.relationButton.label.cancel',
        defaultMessage: 'Annuler la demande'
    }),
    connect: intl.formatMessage({
        id: 'profile.relationButton.label.connect',
        defaultMessage: 'Se connecter'
    }),
    pending: intl.formatMessage({
        id: 'smallDrawer.feed.waitingLabel',
        defaultMessage: 'En attente'
    })
});

const ContactStatusButton = ({
    contactStatus = CONTACT_STATUS.NOT_CONNECTED,
    onLink,
    onUnlink,
    onAccept,
    onDecline,
    isLoading = false,
    isInitiator,
    intl,
    size = 'md'
}) => {
    const isContactStatus = Object.values(CONTACT_STATUS).indexOf(contactStatus) !== -1;
    const i18n = formatMessages(intl)({
        contactStatus
    });

    if (isLoading || !isContactStatus) {
        return (
            <Button isDisabled color="secondary" size={size}>
                {i18n.buttonLoading}
            </Button>
        );
    }

    if (contactStatus === CONTACT_STATUS.ACCEPTED) {
        return (
            <Button color="danger" onClick={onUnlink} size={size}>
                {i18n.buttonLabel}
            </Button>
        );
    }

    if (contactStatus === CONTACT_STATUS.PENDING) {
        if (isInitiator) {
            if (onUnlink) {
                return (
                    <Button color="transparency-bordered-primary" onClick={onUnlink} size={size}>
                        {i18n.cancel}
                    </Button>
                );
            }
            return (
                <Button color="transparency" isDisabled isTransparent size={size}>
                    {i18n.pending}
                </Button>
            );
        }

        return (
            <>
                <Button color="success" onClick={onAccept} size={size}>
                    {i18n.accept}
                </Button>
                <Button color="danger" onClick={onDecline} size={size}>
                    {i18n.dismiss}
                </Button>
            </>
        );
    }

    if (contactStatus === CONTACT_STATUS.DECLINED) {
        if (!isInitiator) {
            return (
                <Button color="success" onClick={onAccept} size={size}>
                    {i18n.connect}
                </Button>
            );
        }
        return (
            <Button color="transparency" isDisabled isTransparent size={size}>
                {i18n.pending}
            </Button>
        );
    }

    return (
        <Button color="success" onClick={onLink} size={size}>
            {i18n.buttonLabel}
        </Button>
    );
};

ContactStatusButton.propTypes = {
    contactStatus: PropTypes.oneOf(Object.values(CONTACT_STATUS)),
    onLink: PropTypes.func.isRequired,
    onUnlink: PropTypes.func.isRequired,
    onAccept: PropTypes.func,
    onDecline: PropTypes.func,
    isLoading: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    isInitiator: PropTypes.bool,
    size: PropTypes.oneOf(buttonProps.sizes)
};

export default injectIntl(ContactStatusButton);
