import React, { memo, useMemo } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {
    StarOutlined,
    PhoneOutlined,
    UsergroupAddOutlined
    // VideoCameraOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import BookmarkList from 'components/CallBuilder/DefaultResults/BookmarkList';
import RecentCallList from 'components/CallBuilder/DefaultResults/RecentCallList';
// import RoomList from 'components/CallBuilder/DefaultResults/RoomList';
import FeedList from 'components/CallBuilder/DefaultResults/FeedList';
import NavButtons from 'components/Shared/Common/NavButtons';

import { NAVIGATION, NavigationKeys }from 'components/CallBuilder/constants';

import { ListProps, getCustomNavButtonsType } from 'components/CallBuilder/types';

const StyledOverflowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow: overlay;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
`;

const getNavButtons: getCustomNavButtonsType = (intl, navigation, onNavigationChange) => [
    {
        type: 'link',
        children: (
            <p>
                <StarOutlined /> {intl.formatMessage({ id: 'call.tabs.bookmarks' })}
            </p>
        ),
        disabled: navigation === NAVIGATION.BOOKMARKS,
        onClick: () => onNavigationChange(NAVIGATION.BOOKMARKS)
    },
    {
        type: 'link',
        children: (
            <p>
                <PhoneOutlined /> {intl.formatMessage({ id: 'call.tabs.callHistory' })}
            </p>
        ),
        disabled: navigation === NAVIGATION.RECENT_CALLS,
        onClick: () => onNavigationChange(NAVIGATION.RECENT_CALLS)
    },
    // Disable the room list until the backend has fixed the ringtone problem
    // {
    //     type: 'link',
    //     children: (
    //         <p>
    //             <VideoCameraOutlined /> {intl.formatMessage({ id: 'menuLeft.rooms.title' })}
    //         </p>
    //     ),
    //     disabled: navigation === NAVIGATION.ROOMS,
    //     onClick: () => onNavigationChange(NAVIGATION.ROOMS)
    // },
    {
        type: 'link',
        children: (
            <p>
                <UsergroupAddOutlined /> {intl.formatMessage({ id: 'menuLeft.feeds.title' })}
            </p>
        ),
        disabled: navigation === NAVIGATION.FEEDS,
        onClick: () => onNavigationChange(NAVIGATION.FEEDS)
    }
];

type DefaultResultsProps = {
    navigation: NavigationKeys | string;
    onNavigationChange: (nav: NavigationKeys) => void;
    maxSipReached: boolean;
    getCustomNavButtons?: getCustomNavButtonsType;
} & WrappedComponentProps & ListProps;

const DefaultResults: React.FC<DefaultResultsProps> = ({
    getCustomNavButtons = getNavButtons,
    intl,
    navigation,
    onNavigationChange,
    showDirectCall,
    maxResultListHeight,
    ...props
}) => {
    const navButtons = useMemo(() => getCustomNavButtons(intl, navigation, onNavigationChange), [intl, navigation, onNavigationChange, getCustomNavButtons]);
    const wrapperStyle = useMemo(() => {
        if (showDirectCall) {
            return {
                minHeight: 200,
                maxHeight: maxResultListHeight,
                flex: 1
            };
        }

        return {};
    }, [showDirectCall]);

    return (
        <>
            <NavButtons buttons={navButtons} />
            <StyledOverflowWrapper style={wrapperStyle} className="ui-wrapper-items">
                {navigation === NAVIGATION.BOOKMARKS && (
                    <BookmarkList
                        {...props}
                        showDirectCall={showDirectCall}
                    />
                )}
                {navigation === NAVIGATION.RECENT_CALLS && (
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    /* @ts-ignore */
                    <RecentCallList
                        {...props}
                        showDirectCall={showDirectCall}
                    />
                )}
                {/* Disable the room list until the backend has fixed the ringtone problem */}
                {/* {navigation === NAVIGATION.ROOMS && <RoomList {...props} />} */}
                {navigation === NAVIGATION.FEEDS && (
                    <FeedList
                        {...props}
                        showDirectCall={showDirectCall}
                    />
                )}
            </StyledOverflowWrapper>
        </>
    );
}

export default memo(injectIntl(DefaultResults));
