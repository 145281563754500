import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { i18n, utils } from '@amplement/backend-connector';
import { message as toast } from 'antd';
import Logger from 'services/debug/logger';

const logger = new Logger('component:toaster');

const ToastInformation = (props) => {
    const {
        duration = 5,
        title = '',
        values,
        referenceError,
        error,
        severity = 'error',
        fromBC = false,
        intl,
        message = '',
    } = props;

    const [api, contextHolder] = toast.useMessage();

    useEffect(() => {
        try {
            const prefix = referenceError ? `(${referenceError}) ` : '';
            let content = message;

            if (!content && title) {
                content = fromBC ?
                    i18n.translate(title, values) :
                    intl.formatMessage({ id: title }, values);
            } else if (error) {
                content = error.message;
            }

            logger.log(severity, {
                content: `${prefix}${content}`,
                duration,
            });
            api.open({
                type: severity,
                content: `${prefix}${content}`,
                duration,
            });
        } catch (e) { // eslint-disable-line
            utils.errorHandler.captureException(error, 'component:ToastInformation');
        }
    }, []);

    return contextHolder;
}

ToastInformation.propTypes = {
    message: PropTypes.string,
    title: PropTypes.string,
    values: PropTypes.object,
    referenceError: PropTypes.string,
    error: PropTypes.object,
    duration: PropTypes.number,
    fromBC: PropTypes.bool,
    severity: PropTypes.oneOf([
        'success',
        'warning',
        'error',
        'info'
    ]),
};

export default injectIntl(ToastInformation);
