import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import UploadButton from 'components/Feed/Creator/UploadButton';
import FeedItemInput from 'components/Feed/Creator/FeedItemInput';
import TypingUsers from 'components/Feed/Creator/TypingUsers';

import ProgressBar from 'components/Shared/Common/ProgressBar';
import styled from 'styled-components';
import { rgba, darken } from 'polished';

const Styled = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px 10px 10px;
    width: 100%;
    position: relative;
    align-items: flex-end;
    .smiley-content{
        background-color: #fff;
        border-radius: 6px;
        bottom: 100%;
        box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
        display: flex;
        flex-direction: column;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 20px;
        transform: translateY(-20px);
        transition: all .25s ease;
        visibility: hidden;
        z-index: 12;
        .emoji-mart-title-label{
            font-size: 14px;
        }
        &.active{
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
        }
    }
    & .textarea {
        border: 1px solid ${({ theme }) => rgba(theme.black, 0.1)};
        border-radius: 30px;
        display: flex;
        align-items: center;
        flex: 1;
        min-height: 0;
        min-width: 0;
        white-space: nowrap;
        transition: all 250ms ease;
        margin-left: 10px;
        &:focus-within{
            background-color: ${({theme}) => darken(0.02, theme.bg.global)};
            border-color: ${({ theme }) => rgba(theme.black, 0.15)};
            &.error{
                background-color: ${({theme}) => rgba(theme.error, 0.1)};
                border-color: ${({ theme }) => rgba(theme.error, 0.15)};    
            }
        }
        .ant-form-item{
            border-radius: 30px;
            &-explain{
                position: absolute;
                top: -23px;
                right: 0;
            }
        }
        textarea {
            border: none;
            resize: none;
            display: flex;
            flex: 1;
            padding: 9px 10px 10px 20px;
            font-size: 16px;
            background-color: transparent;
            line-height: 1.5;
            min-height: 40px;
            max-height: 200px;
            transition: all 250ms ease;
            color: ${({ theme }) => theme.black};
        }
    }

    & .textarea>.icons {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-right: 10px;
        margin-bottom: 3px;
        .item {
            display: flex;
            align-items: center;
            margin-right: 10px;
            &:hover{
                .icon{
                    svg{
                        color: ${({ theme }) => theme.black};
                    }
                }
            }
            :last-child {
                margin-right: 0;
            }
            .icon {
                display: flex;
                align-items: center;
                cursor: pointer;
                svg {
                    transition: all 250ms ease;
                    color: ${({ theme }) => rgba(theme.black, .4)};
                    height: 22px;
                    width: 22px;
                }
            }
        }
    }
`;

const progressStyle = {
    position: 'absolute',
    top: '80px',
    left: '15px'
};

const FeedItemCreator = ({
    intl,
    id = '',
    replyComment = {},
    onReplyFeedItem = () => {},
    fileUploadingProgression = 0,
    setFeedItemFiles
}) => {
    const isFileUploading = fileUploadingProgression > 0 && fileUploadingProgression < 100;

    return (
        <>
            {isFileUploading && (
                <ProgressBar
                    progress={fileUploadingProgression}
                    placeholder={intl.formatMessage({
                        id: 'feed.upload.file.progress',
                        defaultMessage: 'Chargement du fichier en cours {progression}'
                    }, {
                        progression: `(${fileUploadingProgression}%)`
                    })}
                    style={progressStyle}
                />
            )}

            <Styled className="styled-action-area">
                <TypingUsers />
                {id && (
                    <UploadButton
                        isDisabled={isFileUploading}
                        onAddFiles={setFeedItemFiles}
                    />
                )}
                <FeedItemInput
                    key={id}
                    replyComment={replyComment}
                    onReplyFeedItem={onReplyFeedItem}
                    isDisabled={isFileUploading}
                    _feed={id}
                />
            </Styled>
        </>
    );
}

FeedItemCreator.propTypes = {
    id: PropTypes.string,
    replyComment: PropTypes.object,
    onReplyFeedItem: PropTypes.func,
    intl: PropTypes.any.isRequired,
    fileUploadingProgression: PropTypes.number,
    setFeedItemFiles: PropTypes.func.isRequired
};

export default injectIntl(FeedItemCreator);
