import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect , useDispatch } from 'react-redux';
import { $requestLogout } from 'api/session';
import { deleteToken } from 'services/token';
import { Auth } from 'services/authentication';
import Logger from 'services/debug/logger';
import sessionStorage from 'services/storage/sessionStorage';

import FullscreenLoader from 'components/Shared/Layout/FullscreenLoader';
import { getCompanyLanguageSelector } from 'selectors/company';
import { $getCompanyWhois } from 'api/company';
import { getUrl, getQueryStringParameter, compareUrl } from 'utils/url';
import Firebase from 'utils/firebase';
import { utils, actions } from '@amplement/backend-connector';

const logger = new Logger('component:logout');

const redirectTo404 = (e) => {
    utils.errorHandler.captureException(e, 'component:logout');
    document.location.href = getUrl('404');
};

export const redirectToHome = (language, returnUrl) => {
    const extraParams = returnUrl ? { lang: language, redirect_url: returnUrl } : {};
    const url = getUrl('whitelabelHome', { lang: language, ...extraParams });
    logger.log(`redirect from ${document.location.pathname} to ${url}`, language);
    if (!compareUrl(url, document.location.pathname)) {
        document.location.href = `${url}`;
    }
    return null;
};

export const cleanSession = () => {
    logger.log('remove token and _client');
    Auth.signout();
    Firebase.setUserId(null);
    deleteToken();
    sessionStorage.remove('_client');
};

const requestWhois = (language) => {
    logger.log('request Whois', language);
    return language
        ? Promise.resolve(language)
        : $getCompanyWhois().then(({ data }) => data.language);
};

const requestLogout = () => {
    logger.log('request logout', Auth.getAuth());
    return Auth.getAuth() ? $requestLogout() : Promise.resolve();
};

const Logout = ({ language, ...props } = {}) => {
    const returnUrl = getQueryStringParameter('returnUrl')(props?.location?.search);
    const dispatch = useDispatch();

    useEffect(() => {
        Promise.all([requestLogout().catch(e => e), requestWhois(language)])
            .then(([logout, whois]) => redirectToHome(whois, returnUrl)) // eslint-disable-line
            .catch(redirectTo404)
            .then(cleanSession)
            .then(() =>(
                dispatch(actions.session.updateToken(''))
            ));
        // cleanSession();
        return () => {};
    }, []);
    logger.log('render', language);
    return <FullscreenLoader />;
};

Logout.propTypes = {
    language: PropTypes.string
};

const mapStateToProps = state => ({
    language: getCompanyLanguageSelector(state)
});

export default connect(mapStateToProps)(Logout);
