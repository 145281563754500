import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import CallButton from 'components/Room/RecallButton';

export interface CallButtonThemeProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    theme?: {
        mainColor2?: string;
    };
    children?: React.ReactNode;
    [key: string]: unknown;
}
const StyledCallButton = styled(CallButton)<CallButtonThemeProps>`
    flex-direction: row;
    align-items: center;
    height: 32px;
    &.btn {
        border-radius: 30px;
        border: 1px solid ${props => props.theme?.mainColor2};
        color: ${props => props.theme?.mainColor2};
        &:hover {
            background-color: ${props => props.theme?.mainColor2 ? rgba(props.theme.mainColor2, 0.05) : 'transparent'};
        }
        .icon {
            margin-right: 5px;
            svg {
                font-size: 14px;
            }
        }
    }
`;

export default StyledCallButton;
