import React, { memo, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import Action from 'components/Shared/Common/Action';
import { Modal } from 'antd';
import DeviceSettings from 'components/Room/Settings/Devices';

import styled from 'styled-components';

const StyledModal = styled(Modal)`
    max-width: 500px;
    width: 100%;
    .ant-modal{
        &-body{
            >.content{
                margin-bottom: 20px;
            }
        }
        &-header{
            border: none;
        }
        &-content{
            flex: initial;
        }
    }
`;

const ManageRoomSettings = memo((props) => {
    const {
        intl,
        onSubmit,
        onClose
    } = props;
    const [isModalOpened, setIsModalOpened] = useState();

    const handleToggleModal = useCallback(() => {
        setIsModalOpened(!isModalOpened);
        onClose();
    }, [onClose, setIsModalOpened, isModalOpened]);

    const handleCloseModal = useCallback(() => setIsModalOpened(false), [setIsModalOpened]);

    const handleSubmit = useCallback((settings) => onSubmit(settings), [onSubmit]);

    const i18n = useMemo(() => ({
        title: intl.formatMessage({ id: 'room.settings' }),
        manageSettings: intl.formatMessage({ id: 'room.actions.manageOwnSettings' })
    }), [intl]);

    useWhyDidYouUpdate('components:room:Footer:MoreButton:ManageRoomSettings', props);

    return (
        <>
            {isModalOpened && (
                <StyledModal
                    title={i18n.title}
                    open={isModalOpened}
                    closable
                    centered
                    onCancel={handleCloseModal}
                    destroyOnClose
                    maskClosable
                    footer={null}
                >
                    <DeviceSettings
                        onSubmit={handleSubmit}
                        onClose={handleCloseModal}
                        // reset={handleReset}
                    />
                </StyledModal>
            )}
            <Action
                className="line-item"
                onClick={handleToggleModal}
            >
                <div className="line">
                    <div className="icon-hd">
                        HD
                    </div>
                </div>
                <div className="line-wrapper">
                    <div className="line column">
                        <div className="label">{i18n.manageSettings}</div>
                    </div>
                </div>
            </Action>
        </>
    );
})

ManageRoomSettings.propTypes = {
    intl: PropTypes.any.isRequired,
    onSubmit: PropTypes.any.isRequired
};

export default injectIntl(ManageRoomSettings);
