import React from 'react';
import useTimeout from 'components/Room/useTimeout';

type Props = {
    timeout: number;
    label: string;
    isVisible?: boolean;
};

const ArrowFolding = ({ timeout, label, isVisible = true }: Props) => {
    const isTimeout = useTimeout(timeout);

    return !isTimeout && isVisible ? (
        <div className="arrow-folding tooltip">
            {label}
            <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.28 126.07">
                    <path
                        d="M256.21,739.29a1.56,1.56,0,0,1,1.77,1.47,9,9,0,0,1-.43,1.85c-0.47,1.19-1,2.35-1.56,3.5-3.82,7.87-5.56,16.32-6.72,24.88a52.23,52.23,0,0,0,1.2,19c0.77,3.26,1.69,6.5,2.76,9.68,1.85,5.51,5,10.39,8.32,15a84.58,84.58,0,0,0,15.77,16,125,125,0,0,0,20.12,13,158.76,158.76,0,0,0,19.17,8.76,25.59,25.59,0,0,1,3,1.44,1.45,1.45,0,0,1,.29,1.74,2.32,2.32,0,0,1-1.07.82,2.67,2.67,0,0,1-1.4,0,21.38,21.38,0,0,1-2.73-.9c-7.81-3.27-15.62-6.56-22.93-10.87-3.44-2-6.89-4.06-10.2-6.3a102.8,102.8,0,0,1-8.4-6.37,85.85,85.85,0,0,1-15.15-16,66.72,66.72,0,0,1-11-25.3,118.11,118.11,0,0,1-1.69-12.82c-0.32-3.39.42-6.7,0.87-10a83.25,83.25,0,0,1,7.86-26,11.72,11.72,0,0,1,1.2-2A2.56,2.56,0,0,1,256.21,739.29Z"
                        transform="translate(-245.2 -739.28)"
                    />
                    <path
                        d="M317.29,865.36c-2.23-.19-4.45-0.39-6.68-0.57-2.07-.17-4.14-0.3-6.21-0.47-8.35-.69-16.45-2.32-23.86-6.47a18.87,18.87,0,0,1-2-1.32,1.59,1.59,0,0,1,1.32-2.64,5.68,5.68,0,0,1,1.79.62c7.12,4,14.9,5.64,22.93,6.31,7,0.58,14,1.21,21.05.29a7.83,7.83,0,0,0,1.84-.46,0.86,0.86,0,0,0,.38-0.75,16.63,16.63,0,0,0-1.19-2c-6.49-9.87-13.45-19.44-19-29.94a20.62,20.62,0,0,1-2.48-6.7,5.16,5.16,0,0,1,0-1.41,1.39,1.39,0,0,1,1.29-1.22,1.9,1.9,0,0,1,1.26.46,2.39,2.39,0,0,1,.74,1.19c1.12,4.59,3.67,8.49,6,12.47,3.24,5.51,6.75,10.84,10.44,16.08,2.39,3.39,4.53,7,7,10.28a15.85,15.85,0,0,1,1.4,2.49,1.6,1.6,0,0,1-.89,2,5.19,5.19,0,0,1-1.35.47C326.52,864.41,322,865.36,317.29,865.36Z"
                        transform="translate(-245.2 -739.28)"
                    />
                </svg>
            </div>
        </div>
    ) : null;
};

export default ArrowFolding;
