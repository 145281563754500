import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { isNativeScreenSharingSupported } from 'services/screenSharing';

import Button from 'components/Shared/Common/Button';
import DeviceManager from 'components/Room/Helpers/DeviceManager';

import AudioStrengthVisualizer from 'components/Shared/Common/AudioStrengthVisualizer';
import SoundMeter, { THRESHOLD_TYPES } from 'components/Shared/Call/SoundMeterStrength';
import MainPlayer from 'components/Room/MainPlayer';
import { Table, Tag } from 'antd';
import capitalize from 'lodash/capitalize';

import { isGumSupported, isWebRtcCompatible } from 'utils/webRTC';
import { isMobile } from "react-device-detect";
import { constants } from '@amplement/backend-connector';

// -----------------------------------------------------
const AudioStrength = memo(({ strength, error }) => {
    if (error) {
        return <div className="text-error">{error}</div>;
    }

    return <AudioStrengthVisualizer strength={strength} />;
});

const useMicrophoneTest = () => {
    const [stream, setStream] = useState();
    const [error, setError] = useState();

    const handleClick = () => setStream(new Date());

    useEffect(() => {
        if (stream instanceof Date) {
            DeviceManager.getStream({ audio: true }).then(setStream)
                .catch((e) => setError(e.message));
        }
        return () => { }
    }, [stream]);



    return {
        label: 'Micro',
        value: error || (stream && !(stream instanceof Date)? (
            <SoundMeter
                track={stream.getAudioTracks()[0]}
                Component={AudioStrength}
                precision={5}
                thresholdType={THRESHOLD_TYPES.NUMERIC}
            />
        ) : '-'),
        action: (
            <Button
                onClick={handleClick}
                color="secondary"
                size="md"
            >
                Test
            </Button>
        )
    };
};

const useCameraTest = (type, constraints) => {
    const [stream, setStream] = useState();
    const [error, setError] = useState();

    const handleClick = useCallback(() => setStream(new Date()), []);

    useEffect(() => {
        if (stream instanceof Date) {
            DeviceManager.getStream(constraints).then(setStream)
                .catch((e) => setError(e.message));
        }
        return () => { }
    }, [stream]);

    return {
        label: [capitalize(type)],
        value: error || (stream && !(stream instanceof Date) ? (
            <MainPlayer
                isVideoOn
                forceMute
                videoStream={stream}
                className="diag preview"
                renderDefault={undefined}
            />
        ) : '-'),
        action: (
            <Button
                onClick={handleClick}
                color="secondary"
                size="md"
            >
                Test
            </Button>
        )
    };
};

const useScreenTest = () => {
    const [stream, setStream] = useState();
    const [error, setError] = useState();

    const handleClick = useCallback(() => setStream(new Date()), []);

    useEffect(() => {
        if (stream instanceof Date) {
            const constraints = { screen: true };
            DeviceManager.getStream(constraints).then(setStream)
                .catch((e) => setError(e.message));
        }
        return () => { };
    }, [stream]);

    return {
        label: 'Screen',
        value: error || (stream && !(stream instanceof Date) ? (
            <MainPlayer
                isVideoOn
                forceMute
                videoStream={stream}
                className="diag preview"
                renderDefault={undefined}
            />
        ) : '-'),
        action: (
            <Button
                onClick={handleClick}
                color="secondary"
                size="md"
            >
                Test
            </Button>
        )
    };
};

const usePermissions = () => {
    const [cameraPermission, setCameraPermission] = useState();
    const [microphonePermission, setMicrophonePermission] = useState();

    const handleCameraPermission = useCallback(() => setCameraPermission(null), []);
    const handleMicrophonePermission = useCallback(() => setMicrophonePermission(null), []);

    useEffect(() => {
        if (navigator && navigator.permissions && navigator.permissions.query) {
            const handleError = onError => (e) => {
                let error = 'Error';

                if (
                    (e instanceof TypeError && e.message.indexOf('is not a valid value for enumeration PermissionName') !== -1)
                    || (e instanceof Error && e.name === 'ResourceError') // cannot instanceof ResourceError, i don't understand why
                ) {
                    error = 'Not supported';
                }
                onError(error);
            }
            DeviceManager.getPermissionStatus('camera')
                .then((status) => setCameraPermission(status.state))
                .catch(handleError(setCameraPermission));

            DeviceManager.getPermissionStatus('microphone')
                .then((status) => setMicrophonePermission(status.state))
                .catch(handleError(setMicrophonePermission));
        }

        return () => { };
    }, [cameraPermission, microphonePermission]);

    const GUM = isGumSupported() ? 'yes' : 'no';
    const GDM = isNativeScreenSharingSupported ? 'yes' : 'no';
    const ED = navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices ? 'yes' : 'no';
    const PQ = navigator && navigator.permissions && navigator.permissions.query ? 'yes' : 'no';

    return [
        {
            label: 'GUM / GDM / ED / PQ',
            value: `${GUM} / ${GDM} / ${ED} / ${PQ}`
        },
        {
            label: 'Camera permission',
            value: cameraPermission,
            action: 'granted|prompt|denied'.indexOf(cameraPermission) !== -1 && (
                <Button
                    onClick={handleCameraPermission}
                    color="secondary"
                    size="md"
                >
                    Retry
                </Button>
            )
        },
        {
            label: 'Microphone permission',
            value: microphonePermission,
            action: 'granted|prompt|denied'.indexOf(microphonePermission) !== -1 && (
                <Button
                    onClick={handleMicrophonePermission}
                    color="secondary"
                    size="md"
                >
                    Retry
                </Button>
            )
        }
    ];
};

const useNetwork = ({
    isInternetReachable,
    isBackendReachable,
    backendConnectivityStatus,
    backendConnectivityError,
    quality,
    wsSocketId,
    // bandwidth
}) => {
    let readyStateTxt;
    switch (backendConnectivityStatus) {
        case constants.network.READY_STATE.OPENING: // Primus.OPENING:
            readyStateTxt = 'opening';
            break;
        case constants.network.READY_STATE.OPEN: // Primus.OPEN:
            readyStateTxt = 'open';
            break;
        case constants.network.READY_STATE.CLOSED: // Primus.CLOSED:
        default:
            readyStateTxt = 'closed';
            break;
    }
    
    return [
        {
            label: 'Connection (back/www)',
            value: `${isBackendReachable ? 'yes' : 'no'}/${isInternetReachable ? 'yes' : 'no'}`
        },
        {
            label: 'Backend status (WS id)',
            value: `${wsSocketId} (${backendConnectivityError || readyStateTxt})`
        },
        {
            label: 'Quality',
            value: `${quality}/4`
        }
    ];

};

const Diagnostic = memo(({
    user = {},
    isInternetReachable,
    isBackendReachable,
    backendConnectivityStatus,
    backendConnectivityError,
    quality,
    wsSocketId,
    bandwidth,
}) => {
    const [browserName, setBrowserName] = useState();
    const microphoneTest = useMicrophoneTest();
    const cameraTest = useCameraTest('video', { video: true });
    const screenTest = useScreenTest();
    const permissions = usePermissions();
    const network = useNetwork({
        isInternetReachable,
        isBackendReachable,
        backendConnectivityStatus,
        backendConnectivityError,
        quality,
        wsSocketId,
        bandwidth,
    });
    const { firstname, lastname } = user || {};
    let navigatorBandwidth = [];

    useEffect(() => {
        const getBrowser = () => {
            const ua= navigator.userAgent; let tem;
            let M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if(/trident/i.test(M[1])){
                tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
                return `IE ${tem[1] || ''}`;
            }
            if(M[1]=== 'Chrome'){
                tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
                if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
            M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
            if ((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
            return M.join(' ');
        };

        setBrowserName(getBrowser());

        return () => { };
    }, []);

    if (navigator && navigator.connection && navigator.connection.downlink) {
        const { downlink, effectiveType, rtt: navigatorRtt } = navigator.connection;
        navigatorBandwidth = [{label: 'Estimate bandwith', value: `${downlink}Mbps | ${effectiveType || 'N/A'} | ${navigatorRtt !== undefined ? navigatorRtt :  'N/A'}ms` }];
    }

    const columns = [
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label'
        },
        {
            title: `Value (updated at ${(new Date()).toISOString()})`,
            dataIndex: 'value',
            key: 'value'
        },

        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action'
        },
    ];

    const data = [
        {
            label: 'User',
            value: `${firstname} ${lastname}`
        },
        {
            label: 'Browser version / isMobile',
            value: `${browserName} / ${isMobile ? 'yes' : 'no'}`
        },
        {
            label: 'User agent',
            value: window.navigator && window.navigator.userAgent
        },
        ...network,
        ...navigatorBandwidth,
        {
            label: 'Webrtc compatible',
            value: isWebRtcCompatible() ? 'yes' : 'no'
        },
        ...permissions,
        microphoneTest,
        cameraTest,
        screenTest
    ];

    return (
        <div>
            <Table
                rowKey="label"
                columns={columns}
                dataSource={data}
                className="debug-infos-global"
                pagination={{
                    total: data.length,
                    pageSize: data.length,
                    hideOnSinglePage: true
                }}
            />
        </div>
    );
});

Diagnostic.propTypes = {
    user: PropTypes.object,
    isInternetReachable: PropTypes.bool,
    isBackendReachable: PropTypes.bool,
    backendConnectivityStatus: PropTypes.oneOf(Object.values(constants.network.READY_STATE)),
    backendConnectivityError: PropTypes.string,
    quality: PropTypes.number,
    wsSocketId: PropTypes.string,
    bandwidth: PropTypes.object
};

const EnumerateDevices = memo(() => {
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deviceError, setDeviceError] = useState();

    const refreshList = useCallback(() => {
        setLoading(true);
        DeviceManager.enumerateDevices().then((list) => {
            setDevices(list);
            setLoading(false);
        }).catch((e) => setDeviceError(e.message))
    }, []);

    useEffect(refreshList, []);

    const columns = [
        {
            title: 'Kind',
            key: 'kind',
            dataIndex: 'kind',
            render: tag => {
                let color;
                if (tag === 'audioinput') {
                    color = 'volcano';
                }
                if (tag === 'audiooutput') {
                    color = 'geekblue';
                }
                if (tag === 'videoinput') {
                    color = 'green';
                }
                return <Tag color={color} key={tag}>{tag}</Tag>;
            }
        },
        {
            title: 'Devices label',
            dataIndex: 'label',
            key: 'label'
        },
        {
            title: 'DeviceId',
            dataIndex: 'deviceId',
            key: 'deviceId',
            render: text => <span>{text && text.substr(0, 7)}</span>
        },

        {
            title: 'GroupId',
            dataIndex: 'groupId',
            key: 'groupId',
            render: text => <span>{text && text.substr(0, 7)}</span>
        },
    ];

    const data = devices.map(x => ({ key: `${x.deviceId}-${x.kind}`, deviceId: x.deviceId, label: x.label, groupId: x.groupId, kind: x.kind }));

    return (
        <div>
            <Button 
                style={{ float: 'right' }} 
                color="secondary" 
                size="xs" 
                onClick={refreshList} 
                isDisabled={loading}
            >
                Refresh device list
            </Button>
            {deviceError && <div className="text-error">{deviceError}</div>}
            {loading ? 'loading...' : (
                <Table
                    columns={columns}
                    dataSource={data}
                    className="debug-infos-devices"
                    pagination={{
                        total: data.length,
                        pageSize: data.length,
                        hideOnSinglePage: true
                    }}
                />
            )}
        </div>
    );
});

const WebrtcTest = (props) => (
    <div style={{overflow: 'auto', margin: 'auto', height: '100%' }}>
        <Diagnostic {...props} />
        <br />
        <EnumerateDevices />
    </div>
);

export default WebrtcTest;
