import { callbacks } from '@amplement/backend-connector';
import { setMediaBitrate, getPcStats, getWebrtcSupport } from 'utils/webRTC';
import Firebase from 'utils/firebase';
import DeviceCallManager from 'utils/callManager';
import storage from 'services/storage/localStorage';
import soundManager from 'services/soundManager';
import { findBestAvailableLanguage } from 'utils/lang';
import { getFileSize } from 'utils/file';
import uuid from 'utils/state/uuid';
import { setLang } from 'actions/session';
import config from 'config';
import * as Sentry from '@sentry/browser';
import { isCallUrl } from './url';

export const initiator = () => {
    callbacks.set('MediaStream', MediaStream);
    callbacks.set('analyticsLogEvent', Firebase.logAnalyticsEvent);
    callbacks.set('asyncStorageGetItem', storage.get);
    callbacks.set('asyncStorageSetItem', storage.setItem);
    callbacks.set('findBestAvailableLanguage', findBestAvailableLanguage);
    callbacks.set('getTimeZone', () => Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone);
    // callbacks.set('firebasePerf', firebasePerf.perf);
    callbacks.set('requestPermissions', () => Promise.resolve());  // audio/ video
    callbacks.set('getSource', () => 'web');
    callbacks.set('getBundleId', () => {
        if (config.isLocalEnv) {
            return storage.get('getBundleId') || 'web';
        }
        return 'web';
    });
    callbacks.set('getVersion', () => process.env.PKG_VERSION);
    callbacks.set('getHDSupport', () => true);
    // callbacks.set('i18nForceRTL', I18nManager.forceRTL, true);
    callbacks.set('setMediaBitrate', setMediaBitrate, true);
    callbacks.set('getPCStats', getPcStats);
    callbacks.set('sentryWithScope', Sentry.withScope, true);
    callbacks.set('sentryCaptureException', Sentry.captureException, true);
    callbacks.set('sentryCaptureMessage', Sentry.captureMessage, true);
    callbacks.set('sentryInit', (args = {}) => {
        const integrations = [];
        const isCall = isCallUrl();

        if (isCall && config.sentry?.tracesSampleRate > 0.0) {
            integrations.push(new Sentry.BrowserTracing());
        }
        if (isCall && config.sentry?.replaysSessionSampleRate > 0.0 || config.sentry?.replaysOnErrorSampleRate > 0.0) {
            integrations.push(new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: false,
                blockAllMedia: true,
                useCompression: false // to fix bug in progress on sentry GH  : https://github.com/getsentry/sentry-javascript/issues/6923
            }));
        }
        
        Sentry.init({
            ...args,
            dsn: 'https://28778f852cfd4c76869886dc443de97f@sentry.io/1340806',
            release: `${process.env.PKG_NAME}@${process.env.PKG_VERSION}`,
            captureUnhandledRejections: true,
            autoSessionTracking: false,
            beforeSend: async (e, hint) => {
                const event = args.beforeSend ? args.beforeSend(e, hint) : e;

                if (event.extra?.room 
                    || event.message?.name === 'OverconstrainedError' 
                    || event.message?.name === 'rtc.error.noDeviceAvailable' 
                    || event.extra?.audio || event.extra?.video || event.extra?.screen) {
                    event.extra.webrtc = await getWebrtcSupport();
                }

                event.extra.isConnected = navigator.onLine;

                return event;
            },

            tracesSampleRate: isCall ? config.sentry?.tracesSampleRate : undefined,

            // This sets the sample rate to be 10%. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: isCall &&  !!config.sentry?.replaysSessionSampleRate ? config.sentry?.replaysSessionSampleRate : false,
            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: isCall && !!config.sentry?.replaysOnErrorSampleRate ? config.sentry?.replaysOnErrorSampleRate : false,

            integrations
        })
    }, true);
    callbacks.set('sentrySetUser', Sentry.setUser, true);
    callbacks.set('sentrySetExtra', Sentry.setExtra, true);
    callbacks.set('sentrySetTag', Sentry.setTag, true);
    callbacks.set('sentrySetUser', Sentry.setUser, true);
    callbacks.set('sentryUserDialog', Sentry.showReportDialog, true);

    // const setLang = lang => ({
    //     type: 'SET_LANG',
    //     lang,
    // });

    callbacks.set('setLang', setLang, true);

    callbacks.set('getFilename', async file => file?.name);
    callbacks.set('getFileSize',  getFileSize);

    callbacks.set('getAppState', () => 'active');

    callbacks.set('DeviceCallManagerStart', DeviceCallManager.start, true); // rentre dans la room  // stopRingtone
    callbacks.set('DeviceCallManagerStop', DeviceCallManager.stop, true); // part de la room // stopRingtone
    callbacks.set('DeviceCallManagerStopRingback', DeviceCallManager.stopRingback, true);
    callbacks.set('DeviceCallManagerStartRingtone', DeviceCallManager.startRingtone, true); // stopringback
    callbacks.set('DeviceCallManagerStopRingtone', DeviceCallManager.stopRingtone, true); // stopringback

    // config lang
    callbacks.set('i18n/fr', () => require('../i18n/fr.json'), true);
    callbacks.set('i18n/es', () => require('../i18n/es.json'), true);
    callbacks.set('i18n/en', () => require('../i18n/en.json'), true);
    callbacks.set('i18n/ru', () => require('../i18n/ru.json'), true);
    callbacks.set('i18n/de', () => require('../i18n/de.json'), true);
    callbacks.set('i18n/vi', () => require('../i18n/vi.json'), true);
    callbacks.set('i18n/th', () => require('../i18n/th.json'), true);

    // Topology
    // callbacks.set('getTopology', () => 'MESH');
    // callbacks.set('getTopology', () => 'SFU');

    // callbacks.set('ActionWhenMemberJoined', () => soundManager.play('ding'));
    callbacks.set('ActionWhenMemberLeft', () => soundManager.play('pop'));

    callbacks.set('getDeviceId', () => {
        let deviceId = storage.get('deviceId');

        if (!deviceId) {
            deviceId = uuid();
            storage.set('deviceId', deviceId);
        }

        return deviceId;
    });
};
