import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './src';

import { Provider } from 'react-redux';
import { store } from 'store';
import config from 'config';
import {
    callbacks,
    utils
} from '@amplement/backend-connector';
import * as Sentry from '@sentry/browser';

const { env, initialEnv } = config;

if (typeof window === 'object') {
    window.mycDebug = (enable) => {
        if (enable) {
            window.myc = utils.debug.getMycDebug();
        } else {
            delete window.myc;
        }
        return window?.myc;
    };
}

// sentry from BC
utils.errorHandler.init({
    sentryEnabled: config.sentryEnabled,
    env: env || initialEnv,
    sentry: Sentry
});
utils.logger.loggerConfig.setConfig({
    debug: config.debug,
    level: config.logLevel,
    filter: config.logFilter,
    preferConsole: true
});

callbacks.set('dispatch', store.dispatch, true);
callbacks.set('getState', store.getState, true);

const app = document.querySelector('#app');
const root = createRoot(app);

const render = Component => {
    root.render(
        <Provider store={store}>
            <Component />
        </Provider>
    );
}

render(App);

if(module.hot) {
    module.hot.accept('./src', () => {
        setTimeout(() => {
            const RootAppComponent = require("./src/index").default;
            
            render(RootAppComponent);
            console.log('[HMR] Huss huss, components are up to date');
        });
    }, (e) => console.warn('[HMR] components failed', e));
}
