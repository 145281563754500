import React, { memo, useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Badge, Typography } from 'antd';
import styled from 'styled-components';

import { actions, selectors, callbacks, utils } from '@amplement/backend-connector';

import Button from 'components/Shared/Common/Button';
import Dropdown from 'components/Shared/Common/Dropdown';
import RoomSelectorModal from 'components/Room/AntdModal/RoomSelectorModal/container';
import ContactSelectorModal from 'components/Room/AntdModal/ContactSelectorModal';
import KeyboardModal from 'components/Room/AntdModal/KeyboardModal';
import Spinner from 'components/Shared/Common/Spinner';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import useBookmarks from 'hooks/useBookmark';
import useRooms from 'hooks/useRooms';
import groupBy from 'lodash/groupBy';

import { PRESENCE_STATUSES, getStatus } from 'const/user';
import usePhonenumber from 'hooks/usePhonenumber';

const { Text } = Typography;

const StyledBadge = styled(Badge)`
    margin-right: 5px;
`;

const Status = ({ user }) => {
    const status = getStatus(user?.status ?? PRESENCE_STATUSES.OFFLINE);

    return <StyledBadge color={status.color} />;
};

const callTransferSelectorOptions = ({
    transferToPhonenumber,
    transferToRoom,
    transferToSipUser,
    transferableRooms,
    onTransferPending,
    intl,
    onSelect,
    onDelete,
    bookmarks = [],
    isLoading,
}) => {
    const handleClickRoom = () => onSelect(RoomSelectorModal);
    const handleClickContacts = () => onSelect(ContactSelectorModal);
    const handleClickKeyboard = () => onSelect(KeyboardModal);

    let items = [];

    if (isLoading) {
        items.push({
            key: 'loading',
            label: <Spinner />,
            disabled: true
        });
    }

    items.push({
        label: intl.formatMessage({ id: 'global.button.forwardTo' }),
        key: 'forwardTo',
        disabled: true
    });

    items.push({ type: 'divider' });

    const mergedBookmarks = groupBy(bookmarks, `data.label`);
    const mergedBookmarkKeys = Object.keys(mergedBookmarks);

    if (mergedBookmarkKeys.length) {
        mergedBookmarkKeys.forEach((key) => {
            const bookmarksByName = mergedBookmarks[key];
            const firstBookmarkWithEntity = bookmarksByName.find(bmk => !!bmk?._entity) || {};

            const item = {
                key,
                label: (
                    <Text>
                        <Status {...firstBookmarkWithEntity} />
                        {key}
                    </Text>
                ),
                onClick: (a) => onTransferPending({ destNumber: a.key }),
                iconName: 'Star'
            };

            if (bookmarksByName.length === 1 && bookmarksByName[0]?.data?.phoneNumber) {
                item.key = bookmarksByName[0].data.phoneNumber;
                item.onClick = () => onTransferPending({ destNumber: bookmarksByName[0].data.phoneNumber });
            } else {
                item.submenuItems = bookmarksByName.map(bmk => ({
                    key: bmk?.data.phoneNumber || '',
                    label: bmk?.data.phoneNumber || ''
                }));
            }

            items.push(item);
        });

        items.push({ type: 'divider' });
    }

    const handleClickUserItem = _room => () => onTransferPending({ _destRoom: _room });

    if (transferableRooms?.length){
        items = [...items, ...transferableRooms.map(room => ({
            key: room.id,
            label: room.name,
            onClick: handleClickUserItem(room.id),
            iconName: 'Phone'
        }))];
    }

    if (transferToRoom){
        items = [...items, {
            key: 'roomsCall',
            label: intl.formatMessage({ id: 'room.button.transfer.call' }),
            onClick: handleClickRoom,
            iconName: 'Phone'
        }];
    }

    if (transferToSipUser){
        items = [...items, {
            key: 'roomsContacts',
            label: intl.formatMessage({ id: 'room.button.transfer.contacts' }),
            onClick: handleClickContacts,
            iconName: 'Users'
        }];
    }

    if (transferToPhonenumber){
        items = [...items, {
            key: 'pad',
            label: intl.formatMessage({ id: 'room.button.transfer.number' }),
            onClick: handleClickKeyboard,
            iconName: 'Pad'
        }];
    }

    items.push({ type: 'divider' });

    items = [...items, {
        key: 'phoneOff',
        danger: true,
        label: intl.formatMessage({ id: 'call.button.endCall' }),
        onClick: onDelete,
        iconName: 'PhoneOff'
    }];

    return items;
}

const CallTransfer = (props) => {
    const {
        onCallTransfer,
        onDelete,
        _room,
        _liveRooms = [],
        intl,
        _onlineClients,
        isLeftMenu = false,
        transferToPhonenumber = false,
        transferToUser = false,
        transferToRoom = false,
        transferToSipUser = false,
        ...btnProps
    } = props;

    const [isHovered, setIsHovered] = useState();
    const [SelectedComponent, setSelectedComponent] = useState();
    const [isOpen, setIsOpen] = useState();
    const { data: bookmarks } = useBookmarks({ type: 'phoneNumber' });
    const { data, isLoading } = useRooms({
        _room,
        enabled: !!isHovered,
        transfer: true
    });
    const transferableRooms = data?.filter(room => room?.id !== _room);
    const instantRooms = transferableRooms?.filter(room => _liveRooms.includes(room?.id));
    const handleSelect = useCallback((Compo) => {
        setSelectedComponent(Compo);
        setIsOpen(true);
    }, [setIsOpen, setSelectedComponent]);
    const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
    const handleDelete = useCallback(() => onDelete(_room), [_room, onDelete]);
    const { format } = usePhonenumber({ prefix: true });
    const handleSubmit = useCallback((values) => {
        const {
            destNumber,
            _destUser,
            _destRoom,
            phones,
            id
        } = values || {};
        let finalNumber = destNumber;
        let _finalUser = _destUser;

        // specific case for sip user search
        if (phones) { 
            // in cast of sip user
            const phone = phones ? phones?.[0]?.callNumber : id;
            
            // in some case (Fjord for exemple, phonebook is hacked with contact instead of phonebook, target should be user instead of number)
            if (utils.uuid.validate(phone))
            {
                _finalUser = phone;
            } else {
                finalNumber = phone;
            } 
        }

        if (finalNumber) {
            finalNumber = format(finalNumber);
        }

        onCallTransfer(_destRoom, finalNumber, _finalUser);
        handleClose();
    }, [onCallTransfer, format]);
    const dispatch = useDispatch();
    const isTransferable = transferableRooms?.length || transferToRoom || transferToSipUser || transferToPhonenumber || transferToUser;

    useWhyDidYouUpdate('component:CallTransfer', props);

    useEffect(() => {
        callbacks.set('onForwardRoomFailed', ({ _room: roomID }) => {
            if (_room === roomID) {
                dispatch(actions.notification.addNotification({
                    severity: 'error',
                    referenceError: 'CF1',
                    title: 'error.default',
                    duration: 10
                }), true)
            }
        });
    }, [dispatch, _room]);

    const menuSip = callTransferSelectorOptions({
        transferToPhonenumber,
        transferToUser,
        transferToRoom: transferableRooms?.length ? transferToRoom : null,
        transferToSipUser,
        onSelect: handleSelect,
        onTransferPending: handleSubmit,
        transferableRooms: instantRooms,
        onDelete: handleDelete,
        isLoading,
        bookmarks,
        intl,
    });

    const transferDropdownRender = useMemo(() => {
        if (!isTransferable || !menuSip?.length || _onlineClients?.length < 1) {
            if (isLeftMenu) return null;

            return (
                <Button
                    isDisabled
                    iconName="PhoneForwarded"
                    color="light-transparency"
                    {...btnProps}
                />
            );
        }

        return (
            <Dropdown
                items={menuSip}
                placement="top"
                trigger={isTransferable && ['hover']}
                onOpenChange={() => setIsHovered(true)}
            >
                <div>
                    <Button
                        iconName="PhoneForwarded"
                        color="light-transparency"
                        {...btnProps}
                    />
                </div>
            </Dropdown>
        );
    }, [isTransferable, menuSip, isLeftMenu, btnProps, _onlineClients]);

    return (
        <>
            {transferDropdownRender}
            {SelectedComponent && (
                <SelectedComponent
                    _room={_room}
                    onSubmit={handleSubmit}
                    onClose={handleClose}
                    isOpen={isOpen}
                    isAutoScroll
                />
            )}
        </>
    );
}

CallTransfer.propTypes = {
    onCallTransfer: PropTypes.func.isRequired,
    _room: PropTypes.string.isRequired,
    isLeftMenu: PropTypes.bool,
    transferToPhonenumber: PropTypes.bool,
    transferToUser: PropTypes.bool,
    transferToRoom: PropTypes.bool,
    transferToSipUser: PropTypes.bool,
    _liveRooms: PropTypes.array,
}

const Component = memo(injectIntl(CallTransfer));

export default Component;

const mapStateToProps = (state) => ({
    _liveRooms: selectors.rooms.getCurrentByUserRoomsSelector(state),
});

const mapDispatchToProps = (dispatch, { _room }) => ({
    onCallTransfer: (_destRoom, destNumber, _destUser) =>
        dispatch(actions.rooms.forwardRoom(_room, _destRoom, destNumber, _destUser)),
    onDelete: () => dispatch(actions.rooms.requestDeleteRoom(_room))

});

export const container = connect(mapStateToProps, mapDispatchToProps)(Component);
