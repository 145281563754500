import React from 'react';
import PropTypes from 'prop-types';
import CatchError from 'components/CatchError';
import { injectIntl } from 'react-intl';
import ErrorFormatter from 'components/Shared/Errors/Formatter';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import ContentErrorComponent from 'components/Shared/Errors/ContentError';
import { StyledDashboardItems } from 'components/Shared/style';
import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import Icon from 'components/Shared/Common/Icon';

const errorComponent = (
    <ErrorFormatter
        id="error.default"
        buttonType={BUTTON_TYPE.RELOAD}
        render={ContentErrorComponent}
    />
);

const DashboardBlock = ({
    title,
    content,
    itemsLength,
    isLoading,
    placeholder,
    intl,
    quickAction,
    iconName
}) => (
    <CatchError fallbackRender={errorComponent}>
        <StyledDashboardItems className="ui-dashboard-items">
            <CategoryTitle
                label={title}
                quickAction={quickAction}
                icon={<Icon size="xl" iconName={iconName} />}
            />
            <div className="dashboard-items-content">
                {isLoading && !itemsLength && (
                    <div>
                        {intl.formatMessage({
                            id: 'drawer.editGroup.multiSelect.loadingMessageText',
                            defaultMessage: 'Chargement ...'
                        })}
                    </div>
                )}
                {!!itemsLength && content}
                {!isLoading && !itemsLength && placeholder}
            </div>
        </StyledDashboardItems>
    </CatchError>
);

DashboardBlock.propTypes = {
    title: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    content: PropTypes.element.isRequired,
    placeholder: PropTypes.element,
    isLoading: PropTypes.bool.isRequired,
    quickAction: PropTypes.string,
    itemsLength: PropTypes.number.isRequired
};

export default injectIntl(DashboardBlock);
