import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { FileProtectOutlined, ContactsOutlined } from '@ant-design/icons';

import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import { StyledPageLayout, StyledSearchFormWrapper, StyledSimpleLink } from 'components/Shared/style';
import Phonebook from 'components/Contacts/Phonebook';
import Contacts from 'components/Contacts/Contacts';
import SearchBar from 'components/Shared/Forms/SearchBar';
import Icon from 'components/Shared/Common/Icon';

import { getUrl } from 'utils/url';

import { StyledTabs } from 'components/Shared/styles';

const StyledLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-badge{
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .icon{
        display: flex;
        align-items: center;
        margin-right: 5px;
        svg{
            width: 14px;
            height: 14px;
        }
    }
`;

const StyledSearchBar = styled(SearchBar)`
    padding: 10px 20px;
    width: 100%;
    .icon{
        svg{
            height: 18px;
            width: 18px;
        }
    }
    input{
        width: 100%;
        font-size: 16px;
        margin-left: 10px;
    }
`;

const ContactScreen = ({
    intl,
    onInvitBulk,
    isSignUpAllowed = false,
    isPublic,
    unlinkUser,
    hasPhonebook = false,
    history
}) => {
    const [activeTab, setActiveTab] = useState('internalContacts');
    const [query, setQuery] = useState();

    const handleTabChange = useCallback((activeKey) => {
        setActiveTab(activeKey);
        history.push(getUrl(activeKey));
    }, [setActiveTab, history]);

    const handleSearch = useCallback((e) => setQuery(e), [setQuery]);
    const handleClear = useCallback(() => setQuery(''), [setQuery]);

    useEffect(() => {
        const { pathname } = history.location;
        if (pathname?.indexOf(getUrl('businessContacts')) !== -1) {
            setActiveTab('businessContacts');
        } else {
            setActiveTab('internalContacts');
        }
    }, [history.location.pathname]);

    const items = useMemo(() => [{
        key: 'internalContacts',
        label: (
            <StyledLabel>
                <ContactsOutlined />
                {intl.formatMessage({ id: 'contacts.tab.internalContacts' })}
            </StyledLabel>
        ),
        children: (
            <Contacts 
                query={query} 
                isPublic={isPublic} 
                onDelete={isPublic ? unlinkUser : undefined} 
            />
        )
    }, {
        key: 'businessContacts',
        label: (
            <StyledLabel>
                <FileProtectOutlined />
                {intl.formatMessage({ id: 'contacts.tab.businessContacts' })}
            </StyledLabel>
        ),
        children: (
            <Phonebook withCreateContact query={query} />
        ),
        disabled: !hasPhonebook
    }], [intl, query, isPublic, unlinkUser, hasPhonebook]);
        
    return (
        <StyledPageLayout className="ui-contact">
            <CategoryTitle
                label={intl.formatMessage({ id: 'contacts.title' })}
                icon={<Icon size="xl" iconName="Users" />}
            />
            <StyledSearchFormWrapper className="ui-contact-search">
                <StyledSearchBar
                    onSearch={handleSearch}
                    onClear={handleClear}
                    debounceDuration={500}
                    placeholder={intl.formatMessage({ id: 'contacts.placeholder' })}
                    autoFocus
                    className="contacts-form"
                />
                {isSignUpAllowed && isPublic && (
                    <StyledSimpleLink
                        className="ui-simple-link"
                        onClick={onInvitBulk}
                    >
                        {intl.formatMessage({ id: 'contacts.inviteContacts' })}
                    </StyledSimpleLink>
                )}
            </StyledSearchFormWrapper>

            {hasPhonebook ? (
                <StyledTabs
                    activeKey={activeTab}
                    onChange={handleTabChange}
                    destroyInactiveTabPane
                    items={items}
                />
            ): (<Contacts 
                query={query} 
                isPublic={isPublic} 
                onDelete={isPublic ? unlinkUser : undefined} 
            />)}
        </StyledPageLayout>
    );
};

ContactScreen.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    unlinkUser: PropTypes.func.isRequired,
    onInvitBulk: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
    isSignUpAllowed: PropTypes.bool,
    isPublic: PropTypes.bool,
    hasPhonebook: PropTypes.bool,
};

export default injectIntl(ContactScreen);
