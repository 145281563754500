import React, { useCallback, useEffect, useState } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { notification, message } from 'antd';

import CallSchedulerInputContainer from 'components/Shared/Forms/Scheduler/SchedulerContainer';

import useScheduler from 'hooks/useScheduler';

type SchedulerWrapperProps = {
    onClose?: () => void;
    isFakeListDropdown?: boolean;
} & WrappedComponentProps;

const SchedulerWrapper = ({ onClose, isFakeListDropdown = false, intl }: SchedulerWrapperProps): JSX.Element => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const [resetCallBuilder, setResetCallBuilder] = useState<boolean>(false);
    const [scheduleForm, setScheduleForm] = useState({ scheduler : undefined, form: undefined });
    const { status, error } = useScheduler(scheduleForm?.scheduler);

    const handleSubmit = useCallback(({ scheduler }, form) => setScheduleForm({ scheduler, form }), [setScheduleForm]);

    useEffect(() => {
        if (status === 'success') {
            if (onClose) {
                onClose();
                message.success({ content: intl.formatMessage({ id: 'room.scheduleForm.success' }) });
            } else {
                notificationApi.success({
                    message: intl.formatMessage({ id: 'room.scheduleForm.success' }),
                    description: intl.formatMessage({ id: 'room.scheduleForm.successDescription' }),
                    placement: 'top',
                });
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            scheduleForm?.form?.reset();
            setResetCallBuilder(true);
        }

        if (status === 'error') {
            notificationApi.error({
                message: intl.formatMessage({ id: 'room.scheduleForm.error' }),
                placement: 'top',
            });
        }
    }, [status]);

    return (
        <>
            {contextHolder}
            <CallSchedulerInputContainer
                name="scheduler"
                resetCallBuilder={resetCallBuilder}
                setResetCallBuilder={setResetCallBuilder}
                onSubmit={handleSubmit}
                networkError={error}
                isFakeListDropdown={isFakeListDropdown}
            />
        </>
    );
}

export default injectIntl(SchedulerWrapper);
