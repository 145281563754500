import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import UserName from 'components/Shared/User/UserName';
import AvatarList from 'components/Shared/User/AvatarList/container';

import * as activityTypes from 'const/activity';
import ActivitiesItemLayout from 'components/Dashboard/MissedInfos/ActivitiesItem/Layout';
import { Typography } from 'antd';

const formatMessages = intl => ({ count, isMe, name }) => ({
    updateBiography: intl.formatMessage({ id: 'dashboard.whatYouMissed.updateBiography' }, { 
        b: chunks => chunks?.[0] && <UserName firstname={chunks?.[0]} />,
        name, 
        isMe
    }),
    updateTitle: intl.formatMessage({ id: 'dashboard.whatYouMissed.updateTitle' }, { 
        b: chunks => chunks?.[0] && <UserName firstname={chunks?.[0]} />,
        name, 
        isMe
    }),
    updatePicture: intl.formatMessage({ id: 'dashboard.whatYouMissed.updatePicture' }, { 
        b: chunks => chunks?.[0] && <UserName firstname={chunks?.[0]} />,
        name, 
        isMe
    }),
    newRelation: intl.formatMessage({ id: 'dashboard.whatYouMissed.newRelation' }, { count, isMe }),
    you: intl.formatMessage({ id: 'dashboard.whatYouMissed.you' })
});

const ActivitiesItem = (props) => {
    const {
        isMe,
        feedItem,
        intl,
        noBlock,
        deleteActivity,
        isEllipsed = false
    } = props;
    const i18n = formatMessages(intl)({
        count: feedItem?.metasPeers?.length || 0,
        isMe,
        name: isMe ? 0 : `${feedItem?.user?.firstname} ${feedItem?.user?.lastname}`
    });

    const renderUserName = () => (isMe
        ? <UserName firstname={i18n.you} />
        : (
            <UserName
                firstname={feedItem?.user?.firstname}
                lastname={feedItem?.user?.lastname}
            />
        ));

    let iconColor = '';
    let iconName = '';
    let content = '';
    let lighten= '';

    const handleDeleteActivity = useCallback(() => deleteActivity(feedItem?.id), [feedItem]);

    if (feedItem?.metasPeers?.length) {
        iconColor = 'red';
        iconName = 'UserPlus';
        lighten = i18n.newRelation
        content = <AvatarList _users={feedItem.metasPeers} hasStatus={false} />;
    }

    if (feedItem?.content) {
        iconColor = 'purple';
        iconName = 'MessageCircle';
        lighten = renderUserName();
        content = <Typography.Text ellipsis={isEllipsed}>{feedItem.content}</Typography.Text>
    }

    if (feedItem?.metasDiff?.type) {
        iconColor = 'green';
        lighten = renderUserName();
        if (typeof feedItem?.metasDiff?.diff === 'string'){
            content = <Typography.Text ellipsis={isEllipsed}>{feedItem?.metasDiff?.diff}</Typography.Text>;
        }
        switch (feedItem.metasDiff.type) {
            case activityTypes.PUT_BIOGRAPHY:
                iconName = 'AlignLeft';
                lighten = i18n.updateBiography;
                break;

            case activityTypes.PUT_TITLE:
                iconName = 'User';
                lighten = i18n.updateTitle;
                break;

            case activityTypes.PUT_PROFILE_PICTURE:
                iconName = 'Image';
                lighten = i18n.updatePicture;
                break;

            default:
        }
    }

    if (!content) {
        return null;
    }

    return (
        <ActivitiesItemLayout
            createdAt={feedItem?.updatedAt}
            isEdited={feedItem?.createdAt !== feedItem?.updatedAt}
            key={feedItem.id}
            id={feedItem?.id}
            noBlock={noBlock}
            iconName={iconName}
            iconColor={iconColor}
            lighten={lighten}
            onRemove={isMe && feedItem?.content ? handleDeleteActivity : undefined}
            user={feedItem?.user}
        >
            {content}
        </ActivitiesItemLayout>
    );
}

ActivitiesItem.propTypes = {
    intl: PropTypes.any.isRequired,
    noBlock: PropTypes.bool,
    feedItem: PropTypes.object.isRequired,
    isMe: PropTypes.bool.isRequired,
    isEllipsed: PropTypes.bool
}

export default injectIntl(ActivitiesItem);
