import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import MemberItem from 'components/Room/MemberManagement/Member';
import MemberCounter from 'components/Room/MemberManagement/MemberCounter';
import Button from 'components/Shared/Common/Button';
import CheckboxItem from 'components/Shared/Forms/CheckBox/CheckBoxItem';
import InputList from 'components/Shared/Forms/InputList';
import ToggleSelectLabel from 'components/Shared/Forms/CheckBox/ToggleSelectLabel';
import styled from 'styled-components';
import { rgba } from 'polished';
import TextError from 'components/Room/MemberManagement/TextError';
import PremiumBlock from 'components/Shared/Blocks/PremiumBlock';

const StyleUserCheckboxSelector = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => rgba(theme.bgRoom, 0.7)};
    .wrapper{
        display: flex;
        flex-direction: column;
        margin: 20px auto;
        background-color: ${({ theme }) => theme.bgRoom};
        box-shadow: 0 2px 4px ${({ theme }) => rgba(theme.white, 0.1)};
        position: relative;
        min-height: 0;
        min-width: 0;
        padding: 10px;
        border-radius: 4px;
        max-width: 500px;
        width: 100%;
        .user-list-checkbox-action{
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;
            padding-top: 10px;
            .btn{
                margin-right: 10px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .checkbox-list-overflow{
            overflow: auto;
            flex: 1;
            .item{
                ursor: pointer;
                display: flex;
                flex-direction: row;
                -webkit-box-align: center;
                align-items: center;
                padding: 10px;
                border-bottom: 1px solid ${({ theme }) => rgba(theme.white, 0.1)};
                transition: all 250ms ease 0s;
                border-radius: 0px;
                .ant-checkbox-wrapper{
                    span{
                        &:nth-child(2){
                            >div{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                &:hover{
                    background-color: ${({ theme }) => rgba(theme.white, 0.05)};
                }
                &:last-child{
                    border-bottom: 0;
                }
            }
        }
        >.item{
            position: absolute;
            bottom: 20px;
            left: 20px;
        }
    }
`;

const UserCheckboxItemWrapper = memo((props) => {
    const { id, onChange, firstname, lastname, ...rest } = props;
    const { isAdmin } = rest;
    const fullname = `${firstname} ${lastname}`;
    const handleChange = useCallback(() => onChange(id), [id, onChange]);

    return (
        <CheckboxItem
            disabled={!!isAdmin}
            onChange={handleChange}
            ItemComponent={MemberItem}
            fullname={fullname}
            {...rest}
        />
    );
});

const UserCheckboxSelector = ({ users, maxSelected, onSubmit, intl }) => {
    const allChecked = useRef(true);
    const [items, setItems] = useState([]);
    const isClosableWindow = window.opener != null || window.history.length === 1;
    const selectedItems = items.filter(x => x.checked).map(x => x.id);

    // format items if users change
    useEffect(() => {
        const updatedItems = [];

        for (let idx = 0; idx < users.length; idx++) {
            let item = users[idx];
            if (item.checked === undefined) {
                item = { ...users[idx], checked: true};
            }
            updatedItems.push(item);
        }

        setItems(updatedItems);
    }, [users]);

    const handleToggleAll = useCallback(
        () => {
            allChecked.current = !allChecked.current;
            setItems(items.map(x => ({ ...x, checked: allChecked.current })));
        }
        , [items, allChecked]);

    const handleToggle = useCallback(
        (_user) => {
            const updatedItems = [ ...items ];
            for (let idx = 0; idx < updatedItems.length; idx++) {
                const item = updatedItems[idx];
                if (item.id === _user) {
                    updatedItems[idx] = { ...items[idx], checked: !item.checked };
                    setItems(updatedItems);
                    return;
                }
            }
        }
        , [items, setItems]);

    const handleCreateRoom = useCallback(
        () => onSubmit(selectedItems), [selectedItems, onSubmit]);

    const handleCancel = useCallback(() => window.close(), []);

    return (
        <StyleUserCheckboxSelector>
            <div className="wrapper">
                <PremiumBlock descriptionKey="premium.roomMemberCountUsage" />
                {maxSelected && selectedItems?.length > maxSelected && (
                    <TextError
                        errorKey="room.error.maxMemberCreationLimit"
                        errorValues={{ maxMembers: maxSelected }}
                    />
                )}
                <MemberCounter memberCount={selectedItems?.length} maxMembers={maxSelected} />
                <InputList
                    items={items}
                    className="checkbox-list-overflow"
                    isLoading={false}
                    fallbackMessage="fallback message"
                    ItemComponent={UserCheckboxItemWrapper}
                    onChange={handleToggle}
                />
                <CheckboxItem
                    disabled={false}
                    onChange={handleToggleAll}
                    ItemComponent={ToggleSelectLabel}
                    checked={allChecked.current}
                />

                <div className="user-list-checkbox-action">
                    {isClosableWindow && (
                        <Button
                            onClick={handleCancel}
                            color="secondary"
                        >
                            {intl.formatMessage({ id: 'global.button.cancel' })}
                        </Button>
                    )}
                    <Button
                        onClick={handleCreateRoom}
                        isDisabled={selectedItems?.length > maxSelected}
                        color="primary"
                    >
                        {intl.formatMessage({ id: 'feed.header.button.drawer.room' })}
                    </Button>
                </div>
            </div>
        </StyleUserCheckboxSelector>
    );
};

UserCheckboxSelector.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired
    })).isRequired,
    maxSelected: PropTypes.number,
    intl: PropTypes.any.isRequired,
};

export default memo(injectIntl(UserCheckboxSelector));
