import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Space, Divider, Typography, Row } from 'antd';
import styled from 'styled-components';

import config from 'config';

import Icon from 'components/Shared/Common/Icon';
import Duration from 'components/Shared/Call/Duration';
import BrandLogo from 'components/Shared/Company/BrandLogo/container';
import WrappedContactButton from 'components/Contacts/Contacts/WrappedContactButton';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import { StyleHeaderInformationRoom, StyleRoomInformation } from './styles';

const { Text } = Typography;

const StyledContent = styled.div`
    display: flex;
`;

const HeaderTitle = memo((props) => {
    const {
        joinedAt,
        roomName = '',
        roomColor,
        memberCount = 1,
        maxMembers = 1,
        className = ''
    } = props;

    useWhyDidYouUpdate('component:room:roomscreen:header:HeaderTitle', props);

    return (
        <StyleHeaderInformationRoom color={roomColor} className={className}>
            <div className="avatar-group-name">
                <BrandLogo size="md" type="shortLogo" />
            </div>
            <StyleRoomInformation align="middle" justify="center">
                <Space split={<Divider type="vertical" />}>
                    <StyledContent className="item">
                        <WrappedContactButton roomName={roomName} />
                    </StyledContent>
                    <Text className="item">
                        <Row align="middle" justify="center">
                            <Icon iconName="Users"/>
                            {memberCount}
                            {config.debug ? `/ ${maxMembers}` : null}
                        </Row>
                    </Text>
                    <Text className="item">
                        {joinedAt && (
                            <Duration startedAt={new Date(joinedAt)} delay={1000} />
                        )}
                    </Text>
                </Space>
            </StyleRoomInformation>
        </StyleHeaderInformationRoom>
    );
});

HeaderTitle.propTypes = {
    className: PropTypes.string,
    roomName: PropTypes.string,
    joinedAt: PropTypes.string,
    roomColor: PropTypes.string,
    memberCount: PropTypes.number,
    maxMembers: PropTypes.number
};

export default HeaderTitle;
