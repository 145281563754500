import React, { useEffect, useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getStatus, PRESENCE_STATUSES, DEFAULT_AVATAR, AVATAR_SIZES } from 'const/user';
import Icon from 'components/Shared/Common/Icon';
import { getAvatarUrl } from 'services/user';
import Image from 'components/Shared/Common/Image';
import { Styled, StyledStatus } from 'components/Shared/User/Avatar/styles';
import { Avatar as AntdAvatar } from 'antd';
import { defaultColors } from 'common/themes/Colors';

const SCREEN_RETINA = 2;
const SCREEN_4K = 3;

const getAvatarSize = size => AVATAR_SIZES[size] || AVATAR_SIZES.default;

function nameToInitials(fullName) {
    const namesArray = fullName.trim().split(' ');
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
    return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
}

const Avatar = (props) => {
    const [size, setSize] = useState(getAvatarSize(props.size));
    const [src, setSrc] = useState();
    const [srcSet, setSrcSet] = useState();
    const {
        status = PRESENCE_STATUSES.OFFLINE,
        size: sizeProps,
        alt = 'avatar',
        isEditable = false,
        onClick,
        onChange,
        href,
        isLoading = false,
        className = 'avatar',
        hasStatus = true,
        statusIcon,
        statusBg = '',
        statusLabel,
        theme,
        src: srcProps = DEFAULT_AVATAR,
        content
    } = props;
    const intitials = content ? nameToInitials(content)?.toUpperCase() : undefined;

    useEffect(() => {
        setSize(getAvatarSize(sizeProps));
    }, [sizeProps]);

    useEffect(() => {
        if (srcProps && typeof srcProps === 'string') {
            setSrc(getAvatarUrl({ avatarUri: srcProps }, size, size));
            setSrcSet(`${getAvatarUrl({ avatarUri: srcProps }, SCREEN_RETINA * size, SCREEN_RETINA * size)} 2x, ${getAvatarUrl({ avatarUri: srcProps }, SCREEN_4K * size, SCREEN_4K * size)} 3x`)
        }

        else if (srcProps && typeof srcProps === 'object' && (srcProps instanceof File || srcProps instanceof Blob)) {
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                setSrc(reader.result);
                setSrcSet(undefined);
            }, false);

            if (srcProps) {
                reader.readAsDataURL(srcProps);
            }
        }
        else if (srcProps && typeof srcProps === 'object' && srcProps.file) {
            setSrc(getAvatarUrl({ avatar: srcProps }, size, size));
            setSrcSet(`${getAvatarUrl({ avatar: srcProps }, SCREEN_RETINA * size, SCREEN_RETINA * size)} 2x, ${getAvatarUrl({ avatar: srcProps }, SCREEN_4K * size, SCREEN_4K * size)} 3x`)
        } else {
            setSrc(DEFAULT_AVATAR);
            setSrcSet(undefined);
        }
    }, [srcProps, size]);

    const handleClick = useCallback(action => e => {
        if (typeof action === 'function') {
            e.preventDefault();
            action(e);
        }
        return e;
    }, []);

    const statusInfos = getStatus(status);
    const statusClass = statusInfos ? statusInfos.className : '';

    return (
        <Styled
            className={classNames(className, { [sizeProps]: sizeProps }, { loading: isLoading })}
            href={href}
            onClick={onClick && handleClick(onClick)}
            theme={theme}
        >
            {!content || srcProps !== DEFAULT_AVATAR ? (
                <Image
                    shape="circle"
                    alt={alt}
                    src={src}
                    srcSet={srcSet}
                    fallbackSrc={DEFAULT_AVATAR}
                />
            ) : (
                <AntdAvatar
                    size={size}
                    style={{
                        backgroundColor: defaultColors[(intitials?.charCodeAt(0) || 0) % defaultColors.length - 1]
                    }}
                >
                    {intitials}
                </AntdAvatar>
            )}
            {!statusIcon && !statusLabel && hasStatus && status !== null && (
                <StyledStatus className={classNames('s-status', statusClass)} />
            )}
            {statusIcon && (
                <StyledStatus
                    style={{ backgroundColor: statusBg }}
                    className={classNames('s-status-icon', statusClass)}
                >
                    <Icon iconName={statusIcon} />
                </StyledStatus>
            )}
            {statusLabel && (
                <StyledStatus
                    style={{ backgroundColor: statusBg }}
                    className={classNames('s-status-label', statusClass)}
                >
                    {statusLabel}
                </StyledStatus>
            )}
            {isEditable && (
                <div className="edition-container">
                    <Icon iconName="RefreshCw" />
                    <input onChange={onChange} type="file" />
                </div>
            )}
        </Styled>
    );
}

export const avatarProps = {
    status: Object.values(PRESENCE_STATUSES),
    sizes: Object.keys(AVATAR_SIZES)
};

Avatar.propTypes = {
    className: PropTypes.string,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    size: PropTypes.oneOf(avatarProps.sizes),
    status: PropTypes.oneOf(avatarProps.status),
    alt: PropTypes.string,
    isEditable: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    href: PropTypes.string,
    isLoading: PropTypes.bool,
    hasStatus: PropTypes.bool,
    theme: PropTypes.object,
    statusLabel: PropTypes.string,
    statusIcon: PropTypes.string,
    statusBg: PropTypes.string,
    content: PropTypes.string
};

export default memo(Avatar);
