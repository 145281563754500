import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import UiIcon from 'components/Shared/Common/Icon';
import { colors } from 'common/themes/Colors';
import styled from 'styled-components';
import { rgba } from 'polished';
import classNames from 'classnames';
import { selectors, constants } from '@amplement/backend-connector';

const { QUALITY: NETWORK_QUALITY } = constants.network;

const Style = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    &.good{
        background-color: ${colors.success};
        box-shadow: 0 2px 4px ${rgba(colors.success, 0.5)}
    }
    &.medium{
        background-color: ${colors.warning};
        box-shadow: 0 2px 4px ${rgba(colors.warning, 0.5)}
    }
    &.low{
        background-color: ${colors.error};
        box-shadow: 0 2px 4px ${rgba(colors.error, 0.5)}
    }
    .icon{
        display: flex;
        align-items: center;
        svg{
            color: ${colors.white};
            width: 15px;
            height: 15px;
        }
    }
`;

const NetworkIcon = memo(({ strength }) => (
    <Style className={classNames('network', strength)}>
        <UiIcon iconName={strength === 'down' ? 'WifiOff' : 'Wifi'} />
    </Style>
));

NetworkIcon.propTypes = {
    strength: PropTypes.oneOf(['', 'good', 'low', 'medium'])
};

const NetworkPingInfoComponent = memo(({ strength, hideOnGood = true }) => strength !== 'good' || !hideOnGood ? (
    <div className="room-quality-network">
        <NetworkIcon strength={strength} />
        <div className="tooltip">
            {strength || 'N/A'}
        </div>
    </div>
) : null);

export const NetworkPingInfoProps = {
    strengths: ['', 'good', 'low', 'medium']
};
NetworkPingInfoComponent.propTypes = {
    strength: PropTypes.oneOf(NetworkPingInfoProps.strengths),
    hideOnGood: PropTypes.bool
};

export const Component = NetworkPingInfoComponent;

const mapStateToProps = (state) => {
    const quality = selectors.network.quality(state) || {};

    let strength = 'good';
    if (quality === NETWORK_QUALITY.LOW) {
        strength = 'low';
    } else if (quality === NETWORK_QUALITY.MEDIUM) {
        strength = 'medium';
    }

    return {
        strength,
    }
};

export default connect(mapStateToProps, null)(NetworkPingInfoComponent);
