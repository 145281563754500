import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import FullscreenMessageFormatter from 'components/Shared/Errors/Formatter';
import RoomFullscreenMessage from 'components/Shared/Errors/RoomFullscreenMessage';

import { BUTTON_TYPE } from 'const/fullscreenMessage';
import RoomReception from 'components/Room/RoomReception';
import FullscreenLoader from 'components/Shared/Layout/FullscreenLoader';
import useTimeout from 'hooks/useTimeout';
import { callbacks } from '@amplement/backend-connector';

const RoomReceptionPage = ({
    _room,
    intl,
    name,
    isPrivate = false,
    isLoading = true,
    requestGetRoom,
    caller
}) => {
    const { firstname, lastname, avatarUri, _user } = caller || {};
    const [error, setError] = useState();
    const setTimeoutError = useCallback(() => {
        if (!error) {
            console.error(new Error('Timeout: Room has not been joined until the expiration delay (30s).'))
            setError('error.timeout');
        }
    }, [error]);
    const [, clear] = useTimeout(setTimeoutError, 30000);

    useEffect(() => {
        callbacks.set('onRoomNotFound', (id) => {
            if (id === _room) {
                setError('room.error.notFound');
            }
        }, true);
        callbacks.set('onRoomForbidden', (id) => {
            if (id === _room) {
                setError('room.error.forbidden');
            }
        }, true);

        if (_room && name === undefined) {
            requestGetRoom(_room);
        }

        return () => {
            callbacks.unset('onRoomNotFound');
            callbacks.unset('onRoomForbidden');
        }
    }, [_room, name]);

    useEffect(() => {
        if (!isLoading) {
            clear();
        }
        return clear;
    }, [isLoading]);

    if (error) {
        return (
            <FullscreenMessageFormatter
                content={intl.formatMessage({ id: error })}
                buttonType={error === 'error.timeout' ? BUTTON_TYPE.RELOAD : BUTTON_TYPE.CLOSE}
                render={RoomFullscreenMessage}
            />
        );
    }

    if (isLoading) {
        return <FullscreenLoader />;
    }

    return (
        <RoomReception
            avatar={avatarUri}
            firstname={firstname}
            lastname={lastname}
            _user={_user}
            roomName={name}
            _room={_room}
            isPrivate={isPrivate}
        />
    );
}

RoomReceptionPage.propTypes = {
    _room: PropTypes.string.isRequired,
    requestGetRoom: PropTypes.func.isRequired,
    caller: PropTypes.object,
    name: PropTypes.string,
    isLoading: PropTypes.bool,
    isPrivate: PropTypes.bool
};

export default injectIntl(RoomReceptionPage);
