import React from 'react';
import PropTypes from 'prop-types';

import DashboardBlock from 'components/Dashboard/DashboardBlock';
import Button from 'components/Shared/Common/Button';

import { getUrl } from 'utils/url';
import UiIcon from 'components/Shared/Common/Icon';
import Link from 'components/Shared/Common/Link';
import { StyledBloc } from 'components/Dashboard/News/styles';

// Note : we temporary display only two buttons
const BUTTON_NUMBER_LIMIT = 10;

const QuickLink = ({ label, ...props }) => (
    <Link {...props}>
        <span>
            <UiIcon iconName="Link2" />
            {label}
        </span>
    </Link>
);

const QuickLinks = ({ links: quickLinks, _me }) => {
    const { title, links } = quickLinks;

    if (!links || !links.length) {
        return null;
    }

    const renderQuickLink = ({
        routeName, routeParams, url, label, ...rest
    }) => {
        const props = { ...rest, label };
        if (routeName && rest.target !== '_blank') {
            const customRouteName = `custom-${routeName}`;
            props.href = getUrl(customRouteName, routeParams);
        } else if (url) {
            props.href = url.replace(':_user', _me);
        }

        return (
            <li key={label}>
                <QuickLink {...props} />
            </li>
        );
    };

    return (
        <StyledBloc className="bloc-btn">
            <div className="label">{title}</div>
            <ul>{links.map(renderQuickLink)}</ul>
        </StyledBloc>
    );
};

const LinkBtn = ({
    routeName, routeParams, url, label, _me, ...rest
}) => {
    const props = { ...rest };
    if (routeName && rest.target !== '_blank') {
        const customRouteName = `custom-${routeName}`;
        props.href = getUrl(customRouteName, routeParams);
    } else if (url) {
        props.href = url.replace(':_user', _me);
    }

    return (
        <Button key={label} color="primary" {...props}>
            {label}
        </Button>
    );
};

const LinkBtns = ({ links, _me }) => {
    if (!links || !links.length) {
        return null;
    }
    const handleRenderLinkBtn = (item, i) => <LinkBtn key={item.label || i} {...item} _me={_me} />;
    return (
        <div className="bloc-btn">
            {links.slice(0, BUTTON_NUMBER_LIMIT).map(handleRenderLinkBtn)}
        </div>
    );
};

const News = ({
    thumbSrc = null, content, links = [], quickLinks = {}, title, _me
}) => {
    const renderContent = (text, i) => <p key={`${i}-${new Date()}`}>{text}</p>;

    return (
        <DashboardBlock
            title={title}
            iconName="Link2"
            content={(
                <StyledBloc className="bloc-text">
                    {thumbSrc && (
                        <div className="bloc-img">
                            <img src={thumbSrc} alt={title} />
                        </div>
                    )}
                    {content && <div>{content.split('\n').map(renderContent)}</div>}
                    {links && <LinkBtns links={links} _me={_me} />}
                    {quickLinks && <QuickLinks links={quickLinks} _me={_me} />}
                </StyledBloc>
            )}
            isLoading={false}
            itemsLength={100}
        />
    );
};

News.propTypes = {
    title: PropTypes.string.isRequired,
    thumbSrc: PropTypes.string,
    _me: PropTypes.string,
    content: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            routeName: PropTypes.string,
            routeParams: PropTypes.object,
            label: PropTypes.string.isRequired,
            target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top'])
        })
    ),
    quickLinks: PropTypes.shape({
        name: PropTypes.string,
        links: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                routeName: PropTypes.string,
                routeParams: PropTypes.object,
                label: PropTypes.string.isRequired,
                target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top'])
            })
        )
    })
};

export default News;
