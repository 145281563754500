import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { injectIntl } from 'react-intl';

import UiIcon from 'components/Shared/Common/Icon';
import { StyledToggleSoundButton } from 'components/Shared/style';

const ToggleSound = ({
    onClick,
    enabled,
    soundOff = '',
    soundOn = '',
    iconOff = 'HeadphoneOff',
    iconOn = 'Headphone',
    buttonClassName = '',
    intl,
    iconClassName = ''
}) => {
    const handleClick = useCallback(() => onClick(!enabled), [onClick, enabled]);
    const sound = useMemo(() => ({
        soundOff: soundOff || intl.formatMessage({ id: 'menu.dropdown.sound.off' }),
        soundOn: soundOn || intl.formatMessage({ id: 'menu.dropdown.sound.on' }),
    }), [intl, soundOff, soundOn]);

    return (
        <Tooltip
            placement="bottom"
            title={enabled ? sound.soundOff : sound.soundOn}
        >
            <StyledToggleSoundButton
                className={buttonClassName}
                onClick={handleClick}
            >
                <div className="icon-wrapper">
                    <UiIcon
                        className={iconClassName}
                        iconName={enabled ? iconOn : iconOff}
                    />
                </div>
            </StyledToggleSoundButton>
        </Tooltip>
    );
};

ToggleSound.propTypes = {
    intl: PropTypes.any.isRequired,
    soundOff: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    soundOn: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    iconOff: PropTypes.string,
    iconOn: PropTypes.string,
    onClick: PropTypes.func,
    iconClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    enabled: PropTypes.bool,
};

export default injectIntl(ToggleSound);
