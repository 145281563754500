import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import classNames from 'classnames';
import Icon from 'components/Shared/Common/Icon';
import StyleDropdown from './styles';
import TogglePrivateRoom from './DropDown/TogglePrivateRoom';
import TogglePersistentRoom from './DropDown/TogglePersistentRoom';
import CopyRoomLinkButton from './DropDown/CopyRoomLinkButton';
import ManageRoomSettings from './DropDown/ManageRoomSettings';
import LeaveRoomButton from './DropDown/LeaveRoomButton';
import DeleteRoomButton from './DropDown/DeleteRoomButton';
import SendEmailInvitation from './DropDown/SendEmailInvitation';
import CleanChatRoomButton from './DropDown/CleanChatRoomButton';

const DropDownCopyLink = injectIntl(({ intl }) => (
    <>
        <div className="line">
            <Icon iconName="Copy" />
        </div>
        <div className="line-wrapper">
            <div className="line column">
                <div className="label">
                    {intl.formatMessage({ id: 'global.copyClipboard' })}
                </div>
            </div>
        </div>
    </>
));

const MoreButton = memo((props) => {
    const {
        _room,
        isOpen = true,
        onClose,
        requestPatchRoom,
        isPrivate,
        name = '',
        isPersistent,
        isMeAdmin = false,
        intl,
        setDeviceSettings,
        canChangeVisibility = true,
        persistence = true,
        editMembers = true,
        onLeave,
        onDelete,
        feed = true
    } = props;

    useWhyDidYouUpdate('components:room:MoreButton', props);

    const handleTogglePrivate = useCallback((isChecked) => {
        requestPatchRoom({ isPrivate: isChecked });
    }, [requestPatchRoom]);

    const handleTogglePersistent = useCallback((isChecked) => {
        requestPatchRoom({ isPersistent: isChecked });
    }, [requestPatchRoom]);

    const handleChangeName = useCallback((value) => {
        requestPatchRoom({ name: value });
    }, [requestPatchRoom]);

    const handleRoomLeave = useCallback(() => onLeave(_room), [onLeave, _room]);
    const handleRoomDelete = useCallback(() => onDelete(_room), [onDelete, _room]);

    return (
        <StyleDropdown
            className={classNames(
                'more',
                { active: isOpen }
            )}
        >
            <div className="list-line-options">

                {isMeAdmin && isPersistent ? (
                    <SendEmailInvitation onClose={onClose} />
                ) : null}

                {canChangeVisibility && isMeAdmin ? (
                    <TogglePrivateRoom
                        isChecked={isPrivate}
                        onClick={handleTogglePrivate}
                        intl={intl}
                    />
                ) : null}
                {persistence && isMeAdmin ? (
                    <TogglePersistentRoom
                        isChecked={isPersistent}
                        name={name}
                        onClick={handleTogglePersistent}
                        onChange={handleChangeName}
                        intl={intl}
                    />
                ) : null}

                {/* <div
                    className="line-item"
                    onClick={(e) => {
                        e.stopPropagation();
                        setisModalParticipantOpen(!isModalParticipantOpen)
                        onClose(!isOpen)
                    }}
                >
                    <div className="line">
                        <Icon iconName="UserPlus" />
                    </div>
                    <div className="line-wrapper">
                        <div className="line column">
                            <div className="label">Add participant</div>
                        </div>
                    </div>
                </div> */}

                {editMembers && (
                    <CopyRoomLinkButton onClick={onClose}>
                        <DropDownCopyLink />
                    </CopyRoomLinkButton>
                )}

                <ManageRoomSettings onSubmit={setDeviceSettings} onClose={onClose} />

                {!isMeAdmin ? (
                    <LeaveRoomButton
                        name={name}
                        onClick={handleRoomLeave}
                        onClose={onClose}
                    />
                ) : null}

                {!!isMeAdmin && !!feed && (
                    <CleanChatRoomButton
                        name={name}
                        onClose={onClose}
                    />
                )}

                {isMeAdmin && isPersistent ? (
                    <DeleteRoomButton
                        name={name}
                        onClick={handleRoomDelete}
                        onClose={onClose}
                    />
                ) : null}

                {/* <div
                    className="line-item"
                    onClick={(e) => {
                        e.stopPropagation();
                        setisModalSettings(!isModalSettings)
                        onClose(!isOpen)
                    }}
                >
                    <div className="line">
                        <div className="icon-hd">
                            HD
                        </div>
                    </div>
                    <div className="line-wrapper">
                        <div className="line column">
                            <div className="label">Manage your settings</div>
                        </div>
                    </div>
                </div>

                <div className="line-item">
                    <div className="line">
                        <Icon iconName="Maximize" />
                    </div>
                    <div className="line-wrapper">
                        <div className="line column">
                            <div className="label">View fullscreen</div>
                        </div>
                    </div>
                </div>

                <div className="line-item">
                    <div className="line">
                        <Icon iconName="MicOff" />
                    </div>
                    <div className="line-wrapper">
                        <div className="line column">
                            <div className="label">Mute everyone</div>
                        </div>
                    </div>
                </div>

                <div
                    className="line-item"
                    onClick={(e) => {
                        e.stopPropagation();
                        setisModalShortcutOpen(!isModalShortcutOpen)
                        onClose(!isOpen)
                    }}
                >
                    <div className="line">
                        <Icon iconName="Zap" />
                    </div>
                    <div className="line-wrapper">
                        <div className="line column">
                            <div className="label">View shortcuts</div>
                        </div>
                    </div>
                </div>

                <div
                    className="line-item"
                    onClick={(e) => {
                        e.stopPropagation();
                        setisModalProblemOpen(!isModalProblemOpen)
                        onClose(!isOpen)
                    }}
                >
                    <div className="line">
                        <Icon iconName="AlertCircle" />
                    </div>
                    <div className="line-wrapper">
                        <div className="line column">
                            <div className="label">Any problem ?</div>
                        </div>
                    </div>
                </div> */}

            </div>
        </StyleDropdown>
    );
});

MoreButton.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    requestPatchRoom: PropTypes.func,
    setDeviceSettings: PropTypes.func.isRequired,
    isPrivate: PropTypes.bool,
    name: PropTypes.string,
    isPersistent: PropTypes.bool,
    isMeAdmin: PropTypes.bool,
    canChangeVisibility: PropTypes.bool,
    persistence: PropTypes.bool,
    editMembers: PropTypes.bool,
    onLeave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    _room: PropTypes.string.isRequired,
    feed: PropTypes.string,
    intl: PropTypes.any.isRequired
};

export default injectIntl(MoreButton);
