import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Placeholder from 'components/Shared/Common/Placeholder';
import { injectIntl } from 'react-intl';
import CatchError from 'components/CatchError';
import Spinner2 from 'components/Shared/Common/Spinner2';
import { container as NotificationItem } from './Item';

const NotificationList = ({ intl, _notifications, isLoading = false }) => {
    if (isLoading) {
        return <Spinner2 />;
    }

    if (!_notifications?.length) {
        return (
            <div className="placeholder centered">
                <div className="placeholder-image">
                    <Placeholder name="placeholderNotification" />
                </div>
                <div className="placeholder-content">
                    <div className="text">
                        {intl.formatMessage({ id: 'notifications.placeholder.text.title' })}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <CatchError>
            <div className="event">
                {_notifications.map(id => <NotificationItem key={id} _notification={id} />)}
            </div>
        </CatchError>
    );
};

NotificationList.propTypes = {
    intl: PropTypes.any.isRequired,
    _notifications: PropTypes.arrayOf(PropTypes.string),
    isLoading: PropTypes.bool,
};

export default memo(injectIntl(NotificationList));
