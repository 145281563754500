import React, { useCallback, memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledLink, StyledAnchor, StyledDiv } from 'components/Shared/Common/Button/styles';
import Logger from 'services/debug/logger';

const logger = new Logger('component:shared:Link');

const CustomLink = forwardRef((props, ref) => {

    const {
        children = null,
        onClick,
        href,
        className,
        target = null,
        forceReload = false,
        ...otherProps
    } = props;

    let Container = StyledDiv;
    let extraProps = onClick ? {
        onKeyPress: () => null,
        tabIndex: 0,
        role: 'button'
    } : {};

    const handleClick = useCallback((e) => {
        const isNamedTarget = target?.length && target?.[0] !== '_';
        let shouldPreventDefault = false;

        if (onClick) {
            onClick(e);
            shouldPreventDefault = true;
        }

        /**
         * If we have a named frame, we want to reopen tab in previous opened tab
         * to avoid refreshing the targeted table, we add a '#'
         * consequence : if y click twice on a link with named target, only the first time,
         * the page is loaded, and the second time, it's just a tab switch (without reload)
         */
        if (href && isNamedTarget) {
            window.tabs = (window.tabs || []).filter(t => !t?.tab.closed); // remove expired child tabs
            const tabToFocus = window.tabs.find(t => t.target === target);
            
            if (tabToFocus) {
                tabToFocus.tab?.focus();
                shouldPreventDefault = true;
            } else {
                const tab = window.open(href, target);
                if (tab) {
                    window.tabs = [ ...window.tabs, { target, href, tab }];
                    shouldPreventDefault = true;
                } else {
                    logger.error(new Error('Cannot open new Tab, probably because you have adBlocker, please disable it !'));
                }
            }
        }

        // if onClick of named target with successfully window.open => preventDefault
        if (shouldPreventDefault) {
            e.preventDefault();
            return false;
        }

        return true;
    }, [onClick, href, target]);

    if (href && typeof href === 'string') {
        const isUrl = forceReload || href.indexOf('http') !== -1 || target === '_blank';

        if (!isUrl) {
            Container = StyledLink
            extraProps = {
                to: href,
                target,
            };
        } else {
            Container = StyledAnchor;
            extraProps = {
                href,
                target,
            };
        }
    }

    // const handleClick = useCallback((e) => {
    //     e.preventDefault();
    //     onClick(e);
    //     return e;
    // }, [onClick]);

    return (
        <Container
            onClick={handleClick}
            className={className}
            ref={ref}
            {...extraProps}
            {...otherProps}
        >
            {children}
        </Container>
    );
});

CustomLink.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
        PropTypes.element,
        PropTypes.array
    ]),
    forceReload: PropTypes.bool,
    onClick: PropTypes.func,
    href: PropTypes.string,
    className: PropTypes.string,
    target: PropTypes.string
};

export default memo(CustomLink);
