import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';

import FeedItemList from 'components/Feed/FeedItemList/container';
import FeedItemCreator from 'components/Feed/Creator/container';
import ReplyAreaFeedItem from 'components/Feed/ReplyArea';
import FileUploadManager from 'components/Feed/Creator/FileUploadManager/container';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import DropZone from 'components/Shared/Common/DropZone';


const Content = (props) => {
    const {
        isMulticast,
        closeContactDrawer,
        _feed,
    } = props;
    const [isUploading, setIsUploading] = useState(false);
    const [feedItemFiles, setFeedItemFiles] = useState([]);
    const [replyComment, setReplyComment] = useState({ content: '', _feedItem: null });

    useWhyDidYouUpdate('components:feed:content', { ...props, isUploading, feedItemFiles });

    const closeUploadManager = useCallback(() => {
        setFeedItemFiles([]);
        setIsUploading(false);
    }, []);

    const handleSetFeedItemFiles = useCallback((fList) => {
        setFeedItemFiles(fList);
        setIsUploading(true);
    }, []);

    const handleReplyFeedItem = useCallback((_feedItem, content) => setReplyComment({ _feedItem, content }), []);

    useEffect(() => {
        if (replyComment._feedItem) {
            setReplyComment({ content: '', _feedItem: null });
        }
    }, [_feed]);

    useEffect(() => {
        if (!isMulticast) {
            closeContactDrawer();
        }
        closeUploadManager()

        return () => {
            closeContactDrawer();
        };
    }, [_feed]);

    if (!_feed) {
        return null;
    }

    return (
        <>
            <FileUploadManager
                visible={isUploading}
                feedItemFiles={feedItemFiles}
                _feed={_feed}
                onClose={closeUploadManager}
                setFeedItemFiles={handleSetFeedItemFiles}
            />
            <FeedItemList
                onReplyFeedItem={handleReplyFeedItem}
                _feed={_feed}
            />
            {replyComment.content && (
                <ReplyAreaFeedItem content={replyComment.content} />
            )}
            <FeedItemCreator
                id={_feed}
                onReplyFeedItem={handleReplyFeedItem}
                replyComment={replyComment}
                setFeedItemFiles={handleSetFeedItemFiles}
            />
            <DropZone
                containerId="app"
                onAddFileToUpload={handleSetFeedItemFiles}
            />
        </>
    );
}

Content.propTypes = {
    closeContactDrawer: PropTypes.func.isRequired,
    _feed: PropTypes.string,
    isMulticast: PropTypes.bool,
};

export default memo(Content, isEqual);
