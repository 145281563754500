import React, { useCallback, memo } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Button from 'components/Shared/Common/Button';
import { selectors } from '@amplement/backend-connector';
import { injectIntl } from 'react-intl';
import { Badge } from 'antd';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

const ToggleChatButtonComponent = (props) => {
    const {
        active = false,
        onClick,
        unreadCount,
    } = props;

    useWhyDidYouUpdate('Component:room:Footer:ToggleChatButton', props);

    const handleToggleDrawer = useCallback(() => {
        onClick(!active);
    }, [active]);

    return (
        <Badge count={unreadCount} offset={[-10, 0]}>
            <Button
                iconName="MessageCircle"
                color={active ? 'lighten' : 'light-transparency'}
                onClick={handleToggleDrawer}
            />
        </Badge>
    );
}

ToggleChatButtonComponent.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    unreadCount: PropTypes.number,
};

const mapStateToProps = (state, { _feed }) => ({
    unreadCount: selectors.feeds.feedByIdSelector(state, _feed)?.unreadCount
});

export default connect(mapStateToProps, null)(injectIntl(memo(ToggleChatButtonComponent)));
