import memoize from 'lodash.memoize';
import values from 'lodash/values';

import { CONTACT_STATUS, ROLES } from 'const/user';

const _getStoreKeyByContactStatus = (contactStatus, isInitiator) => {

    let storeKey = null;
    switch (contactStatus) {

        case CONTACT_STATUS.NOT_CONNECTED:
            storeKey = 'usersNotConnected';
            break;
        case CONTACT_STATUS.ACCEPTED:
            storeKey = 'usersAccepted';
            break;
        case CONTACT_STATUS.PENDING:
            storeKey = isInitiator ? 'usersPendingInitiator' : 'usersPendingTarget';
            break;
        default:
            storeKey = 'users';

    }

    return storeKey;

};

export const isGuest = roles =>
    roles ? roles.indexOf(ROLES.GUEST) !== -1 : undefined;

export const isAdmin = roles =>
    roles ? roles.indexOf(ROLES.COMPANY_ADMIN) !== -1 || roles.indexOf(ROLES.ADMIN) !== -1 || roles.indexOf(ROLES.COMPANY_PARENT_ADMIN) !== -1 : undefined;

export const isUser = roles =>
    roles ? roles.indexOf(ROLES.USER) !== -1 : undefined;


const cacheKeyByParams = (...args) => values(args).join('_');

export const getStoreKeyByContactStatus = memoize(_getStoreKeyByContactStatus, cacheKeyByParams);
