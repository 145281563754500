import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styled from 'styled-components';

export const Style = styled.div`
    color: #FFF;
    position: absolute;
    left: 50%;
    width: 50px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: 20px;
    z-index: 3;
    background-color: rgba(0,0,0,0.55);
    padding: 5px 5px 5px 10px;
    border-radius: 30px;
    bottom: 20px;
    transition: all 250ms ease;
    opacity: 0;
    visibility: visible;
    transform: translateX(-50%) translateY(20px);
    display: flex;
    align-items: center;
    &.label {
        width: auto;
        padding: 5px 10px 5px 35px;
        height: auto;

        .loader{
            left: 10px;
            transform: translateX(0) translateY(-50%);
        }
        .label{
            font-size: 12px;
            font-weight: 500;
            white-space: nowrap;
        }
    }
    &.active{
        opacity: 1;
        visibility: visible;
        transform: translateX(-50%) translateY(0);
    }

    .loader{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 20px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        text-align: center;

        .dot{
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            -webkit-animation-duration: .5s;
            animation-duration: .5s;
            -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
        .dot1 {
            -webkit-animation-name: reveal;
            animation-name: reveal;
        }
        .dot1, .dot2 {
            left: 0;
        }
        .dot2, .dot3 {
            -webkit-animation-name: slide;
            animation-name: slide;
        }
        .dot3 {
            left: 8px;
        }
        .dot4 {
            -webkit-animation-name: reveal;
            animation-name: reveal;
            animation-direction: reverse;
            left: 16px;
        }
    }
    &.lighten {
        background-color: #EEE;

        .dot {
            background-color: #000000;
        }
        .label {
            color: #000000;
        }
    }
`;

const Spinner = ({ isActive = true, mode = 'lighten', message = null, autoFill = false, align = '' }) => (
    <Style
        className={classNames(
            'loading-container',
            align,
            {
                [`${mode}`]: mode,
                active: isActive,
                label: message,
                fill: autoFill
            })}
    >
        <div className="loader">
            <span className="dot dot1">&nbsp;</span>
            <span className="dot dot2">&nbsp;</span>
            <span className="dot dot3">&nbsp;</span>
            <span className="dot dot4">&nbsp;</span>
        </div>
        {message && <div className="label">{message}</div>}
    </Style>
);

Spinner.propTypes = {
    isActive: PropTypes.bool,
    autoFill: PropTypes.bool,
    align: PropTypes.oneOf([ '', 'center', 'top' ]),
    mode: PropTypes.oneOf(['lighten', 'darken']),
    message: PropTypes.string
};

export default Spinner;
