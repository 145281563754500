import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const File = ({ color = '#A0A2A7', withBorder = true }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="file-img" fill={color} fillRule="nonzero">
                {withBorder && <Border />}
            </g>
            <path
                d="M52.5263158,47 C46.2105263,47 41.122807,52.0877193 41.122807,58.4035088 L41.122807,81.2105263 C41.122807,84.9824561 44.1929825,88.0526316 47.9649123,88.0526316 C51.7368421,88.0526316 54.8070175,84.9824561 54.8070175,81.2105263 L54.8070175,58.4035088 L50.245614,58.4035088 L50.245614,81.2105263 C50.245614,82.4385965 49.1929825,83.4912281 47.9649123,83.4912281 C46.7368421,83.4912281 45.6842105,82.4385965 45.6842105,81.2105263 L45.6842105,58.4035088 C45.6842105,54.6315789 48.754386,51.5614035 52.5263158,51.5614035 C56.2982456,51.5614035 59.3684211,54.6315789 59.3684211,58.4035088 L59.3684211,81.2105263 C59.3684211,87.5263158 54.2807018,92.6140351 47.9649123,92.6140351 C41.6491228,92.6140351 36.5614035,87.5263158 36.5614035,81.2105263 L36.5614035,58.4035088 L32,58.4035088 L32,81.2105263 C32,90.0701754 39.1052632,97.1754386 47.9649123,97.1754386 C56.8245614,97.1754386 63.9298246,90.0701754 63.9298246,81.2105263 L63.9298246,58.4035088 C63.9298246,52.0877193 58.8421053,47 52.5263158,47 Z"
                id="Shape"
                fill={color}
                fillRule="nonzero"
            />
        </g>
    </svg>
);

File.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

export default File;
