import React from 'react';

export default props => (
    <svg 
        width="21px" 
        height="14px" 
        viewBox="0 0 21 14" 
        version="1.1" 
        xmlns="http://www.w3.org/2000/svg" 
        {...props}
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-21.000000, -625.000000)" fill="currentColor" fillRule="nonzero">
                <g transform="translate(20.000000, 621.000000)">
                    <g transform="translate(1.583333, 4.254338)">
                        <path d="M6.41666667,0.328995694 C2.85833333,0.328995694 0,3.18732903 0,6.74566236 C0,10.3039957 2.85833333,13.162329 6.41666667,13.162329 C9.975,13.162329 12.8333333,10.3039957 12.8333333,6.74566236 C12.8333333,3.18732903 9.975,0.328995694 6.41666667,0.328995694 Z M6.41666667,11.9956624 C3.5,11.9956624 1.16666667,9.66232903 1.16666667,6.74566236 C1.16666667,3.82899569 3.5,1.49566236 6.41666667,1.49566236 C9.33333333,1.49566236 11.6666667,3.82899569 11.6666667,6.74566236 C11.6666667,9.66232903 9.33333333,11.9956624 6.41666667,11.9956624 Z M8.575,8.08732903 C8.80833333,8.32066236 8.80833333,8.67066236 8.575,8.90399569 C8.45833333,9.02066236 8.34166667,9.07899569 8.16666667,9.07899569 C7.99166667,9.07899569 7.875,9.02066236 7.75833333,8.90399569 L6.00833333,7.15399569 C5.89166667,7.03732903 5.83333333,6.92066236 5.83333333,6.74566236 L5.83333333,3.24566236 C5.83333333,2.89566236 6.06666667,2.66232903 6.41666667,2.66232903 C6.76666667,2.66232903 7,2.89566236 7,3.24566236 L7,6.51232903 L8.575,8.08732903 Z M14.3660828,0 C14.7214781,0 15.0095828,0.288104763 15.0095828,0.6435 L15.0090828,3.7695 L17.8730828,3.7695 L16.8975546,2.73337516 C16.6783006,2.50049028 16.6663203,2.14732392 16.8547535,1.90142377 L16.9249779,1.82374201 C17.1837389,1.58012648 17.5909955,1.59240428 17.834611,1.85116526 L19.7956794,3.93392239 C20.0602803,4.17118204 20.0824448,4.57802039 19.8451851,4.84262128 L17.8451851,7.0730966 C17.6079255,7.33769749 17.2010871,7.35986195 16.9364862,7.1226023 C16.6718853,6.88534265 16.6497209,6.4785043 16.8869805,6.2139034 L17.9230828,5.0565 L14.3660828,5.05652469 C14.0106876,5.05652469 13.7225828,4.76841992 13.7225828,4.41302469 L13.7225828,0.6435 C13.7225828,0.288104763 14.0106876,0 14.3660828,0 Z"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
