import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { avatarProps } from 'components/Shared/User/Avatar';
import styled from 'styled-components';
import { AVATAR_SIZES } from 'const/user';
import { Avatar, Tooltip } from 'antd';
import { defaultColors } from 'common/themes/Colors';
import toArray from 'lodash/toArray';
import { getAvatarUrl } from 'services/user';
import classNames from 'classnames';

const GAP_INLINE_START = 4;
const Style = styled.div`
    &.square{
        display: flex;
        flex-direction: column;
        &.s-more-members{
            .ant-avatar{
                width: ${({size}) => (size / 2) + GAP_INLINE_START}px!important;
                height: ${({size}) => (size / 2) + GAP_INLINE_START}px!important;
                line-height: ${({size}) => (size / 2) + GAP_INLINE_START}px!important;
                font-size: 12px!important;
                background: ${props => props.theme.black};
                &-string{
                    line-height: ${({size}) => (size / 2) + GAP_INLINE_START}px!important;
                }
            }
        }
    }
`;

const getFirstletter = (name) => {
    // used to get smiley because '🙂'.length === 2 and lodash can handle that
    const arr = toArray(name);
    return arr.length <= 1 ? name : arr[0];
}

const MemberAvatarItem = ({ fullname, id, avatarUri }) => {
    const firstLetter = fullname?.length ? getFirstletter(fullname) : undefined;
    return (
        <Tooltip title={fullname} placement="top" key={id}>
            <Avatar
                src={avatarUri}
                style={avatarUri ? undefined : {
                    backgroundColor: defaultColors[(firstLetter ? firstLetter.charCodeAt(0) : 0) % defaultColors.length]
                }}

            >
                {!avatarUri && firstLetter}
            </Avatar>
        </Tooltip>
    );
}

export const MemberAvatarGroup = ({ members, size, ...props }) => members ? (
    <Avatar.Group
        {...props}
        size={size}
    >
        {members.map(({ fullname, id, avatarUri }) => (
            <MemberAvatarItem
                fullname={fullname}
                id={id}
                key={id}
                avatarUri={getAvatarUrl({ avatarUri }, size, size)}
            />
        ))}
    </Avatar.Group>
) : null;

const AvatarList = ({ members, size, maxCount, mode}) => {
    if (!members) return null;

    if (mode === 'square') {
        return (
            <Style 
                className={classNames(
                    'square',
                    { 's-more-members': members?.length > 1 }
                )}
                size={AVATAR_SIZES[size]}
            >
                <MemberAvatarGroup
                    size={size ? AVATAR_SIZES[size] : undefined}
                    members={members.slice(0,2)}
                />
                {members?.length > 2 && (
                    <MemberAvatarGroup
                        members={members.slice(2, members.length)}
                        max={{ count: 1 }}
                        size={size ? AVATAR_SIZES[size] : undefined}
                    />
                )}
            </Style>
        );
    }

    return (
        <MemberAvatarGroup
            members={members}
            max={{ count: maxCount }}
            size={size ? AVATAR_SIZES[size] : undefined}
        />
    );
}

AvatarList.propTypes = {
    members: PropTypes.arrayOf(PropTypes.shape({
        avatarUri: PropTypes.string,
        fullname: PropTypes.string,
        id: PropTypes.string
    })),
    size: PropTypes.oneOf(avatarProps.sizes),
    maxCount: PropTypes.number,
    mode: PropTypes.oneOf(['', 'square'])
};

export default memo(AvatarList);
