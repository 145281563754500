import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';
import { Field, Form as FinalForm } from 'react-final-form';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { LabelInput } from 'components/Shared/Forms';
import Button from 'components/Shared/Common/Button';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { getCurrentUserRolesSelector } from 'selectors/user';

const errorStyle = { color:'#e74c3c', fontSize: 14 };

const formatMessages = (intl) => ({
    missingFields: intl.formatMessage({ id: 'room.reception.missingFields' }),
    buttonJoinLabel: intl.formatMessage({
        id: 'room.reception.buttonJoinLabel',
        defaultMessage: 'Rejoindre la conférence'
    }),
    inputFirstname: intl.formatMessage({
        id: 'room.reception.input.firstname',
        defaultMessage: 'Prénom'
    }),
    inputLastname: intl.formatMessage({
        id: 'room.reception.input.lastname',
        defaultMessage: 'Nom'
    }),
});

const GuestLogin = (props) => {
    const {
        intl,
        triggerSubmit,
        onSubmit = () => {},
        validators,
        returnUrl,
        isAuthenticated,
        isWsOpen,
        isLoading,
        httpError
    } = props;
    const { 
        roomFirstnameLength, 
        roomLastnameLength 
    } = validators;

    const translations = useMemo(() => formatMessages(intl), [intl]);
    const roles = useSelector(getCurrentUserRolesSelector);

    useWhyDidYouUpdate('component:guestlogin', props);

    if (isAuthenticated && isWsOpen) {
        if (!roles) {
            window.location.href = returnUrl;
            return false;
        } 
        return <Redirect to={returnUrl} />;
        
    }

    if (isAuthenticated) {
        return false;
    }

    return (
        <FinalForm onSubmit={onSubmit}>
            {({ handleSubmit, submitting, form, invalid, submitFailed }) => (
                <Form onSubmit={handleSubmit} layout="vertical">
                    {submitFailed && invalid && <p style={errorStyle}>{translations.missingFields}</p>}
                    {httpError && <p style={errorStyle}>{httpError}</p>}
                    <Field
                        name="firstname"
                        component={LabelInput}
                        validate={roomFirstnameLength}
                        placeholder={translations.inputFirstname}
                    />
                    <Field
                        name="lastname"
                        component={LabelInput}
                        validate={roomLastnameLength}
                        placeholder={translations.inputLastname}
                    />
                    <div className="action-container jc-fe">
                        <Button
                            color={invalid ? 'lighten' : 'primary'}
                            onClick={form.submit}
                            isDisabled={triggerSubmit || submitting || isLoading}
                        >
                            {translations.buttonJoinLabel}
                        </Button>
                    </div>
                </Form>
            )}
        </FinalForm>
    );
};

GuestLogin.propTypes = {
    intl: PropTypes.any.isRequired,
    onSubmit: PropTypes.func,
};

export default injectIntl(GuestLogin);
