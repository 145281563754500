/* eslint-disable no-param-reassign */
import React, { createContext, memo, useCallback, useContext, useMemo } from 'react';
import { Space, Tooltip, Typography, ConfigProvider, Button as AntdButton } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { selectors } from '@amplement/backend-connector';
// import soundManager from 'services/soundManager';

const { Text } = Typography; 

type Peer =  {
    _peer?: SimplePeerType;
    _token?: string;
    _profile?: PeerProfile;
    __entity?: string;
    __client?: string;
    close: (s:string) => void;
}

type SimplePeerType = {
    _isNegotiating: boolean;
    _isRestarting: boolean;
    _needsNegotiation: () => void;
    destroy: (unknown) => void;
    streams: MediaStream[];
    _remoteStreams: MediaStream[]; 
    _iceComplete?: boolean;
    _pc: {
        connectionState: string
    };
    initiator: boolean;
}

type PeerProfile = {
    key: string;
    videoBitrate: number;
    audioBitrate: number;
    audio: boolean;
    video: boolean;
    screenSharing: boolean;
    screenSharingBitrate: number;
};

type PeerConfig = {
    token: string;
    settings: string;
    _client: string;
    profile: PeerProfile;
    _isReconnecting: boolean;
    _entity: string;
    isTrashable: boolean;
    pc: Peer;
};

type PeerCollection = {
    [key: string]: {
        [key: string]: PeerConfig
    }
}

// const Text = (props) => <AntdText {...props} style={{fontSize:6}} />
const Button = (props) => <AntdButton {...props} style={{padding:2}} />

declare global {
    interface Window { 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mycDebug: (a: number) => { PeerManager: { getPeers: () => PeerCollection }}; 
    }
}

const getPeers = () => window.mycDebug(1).PeerManager.getPeers();

const getOwnPcByProfileKey = (_client, profileKey) => Object.values(getPeers())
    ?.map((pcs) => Object.values(pcs).find(pc => pc._client === _client && (pc.profile.key === profileKey || !profileKey)))
    ?.find(x => !!x)?.pc;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipButtonComponent = ({ children, compact = false, ...props }: { children: string, compact?: boolean, [key: string]: any }) => (
    <Tooltip 
        title={children}
        placement="top"
    >
        <Button
            {...props}
        >
            {compact ? children?.[0] : children}
        </Button>
    </Tooltip>
);

const TooltipButton = memo(TooltipButtonComponent);

const PcStateButton = ({ onClick, state, ...props}) => (
    <TooltipButton 
        onClick={() => onClick(state)} 
        {...props}
    >
        {state}
    </TooltipButton>
);

const StateList = memo(({ compact = false, states, value, label, onClick, ...rest }: { compact: boolean, states: string[], value: string, label: string, onClick: (state: string) => void, [key: string]: unknown }) => (
    <Space size={compact ? 1 : 'small'} style={{ lineHeight: 0 }}>
        {label && <Text strong>{label}{states.includes(value) || value === undefined ? null : `(${value})`}:</Text>}<Text>{' '}{value}</Text>
        {states.map(key => (
            <PcStateButton 
                key={key} 
                state={key} 
                onClick={onClick} 
                // eslint-disable-next-line no-nested-ternary
                type={compact ? 'primary' : (value === key ? 'link' : 'success')}
                ghost={!compact}
                danger={value !== key && !!compact} 
                compact={compact}
                {...rest}
            >
                {key}
            </PcStateButton>
        ))}
    </Space>
));

const connectionStates = ['new', 'checking', 'connecting', 'connected','disconnected','closed','failed'];
const iceConnectionStates = ['new', 'checking', 'connected', 'completed', 'failed', 'disconnected', 'closed'];
const iceGatheringStates = ['new', 'gathering', 'complete'];

const PcContext = createContext<{
    _client?: string;
    profileKey?: string;
}>({
    _client: undefined,
    profileKey: undefined
});

const usePeerConnection = () => {
    const ctx = useContext(PcContext);

    const getPc = useCallback(() => ctx._client && ctx.profileKey ? getOwnPcByProfileKey(ctx._client, ctx.profileKey) : null, []);

    return useMemo(() => ({ ...ctx, getPc }), [ctx, getPc]);
}

const PcButtonIceState = ({ currentState, compact }) => {
    const { getPc } = usePeerConnection();
    const dispatch = useDispatch();

    const setReduxState = state => {
        const pc = getPc();

        dispatch({
            type: 'WRTC_ON_PC_ICE_STATE_CHANGE',
            payload: {
                _entity: pc?.__entity,
                token: pc?._token,
                iceConnectionState: state,
                profileKey: pc?._profile?.key
            }
        });
    };

    return (
        <StateList 
            label="IceCo" 
            compact={compact}
            states={iceConnectionStates} 
            value={currentState} 
            onClick={setReduxState}
        />
    );
}


const PcButtonGatheringState = ({ currentState, compact }) => {
    const { getPc } = usePeerConnection();
    const dispatch = useDispatch();

    const setReduxState = state => {
        const pc = getPc();

        dispatch({
            type: 'WRTC_ON_PC_ICE_STATE_CHANGE',
            payload: {
                _entity: pc?.__entity,
                token: pc?._token,
                iceGatheringState: state,
                profileKey: pc?._profile?.key
            }
        });
    };

    return (
        <StateList 
            label="IceGa" 
            compact={compact}
            states={iceGatheringStates} 
            value={currentState} 
            onClick={setReduxState}
        />
    );
}

const PcButtonConnState = ({ currentState, compact }) => {
    const { getPc } = usePeerConnection();
    const dispatch = useDispatch();

    const setReduxState = state => {
        const pc = getPc();

        dispatch({
            type: 'WRTC_CONNECTION_STATE_CHANGE',
            payload: {
                _entity: pc?.__entity,
                token: pc?._token,
                state,
                profileKey: pc?._profile?.key
            }
        });
    };

    return (
        <StateList 
            label="PcCon" 
            compact={compact}
            states={connectionStates} 
            value={currentState} 
            onClick={setReduxState}
        />
    );
}

const StreamStatus = memo(({ stream, _iceComplete }: { stream: MediaStream, _iceComplete: boolean | undefined }) => {
    const { muted, enabled, kind } = (stream?.getTracks()?.[0] || {}) as MediaStreamTrack;

    return (
        <Space size="small">
            <Text strong>StreamId({stream.id?.slice(0, 5)}): </Text>
            <Text>{kind}</Text>
            {stream.active ? <Text>active stream</Text> :<Text type="danger">inactive stream</Text>}
            -
            {!muted ? <Text>not muted</Text> :<Text type="danger">muted</Text>}
            -
            {enabled ? <Text>enabled track</Text> :<Text type="danger">disabled track</Text>}
            -
            {_iceComplete ? <Text>ice complete</Text> :<Text type="danger">ice incomplete</Text>}
        </Space>
    );
});

const PeerActions = ({ pc, compact }: { pc: Peer | undefined, compact: boolean }) => {
    const { initiator } = pc?._peer || { initiator: false };

    const handleClose = () => pc?.close('manualClose');
    const handleDestroy = () => pc?._peer?.destroy({code: 'manualDestroy' });
    const handleRestart = () => {
        if (pc?._peer) {
            pc._peer._isNegotiating = false
            pc._peer._isRestarting = true
            
            pc._peer._needsNegotiation()
        }
    };

    return (
        <Space>
            <TooltipButton 
                compact={compact} 
                onClick={handleClose}
                ghost
                danger
                type="primary"
            >
                Close PC
            </TooltipButton>
            <TooltipButton 
                compact={compact} 
                danger
                type="primary"
                onClick={handleDestroy}
                ghost
            >
                Destroy PC
            </TooltipButton>
            {initiator && (<TooltipButton 
                compact={compact} 
                danger
                type="primary"
                onClick={handleRestart}
                ghost
            >
                Renegociate PC
            </TooltipButton>
            )}
        </Space>
    )
}   

const PeerConnectionCard = ({ profileKey, label, _client, displayMode }: { profileKey: string, label: string, _client: string, displayMode: 'compact' | 'full' }) => {
    const pc = getOwnPcByProfileKey(_client, profileKey);
    const reduxState = useSelector(state => selectors.webRTC.getPcStateFromClient(state, pc?.__entity, pc?.__client, profileKey));
    const iceConn = useSelector(state => selectors.webRTC.getPcIceConnectionStateFromClient(state, pc?.__entity, pc?.__client, profileKey));
    const iceGather = useSelector(state => selectors.webRTC.getPcIceGatheringStateFromClient(state, pc?.__entity, pc?.__client, profileKey));
    const pcState = pc?._peer?._pc?.connectionState;
    const { streams, _remoteStreams, _iceComplete } = pc?._peer || {};
    const compact = displayMode !== 'full';

    const providerValue = useMemo(() => ({ profileKey, _client }), [profileKey, _client]);

    if (!pc) return null;
   
    return (
        <PcContext.Provider value={providerValue}>

            <div style={{ lineHeight: 0 }}>
                <Text underline strong>{label}{' '}</Text>
                <PeerActions pc={pc} compact={compact} />
                {pcState !== reduxState && (
                    <Tooltip 
                        title={`redux(${reduxState}) PC(${pcState})`}
                        placement="top"
                    >
                        <Text type="danger">WARN-Peerstate</Text>
                    </Tooltip>
                )}
            </div>

            {displayMode === 'full' &&
                    streams?.map((s) => (
                        <StreamStatus 
                            key={s?.id} 
                            stream={s} 
                            _iceComplete={_iceComplete} 
                        />
                    ))}
            {displayMode === 'full' &&
                    _remoteStreams?.map((s) => (
                        <StreamStatus 
                            key={s?.id} 
                            stream={s} 
                            _iceComplete={_iceComplete} 
                        />
                    ))}

            <PcButtonConnState compact={compact} currentState={reduxState} />
            <PcButtonIceState compact={compact} currentState={iceConn} />
            <PcButtonGatheringState compact={compact} currentState={iceGather} />
        </PcContext.Provider>
    );
}

// Object.values(Object.values(mycDebug(1).PeerManager.getPeers())[0])[0].pc._peer.destroy('plop')
const PeerDebugger = ({ _client, isMe, displayMode = 'compact' } : { _client: string, isMe: boolean, displayMode: 'compact' | 'full'}) => (
    <ConfigProvider
        theme={{
            token: {
                // Seed Token
                colorPrimary: '#00b96b',
                // borderRadius: 2,

                // Alias Token
                // colorBgContainer: '#f6ffed',

                fontSize: displayMode === 'compact' ? 9 : 12,
                lineHeight: 0
            },
            components: {
                Button: {
                //   contentFontSize: 8,
                    lineHeight: 0,
                    controlHeight: displayMode === 'compact' ? 10 : 15,

                },
                Typography: {
                    lineHeight: 0
                }
            }
        }}
    >
        <div style={{ display: 'flex', flexDirection: 'column', color:'white', paddingTop: displayMode === 'compact' ? 0 : 20 }}>
            {displayMode === 'full' && <Text 
                style={{ paddingBottom: 10, paddingTop: 10 }} 
                type="success"
            >{_client}{isMe ? ' (Me)' : null}</Text>}
            <PeerConnectionCard 
                displayMode={displayMode} 
                _client={isMe ? 'janus' : _client} 
                profileKey="screenSharingHigh" 
                label="SS" 
            />
            <PeerConnectionCard 
                displayMode={displayMode} 
                _client={isMe ? 'janus' : _client} 
                profileKey="videoLow" 
                label="videoLow" 
            />
            <PeerConnectionCard 
                displayMode={displayMode} 
                _client={isMe ? 'janus' : _client} 
                profileKey="videoHigh" 
                label="videoHigh" 
            />
            <PeerConnectionCard 
                displayMode={displayMode} 
                _client={isMe ? 'janus' : _client} 
                profileKey="audioLow" 
                label="audioLow" 
            />
        </div>
    </ConfigProvider>
);

export default PeerDebugger;
