import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import { usePrevious } from 'react-use';
import { LoadingOutlined } from '@ant-design/icons';

import FeedItemWrapper from 'components/Feed/FeedItemList/ItemWrapper';
import FeedItemStartupPlaceholder from 'components/Feed/FeedItem/FeedItemStartupPlaceholder';

const Styled = styled.div`
    display: flex;
    flex: 1;
    flex-shrink: 0;
    overflow-y: auto;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
`;

const StyledLoaderContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const StyledLoader = styled(LoadingOutlined)`
    font-size: 34px;
    color: #08c;
    z-index: 2;
`;

export function scrollToMessage(messageId, position) {
    const messageElement = document.getElementById(messageId);
    if (messageElement) {
        messageElement.scrollIntoView({ behavior: 'auto', block: position });
    }
}

const CustomScroll = (props) => {
    const [loadedCount, setLoadedCount] = useState(0);
    const [canScroll, setCanScroll] = useState(true);
    const [scrollId, setScrollId] = useState(null);

    const {
        items,
        _currentUser,
        isFetching = false,
        updateFeedItem,
        deleteFeedItem,
        onReplyFeedItem = () => {},
        isEndReached = false,
        _selectedFeedItem = null,
        _feed,
        isBottomEndReached = false,
        isAvatarClickable,
        hasStatus,
        setMustScroll,
        ItemComponent = FeedItemWrapper,
        mustScroll = false,
        handleLoadMore = () => {},
        _customFeedItem
    } = props;
    const [scrollToTop, setScrollToTop] = useState(false);
    const [scrollToBottom, setScrollToBottom] = useState(false);
    const firstPrevItem = usePrevious(items?.[0]);
    const lastPrevItem = usePrevious(items?.[items.length - 1]);
    const prevItems = usePrevious(items);
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const previousScrollHeightRef = useRef(0);

    const handleItemLoad = useCallback(() => {
        setLoadedCount(prev => prev + 1);
    }, []);

    const handleScroll = useCallback(() => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

            if (scrollTop === 0 && !isFetching && !isEndReached) {
                previousScrollHeightRef.current = scrollHeight;
                handleLoadMore(true);
            }

            if (scrollTop + clientHeight >= scrollHeight - 10 && !isFetching && !isBottomEndReached) {
                handleLoadMore(false);
            }
        }
    }, [handleLoadMore, isFetching, isBottomEndReached, isEndReached]);


    useEffect(() => {
        if (firstPrevItem && items?.length && firstPrevItem.id !== items[0].id) {
            setCanScroll(true);
            setScrollToTop(true);
            setScrollToBottom(false);
            setScrollId(firstPrevItem.id);
        } else if (lastPrevItem && items?.length && lastPrevItem.id !== items[items.length - 1].id) {
            setCanScroll(true);
            setScrollToTop(false);
            setScrollToBottom(true);
            setScrollId(lastPrevItem.id);
        } else if (mustScroll) {
            setCanScroll(true);
            setScrollToTop(false);
            setScrollToBottom(false);
            setScrollId(typeof mustScroll === 'boolean' && mustScroll ? _customFeedItem : mustScroll);
        }
    }, [items, mustScroll]);

    useEffect(() => {
        if (loadedCount >= items.length && items.length > 0 && canScroll) {
            if (prevItems?.length) {
                if (scrollToTop) {
                    scrollToMessage(scrollId, 'start');
                } else if (scrollToBottom) {
                    scrollToMessage(scrollId, 'end');
                } else {
                    scrollToMessage(scrollId || _customFeedItem, 'end');
                    setMustScroll(false);
                }
            } else {
                scrollToMessage(_customFeedItem, 'end');
            }
            setCanScroll(false);
        }
    }, [loadedCount, canScroll, scrollId]);

    useEffect(() => {
        if (scrollRef.current && prevItems && prevItems?.length < items.length) {
            const currentScrollHeight = scrollRef.current.scrollHeight;
            const heightDifference = currentScrollHeight - previousScrollHeightRef.current;

            scrollRef.current.scrollTop += heightDifference;
        }
    }, [items, prevItems]);

    return (
        <>
            <Styled
                className="scroll-list"
                data-testid="scroll-list"
                ref={scrollRef}
                onScroll={handleScroll}
            >
                {isEndReached && <FeedItemStartupPlaceholder _feed={_feed} />}
                {items.map((item, index) => (
                    <ItemComponent
                        onEdit={updateFeedItem}
                        onReply={onReplyFeedItem}
                        onDelete={deleteFeedItem}
                        _selected={_selectedFeedItem}
                        _currentUser={_currentUser}
                        isAvatarClickable={isAvatarClickable}
                        hasStatus={hasStatus}
                        _customFeedItem={item.id}
                        onLoad={handleItemLoad}
                        item={item}
                        index={index}
                        key={item?.id}
                    />
                ))}
            </Styled>
            {isFetching && (
                <StyledLoaderContainer>
                    <StyledLoader data-testid="loading-spinner" />
                </StyledLoaderContainer>
            )}
        </>
    );
};

export default memo(CustomScroll, isEqual);
