import { memo, useState } from 'react';
import PropTypes from 'prop-types';

import useInterval from 'hooks/useInterval';
import { utils } from '@amplement/backend-connector';

const Duration = ({ startedAt, delay = null }) => {
    const [duration, setDuration] = useState(1000);
    useInterval(() => setDuration(Date.now() - startedAt), delay);

    return utils.date.convertDurationToString(duration, { hideHoursIfnull: true });
};

Duration.propTypes = {
    startedAt: PropTypes.instanceOf(Date).isRequired,
    delay: PropTypes.number
};

export default memo(Duration);
