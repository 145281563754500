import React, { memo } from 'react';
import PropTypes from 'prop-types';

const FileAudioTemplate = ({ src }) => <audio src={src} controls />;

FileAudioTemplate.propTypes = {
    src: PropTypes.string.isRequired,
};

export default memo(FileAudioTemplate);
