import React from 'react';
import PropTypes from 'prop-types';

import { StyledText } from 'components/Shared/Forms/styles';

const Text = ({ value }) => <StyledText>{value}</StyledText>;

Text.propTypes = {
    value: PropTypes.string,
};

export default Text;
