import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import  { i18n, utils } from '@amplement/backend-connector';

const FormattedDate = ({
    startDate,
    isRelative,
}) => {
    let date;

    if (!startDate) {
        console.error('No date provided');
        date = null;
    } else if (isRelative) {
        const diffDays = utils.date.dateDiffInDays(new Date(), new Date(startDate));
        date = i18n.formatRelativeTime(diffDays, 'day', { numeric: 'auto' });
    } else {
        date = i18n.formatDate(new Date(startDate));
    }

    return date;
}

FormattedDate.propTypes = {
    intl: PropTypes.object.isRequired,
    startDate: PropTypes.any.isRequired,
    isRelative: PropTypes.bool,
};

export default injectIntl(FormattedDate);
