import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import DeviceControlButton from 'components/Shared/Call/DeviceControlButton';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import useMyStreamSelector from 'hooks/useMyStreamSelector';

import { isNativeScreenSharingSupported } from 'services/screenSharing';

const i18n = intl => ({ isOn }) => ({
    noDevice: null,
    denied: null,
    button: intl.formatMessage(
        { id: 'room.toggleScreen' },
        { isScreenOn: isOn }
    )
});

const DeviceControlButtonScreen = (props) => {
    const {
        _room,
        isDisabled = false,
        errorId,
        onClick,
        intl
    } = props;

    const { updatedAt, isSharingScreen: hasTrack, hasScreen } = useMyStreamSelector(_room) || {};

    useWhyDidYouUpdate('components:room:controls:DeviceControlButtonScreen', { ...props, updatedAt, hasTrack });

    return (
        <DeviceControlButton
            isDisabled={isDisabled}
            isChecked={hasTrack || hasScreen}
            onToggle={onClick}
            errorId={(!isNativeScreenSharingSupported ?
                'room.error.screenSharing.extensionNotInstalled' : errorId)}
            intl={intl}
            type="screen"
            mediaType={null}
            deviceType={null}
            btnOffLabel="MonitorOff"
            btnOnLabel="Monitor"
            formatter={i18n}
        />
    );
}

DeviceControlButtonScreen.propTypes = {
    intl: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
    _room: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool
};

export default injectIntl(DeviceControlButtonScreen);
