import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Typography, Badge, Grid, Button as AntdButton, Tooltip } from 'antd';
import styled from 'styled-components';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons';
import { connect, useSelector } from 'react-redux';
import { selectors, constants } from '@amplement/backend-connector';

import Icon from 'components/Shared/Common/Icon';
import Link from 'components/Shared/Common/Link';
import Button from 'components/Shared/Common/Button';

import VoiceMails from 'components/Shared/Drawers/Sip/VoiceMails/container';

import { colors } from 'common/themes/Colors';
import { StyledCard } from 'screens/Dashboard/styles';
import StyledCallButton, { CallButtonThemeProps } from 'components/CallBuilder/DefaultResults/style';

import useVoiceMails from 'hooks/useVoiceMails';
import { usePostRoom } from 'hooks/useRooms';
import useSipAccounts from 'hooks/useSipAccounts';
import useSipAccount from 'hooks/useSipAccount';

import { getUrl } from 'utils/url';

const { Title } = Typography;

const { useBreakpoint } = Grid;

const StyledIcon = styled(Icon)`
    svg {
        width: 26px;
        height: 26px;
    }
`;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledTitle = styled.div`
    margin-left: 10px !important;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;

    .label {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    h4 {
        font-weight: 400 !important;
        margin-right: 10px;
        margin-bottom: 0;

        @media (min-width: 1480px) and (max-width: 1600px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
        }

        @media (min-width: 1360px) and (max-width: 1480px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
        }

        @media (min-width: 1200px) and (max-width: 1360px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100px;
        }
    }
`;

const StyledCardWrapper = styled(StyledCard)`
    .ant-card-head {
        border-bottom: none;
    }
    .ant-card-body {
        padding: 0;
    }
`;

const CustomStyledCallButton = styled(StyledCallButton)<CallButtonThemeProps>`
    margin-left: 10px;
`;

const StyledActionsDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StyledButton = styled(AntdButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
`;

type VoiceMailCardPropsType = {
    unreadVoiceMailCount: number;
} & WrappedComponentProps;

export const VoiceMailCard = ({ intl, unreadVoiceMailCount }: VoiceMailCardPropsType): JSX.Element => {
    const { lg, xl, xxl } = useBreakpoint();
    const { data = [] } = useVoiceMails({
        limit: 20,
        page: 1,
        voiceMailCount: unreadVoiceMailCount
    });
    const [voiceMails, setVoiceMails] = useState(data);
    const _client = useSelector<string>(selectors.session._currentClientSelector);
    const { data: accounts } = useSipAccounts();
    const { id: _sipAccount } = accounts?.[0] || {};
    const { data: account, isLoading } = useSipAccount({ _sipAccount });
    const { phone: { voicemailNumber = '' } = {} } = account || {};

    const { mutateAsync: post } = usePostRoom({ _client });

    const handleCall = useCallback(async () => {
        const payload = {
            destNumbers: [String(voicemailNumber)],
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response = await post({ payload, type: constants.rooms.ROOM_TYPE.AUDIO });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response?.data) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const roomUrl = getUrl('room', { _room: response?.data?.id }) as string;
            const a = document.createElement('a');
            a.href = roomUrl;
            a.target = '_blank';
            a.click();
        }
    } , [voicemailNumber, post]);

    const isLargeScreen = useMemo(() => lg || xl || xxl, [lg, xl, xxl]);

    const cardTitle = useMemo(() => (
        <StyledContainer>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <StyledIcon bgColor={colors.black} iconName="Voicemail" />
            <StyledTitle>
                <div className="label">
                    <Title level={4}>{intl.formatMessage({ id: 'call.forward.type.voicemail' })}</Title>
                    <Badge overflowCount={999} count={unreadVoiceMailCount} />
                    {isLoading && <LoadingOutlined style={{ marginLeft: 10 }} />}
                </div>
                <StyledActionsDiv>
                    {voicemailNumber && (
                        <CustomStyledCallButton>
                            <Button
                                iconName="Phone"
                                onClick={handleCall}
                            >
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                {isLargeScreen && <p>{!xxl ? voicemailNumber : `${intl.formatMessage({ id: 'call.call' })} ${voicemailNumber}`}</p>}
                            </Button>
                        </CustomStyledCallButton>
                    )}
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <Link
                        href={getUrl('voicemail')}
                        className="text"
                    >
                        <Tooltip
                            placement="top"
                            title={intl.formatMessage({ id: 'global.button.showMore' })}
                        >
                            <StyledButton shape="circle">
                                <EllipsisOutlined />
                            </StyledButton>
                        </Tooltip>
                    </Link>
                </StyledActionsDiv>
            </StyledTitle>
        </StyledContainer>
    ), [intl, unreadVoiceMailCount, isLargeScreen, handleCall, voicemailNumber, isLoading, xxl]);

    useEffect(() => {
        setVoiceMails((prevData) => {
            const currentIds = new Set(prevData.map(vm => vm.id));
            const newVoiceMails = data.filter(vm => !currentIds.has(vm.id));
            return [...newVoiceMails, ...prevData];
        });
    }, [data]);

    return (
        <StyledCardWrapper title={cardTitle}>
            <VoiceMails
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                /* @ts-ignore */
                setVoiceMails={setVoiceMails}
                data={voiceMails}
                unreadOnly
                shortView
            />
        </StyledCardWrapper>
    )
};

const mapStateToProps = (state) => ({
    unreadVoiceMailCount: selectors.voiceMails.unreadVoiceMailCountSelector(state),
})

export default connect(mapStateToProps, null)(injectIntl(VoiceMailCard));
