import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Style } from './style';

const Toast = (props) => {
    const {
        classPrefix = '',
        style = {},
        children,
        onClose,
        className = '',
    } = props;
    const componentClass = `${classPrefix}-toast`;
    const customClass = {
        [`${componentClass}`]: 1,
        [className]: !!className
    };

    return (
        <Style
            className={classNames(customClass)}
            style={style}
        >
            <div className={`${componentClass}-content`}>
                {React.cloneElement(
                    children,
                    { onClose }
                )}
            </div>
        </Style>
    );
};

Toast.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    classPrefix: PropTypes.string,
    style: PropTypes.object,
    onClose: PropTypes.func.isRequired
};

export default Toast;
