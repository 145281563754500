import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Shared/Common/Icon';
import styled from 'styled-components';
import { rgba } from 'polished';
import { FormattedDate, FormattedTime, injectIntl } from 'react-intl';
import classNames from 'classnames';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { Checkbox, Typography } from 'antd';

const { Text } = Typography;

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;
    transition: all 250ms ease;
    &.checked{
        .checkbox{
            margin-right: 10px;
        }
    }
    &.unread{
        font-weight: bold;
    }
    .line-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        transition: all 250ms ease;
        .content{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            transition: all 250ms ease;
        }
    }
    .icon-container{
        display: flex;
        align-items: center;
        margin-right: 20px;
        justify-content: center;
    }
    .new-container{
        display: flex;
        margin-right: 30px;
    }
    .text-container{
        display: flex;
        flex-direction: column;
        flex: 1;
        font-size: 16px;
        color: ${({theme}) => theme.black};
        .subtitle{
            font-size: 14px;
            color: ${({theme}) => rgba(theme.black, 0.6)};
        }
    }
    .meta-container{
        text-align: right;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: ${({theme}) => rgba(theme.black, 0.6)};
    }
`;

const VoiceMailItemSummary = (props) => {
    const {
        intl,
        id,
        caller = {},
        datetime,
        readState = 'read',
        selectable = false,
        onChange,
        checked = false,
    } = props;
    const isRead = readState === 'read';
    const hasNumber = caller.number && caller.number !== caller.name;

    useWhyDidYouUpdate('component:VoiceMailItemSummary', props);

    const handleChange = useCallback(event => {
        onChange(id, event.target.checked);
        return event.stopPropagation();
    }, [onChange]);

    return (
        <Styled className={classNames(
            { unread: !isRead},
            { checked: selectable},
        )}>
            <div className="line-container">
                {selectable &&(
                    <div className="checkbox">
                        <Checkbox
                            checked={checked}
                            onClick={handleChange}
                        />
                    </div>
                )}
                <div className="icon-container">
                    <Icon iconName="Voicemail" />
                </div>
                <div className="text-container">
                    <div className="title">
                        {caller.name}
                    </div>
                    {hasNumber && (
                        <div className="subtitle">
                            {caller.localizedNumber}
                        </div>
                    )}
                </div>
                {!isRead && (
                    <Text className="new-container" type="danger">
                        {intl.formatMessage({ id: 'global.new' })}
                    </Text>
                )}
                <div className="meta-container">
                    <span>
                        <FormattedDate
                            value={datetime}
                            year="numeric"
                            month="long"
                            day="2-digit"
                        />
                    </span>
                    <span>
                        <FormattedTime
                            value={datetime}
                        />
                    </span>
                </div>
            </div>
        </Styled>
    );
}

VoiceMailItemSummary.propTypes = {
    selectable: PropTypes.bool,
    intl: PropTypes.any.isRequired,
    id: PropTypes.number.isRequired,
    caller: PropTypes.object,
    datetime: PropTypes.string,
    readState: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool
};

export default memo(injectIntl(VoiceMailItemSummary));
