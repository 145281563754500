import { useState, useEffect, useRef, memo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Portal = memo(({ id, children }) => {
    const el = useRef(document.getElementById(id) || document.createElement('div'));
    const [dynamic] = useState(!el.current.parentElement)
    useEffect(() => {
        if (dynamic) {
            el.current.id = id;
            document.getElementById('app').appendChild(el.current);
        }
        return () => {
            if (dynamic && el.current.parentElement) {
                el.current.parentElement.removeChild(el.current);
            }
        }
    }, [id]);
    return createPortal(children, el.current);
});

Portal.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
};

export default Portal;
