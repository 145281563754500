import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import { Form as FinalForm, Field } from 'react-final-form';
import { injectIntl } from 'react-intl';

import { LabelSelect } from 'components/Shared/Forms';

const buttonStyle = { float: 'right' };

const formatMessages = (intl) => ({
    select: intl.formatMessage({ id: 'global.select' }),
    confirm: intl.formatMessage({ id: 'global.button.confirm' }),
    remove: intl.formatMessage({ id: 'global.button.remove' }),
    enable: intl.formatMessage({ id: 'call.tabs.forwards.enable' }),
    disable: intl.formatMessage({ id: 'call.tabs.forwards.disable' }),
});

const BulkForm = ({ onSubmit = () => {}, intl }) => {
    const translations = useMemo(() => formatMessages(intl), [intl]);

    const options = useMemo(() => [
        {
            value: 'delete',
            label: translations.remove
        },
        {
            value: 'enable',
            label: translations.enable
        },
        {
            value: 'disable',
            label: translations.disable
        }
    ], [translations]);

    return (
        <FinalForm onSubmit={onSubmit}>
            {({ handleSubmit, pristine, submitting, valid, form }) => (
                <Form onSubmit={handleSubmit}>
                    <Field
                        component={LabelSelect}
                        name="option"
                        label={translations.select}
                        options={options}
                    />
                    <Button
                        style={buttonStyle}
                        onClick={form.submit}
                        type="primary"
                        disabled={pristine || submitting || !valid}
                    >
                        {translations.confirm}
                    </Button>
                </Form>
            )}
        </FinalForm>
    );
};

BulkForm.propTypes = {
    onSubmit: PropTypes.func,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(BulkForm);
