import React, { memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PhoneKeyboard from 'components/Shared/Call/PhoneKeyboard';
import UnsupportedBrowserComponent from 'components/LeftMenu/Rooms/UnsupportedBrowser';
import { isWebRtcCompatible } from 'utils/webRTC';
import { Form } from 'antd';
import { injectIntl } from 'react-intl';
import Button from '../Common/Button';
import PhoneKeyboardViewer from './PhoneKeyboardViewer';
import { ButtonActions } from '../styles';

const Style = styled.div`
    >.ant-form{
        margin-top: 20px;
    }
    &.call,
    &.phone {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        justify-content: center;
        flex-direction: column;
    }
`;

const SimplePhone = ({
    onKeyPress,
    onChange,
    intl,
    defaultNumber = '',
    onCall = () => {},
    prefix,
    hasCallButton = true,
    isDisabled = false,
    className = '',
    form: formProps
}) => {
    const [newForm] = Form.useForm();
    const form = formProps || newForm;

    if (!isWebRtcCompatible()) {
        return <UnsupportedBrowserComponent />;
    }

    useEffect(() => {
        if (form && onChange) onChange(form.getFieldValue('number'));
    }, []);

    const shouldUpdate = useCallback((prevValues, currentValues) =>
        prevValues.number !== currentValues.number);

    const handleSave = useCallback((values) => {
        if (values?.number) {
            onCall(values?.number);
        }
    }, [onCall]);

    const handleClickCall = useCallback(form.submit, [form]);

    const handleDigitClick = useCallback((digit) => {
        form.setFieldsValue({ number: `${form.getFieldValue('number')}${digit}` });
        onKeyPress(digit);
    }, [onKeyPress, form]);

    const handleChange = useCallback((values) => {
        if (values?.number !== undefined && onChange) onChange(values.number);
    }, [onKeyPress, form]);

    const handleRemoveLastChar = useCallback((e) => {
        e.stopPropagation();
        const values = { number: form.getFieldValue('number').slice(0, -1) };
        form.setFieldsValue(values);
        handleChange(values);
    }, [form]);

    const validators = {
        required: { required: true, message: intl.formatMessage({ id: 'form.input.error.required' }) }
    };

    const {
        required,
    } = validators;

    return (
        <Style className={className}>
            <Form
                initialValues={{ number: defaultNumber }}
                onFinish={handleSave}
                onValuesChange={handleChange}
                form={form}
            >
                <Form.Item
                    shouldUpdate={shouldUpdate}
                    noStyle
                >
                    {({ getFieldValue }) => (
                        <>
                            <Form.Item
                                name="number"
                                validate={[required]}
                                noStyle
                            >
                                <PhoneKeyboardViewer
                                    onSubmit={handleClickCall}
                                    isDisabled={isDisabled || (!onChange && !hasCallButton)}
                                />
                            </Form.Item>
                            <PhoneKeyboard
                                hasRemoveLastChar={!!getFieldValue('number')}
                                onRemoveLastChar={handleRemoveLastChar}
                                onKeyPress={handleDigitClick}
                                isDisabled={isDisabled}
                                prefix={prefix}
                                className={className}
                            />
                            {hasCallButton && (
                                <ButtonActions className="centered">
                                    <Button
                                        iconName={isWebRtcCompatible() ? 'Phone' : 'PhoneOff'}
                                        color="primary"
                                        size="xl"
                                        isDisabled={!getFieldValue('number') || isDisabled}
                                        onClick={handleClickCall}
                                    />
                                </ButtonActions>
                            )}
                        </>
                    )}
                </Form.Item>
            </Form>
        </Style>
    );
};

SimplePhone.propTypes = {
    onKeyPress: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    defaultNumber: PropTypes.string,
    className: PropTypes.string,
    onCall: PropTypes.func,
    prefix: PropTypes.string,
    hasCallButton: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

export default memo(injectIntl(SimplePhone));
