import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Dropdown, Button, Typography } from 'antd';
import { DownOutlined, PhoneOutlined, PrinterOutlined, MobileOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import usePhonenumber from 'hooks/usePhonenumber';

const { Text } = Typography;

const getIconName = type => {
    if (type === 'fax_phone') {
        return <PrinterOutlined />;
    }
    if (type === 'mobile_phone') {
        return <MobileOutlined />;
    }
    return <PhoneOutlined />;
}

const arrow = { pointAtCenter: true };
const PhoneAction = ({
    phones = [],
    intl
}) => {
    const { makeCall, formatProps } = usePhonenumber({ prefix: true });

    if (phones?.length === 0) {
        return null;
    }

    if (phones?.length === 1) {
        const { type, displayNumber, callNumber } = phones[0] || {};

        return <Button {...formatProps(callNumber)}>{getIconName(type)} {displayNumber}</Button>
    }

    const menu = useMemo(() => ({
        items: phones.map(phone => ({
            key: phone.callNumber,
            label: phone.displayNumber,
            icon: getIconName(phone.type)
        })),
        onClick: (e) => makeCall(e.key)
    }), [phones, makeCall]);

    return (
        <Dropdown menu={menu} placement="bottom" arrow={arrow}>
            <Button style={{maxWidth: '100%'}}>
                <Text ellipsis style={{maxWidth: '90%'}}>
                    {intl.formatMessage({ id: 'call.text.selectNumberToCall' })}
                </Text>
                <DownOutlined />
            </Button>
        </Dropdown>
    );
};

PhoneAction.propTypes = {
    phones: PropTypes.array,
    intl: PropTypes.any.isRequired,
};

export default injectIntl(memo(PhoneAction, (prev, next) => prev.phones === next.phones));
