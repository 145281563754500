/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useCallback } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Logger from 'services/debug/logger';
import FullscreenLoader from 'components/Shared/Layout/FullscreenLoader';
import UnsupportedBrowserComponent from 'components/LeftMenu/Rooms/UnsupportedBrowser';
import { isWebRtcCompatible } from 'utils/webRTC';
import DeviceManager from 'components/Room/Helpers/DeviceManager';
import { isNativeScreenSharingSupported } from 'services/screenSharing';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { isMobile } from "react-device-detect";
import StreamManager from '@amplement/backend-connector/dist/utils/streamManager';
import { useSelector } from 'react-redux';
import { getAvailableDevices } from 'selectors/roomSettings';
import { RequestPermission, PermissionDenied, Configure, UnsupportedScreenSharing } from './Screens';
import { Layout } from './Layouts';

const logger = new Logger('component:room:settings:Requirements');

export const STEPS = {
    INIT: 'INIT',
    WEBRTC_NOT_SUPPORTED: 'WEBRTC_NOT_SUPPORTED',
    SCREEN_SHARING_NOT_SUPPORTED: 'SCREEN_SHARING_NOT_SUPPORTED',
    GET_PERMISSIONS: 'GET_PERMISSIONS',
    LIST_DEVICES: 'LIST_DEVICES',
    GET_STREAM: 'GET_STREAM',
    REQUEST: 'REQUEST',
    CONFIGURE: 'CONFIGURE',
    COMPLETE: 'COMPLETE',
    PERMISSION_DENIED: 'PERMISSION_DENIED'
}

type StepsKeys = keyof typeof STEPS;
type Step = (typeof STEPS)[StepsKeys];

type DeviceSettings = {
    videoinput?: string | null;
    audioinput?: string | null;
    audiooutput?: string | null;
}

type RequirementsProps = {
    user?: { avatarUri?: string | null};
    audio?: boolean;
    video?: boolean;
    initialConfig?: DeviceSettings;
    skipNextTime?: boolean;
    onComplete: (settings: DeviceSettings) => void;
    setMediaDevicePermissionState: (state: { state: PermissionState, name: string }) => void;
} & WrappedComponentProps;

const matchDevice = (currentDeviceId?: string | null, availableDevicesIds?: string[]) : boolean => 
    currentDeviceId === undefined || currentDeviceId === 'default' || !currentDeviceId || availableDevicesIds?.indexOf(currentDeviceId) !== -1

const compareWithCurrentlyPluggedDevices = (defaultDevices: DeviceSettings, currentlyPlugged: MediaDeviceInfo[]): DeviceSettings => {
    let devices: DeviceSettings = defaultDevices;

    if (devices && Object.keys(devices).length) {
        // check if deviceId still exist (in case of user unplug his device)
        logger.log('Device currentlyPlugged:', currentlyPlugged)
        const presentDevice = (currentlyPlugged || []).map(x => x.deviceId);
        const isValidVideoDeviceId = matchDevice(devices.videoinput, presentDevice);
        const isValidAudioinputDeviceId = matchDevice(devices.audioinput, presentDevice);
        const isValidAudiooutputDeviceId = matchDevice(devices.audiooutput, presentDevice);

        // si un des perifs est unplug, on configure pour vérifier que tout va bien
        if (!isValidVideoDeviceId || !isValidAudioinputDeviceId || !isValidAudiooutputDeviceId) {
            devices = {
                videoinput: isValidVideoDeviceId ? devices.videoinput : 'default',
                audioinput: isValidAudioinputDeviceId ? devices.audioinput : 'default',
                audiooutput: isValidAudiooutputDeviceId ? devices.audiooutput : 'default'
            };
            logger.log('Almost 1 device is unplugged:', isValidVideoDeviceId, isValidAudioinputDeviceId, isValidAudiooutputDeviceId, devices);
            return devices;
        }
        return defaultDevices;
    }
    return defaultDevices;
}

const Requirements = (props: RequirementsProps) => {
    const {
        intl,
        user,
        audio = false,
        video = false,
        initialConfig,
        skipNextTime = false,
        onComplete,
        setMediaDevicePermissionState
    } = props;
    const devices: MediaDeviceInfo[] = useSelector(getAvailableDevices);

    let initialStep: Step = STEPS.GET_PERMISSIONS;
    if (!isWebRtcCompatible()) {
        initialStep = STEPS.WEBRTC_NOT_SUPPORTED;
    } else if (!isMobile && video && !isNativeScreenSharingSupported && skipNextTime !== true) {
        initialStep = STEPS.SCREEN_SHARING_NOT_SUPPORTED;
    }
    const [step, setStep] = useState<Step>(initialStep);
    const [settings, setSettings] = useState(initialConfig || {});
    const { avatarUri: avatar} = user || {};

    const handleReload = useCallback(() => window.location.reload(), []);
    const handleRequest = useCallback(() => setStep(STEPS.GET_STREAM), [setStep]);

    useWhyDidYouUpdate('component:room:settings:Requirements', { ...props, initialStep, step, settings });

    const handleCheckPermissionGranted = ({ name, state }: PermissionStatus, cb) => {
        logger.log('handleCheckPermissionGranted', name, state);
        if (state === 'granted') {
            cb();
            return true;
        }
        if (state === 'prompt') {
            setStep(STEPS.REQUEST);
            return false;
        } 
        if (state === 'denied') {
            setStep(STEPS.PERMISSION_DENIED);
            return false;
        }
        return true;
    }

    const handleRequestPermissions = async () => {
        const nextStep = STEPS.LIST_DEVICES;

        try {
            const audioPerms = await DeviceManager.getPermissionStatus('microphone');
            logger.log('handleRequestPermissions:audioPerms: ', audioPerms);

            setMediaDevicePermissionState({ state: audioPerms.state, name: audioPerms.name });
            handleCheckPermissionGranted(audioPerms, async () => {
                if (video) {
                    const videoPerms = await DeviceManager.getPermissionStatus('camera');
                    logger.log('handleRequestPermissions:videoPerms: ', videoPerms);

                    setMediaDevicePermissionState({ state: videoPerms.state, name: videoPerms.name });
                    handleCheckPermissionGranted(videoPerms, () => setStep(nextStep));
                } else {
                    setStep(nextStep);
                }
            });

        } catch (e) {
            logger.error('handleRequestPermissions:Permission fail', e);
            setStep(STEPS.REQUEST);
        }
    }

    const handleGetStream = async (constraints) => {
        const nextStep = STEPS.CONFIGURE;
        const hasVideoConstraint: boolean = constraints.video || constraints.video === '';
        const stream: MediaStream = (constraints.audio || hasVideoConstraint) && await DeviceManager.getStream(constraints);

        if (stream) StreamManager.setStreamTrackEnabled(stream, 'video', false, true); // a virer si on veux persister le stream
        if (stream) StreamManager.setStreamTrackEnabled(stream, 'audio', false, true); // a virer si on veux persister le stream
        const checkVideoSettings = video ? settings.videoinput : settings.audioinput;
        if (settings && Object.keys(settings).length > 0 && skipNextTime && checkVideoSettings) {
            logger.log('GUM request:Complete', constraints, settings, skipNextTime);
            onComplete(settings);
        } else {
            logger.log('GUM request:Need configure', constraints, settings, skipNextTime);
            setStep(nextStep);
        }
    }

    const handleCheckStream = async () => {
        const nextStep = STEPS.CONFIGURE;
        const constraints = {
            audio: audio && devices?.some(d => d.kind === 'audioinput') ? settings.audioinput : false,
            video: video && devices?.some(d => d.kind === 'videoinput') ? settings.videoinput : false
        };

        logger.log('GUM request', constraints, settings, skipNextTime);
        try {
            await handleGetStream(constraints);
            setMediaDevicePermissionState({ state: 'granted', name: 'all' });
        } catch (e) {
            try {
                await handleGetStream({ audio: settings.audioinput });
                setMediaDevicePermissionState({ state: 'granted', name: 'audio' });
            } catch (err) {
                logger.error('GUM request:Error', err, constraints, settings, skipNextTime);
                setStep(nextStep);
            }
        }
    }

    useEffect(() => {
        if (step === STEPS.LIST_DEVICES) {
            const nextStep = STEPS.GET_STREAM;

            if (devices && Object.keys(devices).length) {
                const checkedDevices = compareWithCurrentlyPluggedDevices(settings, devices);
                if (checkedDevices === settings) {
                    logger.log('Device settings: done:', checkedDevices === settings, devices, checkedDevices, settings);
                    setSettings(checkedDevices);
                    setStep(nextStep);
                } else { // if almost one device change
                    logger.log('Device settings: not valid', checkedDevices === settings, devices, checkedDevices, settings);
                    setStep(STEPS.CONFIGURE);
                }
            } else if (devices !== undefined) {
                logger.error('STEPS.LIST_DEVICES: no devices');
                setStep(STEPS.REQUEST);
            }
        }
    }, [step, devices]);

    useEffect(() => {
        if (step === STEPS.GET_PERMISSIONS) {
            handleRequestPermissions();
        }
        if (step === STEPS.GET_STREAM) {
            handleCheckStream();
        }
    }, [step]);

    const setPermissionStep = useCallback(() => setStep(STEPS.GET_PERMISSIONS), []);
    if (step === STEPS.SCREEN_SHARING_NOT_SUPPORTED) {
        return (
            <Layout title="" size="sm">
                <UnsupportedScreenSharing
                    onProceed={setPermissionStep}
                />
            </Layout>
        );
    }
    if (step === STEPS.WEBRTC_NOT_SUPPORTED) {
        return (
            <Layout title="" size="sm">
                <UnsupportedBrowserComponent />
            </Layout>
        );
    }
    if (step === STEPS.REQUEST) {
        return (
            <Layout>
                <RequestPermission
                    /*
                    // @ts-ignore */
                    audio={audio}
                    /*
                    // @ts-ignore */
                    video={video}
                    /*
                    // @ts-ignore */
                    avatar={avatar}
                    /*
                    // @ts-ignore */
                    onRequest={handleRequest}
                />
            </Layout>
        );
    }
    if (step === STEPS.PERMISSION_DENIED) {
        return (
            <Layout>
                <PermissionDenied
                    /*
                    // @ts-ignore */
                    avatar={avatar}
                    /*
                    // @ts-ignore */
                    onReload={handleReload}
                />
            </Layout>
        );
    }
    if (step === STEPS.CONFIGURE) {
        return (
            <Layout>
                <Configure
                    onSubmit={onComplete}
                    submitLabel={intl.formatMessage({ id: 'room.joinRoom' })}
                    audio={audio}
                    video={video} 
                    showSkip={undefined} 
                    footerButtonProps={undefined} 
                    layoutDirection={undefined}                
                />
            </Layout>
        );
    }
    if (Object.keys(settings).length === 0) {
        if (step === STEPS.GET_STREAM) {
            return (
                <Layout size="sm">
                    {intl.formatMessage({ id: 'rtc.modal.requestPermission.all.inprogress' })}
                </Layout>
            );
        }
        return <Layout />;
    }
    return <FullscreenLoader />;
};

Requirements.propTypes = {
    initialConfig: PropTypes.shape({
        videoinput: PropTypes.string,
        audioinput: PropTypes.string,
        audiooutput: PropTypes.string
    }),
    intl: PropTypes.any.isRequired,
    user: PropTypes.shape({
        avatarUri: PropTypes.string
    }),
    skipNextTime: PropTypes.bool,
    onComplete: PropTypes.func.isRequired,
    audio: PropTypes.bool,
    video: PropTypes.bool
};

export default injectIntl(Requirements);
