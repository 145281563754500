import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Logger from 'services/debug/logger';
import { getCompanyLanguageSelector } from 'selectors/company';
import Page404Error from 'screens/404';
import { redirectToHome } from './Logout';

const logger = new Logger('component:redirect:Home');

const RedirectToHome = ({ language }) => {
    logger.log('render', language);

    if (!language) {
        logger.log('Redirect to 404 page');
        return <Page404Error />;
    }

    logger.log('Redirect to home');
    return redirectToHome(language);
};

RedirectToHome.propTypes = {
    language: PropTypes.string
};

const mapStateToProps = state => ({
    language: getCompanyLanguageSelector(state)
});

export default connect(mapStateToProps)(RedirectToHome);
