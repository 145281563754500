import React, { forwardRef } from 'react';
import { StyledLabel } from 'components/Shared/Forms/styles';

export default (Component, extraProps = {}) => forwardRef(({
    input,
    className,
    meta,
    children,
    hasFeedback,
    label,
    withRef, // eslint-disable-line
    rules, // eslint-disable-line
    ...rest
}, ref) => {
    const hasError = (meta.touched || meta.dirty) && meta.invalid;
    return (
        <StyledLabel
            label={label}
            validateStatus={hasError ? "error" : "success"}
            hasFeedback={hasFeedback && hasError}
            help={hasError && meta.error}
            className={className}
        >
            <Component
                {...extraProps}
                {...input}
                {...rest}
                ref={ref}
                meta={meta}
            >
                {children}
            </Component>

        </StyledLabel>
    );
});
