import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useToggle } from 'react-use';

import ContactSelectorModal from 'components/Room/AntdModal/ContactSelectorModal';

const ForwardSipUserSearch = (props) => {
    const {
        intl,
        onConfirm,
        TriggerComponent,
        phoneFilterTypes,
        type
    } = props;
    const [isModalOpened, toggleModalOpened] = useToggle(false);

    return (
        <>
            <ContactSelectorModal
                title={intl.formatMessage({ id: 'contacts.tab.businessContacts' })}
                isOpen={isModalOpened}
                isAutoScroll
                onClose={() => toggleModalOpened(false)}
                onSubmit={onConfirm}
                submitText={intl.formatMessage({ id: 'global.button.validate' })}
                phoneFilterTypes={phoneFilterTypes}
                type={type}
            />
            {TriggerComponent && (
                <TriggerComponent onClick={toggleModalOpened} />
            )}
        </>
    );
}

ForwardSipUserSearch.propTypes = {
    intl: PropTypes.any.isRequired,
    onConfirm: PropTypes.func.isRequired,
    TriggerComponent: PropTypes.any,
    phoneFilterTypes: PropTypes.array,
    type: PropTypes.string,
};

export default memo(injectIntl(ForwardSipUserSearch));
