import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { Typography, Row, Pagination, Grid } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import Icon from 'components/Shared/Common/Icon';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import styled from 'styled-components';

import useSearch from 'hooks/useSearch';

import FileList from 'components/FileList';
import MessageList from 'components/MessageList';
import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import { StyledPageLayout } from 'components/Shared/style';
import injectValidators from 'components/Shared/Forms/injectValidators';
import { StyledInput } from 'components/Shared/Forms/styles';
import { StyledTabs } from 'components/Shared/styles';

import { getUrl } from 'utils/url';


const { Text } = Typography;
const { useBreakpoint } = Grid;

const StyledText = styled(Text)`
    margin-top: 5px;
`;

const StyledLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-badge{
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .icon{
        display: flex;
        align-items: center;
        margin-right: 5px;
        svg{
            width: 14px;
            height: 14px;
        }
    }
`;

function getCurrentBreakpoint(screens) {
    const breakpoints = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];

    return breakpoints.find(bp => screens[bp]) || null;
}

// get page size options based on current breakpoint
function getPageSizeOptions(screens) {
    switch (getCurrentBreakpoint(screens)) {
        case 'xs':
        case 'sm':
            return [10, 20];
        case 'md':
            return [10, 20];
        case 'lg':
            return [12, 18];
        case 'xl':
            return [12, 20];
        case 'xxl':
            return [12, 18];
        default:
            return [12, 18];
    }
}

const StyledFileResults = styled.div`
    margin: 20px 0;
    font-size: 12px;
`;
const StyledRow = styled(Row)`
    width: 100%;
`;

const StyledSearchFeedRows = styled(Row)`
    margin-top: 10px;
    width: 100%;
`;

type FilesProps = {
    validators;
    history;
} & WrappedComponentProps;

const Files = ({
    intl,
    validators,
    history,
}: FilesProps) => {
    const screens = useBreakpoint();
    const [pageSizeOptions, setPageSizeOptions] = useState<number[]>(getPageSizeOptions(screens));
    const [searchValue, setSearchValue] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');
    const [limit, setLimit] = useState<number>(pageSizeOptions[0]);
    const [feedItemType, setFeedItemType] = useState<string>('content');
    const [activeTab, setActiveTab] = useState('searchFeeds');
    const warningMessage = useMemo(() => validators.searchLength(debouncedSearchValue), [debouncedSearchValue, validators]);
    const { isLoading, data: results = {} } = useSearch({
        query: warningMessage ? '' : debouncedSearchValue,
        filters: ['feedItems'],
        subFilters: { feedItemType },
        page,
        limit,
        highlight: true,
    });

    const inputPlaceHolder = useMemo(() => {
        if (activeTab === 'searchFeeds') {
            return intl.formatMessage({ id: 'search.feeds' });
        }
        return intl.formatMessage({ id: 'search.files' });
    }, [activeTab, intl]);

    const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setPage(1);
        if (!e.target.value) {
            history.push({ pathname: history.location.pathname });
        } else {
            const params = new URLSearchParams(history.location.search);
            params.set('q', encodeURIComponent(e.target.value));
            history.push({
                pathname: history.location.pathname,
                search: `?${params.toString()}`
            });
        }
    }, [history]);

    const onShowSizeChange = (_, pageSize) => setLimit(pageSize);

    useDebounce(() => setDebouncedSearchValue(searchValue), 200, [searchValue]);

    useEffect(() => {
        const newOptions = getPageSizeOptions(screens);
        setPageSizeOptions(newOptions);
        setLimit(newOptions[0]);
    }, [screens]);

    const handleTabChange = useCallback((activeKey) => {
        setActiveTab(activeKey);
        history.push(getUrl(activeKey));
    }, [setActiveTab, history]);

    useEffect(() => {
        const { pathname, search } = history.location;
        if (pathname?.indexOf(getUrl('searchFiles')) !== -1) {
            setFeedItemType('file');
            setActiveTab('searchFiles');
        } else {
            setFeedItemType('content');
            setActiveTab('searchFeeds');
        }

        if (search) {
            const params = new URLSearchParams(search);
            const searchParam = params.get('q');

            setSearchValue(decodeURIComponent(searchParam || ''));
        }
    }, [history.location.pathname]);

    const items = useMemo(() => [{
        key: 'searchFeeds',
        label: (
            <StyledLabel>
                <Icon size="xl" iconName="MessageCircle" />
                {intl.formatMessage({ id: 'search.tabs.discussions' })}
            </StyledLabel>
        ),
        children: (
            <>
                {!isLoading && results?.feedItems && <MessageList {...results} />}
                {!!results?.feedItems?.results?.length && (
                    <StyledSearchFeedRows
                        align="middle"
                        justify="center"
                        wrap={false}
                    >
                        <Pagination
                            current={page}
                            onChange={setPage}
                            total={results?.feedItems?.paging.total || 1}
                            onShowSizeChange={onShowSizeChange}
                            pageSizeOptions={pageSizeOptions}
                            pageSize={limit}
                        />
                    </StyledSearchFeedRows>
                )}
            </>
        )
    },
    {
        key: 'searchFiles',
        label: (
            <StyledLabel>
                <Icon size="xl" iconName="Paperclip" />
                {intl.formatMessage({ id: 'menuLeft.navigation.files' })}
            </StyledLabel>
        ),
        children: (
            <>
                {!isLoading && results?.feedItems && <FileList {...results} />}
                {!!results?.feedItems?.results?.length && (
                    <StyledRow align="middle" justify="center" wrap={false}>
                        <Pagination
                            current={page}
                            onChange={setPage}
                            total={results?.feedItems?.paging.total || 1}
                            onShowSizeChange={onShowSizeChange}
                            pageSizeOptions={pageSizeOptions}
                            pageSize={limit}
                        />
                    </StyledRow>
                )}
            </>
        )
    }], [intl, isLoading, results, page, limit, pageSizeOptions]);

    return (
        <StyledPageLayout className="ui-contact">
            <CategoryTitle
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                label={intl.formatMessage<string>({ id: 'menuLeft.navigation.search' })}
                icon={<Icon size="xl" iconName="Search" />}
            />
            <StyledInput
                placeholder={inputPlaceHolder}
                prefix={<SearchOutlined />}
                onChange={onInputChange}
                value={searchValue}
            />
            {warningMessage && <StyledText type="danger">{warningMessage}</StyledText>}
            <StyledFileResults>
                <Text strong type="secondary">
                    {results?.feedItems?.paging?.total || 0} {intl.formatMessage({ id: 'global.results' }).toLowerCase()}
                </Text>
            </StyledFileResults>
            <StyledTabs
                activeKey={activeTab}
                onChange={handleTabChange}
                items={items}
                destroyInactiveTabPane
            />
            {isLoading && <LoadingOutlined />}
        </StyledPageLayout>
    );
}

export default injectValidators(injectIntl(Files));
