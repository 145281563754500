import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';

import Actions from 'components/Shared/Forms/Actions';
import { Form as FinalForm, Field } from 'react-final-form';
import { LabelInput } from 'components/Shared/Forms';

import { composeValidators } from 'utils/formValidator';

const formatMessages = intl => ({
    settingSubtitle: intl.formatMessage({
        id: 'settings.resetPassword.title',
        defaultMessage: 'Modifier votre mot de passe'
    }),
    currentPasswordLabel: intl.formatMessage({
        id: 'settings.resetPassword.currentPassword.label',
        defaultMessage: 'Mot de passe actuel'
    }),
    newPasswordLabel: intl.formatMessage({
        id: 'settings.resetPassword.newPassword.label',
        defaultMessage: 'Nouveau mot de passe'
    }),
    newPasswordConfirmLabel: intl.formatMessage({
        id: 'settings.resetPassword.newPasswordConfirm.label',
        defaultMessage: 'Confirmer votre nouveau mot de passe'
    }),
    differentsPassword: intl.formatMessage({
        id: 'settings.resetPassword.newPasswordDifferents.label',
        defaultMessage: 'Les mots de passe sont différents'
    })
});

const createValidator = intl => ({ newPassword, confirmPassword } = {}) => {
    const errors = {};
    const i18n = formatMessages(intl);

    if (newPassword !== confirmPassword) {
        errors.confirmPassword = i18n.differentsPassword;
        errors.newPassword = i18n.differentsPassword;
    }
    return errors;
}

const ResetPasswordForm = memo(({
    intl,
    onSubmit = () => {},
    error = '',
    validators
}) => {
    const {
        required,
        password,
        oldPassword
    } = validators;

    const i18n = useMemo(() => formatMessages(intl), [intl]);
    const validate = createValidator(intl);

    return (
        <FinalForm onSubmit={onSubmit} validate={validate}>
            {({ handleSubmit, pristine, submitting, valid, form }) => (
                <Form onSubmit={handleSubmit} layout="vertical">
                    <div className="subtitle">
                        {i18n.settingSubtitle}
                    </div>

                    <Field
                        component={LabelInput}
                        placeholder={i18n.currentPasswordLabel}
                        validate={composeValidators(required, oldPassword)}
                        name="currentPassword"
                        label={i18n.currentPasswordLabel}
                        type="password"
                    />

                    <Field
                        component={LabelInput}
                        placeholder={i18n.newPasswordLabel}
                        validate={composeValidators(required, password)}
                        name="newPassword"
                        label={i18n.newPasswordLabel}
                        type="password"
                    />

                    <Field
                        component={LabelInput}
                        placeholder={i18n.newPasswordConfirmLabel}
                        validate={composeValidators(required, password)}
                        name="confirmPassword"
                        label={i18n.newPasswordConfirmLabel}
                        type="password"
                    />

                    {error && intl.formatMessage({
                        id: error,
                        defaultMessage: 'Error'
                    })}

                    <Actions
                        className="action"
                        pristine={pristine}
                        reset={form.restart}
                        submitting={submitting}
                        submit={form.submit}
                        valid={valid}
                    />
                </Form>
            )}
        </FinalForm>
    );
});

ResetPasswordForm.propTypes = {
    intl: PropTypes.any.isRequired,
    onSubmit: PropTypes.func,
    validators: PropTypes.shape({
        required: PropTypes.func.isRequired,
        password: PropTypes.func.isRequired
    }).isRequired,
    error: PropTypes.string
};

export default injectIntl(ResetPasswordForm);
