import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyleListUsers = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    min-height: 0;
    width: 100%
`;

const InputList = ({
    fallbackMessage,
    items,
    ItemComponent,
    onChange,
    className = '',
    ...itemProps
}) => (
    <StyleListUsers className={className}>
        {items?.length ? items.map((x) => (
            <ItemComponent
                key={x?.id || x?.value}
                {...x}
                onChange={onChange}
                {...itemProps}
            />
        )) : fallbackMessage}
    </StyleListUsers>
);

InputList.propTypes = {
    fallbackMessage: PropTypes.any,
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    ItemComponent: PropTypes.any.isRequired,
    className: PropTypes.string
};

export default memo(InputList);
