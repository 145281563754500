import React from 'react';
import PropTypes from 'prop-types';
import Logger from 'services/debug/logger';
import { Redirect } from 'react-router-dom';
import RedirectToHome from 'screens/Redirects/Home';

const logger = new Logger('component:redirect:To');

const To = ({ to, params }) => {
    logger.log('render', to, params);

    let path = null;

    if (typeof to === 'string') {
        path = to;
    } else if (typeof to === 'function') {
        path = to(params);
    }

    logger.log('render url=%s', path);

    if (path === null) {
        return <RedirectToHome />;
    }

    return <Redirect to={path} />;
};

To.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    params: PropTypes.object
};

export default To;
