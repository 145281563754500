import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Logger from 'services/debug/logger';
import differenceBy from 'lodash/differenceBy';
import { Button, notification } from 'antd';
import  { i18n } from '@amplement/backend-connector';
import { AudioOutlined, VideoCameraOutlined, VideoCameraAddOutlined, AudioMutedOutlined, CustomerServiceOutlined } from '@ant-design/icons';

const logger = new Logger('component:AlertOnNewMediaDevices');

const getAddedIconFromDeviceKind = kind => {
    switch(kind) {
        case 'audioinput':
            return <AudioOutlined style={{ color: '#108ee9' }} />;
        case 'videoinput':
            return <VideoCameraAddOutlined style={{ color: '#108ee9' }} />;
        case 'audiooutput':
            return <CustomerServiceOutlined style={{ color: '#108ee9' }} />;
        default:
            return undefined;
    }
}

const getRemovedIconFromDeviceKind = kind => {
    switch (kind) {
        case 'audioinput':
            return <AudioMutedOutlined style={{ color: '#ff4d4f' }} />;
        case 'videoinput':
            return <VideoCameraOutlined style={{ color: '#ff4d4f' }} />;
        case 'audiooutput':
        default:
            return undefined;
    }
}

const confirmSwitch = (key, { label, deviceId }) => {
    notification.success({
        message: label || deviceId || i18n.translate('room.member.name.unknown'),
        description: i18n.translate('room.devices.deviceSwitched'),
        key,
        duration: 8,
    });
};

const openAddNotification = ({ kind, label, deviceId }, cb) => {
    const key = deviceId || Date.now();
    logger.log('openNotification:add ', key, kind, label, deviceId);
    const btn = (
        <Button 
            type="primary" 
            size="small" 
            onClick={() => {
                logger.log('openNotification:add:onClick ', key, label, deviceId);
                cb({ kind, label, deviceId });
                confirmSwitch(key, { kind, label, deviceId });
            }}
        >
            {i18n.translate('global.button.switch')}
        </Button>
    );
    notification.info({
        message: label || deviceId || i18n.translate('room.member.name.unknown'),
        description: i18n.translate('room.devices.deviceDetected', { kind }),
        btn,
        key,
        duration: 60,
        icon: getAddedIconFromDeviceKind(kind)
    });
};

const openRemoveNotification = ({ kind, label, deviceId }, cb) => {
    const key = deviceId || Date.now();
    logger.log('openNotification:remove ', kind, key, label, deviceId);
    // const btn = (
    //     <Button type="secondary" size="small" onClick={() => notification.close(key)}>
    //         {i18n.translate('global.button.close')}
    //     </Button>
    // );
    notification.error({
        message: label || deviceId || i18n.translate('room.member.name.unknown'),
        description: i18n.translate('room.devices.deviceRemoved', { kind }),
        btn: undefined,
        key,
        duration: 8,
        icon: getRemovedIconFromDeviceKind(kind)
    });
    cb({ kind });
};

const AlertOnNewMediaDevices = ({ setDeviceSettings, devices }) => {
    const previousDevices = useRef();

    useEffect(() => {
        if (previousDevices.current?.length !== devices?.length && 
            (previousDevices.current?.[0]?.deviceId || !devices?.length)
        ) {
            try {
                const deviceAdded = differenceBy(devices, previousDevices.current, 'label').filter(d => d.deviceId !== 'default');
                const deviceRemoved = differenceBy(previousDevices.current, devices, 'label').filter(d => d.deviceId !== 'default');

                if (deviceAdded?.length && deviceAdded?.length <= 3) {
                    logger.log('deviceAddeded: ', devices, deviceAdded);
                    const cb = ({ kind, deviceId }) => setDeviceSettings({ [kind]: deviceId });
                    deviceAdded.forEach((d) => openAddNotification(d, cb));
                }

                if (deviceRemoved?.length) {
                    logger.log('deviceRemoved: ', devices, deviceRemoved);
                    const cb = ({ kind }) => setDeviceSettings({ [kind]: 'default' });
                    deviceRemoved.forEach((d) => openRemoveNotification(d, cb));

                }
            } catch (e) {
                logger.error(e, 'AlertOnNewMediaDevices');
            }
        }

        previousDevices.current = devices;
    }, [devices, previousDevices.current]);

    logger.log('render', devices, previousDevices.current);

    return null;
}

AlertOnNewMediaDevices.propTypes = {
    devices: PropTypes.array,
    setDeviceSettings: PropTypes.func.isRequired
};

export default memo(AlertOnNewMediaDevices);
