import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Ps = ({ color = '#00B8DF', withBorder = true }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-ps" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={color} fillRule="nonzero">
                {withBorder && <Border />}
                <g id="Group" transform="translate(25.000000, 62.000000)">
                    <path
                        d="M24,11.4 C24,15.1 22.8,17.9 20.5,19.9 C18.2,21.9 14.9,22.8 10.7,22.8 L7.6,22.8 L7.6,35 L0.3,35 L0.3,0.7 L11.2,0.7 C15.4,0.7 18.5,1.6 20.7,3.4 C22.9,5.2 24,7.9 24,11.4 Z M7.6,16.9 L10,16.9 C12.2,16.9 13.9,16.5 15,15.6 C16.1,14.7 16.7,13.4 16.7,11.7 C16.7,10 16.2,8.7 15.3,7.9 C14.4,7.1 12.9,6.7 10.9,6.7 L7.6,6.7 L7.6,16.9 Z"
                        id="Shape"
                    />
                    <path
                        d="M48.2,27.2 C48.2,29.9 47.3,31.9 45.4,33.3 C43.5,34.7 40.7,35.4 37,35.4 C35.1,35.4 33.5,35.3 32.1,35 C30.7,34.7 29.5,34.4 28.3,33.9 L28.3,28 C29.6,28.6 31.1,29.1 32.8,29.6 C34.5,30.1 35.9,30.2 37.2,30.2 C39.8,30.2 41.1,29.4 41.1,27.9 C41.1,27.3 40.9,26.9 40.6,26.5 C40.3,26.1 39.7,25.7 38.8,25.3 C37.9,24.9 36.8,24.3 35.4,23.7 C33.4,22.9 31.9,22.1 31,21.4 C30.1,20.7 29.4,19.9 28.9,18.9 C28.4,17.9 28.3,16.8 28.3,15.5 C28.3,13.2 29.2,11.4 31,10.1 C32.8,8.8 35.4,8.2 38.7,8.2 C41.9,8.2 44.9,8.9 47.9,10.3 L45.7,15.5 C44.4,14.9 43.2,14.5 42,14.1 C40.8,13.7 39.7,13.6 38.5,13.6 C36.4,13.6 35.3,14.2 35.3,15.3 C35.3,15.9 35.6,16.5 36.3,17 C37,17.5 38.5,18.2 40.8,19.1 C42.8,19.9 44.3,20.7 45.3,21.4 C46.3,22.1 47,22.9 47.4,23.9 C47.8,24.9 48.2,25.9 48.2,27.2 Z"
                        id="Shape"
                    />
                </g>
            </g>
        </g>
    </svg>
);

Ps.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

export default Ps;
