import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { selectors, utils } from '@amplement/backend-connector';

import { container as Contact } from 'components/Shared/Notification/Contact';
import { container as Feed } from 'components/Shared/Notification/Feed';
import { container as Room } from 'components/Shared/Notification/Room';

import { NOTIFICATION_CATEGORIES } from 'const/notification';

const NotificationItem = memo(({
    notification,
    ContactComponent = Contact,
    FeedComponent = Feed,
    RoomComponent = Room
}) => {
    const { category, type, createdAt, payload } = notification;
    let Component = null;

    if (!category || !type || !createdAt || !payload) {
        utils.errorHandler.captureException(new Error('missing one of the following props (category, type, createdAt, payload)'), 'component:Shared:notifications:item', notification);
        return null;
    }

    switch (category) {
        case 'contact':
            Component = ContactComponent;
            break;
        case 'member':
            Component = FeedComponent;
            break;
        case 'room':
            Component = RoomComponent;
            break;
        default:
    }

    if (Component) {
        return <Component {...payload} type={type} createdAt={createdAt} />;
    }

    return null;
});

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        id: PropTypes.string.isRequired,
        category: PropTypes.oneOf(Object.values(NOTIFICATION_CATEGORIES)).isRequired,
        createdAt: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        payload: PropTypes.object.isRequired,
    }).isRequired,
    ContactComponent: PropTypes.elementType,
    FeedComponent: PropTypes.elementType,
    RoomComponent: PropTypes.elementType,
};

const Component = memo(NotificationItem);

export default Component;

const mapStateToProps = (state, { _notification }) => ({
    notification: selectors.notifications.getNotificationByIdSelector(state, _notification),
});

export const container = connect(mapStateToProps, null)(Component);
