import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { actions, selectors } from '@amplement/backend-connector';

import Component from './index';

const VoiceMailsContainer = memo(({
    getVoiceMails,
    unReadVoiceMailCount,
    ...rest
}) => {
    const oldCount = useRef();
    useEffect(() => {
        if (!oldCount.current || oldCount.current < unReadVoiceMailCount){
            getVoiceMails()
        }
        oldCount.current = unReadVoiceMailCount;
    }, [unReadVoiceMailCount]);

    return <Component {...rest} />;
});

VoiceMailsContainer.propTypes = {
    getVoiceMails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    unReadVoiceMailCount: selectors.voiceMails.unreadVoiceMailCountSelector(state),
    _voiceMails: selectors.voiceMails._voiceMailsSelector(state),
    loading: selectors.voiceMails.voiceMailsLoadingSelector(state),
    error: !!selectors.voiceMails.voiceMailsErrorSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
    getVoiceMails: () =>
        dispatch(actions.voiceMails.requestGetVoiceMails()),
    deleteVoiceMails: (_voiceMails) =>
        dispatch(actions.voiceMails.requestDeleteVoiceMails(_voiceMails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoiceMailsContainer);
