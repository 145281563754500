import React from 'react';

export default props => (
    <svg 
        width="1em" 
        height="1em" 
        viewBox="0 0 24 24" 
        version="1.1" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <g id="VideoIncoming" stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
            <path d="M23.5,10.1 C23.2,9.9 22.8,10 22.5,10.2 L17,14.1 L17,12 C17,10.3 15.7,9 14,9 L3,9 C1.3,9 0,10.3 0,12 L0,21 C0,22.7 1.3,24 3,24 L14,24 C15.7,24 17,22.7 17,21 L17,17.9 L22.4,21.8 C22.6,21.9 22.8,22 23,22 C23.2,22 23.3,22 23.5,21.9 C23.8,21.7 24,21.4 24,21 L24,11 C24,10.6 23.8,10.3 23.5,10.1 Z M15,21 C15,21.6 14.6,22 14,22 L3,22 C2.4,22 2,21.6 2,21 L2,12 C2,11.4 2.4,11 3,11 L14,11 C14.6,11 15,11.4 15,12 L15,21 Z M22,12.9 L22,19.1 L17.7,16 L22,12.9 Z M24.1179039,0.31441048 C24.5371179,0.733624454 24.5371179,1.36244541 24.1179039,1.78165939 L19.5633188,6.33624454 L23.3362445,6.33624454 C23.9650655,6.33624454 24.3842795,6.75545852 24.3842795,7.38427948 C24.3842795,8.01310044 23.9650655,8.43231441 23.3362445,8.43231441 L17.0480349,8.43231441 C16.9432314,8.43231441 16.7336245,8.43231441 16.628821,8.32751092 C16.419214,8.22270742 16.209607,8.01310044 16.1048035,7.80349345 C16.0209607,7.71965066 16.0041921,7.56873362 16.0008384,7.45806114 L16,1.09606987 C16,0.467248908 16.419214,0.0480349345 17.0480349,0.0480349345 C17.6768559,0.0480349345 18.0960699,0.467248908 18.0960699,1.09606987 L18.0960699,4.86899563 L22.650655,0.31441048 C23.069869,-0.104803493 23.69869,-0.104803493 24.1179039,0.31441048 Z" id="videoincoming" fill="currentColor" fillRule="nonzero"></path>
        </g>
    </svg>
);
