import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import useSipAccounts from 'hooks/useSipAccounts';
import useSipAccount from 'hooks/useSipAccount';

import { ForwardToLabel, PhoneBookNumber } from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/Commons';

const ForwardMobileInput = ({ onChange }) => {
    const { data: accounts } = useSipAccounts();
    const { id: _sipAccount } = accounts?.[0] || {};
    const { data: account } = useSipAccount({ _sipAccount });
    const { sipUsername, phone: { mobileNumber } = {} } = account || {};
    const form = Form.useFormInstance();
    
    useEffect(() => form.setFieldValue('type', 'MOBILE'), []);
    useEffect(() => form.setFieldValue('to', mobileNumber), []);
    useEffect(() => onChange(sipUsername), [sipUsername]);

    return (
        <>
            <ForwardToLabel />
            <PhoneBookNumber emptyText={mobileNumber} />
        </>
    );
}

ForwardMobileInput.propTypes = {
    onChange: PropTypes.func,
};

export default memo(ForwardMobileInput);
