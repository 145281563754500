import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Style = styled.div`

    & {
        ${'' /* position: absolute;
        top: 80px;
        left: 15px; */}
        width: calc(100% - 30px);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        transition: all 250ms ease;
        z-index: 4;
    }

    .bar {
        width: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 6px;
        background-color: #7ee2a8;
        padding: 5px 10px;
    }


    .bar-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: ${props => `${Math.max(props.progress, 0)}%`};
        height: 100%;
        background-color: #2ecc71;
        transition: width 250ms ease;
    }

    .bar-loader:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        background-size: 100%;
        background-image: linear-gradient(-45deg, #ffffff 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 50%, #ffffff 50%, #ffffff 75%, rgba(0,0,0,0) 75%, rgba(0,0,0,0));
        background-size: 30px 30px;
        opacity: .3;
        animation: progress-anim-1-after .5s infinite linear;
    }

    .bar-infos {
        position: relative;
        z-index: 2;
        font-size: 12px;
        color: #FFF;
        font-weight: bold;
    }

`;

const ProgressBar = ({ progress = -1, placeholder = null, style }) => progress !== undefined ? (
    <Style progress={progress} style={style}>
        <div className="bar">
            <div className="bar-loader" />
            <div className="bar-infos">
                {placeholder}
            </div>
        </div>
    </Style>
) : null;

ProgressBar.propTypes = {
    progress: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    placeholder: PropTypes.string,
    style: PropTypes.object,
};

export default ProgressBar;
