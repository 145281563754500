import React, { memo, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import AntdModal from 'components/Shared/Modals';
import CallForwardFooter from 'components/Room/AntdModal/CallForwardFooter';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Radio } from 'antd';
import SearchablePhonebookList,{ PhonebookItem } from 'components/Shared/Phonebook/SearchablePhonebookList';
import RadioItem from 'components/Shared/Forms/Radio/RadioItem';

const StyleIconLabel = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
`;

const Styled = styled(Radio.Group)`
    width: 100%;
`;

const RadioPhonebookItem = ({ onChange, ...props }) => (
    <RadioItem
        onChange={onChange}
        disabled={false}
        value={props}
        ItemComponent={() => <PhonebookItem {...props} />} 
    />
);

const ContactSelectorModal = ({
    intl,
    title,
    onClose,
    onSubmit,
    isOpen = true,
    isAutoScroll = false,
    ItemComponent = RadioPhonebookItem,
    submitText,
    phoneFilterTypes,
    type,
    ...props
}) => {
    const [value, setValue] = useState('');
    const handleChange = useCallback(e => setValue(e.target.value), [setValue]);

    const handleSubmit = useCallback(() => {
        onSubmit(value);
        onClose?.();
    }, [onSubmit, value]);

    const footer = useMemo(
        () => (
            <CallForwardFooter 
                submitText={submitText} 
                onClose={onClose} 
                onSubmit={handleSubmit} 
                isDisabled={!value} 
            />
        ),
        [onClose, handleSubmit, value, submitText]
    );

    return (
        <AntdModal
            title={title || intl.formatMessage({ id: 'room.actions.callTransfer' })}
            isOpen={isOpen}
            isAutoScroll={isAutoScroll}
            footer={footer}
            onClose={onClose}
            {...props}
        >
            <Styled onChange={handleChange} value={value}>
                <StyleIconLabel>
                    <SearchablePhonebookList 
                        onChange={handleChange} 
                        ItemComponent={ItemComponent} 
                        phoneFilterTypes={phoneFilterTypes} 
                        type={type} 
                    />
                </StyleIconLabel>
            </Styled>
        </AntdModal>
    );
};

ContactSelectorModal.propTypes = {
    phoneFilterTypes: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    isAutoScroll: PropTypes.bool,
    ItemComponent: PropTypes.any
};

export default memo(injectIntl(ContactSelectorModal));
