import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { injectIntl } from 'react-intl';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined  } from '@ant-design/icons';
import Icon from 'components/Shared/Common/Icon';

const StyledDiv = styled.div`
    min-height: 58px;
`;

const StyledToggle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
`;

const TogglePrivateRoom = memo((props) => {
    const {
        onClick,
        isChecked = false,
        intl
    } = props;

    useWhyDidYouUpdate('components:room:Footer:MoreButton:TogglePrivateRoom', props);

    return (
        <StyledDiv className="line-item">
            <div className="line">
                {isChecked ? (
                    <Icon iconName="Lock" bgColor="green" />
                ) : (
                    <Icon iconName="Unlock" bgColor="red" />
                )}
            </div>
            <div className="line-wrapper">
                <div className="line column">
                    <div className="label">
                        {isChecked ? (
                            intl.formatMessage({ id: 'room.private.title' })
                        ) : (
                            intl.formatMessage({ id: 'room.public.title' })
                        )}
                    </div>
                    <div className="desc">
                        {isChecked ? (
                            intl.formatMessage({ id: 'room.private.label' })
                        ) : (
                            intl.formatMessage({ id: 'room.public.label' })
                        )}
                    </div>
                </div>
            </div>
            <StyledToggle className="toggle-item">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={isChecked}
                    onClick={onClick}
                />
            </StyledToggle>
        </StyledDiv>
    );
})

TogglePrivateRoom.propTypes = {
    onClick: PropTypes.func.isRequired,
    isChecked: PropTypes.bool,
    intl: PropTypes.any.isRequired
};

export default injectIntl(TogglePrivateRoom);
