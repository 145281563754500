import { useQuery } from "react-query"
import { apis } from '@amplement/backend-connector';

export const enrichAccount = account => {
    if (account)
        account.sipUsername = account.userUri?.split('@')?.[0];

    return account;
};

const useSipAccount = ({ _sipAccount }) => {
    const result = useQuery(
        ['sip-account', { _sipAccount }],
        async () => {
            const response = await apis.sip.$getAccount(_sipAccount);
            enrichAccount(response.data);

            return response?.data;
        },
        { refetchOnWindowFocus: false, refetchOnMount: false, enabled: !!_sipAccount, staleTime: 5000 }
    )

    return result;
}

export default useSipAccount;
