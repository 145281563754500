import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Shared/Common/Button';
import { Col, Row } from 'antd';
import FileIcon from 'components/Shared/Common/FileIcon';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { rgba } from 'polished';

const Styled = styled(Col)`
    position: relative;
    border-radius: 4px;
    max-width: 300px;
    width: 100%;
    border: 1px solid ${({theme}) => rgba(theme.black, 0.1)};
    transition: all 250ms ease;
    background-color: ${({theme}) => theme.white};
    &:hover{
        border: 1px solid ${({theme}) => rgba(theme.black, 0.15)};
        box-shadow: 0 1px 1px ${({theme}) => rgba(theme.black, 0.1)};
    }
    .file-content{
        padding: 10px;
        width: 100%;
        flex-flow: row;
        .file-icon{
            margin-right: 10px;
            width: 35px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            height: 44px;
            img{
                max-width: 100%;
            }
        }
        .file-meta{
            min-width: 0;
            .text,
            .subtext{
                font-size: 14px;
                color: ${({theme}) => theme.black};
            }
            .text{
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .file-actions{
        width: 100%;
        border-top: 1px solid ${({theme}) => rgba(theme.black, 0.1)};
        padding: 10px 8px;
        .btn{
            margin-left: 10px;
        }
    }
    .file-error-info{
        width: 100%;
        border-top: 1px solid ${({theme}) => rgba(theme.black, 0.1)};
        padding: 10px 8px;
        font-size: 14px;
    }
`;

const Header = memo(({ extension, isDisplayable, title, subtitle }) => (
    <Row className="file-content" align="middle">
        <Col className="file-icon">
            <FileIcon
                fileExtension={extension}
                isDisplayable={isDisplayable}
            />
        </Col>
        <Col className="file-meta" flex={1}>
            <div className="content">
                <div className="text" title={title}>{title}</div>
                <div className="subtext">{subtitle}</div>
            </div>
        </Col>
    </Row>
));

const Actions = memo(injectIntl(({ onDownload, href, intl, actions }) => (
    <Row 
        className="file-actions" 
        align="middle"
        justify="end"
    >
        <Button
            onClick={onDownload}
            size="sm"
            color="primary"
            href={href}
        >
            {intl.formatMessage({ id: 'global.button.download' })}
        </Button>
        {actions}
    </Row>
)));

const FileGenericTemplate = ({
    extension,
    isDisplayable,
    title = null,
    subtitle = null,
    onDownload,
    href,
    actions = null,
}) => (
    <Styled className="files-item">
        <Header 
            extension={extension}
            isDisplayable={isDisplayable}
            title={title}
            subtitle={subtitle}
        />
        {!!href && (
            <Actions
                actions={actions}
                onDownload={onDownload}
                href={href}
            />
        )}
    </Styled>
);

FileGenericTemplate.propTypes = {
    extension: PropTypes.string.isRequired,
    isDisplayable: PropTypes.bool.isRequired,
    href: PropTypes.string.isRequired,
    onDownload: PropTypes.func.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    actions: PropTypes.any,
};

export default memo(FileGenericTemplate);
