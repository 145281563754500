/* eslint-ignore */
import storage from 'services/storage/localStorage';

import { config as bcConfig, actions, callbacks, apiClient } from '@amplement/backend-connector';
import production from './env/production.config';
import testing from './env/testing.config';
import staging from './env/staging.config';
import development from './env/development.config';
import common from './env/common.config';

let userConfig;
let env;
const { initHttpClient } = apiClient;

const { CLIENT_ENV, NODE_ENV } = process.env;
const initialEnv = NODE_ENV || CLIENT_ENV;

try {
    if (initialEnv === 'test') {
        env = 'testing';
    } else if (initialEnv !== 'production') {
        env = storage.get('env');
        userConfig = require('./config');
    }
} catch (e) {
    if (e instanceof Error && e.code === "MODULE_NOT_FOUND")
        console.log("No custom file found, skip user override config");
    else
        console.error(e);
    userConfig = undefined;
}

const config = {
    production,
    staging,
    testing,
    development
};
let publicHostname = userConfig?.publicHostname || config[initialEnv]?.publicHostname

if (env) {
    if (!config[env]) {
        env = null;
    } else {
        // if env, we want to keep current url instead of targeted env url
        publicHostname = `${window.location.origin}/`;
    }
}

if (!initialEnv) {
    throw new Error('CLIENT_ENV or NODE_ENV need to be defined');
}

if (!publicHostname) {
    throw new Error('publicHostname need to be defined');
}

const conf = {
    ...common,
    ...config[env || initialEnv],
    ...(userConfig || {}),
    initialEnv,
    env,
    publicHostname,
    publicPath: __webpack_public_path__ // eslint-disable-line
};

const configCb = newConfig => {
    if (newConfig?.apiServerUrl) {
        // recreate axios instance to reprocess baseUrl
        initHttpClient(newConfig);
    }

    callbacks.get('dispatch')(actions.websocket.launchWebsocket());
};
bcConfig.setEnv(env || initialEnv, conf, configCb);

module.exports = conf;

export default conf;
