import React, { memo, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import SimplePhone from 'components/Shared/Call/SimplePhone';
import AntdModal from 'components/Shared/Modals';
import CallForwardFooter from 'components/Room/AntdModal/CallForwardFooter';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';
import useSipAccounts from 'hooks/useSipAccounts';

const KeyboardModal = ({
    _room,
    intl,
    onClose,
    onSubmit,
    isOpen = true,
    defaultNumber,
    ...props
}) => {
    const [value, setValue] = useState('');
    const [form] = Form.useForm();
    const handleChange = useCallback(n => setValue(n), [setValue]);
    const handleClickSubmit = useCallback(form.submit, [form]);
    const handleSubmit = useCallback((number) => onSubmit({ destNumber: number }), [onSubmit]);
    const handleAddDigit = useCallback((digit) => setValue(`${value}${digit}`), [value, setValue]);
    const { data: accounts } = useSipAccounts();
    const { prefix } = accounts?.[0] || {};

    const footer = useMemo(() =>
        <CallForwardFooter onClose={onClose} onSubmit={handleClickSubmit} isDisabled={!value} />
    , [onClose, handleClickSubmit, value]);

    return (
        <AntdModal
            title={intl.formatMessage({ id: 'room.actions.callTransfer' })}
            isOpen={isOpen}
            footer={footer}
            onClose={onClose}
            {...props}
        >
            <SimplePhone
                _room={_room}
                defaultNumber={defaultNumber}
                onKeyPress={handleAddDigit}
                onChange={handleChange}
                hasCallButton={false}
                className="call"
                onCall={handleSubmit}
                prefix={prefix}
                form={form}
            />
        </AntdModal>
    );
}

KeyboardModal.propTypes = {
    _room: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    defaultNumber: PropTypes.func,
    prefix: PropTypes.string,
    isOpen: PropTypes.bool
};

export default memo(injectIntl(KeyboardModal));
