import { Children, isValidElement, cloneElement, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { downloadChunks } from 'components/Shared/Recorder/utils';
import { RECORDER_STATUS } from 'components/Shared/Recorder/constants';

const Recorder = ({ stream = null, onStreamRecordingComplete = null, children = null }) => {
    const [mediaStatus, setStatus] = useState(RECORDER_STATUS.STOPPED);
    const mediaRecorder = useRef(null);
    const recordedChunks = useRef([]);

    const startRecording = async () => {
        setStatus(RECORDER_STATUS.RECORDING);
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
            recordedChunks.current = [];
        }
        mediaRecorder.current = new MediaRecorder(stream);

        mediaRecorder.current.addEventListener("dataavailable", (e) => {
            if (e.data.size > 0) {
                recordedChunks.current.push(e.data);
            }
        });

        // Define the number of milliseconds to record into each blob. If this is not provided, the blob may end up being empty.
        mediaRecorder.current.start(1000);
    };

    const stopRecording = () => {
        setStatus(RECORDER_STATUS.STOPPED);
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
        }
        if (onStreamRecordingComplete) {
            onStreamRecordingComplete(recordedChunks.current, mediaRecorder.current?.mimeType);
        } else {
            downloadChunks(recordedChunks.current, mediaRecorder.current?.mimeType);
        }
        recordedChunks.current = [];
    };

    const pauseRecording = () => {
        setStatus(RECORDER_STATUS.PAUSED);
        if (mediaRecorder.current) {
            mediaRecorder.current.pause();
        }
    };

    const resumeRecording = () => {
        setStatus(RECORDER_STATUS.RECORDING);
        if (mediaRecorder.current) {
            mediaRecorder.current.resume();
        }
    };

    const childrenWithProps = Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, {
                onStartRecording: startRecording,
                onStopRecording: stopRecording,
                onPauseRecording: pauseRecording,
                onResumeRecording: resumeRecording,
                recorderState: mediaStatus,
            });
        }
        return child;
    });

    return childrenWithProps || null;
};

Recorder.propTypes = {
    stream: PropTypes.object,
    onStreamRecordingComplete: PropTypes.func,
    children: PropTypes.node,
};

export default Recorder;
