import React from 'react';
import { Menu } from 'antd';
import { ProfileOutlined, HddOutlined, LockOutlined, PieChartOutlined, HeartOutlined, UserOutlined, PartitionOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { isCallUrl } from 'utils/url';
import config from 'config';

const styleContainer = { display: 'flex', width: '100%' };
const styleMenu = { flex: 1, minWidth: 40, lineHeight: '30px' };

export const items = [
    {
        label: 'Env',
        key: 'env',
        icon: <HddOutlined />,
        disabled: false,
        width: 300
    },
    {
        label: 'Config',
        key: 'config',
        icon: <ProfileOutlined />,
        disabled: false,
    },
    {
        label: 'Profile',
        key: 'profile',
        icon: <UserOutlined />,
        disabled: false,
    },
    {
        label: 'Token',
        key: 'token',
        icon: <LockOutlined />,
        visible: !!config?.jwtSecret,
        disabled: config.login !== 'cedricdelfour@amplement.com',
    },
    {
        label: 'Login',
        key: 'login',
        icon: <UserOutlined />,
    },
    {
        label: 'Redux',
        key: 'reduxAnalyzer',
        icon: <PieChartOutlined />,
        disabled: false,
    },
    {
        label: 'Webrtc',
        key: 'webrtc',
        icon: <PartitionOutlined />,
        disabled: !isCallUrl(),
    },
    {
        label: 'Signaling',
        key: 'webrtcSignaling',
        icon: <PartitionOutlined />,
    },
    {
        label: 'Ws',
        key: 'websocket',
        width: 800,
        icon: <PieChartOutlined />,
    },
    {
        label: 'Health',
        key: 'health',
        icon: <HeartOutlined />,
        disabled: false,
        width: '95%'
    },
    {
        label: 'UI',
        key: 'ui',
        icon: <AppstoreAddOutlined />,
        disabled: false,
        width: 200,
        placement: 'right'
    },
    {
        label: 'Navigation Three - Submenu',
        key: 'SubMenu',
        icon: <PieChartOutlined />,
        children: [
            {
                type: 'group',
                label: 'Item 1',
                children: [
                    {
                        label: 'Option 1',
                        key: 'setting:1',
                    },
                    {
                        label: 'Option 2',
                        key: 'setting:2',
                    },
                ],
            },
        ],
        visible: false
    }
].filter(x => x.visible !== false).map(({
    visible, // eslint-disable-line
    ...rest
}) => rest);

const DebugMenu = ({ onClick }) => (
    <div style={styleContainer}>
        <Menu 
            onClick={onClick}
            mode="horizontal"
            style={styleMenu}
            items={items}
        />
    </div>
);

export default DebugMenu;
