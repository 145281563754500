import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Typography } from 'antd';
import styled from 'styled-components';
import { rgba } from 'polished';
import { selectors, utils, resources, actions, constants } from '@amplement/backend-connector';

import FullscreenMessageFormatter from 'components/Shared/Errors/Formatter';
import RoomFullscreenMessage from 'components/Shared/Errors/RoomFullscreenMessage';
import WrappedContactButton from 'components/Contacts/Contacts/WrappedContactButton';

import { BUTTON_TYPE } from 'const/fullscreenMessage';
import useRoomSelector from 'hooks/useRoomSelector';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import RecallButton from '../RecallButton';

const { websocket : { manager: webSocketManager } } = resources;
const { Text } = Typography;

const StyledText = styled(Text)`
    margin-top: 10px;
    display: block;
    color: ${({ theme }) => rgba(theme.fixedWhite, 0.6)};
`;

const SubTitle = injectIntl(({ intl }) => <StyledText>{intl.formatMessage({ id: 'global.button.recall' })}</StyledText>);

const RoomEnded = (props) => {
    const {
        _room,
        intl,
    } = props;
    const {
        isPersistent,
        _sourceFeed,
        members,
        isMulticast,
    } = useRoomSelector(selectors.rooms.getRoomByIdSelector, _room) || {};
    const title = useRoomSelector(selectors.rooms.getDisplayRoomNameSelector, _room) || {};
    const { joinedAt } = useRoomSelector(selectors.rooms.getMyClientAsARoomMemberSelector, _room) || {};
    const otherMembersWithKicked = useRoomSelector(selectors.rooms.getUniqRoomUsersWithoutMeByRoomIdSelector, _room) || [];
    const otherMembers = useMemo(() => otherMembersWithKicked?.filter(({ status }) => status !== constants.rooms.USER_IN_ROOM_STATUS.KICKED), [otherMembersWithKicked]);
    const [duration, setDuration] = useState();
    const [feedId, setFeedId] = useState(_sourceFeed);
    const [membersArr, setMembers] = useState(members);
    const dispatch = useDispatch();

    const content = useMemo(() => {
        if (isPersistent) {
            return intl.formatMessage({ id: 'room.error.ended' });
        }

        return intl.formatMessage({ id: 'call.info.ended' });
    }, [intl, isPersistent]);

    useEffect(() => {
        dispatch(actions.network.setBackendTerminated());
        webSocketManager.getWs('api')?.close({
            code: 'CLOSE',
            message: `WS closed normally - RoomEnded-${_room}`
        });

        if (joinedAt) {
            setDuration(Date.now() - (new Date(joinedAt)).getTime());
        }
    }, [_room, dispatch]);

    useEffect(() => {
        if (_sourceFeed) {
            setFeedId(_sourceFeed);
        }
        if (members?.length) {
            setMembers(members);
        }
    }, [_sourceFeed, members]);

    useWhyDidYouUpdate('component:room:roomscreen:RoomEnded', { ...props, duration, joinedAt, isPersistent });

    if (duration > 0) {
        return (
            <FullscreenMessageFormatter
                content={content}
                title={title}
                duration={utils.date.convertDurationToString(duration)}
                buttonType={BUTTON_TYPE.CLOSE}
                members={otherMembers}
                isShowStars
                render={RoomFullscreenMessage}
                contactButton={<WrappedContactButton isEndRoom roomName={title} />}
                callButton={!isPersistent ? (
                    <RecallButton
                        color="light-transparency"
                        buttonClassName="item"
                        iconName="Phone"
                        size="xl"
                        isMulticast={isMulticast}
                        members={membersArr}
                        isPersistent={isPersistent}
                        _room={_room}
                        _feed={feedId}
                        subTitleComponent={<SubTitle />}
                        windowTarget="_self"
                    />
                ) : null}
            />
        );
    }

    return (
        <FullscreenMessageFormatter
            content={content}
            title={title}
            buttonType={BUTTON_TYPE.CLOSE}
            render={RoomFullscreenMessage}
        />
    );
}

RoomEnded.propTypes = {
    _room: PropTypes.string,
    intl: PropTypes.any.isRequired
};

export default injectIntl(memo(RoomEnded));
