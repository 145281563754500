import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useDateInterval from 'hooks/useDateInterval';
import FormattedDate from 'components/Shared/Common/FormattedDate';

const AutoRefreshDate = ({
    startDate,
    isRelative
}) => {
    useDateInterval(startDate);

    return <FormattedDate startDate={startDate} isRelative={isRelative} />;
}

AutoRefreshDate.propTypes = {
    startDate: PropTypes.any.isRequired,
    isRelative: PropTypes.bool,
};

export default memo(AutoRefreshDate);
