import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';

import Button from 'components/Shared/Common/Button';

import { CONTACT_STATUS } from 'const/user';
import { requestUpdateContactStatus } from 'actions/user';
import { getUrl } from 'utils/url';
import NotificationLayout from 'components/Shared/Notification/Layout';

const getLabelFromStatus = (isInitiator, contactStatus) => {
    if (isInitiator) {
        switch (contactStatus) {
            case CONTACT_STATUS.ACCEPTED:
                return 'notification.contact.outgoing.accepted';
            case CONTACT_STATUS.DECLINED: // if deny, remote should not be notified !
            case CONTACT_STATUS.PENDING:
            default:
                return 'notification.contact.outgoing.asked';
        }
    }
    switch (contactStatus) {
        case CONTACT_STATUS.DECLINED:
            return 'notification.contact.incoming.denied';
        case CONTACT_STATUS.ACCEPTED:
            return 'notification.contact.incoming.accepted';
        case CONTACT_STATUS.PENDING:
        default:
            return 'notification.contact.incoming.asked';
    }
}

const Contact = ({ intl,
    onConfirm,
    onDeny,
    onCancel,
    id: _user,
    avatarUri,
    title,
    firstname,
    lastname,
    isInitiator,
    contactStatus,
    createdAt
}) => {
    const btnActions = [];

    if (contactStatus === CONTACT_STATUS.PENDING && !isInitiator) {
        btnActions.push(
            <>
                <div className="item">
                    <Button
                        onClick={onDeny}
                        color="secondary"
                    >
                        {intl.formatMessage({ id: 'global.button.deny' })}
                    </Button>
                </div>
                <div className="item">
                    <Button
                        onClick={onConfirm}
                        color="primary"
                    >
                        {intl.formatMessage({ id: 'global.button.accept' })}
                    </Button>
                </div>
            </>
        );
    }
    if (contactStatus === CONTACT_STATUS.DECLINED) {
        btnActions.push(
            <div className="item">
                <Button
                    onClick={onCancel}
                    color="secondary"
                >
                    {intl.formatMessage({ id: 'global.button.cancel' })}
                </Button>
            </div>
        );
    }

    return (
        <NotificationLayout
            imageSrc={avatarUri}
            imageHref={getUrl('profile', { _user })}
            imageAlt={`${firstname} ${lastname}`}
            date={createdAt}
            actions={btnActions?.length ? btnActions : null}
        >
            <div>
                {intl.formatMessage({ id: getLabelFromStatus(isInitiator, contactStatus) }, { 
                    b: chunks => <b>{chunks}</b>,
                    username: intl.formatMessage({ id: 'user.username' }, { firstname, lastname }),
                    title
                })}
            </div>
        </NotificationLayout>
    );
};

Contact.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onDeny: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
    id: PropTypes.string.isRequired,
    avatarUri: PropTypes.string,
    title: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    isInitiator: PropTypes.bool.isRequired,
    contactStatus: PropTypes.oneOf(Object.values(CONTACT_STATUS)).isRequired,
    createdAt: PropTypes.string.isRequired,
};

const Component = memo(injectIntl(Contact));

export default Component;

const mapDispatchToProps = (dispatch, props) => ({
    onConfirm: () => dispatch(
        requestUpdateContactStatus(props.id, CONTACT_STATUS.ACCEPTED)
    ),
    onDeny: () => dispatch(
        requestUpdateContactStatus(props.id, CONTACT_STATUS.DECLINED)
    ),
    onCancel: () => dispatch(
        requestUpdateContactStatus(props.id, CONTACT_STATUS.PENDING)
    )
});

export const container = connect(null, mapDispatchToProps)(Component);
