import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import { FILE_TYPES, FILE_TEMPLATES } from 'const/feed';

import { processFile } from 'utils/file';
import { makeCancelable } from 'utils/cancelablePromise';
import { callbacks } from '@amplement/backend-connector';

import ImageSliderThumb from './Image';
import FileSliderThumb from './File';
import VideoSliderThumb from './Video';
import NoThumb from './NoThumb';

import SliderThumbTemplate from './Layout';

const ThumbFactory = ({ type = FILE_TYPES.FILE, file, isCurrent = false, onRemove, onClick }) => {
    const [loading, setLoading] = useState(true);
    const [base64Url, setBase64Url] = useState();
    const [error, setError] = useState(false);
    let Component = null;

    if (loading) {
        Component = (
            <div className="thumbs-item loading">
                <div className="loader">
                    <div className="bar" />
                </div>
            </div>
        );
    }

    useEffect(() => {       
        if (file.size && file.size > callbacks.get('getFileUploadSizeLimit')()) {
            setError(true);
        }
    }, [file]);

    switch (type) {
        case FILE_TYPES.IMAGE:
            useEffect(() => {
                const cancelablePromise = makeCancelable(processFile(file, FILE_TEMPLATES.THUMB));

                cancelablePromise.promise.then(result => {
                    setBase64Url(result.url);
                    setLoading(false);
                })
                    .catch(e => setError(e));

                return cancelablePromise.cancel;
            }, []);
            if (base64Url) {
                Component = <ImageSliderThumb url={base64Url} />;
            } else {
                Component = <NoThumb />;
            }
            break;
        case FILE_TYPES.VIDEO:
            Component = <VideoSliderThumb file={file} />;
            break;
        case FILE_TYPES.FILE:
            Component = <FileSliderThumb file={file} />;
            break;
        default:
            Component = null;
    }

    return (
        <SliderThumbTemplate
            id={file.name}
            onRemove={onRemove}
            isCurrent={isCurrent}
            onClick={onClick}
            error={error}
        >
            {Component}
        </SliderThumbTemplate>
    );
};

ThumbFactory.propTypes = {
    file: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.keys(FILE_TYPES)),
    isCurrent: PropTypes.bool
};

export default memo(ThumbFactory);
