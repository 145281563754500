import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Animate from 'rc-animate';

import CustomIntlProvider from 'components/CustomIntlProvider/container';
import classNames from 'classnames';
import Toast from './Toast';

class Toaster extends Component {
    state = {
        toasts: []
    };

    closeTimers = {};

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.state.toasts.length !== nextState.toasts.length
            || this.props.language !== nextProps.language
        );
    }

    componentWillUnmount() {
        this.clearAllCloseTimers();
    }

    startCloseTimer = (key, duration) => {
        if (duration) {
            this.closeTimers[key] = setTimeout(() => {
                this.remove(key);
                this.clearCloseTimer(key);
            }, duration * 1000);
        }
    }

    clearCloseTimer = (key) => {
        if (this.closeTimers[key]) {
            clearTimeout(this.closeTimers[key]);
            delete this.closeTimers[key];
        }
    }

    clearAllCloseTimers = () => {
        Object.keys(this.closeTimers).forEach(this.clearCloseTimer);
    }

    startAllCloseTimers = () => {
        const { toasts } = this.state;
        toasts.forEach(({ key, duration }) => this.startCloseTimer(key, duration));
    }

    getTransitionName = () => {
        const {
            transitionName,
            classPrefix,
            animation
        } = this.props;

        if (!transitionName && animation) {
            return `${classPrefix}-${animation}`;
        }

        return transitionName;
    }

    add = (toast) => {
        const { toasts } = this.state;
        const toastIndex = toasts.findIndex(item => item.key === toast.key);
        const updatedToasts = toasts.concat();

        if (toastIndex !== -1) {
            this.clearCloseTimer(toast.key);
            updatedToasts[toastIndex] = {
                ...toasts[toastIndex],
                toast
            };
        } else {
            updatedToasts.push(toast);
        }

        this.startCloseTimer(toast.key, toast.duration);
        this.setState({
            toasts: updatedToasts
        });
    }

    remove = (key) => {
        const { toasts } = this.state;
        const toastIndex = toasts.findIndex(toast => toast.key === key);

        if (toastIndex === -1) {
            return;
        }

        toasts[toastIndex].onClose?.(key);

        this.setState({
            toasts: toasts.filter(toast => toast.key !== key)
        });
    }

    renderToast = ({ ToastComponent, ...rest }) => (
        <Toast
            classPrefix={this.props.classPrefix}
            {...rest}
            onClose={() => {

                this.clearCloseTimer(rest.key);
                this.remove(rest.key);

            }}
        >
            <ToastComponent />
        </Toast>
    );

    render() {
        const { toasts } = this.state;
        const { style } = this.props;
        const className = {
            [this.props.classPrefix]: 1,
            [this.props.className]: !!this.props.className
        };

        return (
            ReactDOM.createPortal(
                (
                    <CustomIntlProvider locale={this.props.language}>
                        <div
                            className={classNames(className)}
                            style={style}
                            onMouseEnter={this.clearAllCloseTimers}
                            onMouseLeave={this.startAllCloseTimers}
                        >
                            <Animate
                                component="div"
                                className="amp-toaster-animation"
                                transitionName={this.getTransitionName()}
                            >
                                {
                                    toasts.map(this.renderToast)
                                }
                            </Animate>
                        </div>
                    </CustomIntlProvider>
                ),
                document.getElementById('modal-toaster')
            )
        );
    }
}

Toaster.propTypes = {
    classPrefix: PropTypes.string,
    animation: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Toaster.defaultProps = {
    classPrefix: 'amp-toaster',
    animation: 'fade'
};

export default Toaster;
