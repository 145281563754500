import React, { useEffect }  from 'react';
import PropTypes from 'prop-types';
import useSoundMeter from 'hooks/useSoundMeter';
import { useSoundMeter as useSoundMeterContext } from 'contexts/SoundMeterContext';
import { injectIntl } from 'react-intl';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

export const THRESHOLD_TYPES = {
    BOOL: 'BOOL',
    NUMERIC: 'NUMERIC'
}

const SoundMeterStrength = injectIntl((props) => {
    const {
        track = null,
        Component,
        refreshRate = 300,
        thresholdType = THRESHOLD_TYPES.NUMERIC,
        onChange,
        onError,
        alertOnError,
        isTrackMuted,
        isMe,
        intl,
        _client,
        avatar = null
    } = props;
    const { soundMeters, setSoundMeters } = useSoundMeterContext();
    const [
        strength,
        muted,
        error,
        isSpeaking,
        warning,
    ] = useSoundMeter({
        track,
        refreshRate,
        isMe,
        isTrackMuted,
        _client,
        setSoundMeters,
        soundMeters,
    });
    let finalStrength = strength;

    if (_client && soundMeters[_client]) {
        finalStrength = soundMeters[_client]?.strength;

        if (thresholdType === THRESHOLD_TYPES.Bool) {
            finalStrength = soundMeters[_client]?.isSpeaking ? 1 : 0;
        }
    } else if (thresholdType === THRESHOLD_TYPES.Bool) {
        finalStrength = isSpeaking ? 1 : 0;
    }

    useWhyDidYouUpdate('SoundMeterStrength', { ...props, error } );

    useEffect(() => {
        if (typeof onChange === 'function') onChange(finalStrength);
    }, [finalStrength, onChange]);

    useEffect(() => {
        if (typeof onError === 'function') {
            onError(error ? intl.formatMessage({ id: error }) : undefined);
        }
    }, [onError, error]);

    useEffect(() => {
        if (typeof onError === 'function') {
            onError(warning ? intl.formatMessage({ id: warning }) : undefined);
        }
    }, [onError, warning]);

    if (_client && soundMeters[_client]) {
        return (
            <Component
                strength={finalStrength}
                error={error ? intl.formatMessage({ id: error }) : undefined}
                warning={warning ? intl.formatMessage({ id: warning }) : undefined}
                track={track}
                muted={isTrackMuted || soundMeters[_client]?.muted}
                alertOnError={alertOnError}
                avatar={avatar}
                _client={_client}
            />
        );
    }

    return (
        <Component
            strength={finalStrength}
            error={error ? intl.formatMessage({ id: error }) : undefined}
            warning={warning ? intl.formatMessage({ id: warning }) : undefined}
            track={track}
            muted={isTrackMuted || muted}
            alertOnError={alertOnError}
            avatar={avatar}
        />
    );
});

SoundMeterStrength.propTypes = {
    Component: PropTypes.any.isRequired,
    track: PropTypes.object,
    avatar: PropTypes.object,
    refreshRate: PropTypes.number,
    onChange: PropTypes.func,
    onError: PropTypes.func,
    alertOnError: PropTypes.bool,
    isTrackMuted: PropTypes.bool,
    _client: PropTypes.string,
    thresholdType: PropTypes.oneOf(Object.values(THRESHOLD_TYPES))
};

export default SoundMeterStrength;
