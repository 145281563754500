import { useQuery, UseQueryResult } from 'react-query';
import { apis } from '@amplement/backend-connector';

import { PhonebookDb } from 'hooks/usePhonebook';

export interface SearchResult {
    id: string;
}

export interface PhoneBookData {
    label: string;
    phoneNumber: string;
}

export interface UserMetadata {
    firstname: string;
    lastname: string;
    id?: string;
    status?: number;
    title: string;
    avatarUri?: string;
}

export interface BookmarkResult extends SearchResult {
    data: PhoneBookData;
    user?: UserMetadata;
    _entity?: string;
}

export interface FeedResult extends SearchResult {
    name: string;
}

export interface UserResult extends SearchResult {
    firstname: string;
    lastname: string;
}

export interface MetasFile {
    thumbnailUri?: string;
    thumbnailHeight?: number;
    thumbnailWidth?: number;
    height?: number;
    width?: number;
    uri: string;
    extension: string;
    isDisplayable: boolean;
    mimeType: string;
    name: string;
}

export interface MetasBot {
    id: string;
    message: string;
    name: string;
    type: string;
    status: number;
    additionalData: {
        isFinished: boolean;
        tokenUsed: number;
    };
}

export interface FileResult extends SearchResult {
    _feed: string;
    _user: string;
    metasFile: MetasFile;
    updatedAt: string;
}

interface RoomType {
    id: string;
    name: string;
}

interface FeedType {
    _room?: string;
    name: string;
}

export interface FeedItemResult extends SearchResult {
    _feed: string;
    _user: string;
    content?: string;
    metasFile?: MetasFile;
    metasBot?: MetasBot;
    updatedAt?: string;
    createdAt?: string;
    room?: RoomType;
    user?: UserMetadata;
    feed?: FeedType;
}

export interface RoomResult extends SearchResult {
    name: string;
}

export interface Paging {
    from: number;
    to: number;
    total: number;
}

export interface SearchCategoryResult<T> {
    results: T[];
    paging: Paging;
    error?: string;
}

export interface SearchResultsData {
    bookmarks?: SearchCategoryResult<BookmarkResult>;
    feeds?: SearchCategoryResult<FeedResult>;
    phoneBook?: SearchCategoryResult<PhonebookDb>;
    rooms?: SearchCategoryResult<RoomResult>;
    users?: SearchCategoryResult<UserResult>;
    feedItems?: SearchCategoryResult<FileResult | FeedItemResult>;
}

type Filter = 'all' | 'feeds' | 'bookmarks' | 'rooms' | 'users' | 'phoneBook' | 'feedItems';
interface UseSearchProps {
    query?: string;
    filters?: Array<Filter>;
    subFilters?: {
        [key: 'feedItemType' | string]: 'file' | 'content' | string;
    };
    page?: number;
    limit?: number;
    highlight?: boolean;
    _users?:string[];
    _feed?:string;
    fromDate?:string;
    toDate?:string;
}

const useSearch = (searchProps: UseSearchProps): UseQueryResult<SearchResultsData> =>
    useQuery<SearchResultsData>(
        ['search', searchProps],
        async () => {
            const useExperimentalSearch = localStorage.getItem('_SEARCH_USE_EXPERIMENTAL') === "1";
            const showScore = localStorage.getItem('_SEARCH_SHOW_SCORE') === "1";
            const showRequest = localStorage.getItem('_SEARCH_SHOW_REQUEST') === "1";

            const response = await apis.app.$search(searchProps, {useExperimentalSearch, showScore, showRequest});
            return response.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            keepPreviousData: true,
        }
    );

export default useSearch;
