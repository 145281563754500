import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import GuestLogin from 'components/Shared/Forms/GuestLogin/container';

const GuestLoginPage = ({ returnUrl }) => {
    const { search } = window.location;
    let scope = {};

    if (search.indexOf('/room/') !== -1) {
        scope = {
            key: 'room',
            value: search.split('/room/')?.[1]
        }
    }
    return (
        <div className="room-configurator">
            <div className="login-wrapper">
                <div className="content-container">
                    <GuestLogin
                        scope={scope}
                        returnUrl={returnUrl}
                    />
                </div>
            </div>
        </div>
    );
};

GuestLoginPage.propTypes = {
    returnUrl: PropTypes.string.isRequired
};

export default injectIntl(GuestLoginPage);
