import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import { FILE_TYPES, FILE_TEMPLATES } from 'const/feed';

import { processFile } from 'utils/file';
import { makeCancelable } from 'utils/cancelablePromise';

import ImageSliderPreview from 'components/Feed/Creator/FileUploadManager/PreviewTemplates/Image';
import FileSliderPreview from 'components/Feed/Creator/FileUploadManager/PreviewTemplates/File';
import VideoSliderPreview from 'components/Feed/Creator/FileUploadManager/PreviewTemplates/Video';
import NoPreview from 'components/Feed/Creator/FileUploadManager/PreviewTemplates/NoPreview';

import SliderPreviewTemplate from 'components/Feed/Creator/FileUploadManager/PreviewTemplates/Layout';

const PreviewFactopry = ({ type = FILE_TYPES.FILE, file, isCurrent = false, content = '', onChange, onFocus }) => {
    const [loading, setLoading] = useState(type === FILE_TYPES.IMAGE);
    const [base64Url, setBase64Url] = useState();
    const [error, setError] = useState();
    let Component = null;

    if (loading) {
        Component = 'loading';
    }

    if (error) {
        Component = 'error';
    }

    switch (type) {
        case FILE_TYPES.IMAGE:
            useEffect(() => {
                const cancelablePromise = makeCancelable(processFile(file, FILE_TEMPLATES.PREVIEW));

                cancelablePromise.promise.then(result => {
                    setBase64Url(result.url);
                    setLoading(false);
                })
                    .catch(e => {
                        if (e?.isCanceled) {
                            console.warn('PreviewFactory', e);
                        } else {
                            setError(e);
                        }
                    });

                return cancelablePromise.cancel;
            }, []);
            if (base64Url) {
                Component = <ImageSliderPreview url={base64Url} />;
            } else {
                Component = <NoPreview />;
            }
            break;
        case FILE_TYPES.VIDEO:
            Component = <VideoSliderPreview file={file} />;
            break;
        case FILE_TYPES.FILE:
            Component = <FileSliderPreview file={file} />;
            break;
        default:
            Component = null;
    }

    return (
        <SliderPreviewTemplate
            id={file.name}
            onChange={onChange}
            content={content}
            isCurrent={isCurrent}
            onFocus={onFocus}
        >
            {Component}
        </SliderPreviewTemplate>
    );
};

PreviewFactopry.propTypes = {
    file: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    content: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(FILE_TYPES)),
    isCurrent: PropTypes.bool,
    onFocus: PropTypes.func.isRequired
};

export default memo(PreviewFactopry);
