import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Dropdown, Button } from 'antd';
import { StarOutlined, StarFilled } from '@ant-design/icons';

import { injectIntl } from 'react-intl';
import { colors } from 'common/themes/Colors';
import { usePostBookmark, useDeleteBookmark } from 'hooks/useBookmark';

const iconStyle = { color: colors.badges.bgAdmin };
const buttonStyle = { maxWidth: '100%' };

const getIcon = (hasBookmark) => {
    if (!hasBookmark) {
        return <StarOutlined style={iconStyle} />;
    }    
    return <StarFilled style={iconStyle} />;
}

const arrow = { pointAtCenter: true };
const noCursor = { cursor: 'auto' };
const BookmarkAction = ({
    contact = {},
    bookmarks,
    intl
}) => {
    const { phones, firstname, lastname, _user, id } = contact;
    const {
        isLoading: postIsLoading,
        mutate: post,
    } = usePostBookmark({ type: 'phoneNumber', intl });
    const {
        isLoading: removeIsLoading,
        mutate: remove,
    } = useDeleteBookmark({ type: 'phoneNumber', intl });

    const bookmarkedPhones = useMemo(() => phones.reduce((acc, phone) => {        
        const b = bookmarks.find(bookmark => bookmark.type === 'phoneNumber' && bookmark.data.phoneNumber === phone.displayNumber);
        if (b) {
            acc[phone.displayNumber] = b.id;
        }
        return acc;
    }, {})
    , [phones, bookmarks]);

    const hasBookmark = Object.keys(bookmarkedPhones).length > 0;
    
    const handleBookmark = useCallback((destNumber) => {
        if (bookmarkedPhones[destNumber]) {
            remove(bookmarkedPhones[destNumber]);
        } else {
            post({
                "phoneNumber": String(destNumber),
                "label": intl.formatMessage({ id: 'user.username' }, { firstname, lastname }),
                externalId: String(id),
                _entity: _user
            });
        }
    }, [bookmarkedPhones, remove, post, firstname, lastname, _user, id, intl]);

    const menu = useMemo(() => ({
        items: phones.map(phone => ({
            key: phone.displayNumber,
            label: phone.displayNumber,
            icon: getIcon(bookmarkedPhones[phone.displayNumber])
        })),
        onClick: (e) => handleBookmark(e.key),
        disabled: removeIsLoading || postIsLoading
    }), [phones, handleBookmark, bookmarkedPhones, removeIsLoading, postIsLoading]);

    const handleBookmarkFirstNumber = useCallback(() => {
        handleBookmark(phones[0]?.displayNumber)
    }, [handleBookmark, phones]);

    if (!bookmarks) {
        return (
            <Button disabled type="text">
                <StarOutlined style={iconStyle} />
            </Button>
        );
    }

    if (phones?.length === 0) {
        return null;
    }

    if (phones?.length === 1) {
        return (
            <Button 
                disabled={removeIsLoading || postIsLoading} 
                onClick={handleBookmarkFirstNumber} 
                style={removeIsLoading || postIsLoading ? noCursor : undefined}
                type="text"
            >
                {getIcon(hasBookmark)}
            </Button>
        );
    }

    return (
        <Dropdown menu={menu} placement="bottomRight" arrow={arrow}>
            <Button style={buttonStyle} type="text">
                {getIcon(hasBookmark)}
            </Button>
        </Dropdown>
    );
};

BookmarkAction.propTypes = {
    contact: PropTypes.object,
    bookmarks: PropTypes.any,
    intl: PropTypes.any.isRequired,
};

export default injectIntl(memo(BookmarkAction, (prev, next) => prev.phones === next.phones && prev.bookmarks === next.bookmarks));
