import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const SketchIcon = ({ color = '#FFC100', withBorder = true }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-sketch" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="file-ai" fill={color} fillRule="nonzero">
                {withBorder && <Border />}
            </g>
            <g id="if_Sketch_1298712-(1)" transform="translate(20.000000, 50.000000)">
                <rect
                    id="Rectangle-path"
                    x="0"
                    y="0"
                    width="50"
                    height="50"
                />
                <path
                    d="M41.240625,20.0574219 L36.9246094,14.0460938 L39.9964844,11.7707031 L44.784375,15.3980469 L41.240625,20.0574219 Z M25,41.4113281 L13.1191406,25.7894531 L36.88125,25.7894531 L25,41.4113281 Z M9.99648438,11.7761719 L13.9710938,14.1335938 L9.40390625,20.9046875 L5.21523438,15.3980469 L9.99648438,11.7761719 Z M18.2046875,15.6214844 L32.7941406,15.7375 L36.8914063,21.4441406 L14.2765625,21.4441406 L18.2046875,15.6214844 Z M16.0710937,7.17539063 L33.9296875,7.17539063 L36.3890625,9.03867188 L33.2058594,11.3953125 L17.6605469,11.2726563 L13.7351563,8.94453125 L16.0710937,7.17539063 Z M49.1390625,13.2480469 L41.6089844,7.54335938 C41.4402344,7.36601563 41.2496094,7.22070313 41.0402344,7.11328125 L35.9671875,3.26953125 C35.5898437,2.98476563 35.1304687,2.83007813 34.6578125,2.83007813 L15.3425781,2.83007813 C14.8699219,2.83007813 14.409375,2.98476563 14.0332031,3.26953125 L0.860546875,13.2480469 C-0.096875,13.9734375 -0.283984375,15.3394531 0.44375,16.2960938 L23.2730469,46.3132812 C24.1417969,47.4558594 25.8578125,47.4558594 26.7269531,46.3132812 L49.5570313,16.2960937 C50.2839844,15.3394531 50.0964844,13.9734375 49.1390625,13.2480469 Z"
                    id="Sketch"
                    fill={color}
                />
            </g>
        </g>
    </svg>
);

SketchIcon.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

export default SketchIcon;
