import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Loader from 'components/Shared/Common/Loader';

const ResponderLoading = memo(({ intl, name = '', status = 'new' }) => (
    <>
        <div className="line">
            <div className="line-container">
                {status && (
                    <div className="state">
                        {intl.formatMessage({ id: 'room.responder.status' }, { status })}
                    </div>
                )}
            </div>
            <div className="line-container">
                <div className="name">
                    {/* {isAdmin && (
                        <Icon iconName="Star" />
                    )} */}
                    {name}
                </div>
            </div>
        </div>
        <Loader size="md" />
    </>
));

export const responderLoadingStatus = ['new', 'checking', 'connected', 'completed', 'failed', 'disconnected', 'closed'];
ResponderLoading.propTypes = {
    status: PropTypes.oneOf(responderLoadingStatus),
    name: PropTypes.string,
    intl: PropTypes.any.isRequired
};

export default memo(injectIntl(ResponderLoading));
