import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import Action from 'components/Shared/Common/Action';
import { Input } from 'antd';
import Icon from 'components/Shared/Common/Icon';
import debounce from 'debounce-promise';

const TogglePersistentRoom = memo((props) => {
    const {
        onClick,
        name = '',
        isChecked = false,
        onChange,
        intl
    } = props;

    useWhyDidYouUpdate('components:room:Footer:MoreButton:TogglePersistentRoom', props);

    const deb = debounce(onChange, 700);
    const handleChange = useCallback((e) => deb(e.target.value), [deb, onChange]);
    const handleClick = useCallback(() => !isChecked && onClick(true), [isChecked, onClick]);

    return (
        <Action
            className="line-item"
            onClick={handleClick}
        >
            <div className="line">
                <Icon iconName="Save" />
            </div>
            <div className="line-wrapper">
                <div className="line column">
                    <div className="label">
                        {intl.formatMessage({
                            id: !isChecked ? 'room.action.save' : 'room.name.input.placeholder'
                        })}
                    </div>
                    {isChecked ? (
                        <Input
                            placeholder={intl.formatMessage({ id: 'room.name.input.placeholder' })}
                            onChange={handleChange}
                            defaultValue={name}
                            maxLength={30}
                        />
                    ) : (
                        <div className="desc">
                            {intl.formatMessage({ id: 'room.save.label' })}
                        </div>
                    )}
                </div>
            </div>
        </Action>
    );
});

TogglePersistentRoom.propTypes = {
    onClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    isChecked: PropTypes.bool,
    intl: PropTypes.any.isRequired
};

export default injectIntl(TogglePersistentRoom);
