import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import DeviceControlButton from 'components/Shared/Call/DeviceControlButton';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import useMyStreamSelector from 'hooks/useMyStreamSelector';

const i18n = intl => ({ isOn }) => ({
    noDevice: intl.formatMessage({ id: 'rtc.error.noMicrophone' }),
    denied: intl.formatMessage({ id: 'rtc.error.audioDeniedAccess' }),
    button: intl.formatMessage(
        { id: 'room.toggleAudio' },
        { isAudioOn: isOn }
    )
});

const DeviceControlButtonMicrophone = (props) => {
    const {
        _room,
        isDisabled = false,
        onClick,
        errorId,
        intl
    } = props;

    const { updatedAt, hasAudio: hasTrack } = useMyStreamSelector(_room) || {};

    useWhyDidYouUpdate('components:room:controls:DeviceControlButtonMicrophone', { ...props, updatedAt, hasTrack });

    return (
        <DeviceControlButton
            isDisabled={isDisabled}
            isChecked={hasTrack}
            onToggle={onClick}
            intl={intl}
            errorId={errorId}
            type="audio"
            mediaType="audioinput"
            deviceType="microphone"
            btnOffLabel="MicOff"
            btnOnLabel="Mic"
            formatter={i18n}
        />
    );
}

DeviceControlButtonMicrophone.propTypes = {
    intl: PropTypes.any.isRequired,
    errorId: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    _room: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool
};

export default injectIntl(DeviceControlButtonMicrophone);
