import React from 'react';
import PropTypes from 'prop-types';

import ItemList from 'components/Shared/User/ItemList';
import styled from 'styled-components';

export const StyleListUsers = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 0;
    min-height: 0;
    width: 100%
`;

const MemberItemList = ({
    fallbackMessage,
    isLoading,
    _room,
    ids,
    ItemComponent,
    canManage
}) => (
    <StyleListUsers>
        <ItemList
            ids={ids}
            fallbackMessage={fallbackMessage}
            isLoading={isLoading}
            itemRender={(_user) => (
                <ItemComponent
                    key={_user}
                    _user={_user}
                    _room={_room}
                    canManage={canManage}
                />
            )}
        />
    </StyleListUsers>
);

MemberItemList.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string),
    _room: PropTypes.string.isRequired,
    fallbackMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isLoading: PropTypes.bool,
    canManage: PropTypes.bool,
    ItemComponent: PropTypes.any.isRequired
};

export default MemberItemList;
