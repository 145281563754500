import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import config from 'config';

import AvatarProfile from 'components/Shared/Common/AvatarProfile';
import SoundMeter, { THRESHOLD_TYPES } from 'components/Shared/Call/SoundMeterStrength';

const AvatarAudioStrength = memo(({ strength, avatar }) => (
    <AvatarProfile
        src={avatar?.src}
        firstname={avatar?.firstname}
        strengthRate={strength}
        className="avatar avatar-equalizer"
        size={avatar?.size}
    />
));

const AvatarEqualizer = ({
    track,
    alertOnError,
    isTrackMuted,
    isMe,
    _client,
    avatar,
}) => (
    <SoundMeter
        track={track}
        Component={AvatarAudioStrength}
        thresholdType={THRESHOLD_TYPES.BOOL}
        alertOnError={alertOnError}
        isTrackMuted={isTrackMuted}
        refreshRate={config.speakingMeterRefreshRate}
        isMe={isMe}
        avatar={avatar}
        _client={_client}
    />
);

AvatarEqualizer.propTypes = {
    track: PropTypes.shape({
        id: PropTypes.string.isRequired
    }),
    alertOnError: PropTypes.bool,
    isMe: PropTypes.bool,
    isTrackMuted: PropTypes.bool,
    _client: PropTypes.string
};

export default injectIntl(AvatarEqualizer);
