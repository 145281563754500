import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Shared/Common/Button';
import { injectIntl } from 'react-intl';
import { Dropdown } from 'antd';
import { getUrl } from 'utils/url';
import { CONTACT_STATUS } from 'const/user';
import ContactStatusButton from 'components/Shared/User/ContactStatusButton';
import { StyledInformationLabel } from 'components/Shared/styles';
import { StyledActions } from 'components/Shared/User/Item/styles';
import Icon from 'components/Shared/Common/Icon';
import { colors } from 'common/themes/Colors';
import { isWebRtcCompatible } from 'utils/webRTC';

const dropdownTrigger = ['click'];

export const PendingAction = ({ intl }) => (
    <StyledInformationLabel className="styled-information">
        <div className="item waiting">
            {intl.formatMessage({
                id: 'smallDrawer.feed.waitingLabel',
                defaultMessage: 'En attente'
            })}
        </div>
    </StyledInformationLabel>
);

PendingAction.propTypes = {
    intl: PropTypes.object.isRequired
};

export const MultipleActions = injectIntl(({
    intl,
    onKick,
    onChangeFeedAdministrator = () => {},
    isPendingMembers,
    isAdmin = false
}) => {
    const menu = useMemo(() => ({
        items: [
            !isAdmin && !isPendingMembers && {
                label: intl.formatMessage({ id: 'feed.tooltip.add.administrator' }),
                icon: <Icon bgColor={colors.badges.bgAdmin} iconName="King" />,
                onClick: onChangeFeedAdministrator('add'),
                key: 'addAdministrator',
            },
            isAdmin && !isPendingMembers && {
                label: intl.formatMessage({ id: 'feed.tooltip.remove.administrator' }),
                icon: <Icon bgColor={colors.roomPulseBullet} iconName="King" />,
                onClick: onChangeFeedAdministrator('remove'),
                key: 'removeAdministrator',
            },
            onKick && {
                label: intl.formatMessage({ id: 'feed.tooltip.remove.user' }),
                icon: <Icon iconName="Trash2" />,
                onClick: onKick,
                danger: true,
                key: 'removeUserGroup',
            },
        ],
    }), [intl, onKick, onChangeFeedAdministrator, isPendingMembers, isAdmin]);
    
    return (
        <StyledActions className="ui-list-users-actions">
            <Dropdown menu={menu} trigger={dropdownTrigger}>
                <Button 
                    color="secondary" 
                    isTransparent 
                    size="sm" 
                    iconName="MoreHorizontal" 
                />
            </Dropdown>
        </StyledActions>
    );
});

MultipleActions.propTypes = {
    onKick: PropTypes.func,
    onChangeFeedAdministrator: PropTypes.func,
    isAdmin: PropTypes.bool
};

export const SimpleAction = memo(({ _user }) => (
    <StyledActions className="ui-list-users-actions">
        <div className="item">
            {_user && (
                <Button
                    color="secondary"
                    isTransparent
                    size="sm"
                    iconName={isWebRtcCompatible() ? 'Phone' : 'PhoneOff'}
                    href={getUrl('roomByUser', { _user })}
                    target={`roomByUser-${_user}`}
                    isDisabled={!isWebRtcCompatible()}
                />
            )}
        </div>
    </StyledActions>
));

SimpleAction.propTypes = {
    _user: PropTypes.string
};

export const ContactStatusButtonAction = ({
    contactStatus = CONTACT_STATUS.NOT_CONNECTED,
    isInitiator,
    onAccept,
    onDecline,
    onLink,
    onUnlink
}) => (
    <StyledActions className="ui-list-users-actions fixed s-search-btn">
        <div className="item">
            <ContactStatusButton
                contactStatus={contactStatus}
                onLink={onLink}
                onUnlink={onUnlink}
                onAccept={onAccept}
                onDecline={onDecline}
                isInitiator={isInitiator}
                size="md"
            />
        </div>
    </StyledActions>
);

ContactStatusButtonAction.propTypes = {
    contactStatus: PropTypes.oneOf(Object.values(CONTACT_STATUS)),
    onLink: PropTypes.func.isRequired,
    onUnlink: PropTypes.func.isRequired,
    onAccept: PropTypes.func,
    onDecline: PropTypes.func,
    isInitiator: PropTypes.bool
};

export default {};
