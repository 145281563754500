import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { injectIntl } from 'react-intl';

const StylePanelError = styled.div`
    padding: 15px;
    background-color: #FF4040;
    border-radius: 4px;
    margin: 20px 0;
    .text{
        font-size: 14px;
        color: ${({ theme }) => theme.fixedWhite};
    }
`;

const TextError = ({ intl, errorKey, errorValues }) => (
    <StylePanelError>
        <div className="text">
            {intl.formatMessage({ id: errorKey }, errorValues)}
        </div>
    </StylePanelError>
);

TextError.propTypes = {
    intl: PropTypes.any.isRequired,
    errorKey: PropTypes.string.isRequired,
    errorValues: PropTypes.object
};

export default injectIntl(TextError);


