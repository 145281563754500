import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

const Acknowledgement = ({
    _feed,
    hasTabFocus,
    requestAcknowledgement,
    lastAckAt,
    unreadCount
}) => {
    const debouncedAckRequest = useRef(debounce(requestAcknowledgement, 2000));

    useEffect(() => {
        if (_feed && hasTabFocus && unreadCount > 0) {
            debouncedAckRequest.current(_feed);
        }
    }, [unreadCount, _feed, hasTabFocus, lastAckAt, debouncedAckRequest]);

    return null;
};

Acknowledgement.propTypes = {
    _feed: PropTypes.string.isRequired,
    hasTabFocus: PropTypes.bool.isRequired,
    lastAckAt: PropTypes.string,
    unreadCount: PropTypes.number,
    requestAcknowledgement: PropTypes.func.isRequired
};

export default Acknowledgement;
