import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import Button from 'components/Shared/Common/Button';
import AntdModal from 'components/Shared/Modals';

const ModalConfirm = (props) => {
    const {
        intl,
        title,
        onConfirm,
        onClose,
        TriggerComponent
    } = props;
    const [isModalOpened, setIsModalOpened] = useState();
    const handleCloseModal = useCallback(() => setIsModalOpened(false), [setIsModalOpened]);
    const handleConfirm = useCallback(() => onConfirm(), [onConfirm]);
    const handleToggleModal = useCallback(() => {
        setIsModalOpened(!isModalOpened);
        if (onClose) onClose();
    }, [onClose, setIsModalOpened, isModalOpened]);

    return (
        <>
            <AntdModal
                isOpen={isModalOpened}
                footer={(
                    <>
                        <Button
                            onClick={handleCloseModal}
                            color="secondary"
                        >
                            {intl.formatMessage({ id: 'global.button.cancel' })}
                        </Button>
                        <Button
                            onClick={handleConfirm}
                            color="error"
                        >
                            {intl.formatMessage({ id: 'global.button.confirm' })}
                        </Button>
                    </>
                )}
                onClose={handleCloseModal}
            >
                {title}
            </AntdModal>
            <TriggerComponent
                onClick={handleToggleModal}
            />
        </>
    );
}

ModalConfirm.propTypes = {
    intl: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    TriggerComponent: PropTypes.any.isRequired
};

export default memo(injectIntl(ModalConfirm));
