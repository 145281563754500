import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Field, Form as FinalForm } from 'react-final-form';
import { Row, Col, Form, Typography, Radio } from 'antd';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import { UsergroupAddOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useSelector } from 'react-redux';
import { selectors } from '@amplement/backend-connector';

import {
    LabelInput,
    LabelTextArea,
    LabelDatePicker,
    LabelMultiSelect,
    LabelSelect
} from 'components/Shared/Forms';

import CallBuilder from 'components/CallBuilder';
import Button from 'components/Shared/Common/Button';
import injectValidators from 'components/Shared/Forms/injectValidators';
import { NAVIGATION } from 'components/CallBuilder/constants';
import { ButtonActions } from 'components/Shared/styles';
import Icon from 'components/Shared/Common/Icon';
import { composeValidators } from 'utils/formValidator';

import ApiResponseError from '../ApiResponseError';


const { Text } = Typography;

const StyledDiv = styled.div`
    margin-bottom: 20px;
`;

const StyledSwitchDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const StyledText = styled(Text)`
    padding: 0 0 8px;
    white-space: initial;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: ${({ theme }) => rgba(theme.black, 0.6)}!important;
    font-size: 10px!important;
    font-weight: bold;
`;

const StyledForm = styled(Form)`
    width: 100%;
    max-width: 650px;
`;

const getNavButtons = (intl, navigation, onNavigationChange) => [
    {
        type: 'link',
        children: (
            <p>
                <UsergroupAddOutlined /> {intl.formatMessage({ id: 'menuLeft.feeds.title' })}
            </p>
        ),
        disabled: navigation === NAVIGATION.FEEDS,
        onClick: () => onNavigationChange(NAVIGATION.FEEDS)
    }
];

const searchResultsKeys = [NAVIGATION.FEEDS, NAVIGATION.USERS];

const CallBuilderWrapper = ({
    input,
    isVisible,
    onVisibleChange,
    label,
    isFakeListDropdown,
    ...props
}) => {
    const handleFocus = useCallback(() => onVisibleChange(true), []);
    const handleBlur = useCallback(() => onVisibleChange(false), []);
    const handleClose = useCallback(() => onVisibleChange(false), []);

    const handleChange = useCallback((value) => {
        const _users = value.map(item => !item.phoneNumber ? item._user : undefined).filter(user => !!user);
        input.onChange(_users);
    }, [input.onChange]);

    return (
        <StyledDiv>
            <StyledText>{label}</StyledText>
            <CallBuilder
                {...props}
                selectPlaceholder={label}
                _room='form'
                onlySearch
                maxListHeight={400}
                isFakeListDropdown={isFakeListDropdown}
                isListOpen={isVisible}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onClose={handleClose}
                onInputChange={handleChange}
                getCustomNavButtons={getNavButtons}
                defaultNavigation={searchResultsKeys[0]}
                searchResultsKeys={searchResultsKeys}
            />
        </StyledDiv>
    );
}

const autoSize = { minRows: 3, maxRows: 7 };

const validate = (values) => {
    const errors = { scheduler: {} };
    const { startDate, endDate } = values?.scheduler || {};

    if (startDate && endDate && dayjs(startDate).isAfter(endDate)) {
        errors.scheduler.startDate = 'startDate should be before endDate';
    }

    if (startDate && !dayjs(startDate).isAfter()) {
        errors.scheduler.startDate = 'startDate should be after now';
    }

    if (endDate && !dayjs(endDate).isAfter()) {
        errors.scheduler.endDate = 'startDate should be after now';
    }

    return errors;
}

const formatMessages = (intl) => ({
    scheduleFormInputSubject: intl.formatMessage({ id: 'room.scheduleForm.input.subject' }),
    scheduleFormPlaceholderSubject: intl.formatMessage({ id: 'room.scheduleForm.placeholder.subject' }),
    scheduleFormInputDescription: intl.formatMessage({ id: 'room.scheduleForm.input.description' }),
    scheduleFormPlaceholderDescription: intl.formatMessage({ id: 'room.scheduleForm.placeholder.description' }),
    scheduleFormInputMembers: intl.formatMessage({ id: 'room.scheduleForm.input.members' }),
    scheduleFormMultiSelect: intl.formatMessage({ id: 'drawer.createGroup.multiSelect.noOptionsMessageText' }),
    scheduleFormPlaceholderMembers: intl.formatMessage({ id: 'room.scheduleForm.placeholder.members' }),
    scheduleFormInputEmails: intl.formatMessage({ id: 'room.scheduleForm.input.emails' }),
    scheduleFormInputDateStart: intl.formatMessage({ id: 'room.scheduleForm.input.dateStart' }),
    scheduleFormInputDateEnd: intl.formatMessage({ id: 'room.scheduleForm.input.dateEnd' }),
    sendButton: intl.formatMessage({ id: 'global.button.send' }),
    useExistantConference: intl.formatMessage({ id: 'room.scheduleForm.input.useExistantConference' }),
    createNewConference: intl.formatMessage({ id: 'room.scheduleForm.input.createNewConference' }),
    selectExistingConference: intl.formatMessage({ id: 'room.scheduleForm.input.selectExistingConference' }),
});

const span = 12;

const showTime = {
    format: 'HH:mm',
    minuteStep: 5
};

const StartDateField = memo(({ name, label, validate: startDateValidation, change }) => {
    const handleStartDateChange = useCallback(value => {
        if (value) {
            change(`${name}.startDate`, dayjs(value));
            change(`${name}.endDate`, dayjs(value).add(1, 'hour'));
        }
    }, [name]);

    return (
        <Field
            label={label}
            name={`${name}.startDate`}
            component={LabelDatePicker}
            showTime={showTime}
            customFormat="YYYY-MM-DD HH:mm"
            validate={startDateValidation}
            onChange={handleStartDateChange}
        />
    );
}, isEqual);

const SchedulerInput = memo(({
    name = '',
    validators,
    intl,
    onSubmit = () => {},
    networkError,
    isFakeListDropdown = false,
    error,
    resetCallBuilder = false,
    setResetCallBuilder = () => {},
    initialValues
}) => {
    const [isCallbuilderVisible, setIsCallbuilderVisible] = useState(false);
    const [conferenceOption, setConferenceOption] = useState(1);
    const { required, roomInvitTopicLength, emails, roomInvitDescriptionLength } = validators;
    const translations = useMemo(() => formatMessages(intl), [intl]);
    const initial = useMemo(() => initialValues, [initialValues.scheduler._entity]);
    const rooms = useSelector(selectors.rooms.getRooms);

    const handleChangeConferenceOption = useCallback(e => setConferenceOption(e.target.value), [setConferenceOption]);

    const options = useMemo(() => {
        if (rooms && rooms.length) {
            return rooms.map(room => ({ value: room.id, label: room.name }));
        }

        return [];
    }, [rooms]);

    return (
        <FinalForm onSubmit={onSubmit} validate={validate} initialValues={initial}>
            {({ handleSubmit, submitting, form, pristine, invalid }) => (
                <StyledForm onSubmit={handleSubmit} layout="vertical">
                    <ApiResponseError value={networkError} />
                    {error && <Text type="danger">{error}</Text>}
                    <Field name={`${name}.type`}>
                        {({ input }) => <input type="hidden" {...input} />}
                    </Field>

                    <Field name={`${name}._entity`}>
                        {({ input }) => <input type="hidden" {...input} />}
                    </Field>

                    <Field
                        label={translations.scheduleFormInputSubject}
                        name={`${name}.subject`}
                        component={LabelInput}
                        placeholder={translations.scheduleFormPlaceholderSubject}
                        validate={composeValidators(required, roomInvitTopicLength)}
                        autoFocus
                    />

                    <Field
                        label={translations.scheduleFormInputDescription}
                        name={`${name}.description`}
                        component={LabelTextArea}
                        validate={roomInvitDescriptionLength}
                        placeholder={translations.scheduleFormPlaceholderDescription}
                        autoSize={autoSize}
                    />

                    {!initialValues.scheduler._entity && (
                        <StyledSwitchDiv>
                            <Radio.Group
                                onChange={handleChangeConferenceOption}
                                value={conferenceOption}
                            >
                                <Radio value={1}>{translations.useExistantConference}</Radio>
                                <Radio value={0}>{translations.createNewConference}</Radio>
                            </Radio.Group>
                        </StyledSwitchDiv>
                    )}

                    {!!conferenceOption && !initialValues.scheduler._entity && (
                        <Field
                            label={translations.selectExistingConference}
                            name={`${name}._room`}
                            component={LabelSelect}
                            options={options}
                            validate={required}
                            notFoundContent={translations.scheduleFormMultiSelect}
                            placeholder={translations.selectExistingConference}
                        />
                    )}

                    <Field
                        label={translations.scheduleFormInputMembers}
                        name={`${name}._users`}
                        isFakeListDropdown={isFakeListDropdown}
                        isVisible={isCallbuilderVisible}
                        onVisibleChange={setIsCallbuilderVisible}
                        component={CallBuilderWrapper}
                        resetCallBuilder={resetCallBuilder}
                        setResetCallBuilder={setResetCallBuilder}
                        placeholder={translations.scheduleFormPlaceholderMembers}
                        notFoundContent={null}
                        defaultOpen={false}
                    />

                    {!isCallbuilderVisible && (
                        <>
                            <Field
                                label={translations.scheduleFormInputEmails}
                                name={`${name}.emails`}
                                component={LabelMultiSelect}
                                placeholder={translations.scheduleFormInputEmails}
                                validate={emails}
                                notFoundContent={null}
                                defaultOpen={false}
                                suffixIcon={<Icon iconName="AtSign" />}
                            />

                            <Row gutter={25}>
                                <Col span={span}>
                                    <StartDateField
                                        name={name}
                                        label={translations.scheduleFormInputDateStart}
                                        validate={required}
                                        change={form.change}
                                    />
                                </Col>
                                <Col span={span}>
                                    <Field
                                        label={translations.scheduleFormInputDateEnd}
                                        name={`${name}.endDate`}
                                        component={LabelDatePicker}
                                        showTime={showTime}
                                        customFormat="YYYY-MM-DD HH:mm"
                                        validate={required}
                                    />
                                </Col>
                            </Row>

                            <ButtonActions>
                                <Button
                                    iconName="Check"
                                    onClick={form.submit}
                                    color="primary"
                                    isDisabled={submitting || pristine || invalid}
                                    size="lg"
                                >
                                    {translations.sendButton}
                                </Button>
                            </ButtonActions>
                        </>
                    )}
                </StyledForm>
            )}
        </FinalForm>
    );
}, isEqual);

SchedulerInput.propTypes = {
    intl: PropTypes.any.isRequired,
    resetCallBuilder: PropTypes.bool,
    isFakeListDropdown: PropTypes.bool,
    setResetCallBuilder: PropTypes.func,
    name: PropTypes.string,
    validators: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    networkError: PropTypes.object,
    error: PropTypes.object,
};

export default injectValidators(injectIntl(SchedulerInput));
