import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Xls = ({ color = '#007732', withBorder = true }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-xls" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="file-img" fill={color} fillRule="nonzero">
                {withBorder && <Border />}
                <polygon
                    id="Shape"
                    points="62.7370264 97.0900002 54.2458602 97.0900002 46.0616036 83.7905832 37.877347 97.0900002 30 97.0900002 41.6625656 78.9823324 30.7161225 62 38.900379 62 46.4708164 74.5832945 53.8366473 62 61.8162975 62 50.7675511 79.3915453"
                />
            </g>
        </g>
    </svg>
);

Xls.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

export default Xls;
