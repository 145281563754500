import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import DeviceControlButton from 'components/Shared/Call/DeviceControlButton';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import useMyStreamSelector from 'hooks/useMyStreamSelector';

const i18n = intl => ({ isOn }) => ({
    noDevice: intl.formatMessage({ id: 'rtc.error.noCamera' }),
    denied: intl.formatMessage({ id: 'rtc.error.videoDeniedAccess' }),
    button: intl.formatMessage(
        { id: 'room.toggleVideo' },
        { isVideoOn: isOn }
    )
});

const DeviceControlButtonCamera = (props) => {
    const {
        _room,
        isDisabled = false,
        onClick,
        errorId,
        intl
    } = props;

    const { updatedAt, hasVideo: hasTrack } = useMyStreamSelector(_room) || {};

    useWhyDidYouUpdate('components:room:controls:DeviceControlButtonCamera',  { ...props, updatedAt, hasTrack });

    return (
        <DeviceControlButton
            isDisabled={isDisabled}
            isChecked={hasTrack}
            onToggle={onClick}
            intl={intl}
            errorId={errorId}
            type="video"
            mediaType="videoinput"
            deviceType="camera"
            btnOffLabel="VideoOff"
            btnOnLabel="Video"
            formatter={i18n}
        />
    );
}

DeviceControlButtonCamera.propTypes = {
    intl: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
    _room: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool
};

export default injectIntl(DeviceControlButtonCamera);
