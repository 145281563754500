import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { StyledCategoryTitle } from 'components/Shared/style';
import { Typography, Row, Space } from 'antd';
import Link from 'components/Shared/Common/Link';
import styled from 'styled-components';

const { Title } = Typography;
const StyledSpaceFlex = styled(Space)`
    flex: 1;
`;
const CategoryTitle = memo(({ intl, label, icon, count, quickAction }) => ( // todo ! move to shared;
    <StyledCategoryTitle className="s-title-page">
        <Row align="middle">
            <StyledSpaceFlex>
                {icon}
                <Title className="s-title" level={4}>{label}</Title>
                {!!count && <span className="number">({count})</span>}
            </StyledSpaceFlex>
            {!!quickAction && (
                <Link
                    href={quickAction}
                    className="s-link-text"
                >
                    {intl.formatMessage({ id: 'global.button.showMore' })}
                </Link>
            )}
        </Row>
    </StyledCategoryTitle>
));

CategoryTitle.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.any,
    quickAction: PropTypes.string,
};

export default injectIntl(CategoryTitle);
