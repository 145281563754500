import React, { useCallback, useMemo } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

import useUser from 'hooks/useUser';
import Row from 'components/CallBuilder/Row/Row';
import CallButton from 'components/CallBuilder/DefaultResults/style';

import { MAX, NAVIGATION } from 'components/CallBuilder/constants';

import { CommonListProps, ListProps } from 'components/CallBuilder/types';
import ButtonClick from 'components/Shared/Common/Button/ButtonClick';

const { Text } = Typography;

type UserProps = {
    // eslint-disable-next-line
    user: any;
} & WrappedComponentProps & CommonListProps;

const User = injectIntl(({
    user,
    inputValue,
    onAddParticipant,
    onRemoveParticipant,
    onCloseModal,
    intl,
    showDirectCall,
}: UserProps): JSX.Element => {
    const { data: { avatarUri /* status, TODO */ } = {} } = useUser({ _user: user.id });
    const translation = useMemo(() => ({ call: intl.formatMessage({ id: 'call.call' }) }), [intl]);

    const member = useMemo(() => ({
        ...user,
        fullname: `${user.firstname} ${user.lastname}`,
        avatarUri,
    }), [user, avatarUri]);

    const handleAddUser = useCallback(() => {
        if (inputValue.find((item) => item?._user === member?.id)) {
            return;
        }

        onAddParticipant?.({
            ...member,
            resultType: NAVIGATION.USERS,
            label: member.fullname,
            value: member.id,
            _user: member.id,
        });
    }, [onAddParticipant, inputValue, member]);

    const handleRemoveParticipant = useCallback(() => {
        onRemoveParticipant?.({
            ...member,
            label: member.fullname,
            value: member.id,
        }, `id`);
    }, [onRemoveParticipant, member]);

    const hasParticipant = useMemo(() => !!inputValue.find((item) => item?._user === member?.id), [inputValue, member]);

    return (
        <Row
            label={`${user.firstname} ${user.lastname}`}
            onAddParticipant={handleAddUser}
            onRemoveParticipant={handleRemoveParticipant}
            hasParticipant={hasParticipant}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            user={member}
        >
            {!inputValue.length && showDirectCall ? (
                <ButtonClick onClick={onCloseModal}>
                    <CallButton _user={user.id} iconName="Phone">
                        <p>{translation.call}</p>
                    </CallButton>
                </ButtonClick>
            ) : null}
        </Row>
    );
});


const UserList = ({
    max = MAX,
    inputValue = [],
    isLoading = false,
    searchResults: { [NAVIGATION.USERS]: users } = {},
    onAddParticipant,
    onRemoveParticipant,
    onCloseModal,
    intl,
    showDirectCall = false,
}: ListProps & WrappedComponentProps): JSX.Element | JSX.Element[] => {
    const { results = [] } = users || {};
    if (isLoading) {
        return <LoadingOutlined />;
    }

    if (!results || !results.length) {
        return <Text>{intl.formatMessage({ id: 'callbuilder.emptyList' })}</Text>;
    }

    return (
        results.slice(0, max).map(user => (
            <User
                key={user.id}
                user={user}
                showDirectCall={showDirectCall}
                inputValue={inputValue}
                onCloseModal={onCloseModal}
                onAddParticipant={onAddParticipant}
                onRemoveParticipant={onRemoveParticipant}
            />
        ))
    );
};

export default injectIntl(UserList as React.FunctionComponent<ListProps & WrappedComponentProps>);
