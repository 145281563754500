import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Link from './Link';


const defaultProps = {
    rel: null,
    children: null,
    onClick: null,
    href: null,
    className: null,
    target: null
};

const Action = forwardRef((props = defaultProps, ref) => <Link ref={ref} {...props} />);

Action.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
        PropTypes.element,
        PropTypes.array
    ]),
    onClick: PropTypes.func,
    href: PropTypes.string,
    className: PropTypes.string,
    rel: PropTypes.string,
    target: PropTypes.oneOf([
        '_blank',
        '_self',
        '_parent',
        '_top'
    ])
};

export default Action;
