import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import debounce from 'lodash/debounce';
import { $searchUsers } from 'api/user';
import { convertUserToSelect } from 'utils/multiselect';
import { SEARCH_INPUT_MIN_LENGTH } from 'const/input';
import { StyledSelect } from 'components/Shared/Forms/styles';

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <StyledSelect
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : props.notFoundContent}
            {...props}
            options={options}
        />
    );
} // Usage of DebounceSelect


async function fetchList(query) {
    if (query?.length < SEARCH_INPUT_MIN_LENGTH) {
        return Promise.resolve([]);
    }

    return $searchUsers(query, null, null, 30, 1)
        .then(({ data }) => {
            if (data && data.entities.users) {
                return Object.values(data.entities.users).map(convertUserToSelect);
            }
            return [];
        });
}

const UserMultiSelect = ({ value = [], onChange, onBlur, ...props }) => ( // eslint-disable-line
    <DebounceSelect
        {...props}
        mode="multiple"
        value={value || []}
        fetchOptions={fetchList}
        onChange={onChange}
    />
);

UserMultiSelect.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    onChange: PropTypes.func.isRequired
};

export default memo(UserMultiSelect);
