import React, { memo } from 'react';
import PropTypes from 'prop-types';

import AvatarStatus from 'components/Shared/User/Avatar';
import UserName from 'components/Shared/User/UserName';
import { PRESENCE_STATUSES } from 'const/user';
import { StyledFeedHeaderText } from 'components/Shared/style';

import { getUrl } from 'utils/url';

const FeedHeaderUnicastIdentity = ({
    id,
    avatar,
    avatarUri,
    presence = PRESENCE_STATUSES.OFFLINE,
    lastname,
    firstname,
    title = null
}) => {
    const profileUrl = getUrl('profile', { _user: id });

    return (
        <>
            <AvatarStatus
                src={avatar || avatarUri}
                status={Number.isInteger(presence) ? presence : undefined}
                size="md"
                alt={`${firstname} ${lastname}`}
                href={profileUrl}
            />
            <StyledFeedHeaderText className="ui-text">
                <span className="name">
                    <UserName firstname={firstname} lastname={lastname} />
                </span>
                <span className="subtitle">{title}</span>
            </StyledFeedHeaderText>
        </>
    );
};

FeedHeaderUnicastIdentity.propTypes = {
    id: PropTypes.string,
    avatar: PropTypes.object,
    presence: PropTypes.number,
    lastname: PropTypes.string,
    firstname: PropTypes.string,
    title: PropTypes.string
};

export default memo(FeedHeaderUnicastIdentity);
