import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'components/Shared/Common/Button';
import Dropdown from 'components/Shared/Common/Dropdown';
import { openModal } from 'actions/modal';
import { openSmallDrawer } from 'actions/smallDrawer';
import * as smallDrawerTypes from 'types/smallDrawer';
import { MODAL_DELETE_GROUP, MODAL_LEAVE_GROUP } from 'types/modal';
import { injectIntl } from 'react-intl';
import { openDrawer } from 'actions/drawer';
import { DRAWER_ADD_MEMBERS } from 'types/drawer';
import  { selectors } from '@amplement/backend-connector';
import { getCurrentUserIdSelector } from 'selectors/user';

const formatMessages = intl => () => ({
    inviteContact: intl.formatMessage({
        id: 'feed.header.button.invite.user',
        defaultMessage: 'Inviter des membres'
    }),
    settingDrawer: intl.formatMessage({
        id: 'feed.header.dropdown.settingDrawer',
        defaultMessage: 'Paramètres du groupe'
    }),
    deleteFeed: intl.formatMessage({
        id: 'feed.header.dropdown.deleteFeed',
        defaultMessage: 'Supprimer le groupe'
    }),
    leaveFeed: intl.formatMessage({
        id: 'feed.header.dropdown.leaveFeed',
        defaultMessage: 'Quitter le groupe'
    }),
    contactDrawer: intl.formatMessage({
        id: 'feed.header.button.drawer.users',
        defaultMessage: 'Membres'
    }),
    galleryDrawer: intl.formatMessage({
        id: 'feed.header.button.drawer.gallery',
        defaultMessage: 'Documents'
    })
});

// voir pourquoi j'ai besoin d'un feed ? je devrait avoir juste besoin de _me et _feed ?
const DropDownActions = ({
    isFeedAdmin = false,
    onOpenAddContactsDrawer,
    onOpenInformationDrawer,
    onRemoveFeed,
    onLeaveFeed,
    feedName,
    intl,
    onClickContactsDrawer,
    onClickGalleryDrawer,
    isCompact
}) => {
    const i18n = formatMessages(intl)();

    const handleRemoveFeed = useCallback(() => {
        onRemoveFeed(feedName);
    }, [onRemoveFeed, feedName]);

    const handleLeaveFeed = useCallback(() => {
        onLeaveFeed(feedName);
    }, [onLeaveFeed, feedName]);

    const extendedActions = useMemo(() => (
        isCompact ? [
            {
                id: 'contactDrawer',
                label: i18n.contactDrawer,
                onClick: onClickContactsDrawer
            },
            {
                id: 'galleryDrawer',
                label: i18n.galleryDrawer,
                onClick: onClickGalleryDrawer
            }
        ] : []
    ), [isCompact, i18n, onClickContactsDrawer, onClickGalleryDrawer]);

    const actions = useMemo(() => isFeedAdmin
        ? [
            ...extendedActions,
            {
                id: 'inviteContacts',
                label: i18n.inviteContact,
                onClick: onOpenAddContactsDrawer
            },
            {
                id: 'feedSettingsDrawer',
                label: i18n.settingDrawer,
                onClick: onOpenInformationDrawer
            },
            {
                id: 'leave',
                label: i18n.deleteFeed,
                onClick: handleRemoveFeed,
                danger: true
            }
        ]
        : [
            ...extendedActions,
            {
                id: 'leave',
                label: i18n.leaveFeed,
                onClick: handleLeaveFeed,
                danger: true
            }
        ], [isFeedAdmin, i18n, onOpenInformationDrawer, onOpenAddContactsDrawer, handleRemoveFeed, handleLeaveFeed, extendedActions]);

    return (
        <Dropdown items={actions} placement="bottomRight">
            <Button iconName="MoreVertical" isTransparent />
        </Dropdown>
    );
};

DropDownActions.propTypes = {
    intl: PropTypes.any.isRequired,
    isFeedAdmin: PropTypes.bool,
    onOpenAddContactsDrawer: PropTypes.func.isRequired,
    onOpenInformationDrawer: PropTypes.func.isRequired,
    onRemoveFeed: PropTypes.func.isRequired,
    onLeaveFeed: PropTypes.func.isRequired,
    feedName: PropTypes.string.isRequired
};

const mapStateToProps = (state, { _feed }) => {
    const feed = selectors.feeds.feedByIdSelector(state, _feed);
    const _me = getCurrentUserIdSelector(state);
    const { name, user } = feed || {};
    const isFeedAdmin = selectors.feeds.isFeedAdministratorById(_feed, _me)(state);
    const feedName = name || `${user?.firstname} ${user?.lastname}`;

    return {
        feedName,
        isFeedAdmin
    };
};

const mapDispatchToProps = (dispatch, { _feed }) => ({
    onRemoveFeed: name => dispatch(openModal(MODAL_DELETE_GROUP, { _feed, name })),
    onLeaveFeed: name => dispatch(openModal(MODAL_LEAVE_GROUP, { _feed, name })),
    onOpenInformationDrawer: () =>
        dispatch(openSmallDrawer(smallDrawerTypes.FEED_DRAWER_INFORMATIONS)),
    onOpenGalleryDrawer: () => dispatch(openSmallDrawer(smallDrawerTypes.FEED_DRAWER_GALLERY)),
    onOpenAddContactsDrawer: () => dispatch(openDrawer(DRAWER_ADD_MEMBERS, { _feed })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(DropDownActions));
