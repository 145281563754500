import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Img = ({ color = '#1D409D', withBorder = true }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-img" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={color} fillRule="nonzero">
                {withBorder && <Border />}
                <path
                    d="M75.4285714,80.9285714 L75.4285714,97 L24,97 L24,87.3571429 L33.6428571,77.7142857 L40.0714286,84.1428571 L59.3571429,64.8571429 L75.4285714,80.9285714 Z M33.6428571,71.2857143 C30.9642723,71.2857143 28.6875094,70.3482237 26.8125,68.4732143 C24.9374906,66.5982049 24,64.321442 24,61.6428571 C24,58.9642723 24.9374906,56.6875094 26.8125,54.8125 C28.6875094,52.9374906 30.9642723,52 33.6428571,52 C36.321442,52 38.5982049,52.9374906 40.4732143,54.8125 C42.3482237,56.6875094 43.2857143,58.9642723 43.2857143,61.6428571 C43.2857143,64.321442 42.3482237,66.5982049 40.4732143,68.4732143 C38.5982049,70.3482237 36.321442,71.2857143 33.6428571,71.2857143 Z"
                    id="file-image-o---FontAwesome"
                />
            </g>
        </g>
    </svg>
);

Img.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

export default Img;
