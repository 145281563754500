import React, { memo } from 'react';
import styled from 'styled-components';
import { VideoCameraOutlined } from '@ant-design/icons';

import { StyledFeedHeaderText } from 'components/Shared/style';

const StyledDiv = styled.div`
    margin-left: 10px;
    display: flex;
`;

const FeedHeaderRoom = ({ name = '' }: { name: string }): JSX.Element => (
    <StyledDiv>
        <VideoCameraOutlined className="video-camera" />
        <StyledFeedHeaderText className="ui-text">
            <span className="name">{name}</span>
        </StyledFeedHeaderText>
    </StyledDiv>
);

export default memo(FeedHeaderRoom);
