import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Shared/Common/Spinner';

const EmojiPicker = lazy(() => import('./EmojiMartPicker'));

const FeedItemInput = ({ onSelect }) => (
    <Suspense fallback={<Spinner />}>
        <EmojiPicker 
            onEmojiSelect={onSelect}
        />
    </Suspense>
);

FeedItemInput.propTypes = {
    onSelect: PropTypes.func.isRequired,
};

export default FeedItemInput;
