import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, message, Typography } from 'antd';
import { injectIntl } from 'react-intl';
import { useToggle } from 'react-use';
import styled from 'styled-components';
import { useMutation, useQueryClient } from 'react-query';
import { apis } from '@amplement/backend-connector';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import useSipAccounts from 'hooks/useSipAccounts';

import ToggleButton from 'components/Shared/Forms/ToggleButton';
import ForwardTypeSelector from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/ForwardTypeSelector';
import ForwardSummaryLayout from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/ForwardSummaryLayout';
import Icon from 'components/Shared/Common/Icon';

import FORWARD_TRIGGERS_COMPONENTS from 'components/Shared/Drawers/Sip/Forwards/const';

import { FORWARD_TRIGGER_TYPES, FORWARD_TYPES } from 'const/call';

const { Text } = Typography;
const StyledText = styled(Text)`
    margin-left: 5px;
    white-space: normal;
    font-weight: 300;
`;

const getResourceKeyByTrigger = trigger => {
    if (trigger === FORWARD_TRIGGER_TYPES[3]) return 'call.forward.title.unreachable';
    if (trigger === FORWARD_TRIGGER_TYPES[2]) return 'call.forward.title.unconditional';
    if (trigger === FORWARD_TRIGGER_TYPES[1]) return 'call.forward.title.unavailable';
    if (trigger === FORWARD_TRIGGER_TYPES[0]) return 'call.forward.title.busy';

    return '';
}

const getResourceDescKeyByTrigger = trigger => {
    if (trigger === FORWARD_TRIGGER_TYPES[3]) return 'call.forward.description.unreachable';
    if (trigger === FORWARD_TRIGGER_TYPES[2]) return 'call.forward.description.unconditional';
    if (trigger === FORWARD_TRIGGER_TYPES[1]) return 'call.forward.description.unavailable';
    if (trigger === FORWARD_TRIGGER_TYPES[0]) return 'call.forward.description.busy';

    return '';
}

const getIconByTrigger = trigger => {
    if (trigger === FORWARD_TRIGGER_TYPES[3]) return 'MinusCircleO';
    if (trigger === FORWARD_TRIGGER_TYPES[2]) return 'InconditionnalCall';
    if (trigger === FORWARD_TRIGGER_TYPES[1]) return 'NoAnswerCall';
    if (trigger === FORWARD_TRIGGER_TYPES[0]) return 'OccupedCall';

    return '';
}

const ForwardItem = (props) => {
    const { data, intl } = props;
    const { trigger, type } = data || {};
    const [enabled, toggle] = useToggle(type !== 'NONE');
    const [isEdit, setEdit] = useToggle();
    const { data: accounts } = useSipAccounts();
    const { id: _sipAccount } = accounts?.[0] || {};
    const queryClient = useQueryClient();
    const border = '1px dotted green';
    const iconName = getIconByTrigger(trigger);

    const handleEdit = () => setEdit(true);
    
    const handleCancelEdit = () => setEdit(false);

    const { isLoading, mutate: save, data: mutationData } = useMutation(
        ({
            meta, // eslint-disable-line
            ...settings
        }) => apis.callForward.$postForwardSettings(_sipAccount, settings), 
        {
            onSuccess: (response) => {
                // queryClient.invalidateQueries(["callForward", { _sipAccount }]); // refetch data to get latest
                // const previous = queryClient.getQueryData(['callForward',  { _sipAccount }]);
                // // replace cache instead of invalidate
                // queryClient.setQueryData(["callForward", { _sipAccount }], previous.map(fi => fi.trigger === response?.data?.trigger ? response?.data : fi))

                queryClient.setQueryData(["callForward", { _sipAccount, trigger: undefined }], 
                    (previous) => [...previous.map(fi => fi.trigger === response?.data?.trigger ? response?.data : fi)]
                );

                if (response?.data?.type !== 'NONE') {
                    handleCancelEdit();
                }

                message.success({
                    key: 'callForwardSettingsSuccess',
                    content : 'Update success',
                    duration: 3,
                });
            },
            onError: (e) => {
                message.error({
                    key: 'callForwardSettingsError',
                    content : e.response?.data?.message || e.message,
                    duration: 5,
                });
            },
        });
        
    useWhyDidYouUpdate(
        `components:ForwardList:ForwardItem:${trigger}`, 
        {...props, data, mutationData, trigger, type, isEdit, enabled, accounts, _sipAccount, queryClient }
    );

    const handleSubmit = (values) => {
        const payload = {
            ...values,
            trigger: data?.trigger,
            to: values.to?.replace(/^[+]/, '00')
        };

        if (type === 'NONE' && values.type === 'NONE') return;

        save(payload);
    }

    useEffect(() => {
        if (!enabled) {
            handleSubmit({ type: 'NONE' });
        } else if (type === 'NONE') {
            handleEdit();
        }
    }, [enabled, type]);

    return (
        <Card
            title={(
                <>
                    <Row align="middle">
                        {iconName && <Icon iconName={iconName} />}
                        <span style={{marginLeft: 5}}>{intl.formatMessage({ id: getResourceKeyByTrigger(trigger) })}</span>
                    </Row>
                    <Row align="middle">
                        <StyledText>{intl.formatMessage({ id: getResourceDescKeyByTrigger(trigger) })}</StyledText>
                    </Row>
                </>
            )}
            extra={(
                <ToggleButton
                    toggleOnText={intl.formatMessage({ id: 'button.toggle.active' })}
                    toggleOffText={intl.formatMessage({ id: 'button.toggle.inactive' })}
                    {...props}
                    value={enabled}
                    onChange={toggle}
                    loading={isLoading}
                />
            )} 
            bodyStyle={{ display: enabled ? 'block': 'none' }}
            headStyle={!enabled ? { border } : undefined}
            style={enabled ? { border } : undefined}
        >
            {enabled && (
                isEdit ? (
                    <ForwardTypeSelector
                        onSubmit={handleSubmit}
                        value={data}
                        onCancel={handleCancelEdit}
                        isLoading={isLoading}
                    />
                ) : ( 
                    <ForwardSummaryLayout
                        data={data}
                        ContentComponent={FORWARD_TRIGGERS_COMPONENTS[type]}
                        onEdit={handleEdit}
                        isLoading={isLoading}
                    />
                )
            )}
        </Card>
    );
};

ForwardItem.propTypes = {
    intl: PropTypes.any.isRequired,
    data: PropTypes.shape({
        type: PropTypes.oneOf(FORWARD_TYPES).isRequired,
        trigger: PropTypes.oneOf(FORWARD_TRIGGER_TYPES).isRequired,
        to: PropTypes.string,
        context: PropTypes.string,
    })
};

export default injectIntl(ForwardItem);
