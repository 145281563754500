import React, { memo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import styled from 'styled-components';
import { Modal } from 'antd';
import { rgba } from 'polished';

const StyleModal = styled(Modal)`
    &.autoscroll{
        height: calc(100% - 1.5rem);
        .ant-modal-content{
            max-height: 100%;
            display: flex;
            flex-direction: column;
            .ant-modal-body{
                overflow-y: auto;
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
            }
        }
    }
    &.remove-body-pd{
        .ant-modal-content{
            .ant-modal-body{
                padding: 0;
            }
        }
    }
    .ant-modal{
        &-header{
            background-color: ${({ theme }) => theme.bgRoom};
            border: 0;
        }
        &-content{
            background-color: ${({ theme }) => theme.bgRoom};
        }
        &-title{
            color: ${({ theme }) => theme.black};
            display: flex;
            align-items: center;
            .icon{
                margin-right: 10px;
                display: flex;
            }
        }
        &-close{
            color: ${({theme}) => rgba(theme.black, 0.45)};
        }
        &-body{
            color: ${({ theme }) => theme.black};
            padding: 20px;
            .search-contact{
                margin-bottom: 20px;
            }
        }
        &-footer{
            border-color: ${({theme}) => rgba(theme.black, 0.1)};
            display: flex;
            flex-direction: row;
            padding: 20px;
            justify-content: space-between;
        }
    }
`;

const AntdModal = ({
    isOpen = false,
    title,
    isAutoScroll = false,
    onClose,
    width = 400,
    children,
    footer,
    destroyOnClose = false,
    isRemovePaddingBody = false
}) => (
    <StyleModal
        className={classNames({
            autoscroll: isAutoScroll,
            'remove-body-pd': isRemovePaddingBody
        })}
        title={title}
        width={width}
        onCancel={onClose}
        open={isOpen}
        footer={footer}
        centered
        destroyOnClose={destroyOnClose}
    >
        {children}
    </StyleModal>
);

AntdModal.propTypes = {
    footer: PropTypes.any,
    title: PropTypes.any,
    width: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    isAutoScroll: PropTypes.bool,
    isRemovePaddingBody: PropTypes.bool,
    destroyOnClose: PropTypes.bool,
};

export default memo(AntdModal);
