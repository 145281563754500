import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import Action from 'components/Shared/Common/Action';
import Icon from 'components/Shared/Common/Icon';
import { selectors } from '@amplement/backend-connector';
import { useCleanChatRoom } from 'hooks/useRooms';
import { useSelector, useDispatch } from 'react-redux';
import useRoomSelector from 'hooks/useRoomSelector';
import { message, Popconfirm } from 'antd';

const CleanChatRoomButton = memo(injectIntl(({ onClick, intl }) => (
    <Action
        className="line-item"
        onClick={onClick}
    >
        <div className="line">
            <Icon iconName="Trash2" />
        </div>
        <div className="line-wrapper">
            <div className="line column">
                <div className="label">
                    {intl.formatMessage({ id: 'room.actions.deleteFeeditems' })}
                </div>
            </div>
        </div>
    </Action>
)));

const CleanChatButton = ({ intl, onClose, name }) => {
    const _room = useSelector(selectors.rooms._currentRoomSelector);
    const room = useRoomSelector(selectors.rooms.getRoomByIdSelector, _room);
    const dispatch = useDispatch();

    const onSuccess = useCallback(() => {
        message.success({
            key: 'deletePhoneBookUserSuccess',
            content: intl.formatMessage({ id: 'room.actions.deleteFeeditems.success' }),
            duration: 5,
        });
    }, [intl]);

    const onError = useCallback(() => {
        message.error({
            key: 'deletePhoneBookUserError',
            content: intl.formatMessage({ id: 'room.actions.deleteFeeditems.error' }),
            duration: 7,
        });
    }, [intl]);

    const { mutateAsync: cleanChat } = useCleanChatRoom({ _room, 
        onSuccess: () => {
            dispatch({
                type: 'WS_DELETE_FEED',
                deleteSubscription: true,
                payload: { id: room?._feed }
            })
            onSuccess();
        }, onError});

    const handleConfirm = useCallback(() => {
        cleanChat();
        onClose();
    }, [cleanChat, onClose]);
    
    return (
        <Popconfirm
            placement="left"
            title={intl.formatMessage({ id: 'room.actions.confirmDeleteFeeditems' }, { name })}
            onConfirm={handleConfirm}
            onCancel={onClose}
            okText={intl.formatMessage({ id: 'global.button.confirm'})}
            cancelText={intl.formatMessage({ id: 'global.button.cancel'})}
        >
            <CleanChatRoomButton />
        </Popconfirm>
    );
}


CleanChatButton.propTypes = {
    intl: PropTypes.any.isRequired,
    name: PropTypes.any.isRequired,
    onClose: PropTypes.any.isRequired,
};

export default memo(injectIntl(CleanChatButton));
