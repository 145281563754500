import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { batch, connect } from 'react-redux';
import { actions, selectors } from '@amplement/backend-connector';

import injectValidators from 'components/Shared/Forms/injectValidators';
import { StyledForm } from 'components/Shared/Forms/styles';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import { openModal } from 'actions/modal';
import { MODAL_DELETE_FEEDITEM } from 'types/modal';

import { ENTER } from 'const/eventKey';

import LabelFeedItemTextArea from './LabelFeedItemTextArea';

const autoSize = { minRows: 1, maxRows: 8 };

const FeedItemInput = (props) => {
    const {
        _feed,
        intl,
        isDisabled = false,
        validators,
        initialValues = {},
        replyComment = {},
        onReplyFeedItem = () => {},
        onSubmit = () => {},
    } = props;

    useWhyDidYouUpdate('components:feed:FeedItemInput', props);

    // onPressEnter is not triggered when input is empty, handleKeyUp is added to trigger this case.
    const handleKeyUp = useCallback((form) => (e) => {
        if (e.keyCode === ENTER && !e.shiftKey) {
            form.submit();
        }
    }, [onSubmit]);

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, form }) => (
                <StyledForm onSubmit={handleSubmit}>
                    <Field
                        form={form}
                        name="content"
                        disabled={isDisabled}
                        placeholder={intl.formatMessage({ id: 'feed.textArea.placeholder' })}
                        component={LabelFeedItemTextArea}
                        onPressEnter={handleSubmit}
                        replyComment={replyComment}
                        onReplyFeedItem={onReplyFeedItem}
                        onKeyUp={handleKeyUp(form)}
                        autoSize={autoSize}
                        clearOnPressEscape
                        validate={validators.feedItemLength}
                        _feed={_feed}
                        hasEmoji
                    />
                </StyledForm>
            )}
        </Form>
    );
}

FeedItemInput.propTypes = {
    _feed: PropTypes.string,
    replyComment: PropTypes.object,
    onReplyFeedItem: PropTypes.func,
    isDisabled: PropTypes.bool,
    intl: PropTypes.any.isRequired,
    validators: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.shape({
        content: PropTypes.string,
        _feedItem: PropTypes.string,
    }),
};

const mapStateToProps = (state, { _feed }) => ({
    initialValues: selectors.feeds.feedInputByIdSelector(_feed)(state),
});

const mapDispatchToProps = (dispatch, { _feed, replyComment, onReplyFeedItem }) => ({
    onSubmit: ({ content: feedContent, _feedItem }, form) => {
        batch(() => {
            const content = feedContent ? feedContent.trim().replace(/\n+$/, '') : undefined;
            const feedItem = { content };

            if (content) {
                if (replyComment?._feedItem) {
                    dispatch(actions.feedItems.requestPostFeedItem({
                        _feed,
                        feedItem: {
                            content,
                            _itemToQuote: replyComment._feedItem,
                        },
                    }));
                    onReplyFeedItem(null, '');
                } else if (_feedItem) {
                    dispatch(actions.feedItems.requestPatchFeedItem(_feed, {
                        ...feedItem,
                        id: _feedItem
                    }))
                } else {
                    dispatch(actions.feedItems.requestPostFeedItem({
                        _feed,
                        feedItem
                    }))
                }
                dispatch(actions.feeds.setFeedInput(_feed, undefined, undefined));
            } else if (_feedItem) {
                dispatch(
                    openModal(MODAL_DELETE_FEEDITEM, { _feed, _feedItem, content })
                );
            }
            form.change('_feedItem', undefined);
            form.change('content', '');
        });
    }
});

export default compose(
    injectValidators,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
)(memo(FeedItemInput, (prevProps, nextProps) => (
    prevProps.isDisabled === nextProps.isDisabled
    && prevProps._feed === nextProps._feed
    && prevProps.replyComment === nextProps.replyComment
)));
