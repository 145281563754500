import React from 'react';
import PropTypes from 'prop-types';

import HeaderMainTitle from 'components/Room/Header/HeaderTitle';
import Participant from 'components/Room/Participant/container';

import NetworkPingInfo from 'components/Room/Participant/NetworkPingInfo';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { StyleHeader, StyleHeaderMeRoom } from './styles';

const Header = (props) => {
    const {
        _room = '',
        className = '',
        roomName = '',
        roomColor = '#A00',
        memberCount = 1,
        maxMembers = 1,
        joinedAt = '',
        onFocusMember,
        hasFocus = false,
        _client
    } = props;

    useWhyDidYouUpdate('component:room:roomscreen:header', props);

    return (
        <StyleHeader className={className}>
            <HeaderMainTitle
                roomName={roomName}
                roomColor={roomColor}
                memberCount={memberCount}
                maxMembers={maxMembers}
                joinedAt={joinedAt}
            />
            <StyleHeaderMeRoom>
                <NetworkPingInfo />
                {_client && (
                    <Participant
                        forceMute
                        onToggleFocus={hasFocus ? undefined : onFocusMember}
                        _client={_client}
                        _room={_room} // for kick
                        width={120}
                        height={68}
                    />
                )}
            </StyleHeaderMeRoom>
        </StyleHeader>
    );
};

Header.propTypes = {
    className: PropTypes.string,
    _room: PropTypes.string,
    joinedAt: PropTypes.string,
    roomName: PropTypes.string,
    _client: PropTypes.string.isRequired,
    roomColor: PropTypes.string,
    onFocusMember: PropTypes.func,
    hasFocus: PropTypes.bool,
    memberCount: PropTypes.number,
    maxMembers: PropTypes.number,
};

export default Header;
