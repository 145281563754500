import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Typography, Row } from 'antd';
import { StyledRole } from 'components/Shared/User/Item/styles';

const { Text } = Typography;

const Badge = intl => (name, i) => {
    let label;

    if (name === 'pending') {
        label = intl.formatMessage({
            id: 'smallDrawer.feed.waitingLabel',
            defaultMessage: 'En attente'
        });
    } else if (name === 'admin') {
        label = intl.formatMessage({ id: 'user.roles.admin' });
    } else if (name === 'creator') {
        label = intl.formatMessage({ id: 'user.roles.creator' });
    }

    return (
        <StyledRole key={`${name}-${i}`} className={`ui-role ${name}`}>
            {label}
        </StyledRole>
    );
};

const UserName = ({
    style = {},
    firstname = '',
    lastname = '',
    badges = [],
    badge,
    intl
}) => (
    <Row align="middle">
        <Text style={style} className="ui-name" strong ellipsis>
            {intl.formatMessage(
                {
                    id: 'user.username',
                    defaultMessage: '{firstname} {lastname}'
                },
                {
                    firstname,
                    lastname
                }
            )}
        </Text>
        {badges && badges.map(Badge(intl))}
        {badge && Badge(badge(intl))}
    </Row>
);

UserName.propTypes = {
    intl: PropTypes.any.isRequired,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    badges: PropTypes.array,
    style: PropTypes.object,
    badge: PropTypes.string,
};

export default injectIntl(UserName);
