import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Skeleton, Row, Col } from 'antd';

const StyleCard = styled(Col)`
    .card{
        &-header{
            margin-bottom: 20px;
            .ant-skeleton-avatar{
                width: 60px;
                height: 60px;
            }
        }
        &-content{
            margin-bottom: 20px;
            .ant-skeleton{
                &-element{
                    margin-right: 10px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
`;

const SimpleSkeleton = ({ active = true, size = 'large', avatarShape = 'circle', inputSize = 'small' }) => (
    <StyleCard>
        <Row align="middle" justify="center" className="card-header">
            <Skeleton.Avatar active={active} size={size} shape={avatarShape} />
        </Row>
        <Row align="middle" justify="center" className="card-content">
            <Skeleton.Input style={{ width: 60 }} active={active} size={inputSize} />
            <Skeleton.Input style={{ width: 60 }} active={active} size={inputSize} />
        </Row>
    </StyleCard>
)

SimpleSkeleton.propTypes = {
    active: PropTypes.bool,
    size: PropTypes.string,
    avatarShape: PropTypes.string,
    inputSize: PropTypes.string,
};

export default memo(SimpleSkeleton);
