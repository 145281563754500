import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { CONTACT_STATUS } from 'const/user';
import buttonProps from 'components/Shared/Common/Button/Button.props';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { requestPostContact, requestDeleteContact, requestUpdateContactStatus } from 'actions/user';
import { SimpleAction, ContactStatusButtonAction } from './Actions/actions';

const ContactActions = ({
    _user,
    showUnlink = false,
    contactStatus,
    isInitiator,
    intl,
    size = 'md'
}) => {
    const dispatch = useDispatch();
    const handleRequest = useCallback(() => dispatch(requestPostContact(_user)), [_user]);
    const handleRemove = useCallback(() => dispatch(requestDeleteContact(_user)), [_user]);
    const handleAccept = useCallback(() => dispatch(requestUpdateContactStatus(_user, CONTACT_STATUS.ACCEPTED)), [_user]);
    const handleDecline = useCallback(() => dispatch(requestUpdateContactStatus(_user, CONTACT_STATUS.DECLINED)), [_user]);

    if (showUnlink || contactStatus !== CONTACT_STATUS.ACCEPTED) {
        return (
            <ContactStatusButtonAction
                _user={_user}
                contactStatus={contactStatus}
                isInitiator={isInitiator}
                onAccept={handleAccept}
                onDecline={handleDecline}
                intl={intl}
                onLink={handleRequest}
                onUnlink={handleRemove}
                size={size}
            />
        );
    }

    if (contactStatus === CONTACT_STATUS.ACCEPTED) {
        return <SimpleAction _user={_user} />;
    }

    return null;
};

ContactActions.propTypes = {
    _user: PropTypes.string,
    contactStatus: PropTypes.oneOf(Object.values(CONTACT_STATUS)),
    isInitiator: PropTypes.bool,
    showUnlink: PropTypes.bool,
    intl: PropTypes.any,
    size: PropTypes.oneOf(buttonProps.sizes)
};

export default injectIntl(ContactActions);
