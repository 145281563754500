import React, { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Form } from 'antd';
import { useQueryClient } from 'react-query';

import useSipAccounts from 'hooks/useSipAccounts';

import ForwardSipUserSearch from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/ForwardSipUserSearch';
import { ForwardSummaryVoiceMail } from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/ItemSummary';
import {
    SearchBtn,
    ForwardToLabel,
    PhoneBookNumber,
    voiceMailFilterPhoneType
} from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/Commons';

const ForwardVoiceMailInput = (props) => {
    const { value = '', onChange, intl } = props;
    const [meta, setMeta] = useState();
    const { data: accounts, isMyAccount } = useSipAccounts();
    const { sipUsername } = accounts?.[0] || {};
    const isMe = isMyAccount(value);
    const form = Form.useFormInstance();
    const queryClient = useQueryClient();
    
    const handleChange = (val) => {
        onChange(val?.sipUsername);
        queryClient.setQueryData(['phonebooks', { query: val?.sipUsername, type: 'internal' }], [val]);
        setMeta(val);
    }

    const handleReset = useCallback(() => {
        onChange(sipUsername);
        setMeta();
    }, [onChange, sipUsername]);

    useEffect(() => {
        if (!value) {
            onChange(sipUsername);
        }
        form.setFieldValue('type', 'VOICEMAIL');
    }, [value, sipUsername]);

    return (
        <>
            <ForwardToLabel />
            {meta ?
                (
                    <PhoneBookNumber 
                        {...meta} 
                        emptyText={isMe ? intl.formatMessage({ id: 'call.forward.myVoicemail' }) : `voicemailUser(${value})`} 
                    /> 
                ) : <ForwardSummaryVoiceMail data={{ context: value }} />
            }
            {!isMe && value && (
                <Button 
                    type="link"
                    danger 
                    onClick={handleReset} 
                    size="small"
                >
                    x
                </Button>
            )}
            {' '}
            <ForwardSipUserSearch
                TriggerComponent={SearchBtn}
                onConfirm={handleChange}
                phoneFilterTypes={voiceMailFilterPhoneType}
                type="internal"
            />
        </>
    );
}

ForwardVoiceMailInput.propTypes = {
    intl: PropTypes.any.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default injectIntl(memo(ForwardVoiceMailInput));

