import React, { useMemo } from 'react';
import { Typography, Grid } from 'antd';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { UseQueryResult } from 'react-query';

import Icon from 'components/Shared/Common/Icon';
import Link from 'components/Shared/Common/Link';
import Button from 'components/Shared/Common/Button';

import Forward from 'screens/Dashboard/ForwardCard/ForwardView';

import useSipAccounts from 'hooks/useSipAccounts';
import useCallForwards, { CallForward } from 'hooks/useCallForwards';

import { getUrl } from 'utils/url';

import StyledCallButton from 'components/CallBuilder/DefaultResults/style';
import { StyledCard, StyledEmptyCardWrapper } from 'screens/Dashboard/styles';
import { colors } from 'common/themes/Colors';

import { FORWARD_TYPES } from 'const/call';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const StyledIcon = styled(Icon)`
    svg {
        width: 26px;
        height: 26px;
    }
`;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledTitle = styled.div`
    margin-left: 10px !important;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    .label{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    h4{
        font-weight: 400 !important;
        margin-right: 10px;
        margin-bottom: 0;
    }
`;

const StyledCardWrapper = styled(StyledCard)`
    .ant-card-body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
    }
`;

const ForwardCard = ({ intl }): JSX.Element | null => {
    const { data: accounts } = useSipAccounts();
    const { id: _sipAccount } = accounts?.[0] || {};
    const { isLoading, error, isError, data }: UseQueryResult = useCallForwards({ _sipAccount });
    const { lg, xl, xxl } = useBreakpoint();

    const isLargeScreen = useMemo(() => lg || xl || xxl, [lg, xl, xxl]);

    const cardTitle = useMemo(() => (
        <StyledContainer>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <StyledIcon bgColor={colors.black} iconName="PhoneForwarded" />
            <StyledTitle>
                <div className="label">
                    <Title level={4}>{intl.formatMessage({ id: 'call.tabs.forwardSettings' })}</Title>
                </div>
            </StyledTitle>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Link
                href={getUrl('forwards')}
                className="text"
            >
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <StyledCallButton type="text">
                    <Button iconName="Settings">
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        {isLargeScreen && <p>{intl.formatMessage({ id: 'dashboard.parameters.configuration' })}</p>}
                    </Button>
                </StyledCallButton>
            </Link>
        </StyledContainer>
    ), [intl, isLargeScreen]);

    if (!data) {
        return null;
    }

    if (!(data as CallForward[]).find((forward: CallForward) => forward.type !== FORWARD_TYPES[4])) {
        return <StyledEmptyCardWrapper title={cardTitle} />
    }

    return (
        <StyledCardWrapper title={cardTitle}>
            <Forward
                isLoading={isLoading}
                error={error as string}
                isError={isError}
                data={data as CallForward[]}
            />
        </StyledCardWrapper>
    )
};

export default injectIntl(ForwardCard);
