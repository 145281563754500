import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Drawer from 'components/Shared/Drawers';
import MemberManagementContentDrawer from 'components/Room/MemberManagement/Drawer/MemberManagementContentDrawer/container';
import { selectors } from '@amplement/backend-connector';
import { injectIntl } from 'react-intl';
import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import Icon from 'components/Shared/Common/Icon';

const InviteUserButton = ({ _room, isMeAdmin, intl, Button }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleToggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    }

    const handleDrawerClose = useCallback(() => setIsDrawerOpen(false), [setIsDrawerOpen]);

    return (
        <>
            <Button
                onClick={handleToggleDrawer}
                isActive={isDrawerOpen}
            />
            <Drawer
                isOpen={isDrawerOpen}
                onClose={handleDrawerClose}
                title={(
                    <CategoryTitle
                        label={intl.formatMessage({
                            id: isMeAdmin ? 'room.drawer.members.manage.title'
                                : 'room.drawer.members.list.title'
                        })}
                        icon={<Icon size="xl" iconName="Users" />}
                    />
                )}
            >
                <MemberManagementContentDrawer
                    _room={_room}
                />
            </Drawer>
        </>
    );
}

InviteUserButton.propTypes = {
    Button: PropTypes.any.isRequired,
    _room: PropTypes.string,
    isMeAdmin: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
    _users: selectors.rooms.getRoomMemberIdsByRoomIdSelector(state, ownProps?._room),
    isMeAdmin: selectors.rooms.getUserIsAdminByCurrentUserIdSelector(state, ownProps?._room)
});

export default connect(mapStateToProps, null)(injectIntl(InviteUserButton));
