import React from 'react';
import PropTypes from 'prop-types';

const ToolBoxIcon = ({ color = '' }) => (
    <svg width="1em" height="1em" viewBox="0 0 48 48">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                stroke="currentColor"
                strokeWidth="4"
            >
                <path d="M3,2 C2.44771525,2 2,2.44771525 2,3 L2,20 C2,20.5522847 2.44771525,21 3,21 L20,21 C20.5522847,21 21,20.5522847 21,20 L21,3 C21,2.44771525 20.5522847,2 20,2 L3,2 Z M3,27 C2.44771525,27 2,27.4477153 2,28 L2,45 C2,45.5522847 2.44771525,46 3,46 L20,46 C20.5522847,46 21,45.5522847 21,45 L21,28 C21,27.4477153 20.5522847,27 20,27 L3,27 Z" />
                <rect fill={color} x="28" y="2" width="18" height="44" rx="3" />

            </g>
        </g>
    </svg>
);

ToolBoxIcon.propTypes = {
    color: PropTypes.string
};

export default ToolBoxIcon;
