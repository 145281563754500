import React, { useCallback, forwardRef, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { EmojiConvertor } from 'emoji-js';
import { useDebounce, useEnsuredForwardedRef } from 'react-use';

import { StyledTextArea } from 'components/Shared/Forms/styles';

import { ENTER, ESCAPE } from 'const/eventKey';
import replaceStringWithEmojis from './EmojiIndex';

const emoji = new EmojiConvertor();

const autoSize = { minRows: 1, maxRows: 15 };
const loadEmojis = async () => (await import("./EmojiIndex"))?.default?.emojis;

// const getEmojiDataByNativeString = (nativeString, emojiIndex) => { @cedrouck
//     if (!emojiIndex.current) return nativeString;

//     let result = nativeString.replace(/([a-zA-Z0-9]):/gim, '$1: ');

//     const emoticons = Object.values(emojiIndex.current)
//         .filter(item => item.emoticons && item.emoticons.length);

//     emoticons.forEach((item) => {
//         item.emoticons.forEach((emo) => {
//             result = result.replace(emo, item.native);
//         });
//     });

//     return result.replace(/([a-zA-Z0-9]): /gim, '$1:');
// }

const TextArea = forwardRef((props, ref) => {
    const emojiIndex = useRef();
    const inputRef = useEnsuredForwardedRef(ref);
    const {
        className, // eslint-disable-line
        clearOnPressEscape = true,
        onPressEnter,
        onChange = () => {},
        value,
        autoFocus = false,
        onKeyUp = () => {},
        ...rest
    } = props;
    const [textAreaValue, setTextAreaValue] = useState(value);

    const handlePressEnter = useCallback(({ keyCode, shiftKey, ...event }) => {
        onKeyUp({ keyCode, shiftKey, ...event });
        if (!!value?.trimEnd?.() && onPressEnter && keyCode === ENTER && !shiftKey) {
            onPressEnter(value?.trimEnd?.());
            setTextAreaValue('');
        } else if (clearOnPressEscape && keyCode === ESCAPE) {
            onChange('');
            setTextAreaValue('');
        }
        return false;
    }, [clearOnPressEscape, onChange, onPressEnter, value]);

    const handleChange = useCallback((e) => {
        const { value: inputValue } = e.target;
        const text = emoji.replace_colons(inputValue); // replace ":)"
        // const textWithEmoji = getEmojiDataByNativeString(text, emojiIndex) // replace ":+1:" @Cedrouck

        // Force selection due to conflicting bug between ant design input and emojis
        if (inputValue !== text) {
            const { selectionStart, selectionEnd } = inputRef.current.resizableTextArea.textArea;
            const differenceLength = text.length - inputValue.length;
            setTimeout(() => {
                inputRef.current.resizableTextArea.textArea.selectionStart = selectionStart + differenceLength;
                inputRef.current.resizableTextArea.textArea.selectionEnd = selectionEnd + differenceLength;
            });
        }
        onChange(text);
        setTextAreaValue(text);

    }, [onChange, emojiIndex.current, inputRef]);

    const handleLockEnter = useCallback((e) => {
        const { charCode, shiftKey } = e;
        if (onPressEnter && charCode === ENTER && !shiftKey) {
            e.preventDefault();
            e.stopPropagation();
        }}, [onChange, onPressEnter]);

    useEffect(() => {
        loadEmojis().then(result => {
            emojiIndex.current = result;
        });
    }, [])

    useEffect(() => {
        if (autoFocus) {
            const inputValueLength = inputRef.current.resizableTextArea.textArea.value.length;
            inputRef.current.focus();
            inputRef.current.resizableTextArea.textArea.selectionStart = inputValueLength;
        }
    }, [autoFocus, inputRef]);

    useEffect(() => {
        setTextAreaValue(value);
    }, [value]);

    useDebounce(async () => {
        const text = emoji.replace_colons(value);
        const textWithEmoji = await replaceStringWithEmojis(text);
        // Force selection due to conflicting bug between ant design input and emojis
        if (value !== textWithEmoji) {
            const { selectionStart, selectionEnd } = inputRef.current.resizableTextArea.textArea;
            const differenceLength = textWithEmoji.length - value.length;
            setTimeout(() => {
                inputRef.current.resizableTextArea.textArea.selectionStart = selectionStart + differenceLength;
                inputRef.current.resizableTextArea.textArea.selectionEnd = selectionEnd + differenceLength;
            });
        }
        onChange(textWithEmoji);
        setTextAreaValue(textWithEmoji);
    }, 10, [value, inputRef, onChange]);

    return (
        <StyledTextArea
            data-testid="TextArea"
            autoSize={autoSize}
            {...rest}
            onChange={handleChange}
            value={textAreaValue}
            // onPressEnter={handlePressEnter}
            onKeyUp={handlePressEnter}
            onKeyPress={handleLockEnter}
            // onFocus={handleFocus}
            ref={inputRef}
        />
    );
});

TextArea.propTypes = {
    clearOnPressEscape: PropTypes.bool,
    onPressEnter: PropTypes.func,
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    autoFocus: PropTypes.bool,
};

export default (TextArea);
