import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Json = ({ color = '#000105', withBorder = true }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-json" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="file-ai" fill={color} fillRule="nonzero">
                {withBorder && <Border />}
            </g>
            <g id="Group" transform="translate(17.000000, 52.000000)" fill={color}>
                <polygon
                    id="Fill-8"
                    points="6.92307692 16.1 11.5384615 16.1 11.5384615 6.9 6.92307692 6.9"
                />
                <path
                    d="M9.23076923,9.2 C7.95461538,9.2 6.92307692,8.1696 6.92307692,6.9 C6.92307692,3.0958 10.0292308,0 13.8461538,0 C15.1223077,0 16.1538462,1.0304 16.1538462,2.3 C16.1538462,3.5696 15.1223077,4.6 13.8461538,4.6 C12.5723077,4.6 11.5384615,5.6327 11.5384615,6.9 C11.5384615,8.1696 10.5069231,9.2 9.23076923,9.2"
                    id="Fill-9"
                />
                <path
                    d="M2.30769231,25.3 C1.03153846,25.3 0,24.2696 0,23 C0,21.7304 1.03153846,20.7 2.30769231,20.7 C4.85076923,20.7 6.92307692,18.6369 6.92307692,16.1 C6.92307692,14.8304 7.95461538,13.8 9.23076923,13.8 C10.5069231,13.8 11.5384615,14.8304 11.5384615,16.1 C11.5384615,21.1738 7.39846154,25.3 2.30769231,25.3"
                    id="Fill-10"
                />
                <polygon
                    id="Fill-11"
                    points="6.92307692 39.1 11.5384615 39.1 11.5384615 29.9 6.92307692 29.9"
                />
                <path
                    d="M13.8461538,46 C10.0292308,46 6.92307692,42.9042 6.92307692,39.1 C6.92307692,37.8304 7.95461538,36.8 9.23076923,36.8 C10.5069231,36.8 11.5384615,37.8304 11.5384615,39.1 C11.5384615,40.3673 12.5723077,41.4 13.8461538,41.4 C15.1223077,41.4 16.1538462,42.4304 16.1538462,43.7 C16.1538462,44.9696 15.1223077,46 13.8461538,46"
                    id="Fill-12"
                />
                <path
                    d="M9.23076923,32.2 C7.95461538,32.2 6.92307692,31.1696 6.92307692,29.9 C6.92307692,27.3631 4.85076923,25.3 2.30769231,25.3 C1.03153846,25.3 0,24.2696 0,23 C0,21.7304 1.03153846,20.7 2.30769231,20.7 C7.39846154,20.7 11.5384615,24.8262 11.5384615,29.9 C11.5384615,31.1696 10.5069231,32.2 9.23076923,32.2"
                    id="Fill-13"
                />
                <path
                    d="M33.4615385,14.95 C33.4615385,16.8544 31.9107692,18.4 30,18.4 C28.0892308,18.4 26.5384615,16.8544 26.5384615,14.95 C26.5384615,13.0456 28.0892308,11.5 30,11.5 C31.9107692,11.5 33.4615385,13.0456 33.4615385,14.95"
                    id="Fill-14"
                />
                <polygon
                    id="Fill-15"
                    points="48.4615385 39.1 53.0769231 39.1 53.0769231 29.9 48.4615385 29.9"
                />
                <path
                    d="M46.1538462,46 C44.8776923,46 43.8461538,44.9696 43.8461538,43.7 C43.8461538,42.4304 44.8776923,41.4 46.1538462,41.4 C47.4276923,41.4 48.4615385,40.3673 48.4615385,39.1 C48.4615385,37.8304 49.4930769,36.8 50.7692308,36.8 C52.0453846,36.8 53.0769231,37.8304 53.0769231,39.1 C53.0769231,42.9042 49.9707692,46 46.1538462,46"
                    id="Fill-16"
                />
                <path
                    d="M50.7692308,32.2 C49.4930769,32.2 48.4615385,31.1696 48.4615385,29.9 C48.4615385,24.8262 52.6015385,20.7 57.6923077,20.7 C58.9684615,20.7 60,21.7304 60,23 C60,24.2696 58.9684615,25.3 57.6923077,25.3 C55.1492308,25.3 53.0769231,27.3631 53.0769231,29.9 C53.0769231,31.1696 52.0453846,32.2 50.7692308,32.2"
                    id="Fill-17"
                />
                <polygon
                    id="Fill-18"
                    points="48.4615385 16.1 53.0769231 16.1 53.0769231 6.9 48.4615385 6.9"
                />
                <path
                    d="M50.7692308,9.2 C49.4930769,9.2 48.4615385,8.1696 48.4615385,6.9 C48.4615385,5.6327 47.4276923,4.6 46.1538462,4.6 C44.8776923,4.6 43.8461538,3.5696 43.8461538,2.3 C43.8461538,1.0304 44.8776923,0 46.1538462,0 C49.9707692,0 53.0769231,3.0958 53.0769231,6.9 C53.0769231,8.1696 52.0453846,9.2 50.7692308,9.2"
                    id="Fill-19"
                />
                <path
                    d="M57.6923077,25.3 C52.6015385,25.3 48.4615385,21.1738 48.4615385,16.1 C48.4615385,14.8304 49.4930769,13.8 50.7692308,13.8 C52.0453846,13.8 53.0769231,14.8304 53.0769231,16.1 C53.0769231,18.6369 55.1492308,20.7 57.6923077,20.7 C58.9684615,20.7 60,21.7304 60,23 C60,24.2696 58.9684615,25.3 57.6923077,25.3"
                    id="Fill-20"
                />
                <path
                    d="M30,39.1 C28.7238462,39.1 27.6923077,38.0696 27.6923077,36.8 L27.6923077,29.9 C27.6923077,28.6304 28.7238462,27.6 30,27.6 C31.2761538,27.6 32.3076923,28.6304 32.3076923,29.9 L32.3076923,36.8 C32.3076923,38.0696 31.2761538,39.1 30,39.1"
                    id="Fill-21"
                />
            </g>
        </g>
    </svg>
);

Json.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

export default Json;
