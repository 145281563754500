import React from 'react';

export default props => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <g id="VideoMissed" stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
            <path d="M23.5,10.1 C23.2,9.9 22.8,10 22.5,10.2 L17,14.1 L17,12 C17,10.3 15.7,9 14,9 L3,9 C1.3,9 0,10.3 0,12 L0,21 C0,22.7 1.3,24 3,24 L14,24 C15.7,24 17,22.7 17,21 L17,17.9 L22.4,21.8 C22.6,21.9 22.8,22 23,22 C23.2,22 23.3,22 23.5,21.9 C23.8,21.7 24,21.4 24,21 L24,11 C24,10.6 23.8,10.3 23.5,10.1 Z M15,21 C15,21.6 14.6,22 14,22 L3,22 C2.4,22 2,21.6 2,21 L2,12 C2,11.4 2.4,11 3,11 L14,11 C14.6,11 15,11.4 15,12 L15,21 Z M22,12.9 L22,19.1 L17.7,16 L22,12.9 Z M17.7816594,0.31441048 L20.1921397,2.72489083 L22.6026201,0.31441048 C23.0218341,-0.104803493 23.650655,-0.104803493 24.069869,0.31441048 C24.489083,0.733624454 24.489083,1.36244541 24.069869,1.78165939 L21.6593886,4.19213974 L24.069869,6.60262009 C24.489083,7.02183406 24.489083,7.65065502 24.069869,8.069869 C23.860262,8.27947598 23.650655,8.38427948 23.3362445,8.38427948 C23.0218341,8.38427948 22.8122271,8.27947598 22.6026201,8.069869 L20.1921397,5.65938865 L17.7816594,8.069869 C17.5720524,8.27947598 17.3624454,8.38427948 17.0480349,8.38427948 C16.7336245,8.38427948 16.5240175,8.27947598 16.3144105,8.069869 C15.8951965,7.65065502 15.8951965,7.02183406 16.3144105,6.60262009 L18.7248908,4.19213974 L16.3144105,1.78165939 C15.8951965,1.36244541 15.8951965,0.733624454 16.3144105,0.31441048 C16.7336245,-0.104803493 17.3624454,-0.104803493 17.7816594,0.31441048 Z" id="videomissed" fill="currentColor" fillRule="nonzero"></path>
        </g>
    </svg>
);
