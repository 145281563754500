import React, { useCallback, useRef } from 'react';

type ButtonClickProps = {
    children: React.ReactNode;
    onClick?: (event) => void;
};

function isEventTargetOrParentLink(el) {
    function isLink(element) {
        return (
            (element.tagName === 'A' && element.hasAttribute('href')) ||
            (element.tagName === 'BUTTON' && element.hasAttribute('onclick')) ||
            (element.tagName === 'DIV' && element.getAttribute('role') === 'button')
        );
    }

    function hasLinkChild(element) {
        if (isLink(element)) {
            return true;
        }

        return Array.from(element.children).some(hasLinkChild);
    }

    return isLink(el.current) || hasLinkChild(el.current);
}

const ButtonClick = ({
    children,
    onClick = () => {}
}: ButtonClickProps): JSX.Element => {
    const ref = useRef(null);

    const handleClick = useCallback(event => {
        if (ref.current && isEventTargetOrParentLink(ref)) {
            onClick?.(event);
        }
    }, [onClick]);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleClick(event);
        }
    }, [handleClick]);

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            ref={ref}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
        >
            {children}
        </div>
    );
}

export default ButtonClick;
