import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PhonebookSkeleton from 'components/Shared/Contacts/PhonebookSkeleton';
import ContactCardList from 'components/Shared/Contacts/ContactCardList';
import usePhonebooks from 'hooks/usePhonebook';
import useBookmarks from 'hooks/useBookmark';
import RelationCard from 'components/Contacts/RelationCard';
import PhoneAction from 'components/Contacts/RelationCard/PhoneAction';
import BookmarkAction from 'components/Contacts/RelationCard/BookmarkAction';
import ContactButton from 'components/Contacts/Contacts/ContactButton';
import { getUserActions } from 'components/Contacts/Contacts';

const StyledDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
`;

const RelationCardWithPhones = ({ phones, bookmarks, ...props }) => {
    const getBookmarkActions = useCallback((contact) => bookmarks && contact?.phones && [(
        <BookmarkAction 
            key={contact.phones}
            contact={contact} 
            bookmarks={bookmarks}
        />
    )], [bookmarks]);

    const getActions = useCallback(() => phones ? [(
        <PhoneAction 
            key={phones}
            phones={phones} 
        />
    )] : getUserActions()
    , [phones, getUserActions]);

    return (
        <RelationCard
            {...props}
            renderActions={getActions}
            renderBookmarkActions={getBookmarkActions}
            noClick={!!phones} 
            phones={phones}
            bookmarks={bookmarks}
        />
    );
};

const Phonebook = ({
    query,
    ItemSkeleton = PhonebookSkeleton,
    ItemComponent = RelationCardWithPhones,
    displayMode,
    flat,
    phoneFilterTypes,
    type,
    withCreateContact = false,
    ...rest
}) => {
    const { isLoading, error, isError, isSuccess, data, isFetching } = usePhonebooks({ query, flat, phoneFilterTypes, type });
    const { isSuccess: isSuccessBookmarks, data: dataBookmarks} = useBookmarks({ type: 'phoneNumber'});

    return (
        <>
            <StyledDiv>
                {withCreateContact && <ContactButton />}
            </StyledDiv>
            <ContactCardList
                data={isSuccess ? data : undefined}
                bookmarks={isSuccessBookmarks ? dataBookmarks : undefined}
                noData={!data?.length}
                isLoading={isLoading || isFetching}
                error={isError && error.message}
                ItemComponent={ItemComponent}
                SkeletonComponent={ItemSkeleton}
                hasStatus={false}
                displayMode={displayMode}
                isFiltered={!!query}
                {...rest}
            />
        </>
    );
}

Phonebook.propTypes = {
    query: PropTypes.string,
    withCreateContact: PropTypes.bool,
    ItemSkeleton: PropTypes.any,
    ItemComponent: PropTypes.any,
    displayMode: PropTypes.string,
    phoneFilterTypes: PropTypes.array,
    flat: PropTypes.bool,
    type: PropTypes.oneOf([ 'internal', 'external'])
};

export default memo(Phonebook);
