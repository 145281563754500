import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Avatar from 'components/Shared/User/Avatar';

import { getUrl } from 'utils/url';
import BrandLogo from 'components/Shared/Company/BrandLogo/container';
import GuestLogin from 'components/Shared/Forms/GuestLogin/container';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import ConnectButton from 'components/Shared/Common/ConnectButton';
import useGuestLogin from 'hooks/useGuestLogin';
import { ThemeContext } from 'styled-components';
import { StyledBgApp } from 'components/Shared/style';
import { Styled } from './styles';

const generateId = () => Math.floor(Math.random() * 99999999 + 10000000);

const RoomReception = (props) => {
    const {
        intl,
        avatar,
        firstname,
        lastname,
        _user,
        roomName,
        _room,
        isPrivate = true
    } = props;
    const [user, setUser] = useState({});
    const { isLoading, error } = useGuestLogin(user.firstname, user.lastname, {
        key: 'room',
        value: _room
    });
    const returnUrl = getUrl('room', { _room });

    const handleSubmit = useCallback((values) => setUser({
        firstname: values.firstname || intl.formatMessage({ id: 'room.guest' }),
        lastname: values.lastname || `(${generateId()})`,
    }), [setUser]);
    const { mainColor1, backgroundImage } = useContext(ThemeContext);

    useWhyDidYouUpdate('screens:room:reception', props);

    return (
        <Styled className="-room-configurator">
            <StyledBgApp backgroundColor={mainColor1} backgroundImage={backgroundImage}>
                <div className="bg" />
                <span className="overlay" />
            </StyledBgApp>
            <div className="container">
                <div className="logo">
                    <BrandLogo size="md" type="shortLogo" />
                </div>
                <div className="wrapper">
                    <div className="content-container">
                        {isPrivate && (
                            <>
                                <div className="block-container">
                                    <div className="text-container">
                                        <p>
                                            {intl.formatMessage({ id: 'room.reception.privateconf' })}
                                        </p>
                                    </div>
                                </div>
                                <div className="action-container">
                                    <ConnectButton returnUrl={returnUrl} />
                                </div>
                            </>
                        )}
                        {isPrivate === false && _user && (
                            <div className="block-container">
                                {avatar && (
                                    <div className="avatar-container">
                                        <Avatar
                                            src={avatar}
                                            size="xl"
                                            hasStatus={false}
                                        />
                                    </div>
                                )}
                                <div className="text-container">
                                    <p>
                                        {intl.formatMessage({ id: 'room.reception.title1' })}
                                    </p>
                                    <p>
                                        {intl.formatMessage({ id: 'room.reception.title2' }, {
                                            fullname: `${firstname} ${lastname}`,
                                            brandName: roomName || false
                                        })}
                                    </p>
                                    <p>
                                        {intl.formatMessage({ id: 'room.reception.label.presentation' })}
                                    </p>
                                </div>
                            </div>
                        )}
                        {isPrivate === false && (
                            <GuestLogin
                                scope={{
                                    key: 'room',
                                    value: _room
                                }}
                                returnUrl={returnUrl}
                                onSubmit={handleSubmit}
                                isLoading={isLoading}
                                httpError={error?.message}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Styled>
    );
}

RoomReception.propTypes = {
    intl: PropTypes.any.isRequired,
    _room: PropTypes.string.isRequired,
    avatar: PropTypes.any,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    _user: PropTypes.string,
    roomName: PropTypes.string,
    isPrivate: PropTypes.bool

};

export default injectIntl(RoomReception);
