import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { usePrevious } from 'react-use';
import isEqual from 'lodash/isEqual';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { FeedResult, RoomResult } from 'hooks/useSearch';

import { FeedProps, ListProps, RoomProps } from 'components/CallBuilder/types';
import { MAX, NAVIGATION } from 'components/CallBuilder/constants';

const { Text } = Typography;

type InfiniteScrollListProps = {
    dataKey: string;
    component: React.FC<RoomProps | FeedProps>;
    searchResults: {
        [NAVIGATION.ROOMS]?: {
            results: RoomResult[];
            paging: {
                to: number;
                total: number;
            };
        };
        [NAVIGATION.FEEDS]?: {
            results: FeedResult[];
            paging: {
                to: number;
                total: number;
            };
        };
    };
} & ListProps & WrappedComponentProps;

const InfiniteScrollList = ({
    dataKey,
    component: Component,
    searchResults = {},
    max = MAX,
    isLoading,
    intl,
    query = '',
    onChangePage,
    ...rest
}: InfiniteScrollListProps): JSX.Element | JSX.Element[] => {
    const data = searchResults[dataKey];
    const {
        results = [],
        paging: {
            to = 0,
            total = 1
        } = {}
    } = data || {};

    const prevQuery = usePrevious(query);
    const prevResults = usePrevious(results);
    const hasNextPage = useMemo(() =>
        to < total - 1 && !query && !prevQuery,
    [to, total, query, prevQuery]);
    const [items, setItems] = useState<RoomResult[]>([]);

    useEffect(() => {
        if (!isEqual(results, prevResults)) {
            if (query || prevQuery) {
                setItems(results);
            } else {
                setItems(previousResults => {
                    const newItems = [...previousResults, ...results];
                    return isEqual(newItems, previousResults) ? previousResults : newItems;
                });
            }
        }
    }, [results, query, prevResults]);

    const handleChangePage = useCallback(() => {
        onChangePage?.(prevPage => prevPage + 1);
    }, [onChangePage]);

    const [sentryRef] = useInfiniteScroll({
        loading: !!isLoading,
        hasNextPage,
        onLoadMore: handleChangePage,
    });

    if (!items || !items.length) {
        if (isLoading) {
            return <LoadingOutlined />;
        }

        return <Text>{intl.formatMessage({ id: 'callbuilder.emptyList' })}</Text>;
    }

    return (
        <>
            {items.slice(0, max).map(item => (
                <Component
                    key={item.id}
                    item={item}
                    intl={intl}
                    {...rest}
                />
            ))}
            {hasNextPage && items?.length && (
                <div ref={sentryRef}>
                    <LoadingOutlined />
                </div>
            )}
        </>
    );
}

InfiniteScrollList.propTypes = {
    dataKey: PropTypes.string.isRequired,
    component: PropTypes.elementType.isRequired,
    searchResults: PropTypes.object,
    max: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
    query: PropTypes.string,
    onChangePage: PropTypes.func,
};

export default memo(injectIntl(InfiniteScrollList as React.FC<InfiniteScrollListProps>));
