import React, { memo, useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Badge, Spin, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';

import MainPlayer from 'components/Room/MainPlayer';
import Icon from 'components/Shared/Common/Icon';
import Button from 'components/Shared/Common/Button';
import VoiceEqualizer from 'components/Room/Participant/VoiceEqualizer';
import AvatarEqualizer from 'components/Room/Participant/AvatarEqualizer';
import { RECORDER_API_STATUS } from 'components/Shared/Recorder/constants';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import soundManager from 'services/soundManager';
import { LoadingOutlined } from '@ant-design/icons';
import { useCopyToClipboard } from 'react-use';
import { useDebug } from 'contexts/sessionContext';
import PeerDebugger from './PeerDebugger';

const badgeStyle = {
    marginRight: 10,
};

const VoiceEqualizerManager = injectIntl(({ audioPeerState, intl, children }) => {
    if (audioPeerState === 'failed') {
        return (
            <Tooltip
                title={intl.formatMessage({ id: 'room.error.remoteConnectivityIssues' })}
                placement="left"
            >
                <div className="icon-container">
                    <Icon
                        iconName="X"
                        className="danger"
                    />
                </div>
            </Tooltip>
        );
    }

    if (['new', 'checking', 'connecting'].includes(audioPeerState)) {
        return (
            <Tooltip
                title={intl.formatMessage({ id: 'global.loading' })}
                placement="left"
            >
                <div className="icon-container">
                    <Spin size="small" indicator={<LoadingOutlined spin />} />
                </div>
            </Tooltip>
        );
    }

    if (['disconnected', 'closed'].includes(audioPeerState)) {
        return (
            <Tooltip
                title={intl.formatMessage({ id: 'room.error.remoteConnectivityIssues' })}
                placement="left"
            >
                <div className="icon-container">
                    <Icon
                        iconName="CloudOff"
                        className="danger"
                    />
                </div>
            </Tooltip>
        );
    }

    return children;
});

const styledDebugLine = { flex: 10, alignItems: 'center' };

const ThumbUser = memo(
    (props) => {
        const {
            name = '',
            recordingState = RECORDER_API_STATUS.STOPPED,
            isAudioOn = true,
            isVideoOn = true,
            avatar = null,
            forceMute = false,
            videoStream,
            audioStream,
            isAway = false,
            isAdmin = false,
            isMe = false,
            updatedAt,
            _user,
            _client = '',
            isMyUser = false,
            intl,
            hasConnectivityIssues,
            isGalleryMode = false,
            // audioPeerState,
            videoPeerState,
            streamEvent,
            _room
        } = props;
        const [isDebug]= useDebug();
        const alertOnError = isDebug;
        const [forcedUpdatedAt, setForcedUpdatedAt] = useState();
        const audioTrack = audioStream && audioStream.getAudioTracks()?.[0];
        const [, copyToClipboard] = useCopyToClipboard();

        useEffect(() => {
            if (streamEvent) {
                soundManager.play('ding');
            }
        }, [streamEvent]);

        const avatarProps = useMemo(() => ({
            src: avatar,
            firstname: name,
            size: !isMyUser && isGalleryMode ? 'xxxxl' : null
        }), [avatar, name, isMyUser, isGalleryMode]);

        useWhyDidYouUpdate(`component:room:roomscreen:ThumbUser:${name || _client?.slice(0, 5) || _user?.slice(0, 5)}`, props);

        useEffect(() => {
            setForcedUpdatedAt(updatedAt);
        }, [updatedAt])

        const handleForcePlayerReload = useCallback(() => {
            setForcedUpdatedAt(new Date());
        }, []);

        const playerAvatar = useMemo(() => {
            // TODO: complete this method
            if (videoPeerState === 'failed') {
                // return 'failed';
            }
            if (['disconnected', 'closed'].includes(videoPeerState)) {
                // return 'disconnected';
            }
            if (['new', 'checking', 'connecting'].includes(videoPeerState)) {
                // return 'failed';
            }

            return (
                <AvatarEqualizer
                    track={audioTrack}
                    updatedAt={updatedAt}
                    alertOnError={alertOnError}
                    isMe={isMe}
                    isTrackMuted={!isAudioOn}
                    _client={_client}
                    avatar={avatarProps} 
                />
            );
        }, [avatarProps, isMe, _client, audioTrack, updatedAt, alertOnError, isAudioOn, videoPeerState]);

        return (
            <>
                <span className="overlay" />
                <MainPlayer
                    updatedAt={forcedUpdatedAt}
                    isMyUser={isMyUser}
                    id={`thumb-${_client}`}
                    isVideoOn={isVideoOn}
                    isAudioOn={isAudioOn}
                    forceMute={forceMute}
                    videoStream={videoStream}
                    audioStream={audioStream}
                    className="video filled"
                    renderDefault={playerAvatar}
                    loader={avatar}
                    _room={_room}
                />
                <div className="line-wrapper">
                    <div className="line">
                        <div className="line-container">
                            {isDebug && (
                                <Tooltip 
                                    title="Re-render component (only on debug mode)"
                                    placement="left"
                                >
                                    <Button
                                        onClick={handleForcePlayerReload}
                                        iconName="RefreshCw"
                                        size="xs"
                                        color="light-transparency"
                                    />
                                </Tooltip>
                            )}
                        </div>
                        <div className="line-container">
                            {recordingState === RECORDER_API_STATUS.STARTED && (
                                <Badge style={badgeStyle} status="error" />
                            )}
                            {isDebug && (
                                <div className="name">
                                    <Button
                                        onClick={() => copyToClipboard(_client)}
                                        size="xs"
                                        color="light-transparency"
                                    >{_client?.slice(-7)}</Button>
                                </div>
                            )}
                            {isAdmin && (
                                <div className="admin">
                                    <Icon iconName="King" />
                                </div>
                            )}
                            <div className="name" title={name}>
                                {isMe ? intl.formatMessage({ id: 'global.me' }) : name}
                            </div>
                        </div>
                    </div>
                    {isDebug && (
                        <div className="line" style={styledDebugLine}>
                            <PeerDebugger _client={_client} isMe={isMe} />
                        </div>
                    )}
                    <div className="line">
                        <div className="line-container" />
                        <div className="line-container">
                            {hasConnectivityIssues && (
                                <Tooltip 
                                    title={intl.formatMessage({ id: isMe ? 'error.networkDegradedMode' : 'room.error.remoteConnectivityIssues' })}
                                    placement="left"
                                >
                                    <div className="icon-container">
                                        <Icon
                                            iconName="AlertTriangle"
                                            className="danger"
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {!isAway && (
                                <VoiceEqualizerManager>
                                    <VoiceEqualizer 
                                        track={audioTrack} 
                                        updatedAt={updatedAt} 
                                        alertOnError={alertOnError} 
                                        isMe={isMe} 
                                        isTrackMuted={!isAudioOn} 
                                        _client={_client} 
                                    />
                                </VoiceEqualizerManager>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
);

ThumbUser.propTypes = {
    _client: PropTypes.string,
    recordingState: PropTypes.oneOf(Object.values(RECORDER_API_STATUS)),
    avatar: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.string,
    videoStream: PropTypes.shape({
        id: PropTypes.string.isRequired
    }),
    audioStream: PropTypes.shape({
        id: PropTypes.string.isRequired
    }),
    isVideoOn: PropTypes.bool,
    isAudioOn: PropTypes.bool,
    forceMute: PropTypes.bool,
    isAway: PropTypes.bool,
    isMyUser: PropTypes.bool,
    isAdmin: PropTypes.bool,
    _room: PropTypes.string,
    isMe: PropTypes.bool,
    isGalleryMode: PropTypes.bool
};

export default injectIntl(ThumbUser);
