import React from 'react';

export default props => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="none" 
            strokeWidth="1" 
            fill="none" 
            fillRule="evenodd" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        >
            <g transform="translate(1.000000, 1.000000)" strokeWidth="2">
                <path d="M12.73,20 C12.3722022,20.6168043 11.7130686,20.9964563 11,20.9964563 C10.2869314,20.9964563 9.62779776,20.6168043 9.27,20" id="Path" stroke="currentColor"></path>
                <path d="M17.63,12 C17.1851297,10.3714084 16.9730258,8.68804397 17,7" id="Path" stroke="currentColor"></path>
                <path d="M5.26,5.26 C5.08626583,5.82361139 4.99861155,6.41022086 5,7 C5,14 2,16 2,16 L16,16" id="Path" stroke="currentColor"></path>
                <path d="M17.0000066,7 C17.0032861,4.78524365 15.7862315,2.74878606 13.8341215,1.70263924 C11.8820116,0.656492418 9.51234624,0.770806432 7.67,2" id="Path" stroke="currentColor"></path>
                <line x1="0" y1="0" x2="22" y2="22" id="Path" stroke="#EB144C"></line>
            </g>
        </g>
    </svg>
);
