import React, { useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, Form as FinalForm } from 'react-final-form';

import AvatarStatus from 'components/Shared/User/Avatar';
import Icon from 'components/Shared/Common/Icon';
import { LabelInput, LabelTextArea } from 'components/Shared/Forms';
import { StyledPageLayout } from 'components/Shared/style';
import ErrorFormatter from 'components/Shared/Errors/Formatter';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import ContentErrorComponent from 'components/Shared/Errors/ContentError';
import Spinner2 from 'components/Shared/Common/Spinner2';

import { 
    StyledContainer, 
    StyledProfileHeader, 
    StyledProfileContentForm, 
    StyledActionsComponent,
    StyledProfileItem, 
    StyledProfileTitle, 
    StyledProfileList 
} from 'screens/Profile/styles';

import { composeValidators } from 'utils/formValidator';
import useUser from 'hooks/useUser';
import { useSelector } from 'react-redux';
import { getCurrentUserIdSelector } from 'selectors/user';

const formatMessages = intl => ({
    biographyPlaceholder: intl.formatMessage({
        id: 'profile.biography.placeholder',
        defaultMessage: 'Cliquer ici pour remplir votre biographie'
    }),
    companyPlaceholder: intl.formatMessage({
        id: 'profile.company.placeholder',
        defaultMessage: 'Cliquer ici pour remplir le nom de votre entreprise'
    }),
    diplomaPlaceholder: intl.formatMessage({
        id: 'profile.diploma.placeholder',
        defaultMessage: 'Cliquer ici pour remplir le nom de votre diplome'
    }),
    firstNamePlaceholder: intl.formatMessage({
        id: 'profile.fields.firstname.placeholder',
        defaultMessage: 'Prenom'
    }),
    lastNamePlaceholder: intl.formatMessage({
        id: 'profile.fields.lastname.placeholder',
        defaultMessage: 'Nom'
    }),
    bioTitle: intl.formatMessage({
        id: 'profile.fields.bio',
        defaultMessage: 'Biographie'
    }),
    titlePlaceholder: intl.formatMessage({
        id: 'profile.fields.title.placeholder',
        defaultMessage: 'Poste'
    }),
    cancel: intl.formatMessage({
        id: 'profile.cancelEdition',
        defaultMessage: 'Annuler'
    })
});

const EditAvatar = memo((props) => {
    const { name, input: { value, onChange }, status, firstname, lastname, ...rest } = props; // eslint-disable-line

    const handleChange = useCallback(({ target: { files } }) => onChange(files[0]), []);

    return (
        <AvatarStatus
            src={value}
            status={status}
            size="xxxxl"
            alt={`${firstname} ${lastname}`}
            isEditable
            onChange={handleChange}
            {...rest}
        />
    );
});

const EditableProfile = ({
    validators, intl,
    switchToReadMode,
    onSubmit = () => {},
}) => {
    const {
        userFirstNameLength, userLastNameLength, required,
        userBioLength, userCompanyLength, userEducationLength
    } = validators;
    const i18n = useMemo(() => formatMessages(intl), [intl]);
    const _user = useSelector(getCurrentUserIdSelector);
    const data = useUser({ _user });
    const {
        data: {
            firstname,
            lastname,
            status
        } = {}, 
        data: initialValues = {},
        isError, 
        isLoading,
        error
    } = data;

    const handleReset = useCallback((reset) => {
        reset();
        switchToReadMode(_user);
    }, [_user, switchToReadMode]);

    const submit = useCallback((values, form) => {
        onSubmit({
            firstname: values.firstname,
            lastname: values.lastname,
            biography: values.biography,
            company: values.company,
            education: values.education,
            title: values.title,
            avatar: values.avatarUri
        }, form, _user);
    }, [onSubmit, _user]);

    if (isLoading) {
        return <Spinner2 />;
    }

    if (isError) {
        return (
            <ErrorFormatter
                error={error}
                buttonType={BUTTON_TYPE.GOHOME}
                render={ContentErrorComponent}
            />
        )
    }

    return (
        <FinalForm onSubmit={submit} initialValues={initialValues}>
            {({ handleSubmit, pristine, submitting, form, valid }) => (
                <StyledPageLayout className="ui-profile">
                    <StyledContainer className="ui-profile-container">
                        <StyledProfileHeader className="ui-profile-header">
                            <Field
                                name="avatarUri"
                                component={EditAvatar}
                                firstname={firstname}
                                lastname={lastname}
                                status={status}
                            />
                            <div className="break">
                                <div className="wrapper">
                                    <div className="text">
                                        <div className="name">
                                            <div className="firstname">
                                                <Field
                                                    name="firstname"
                                                    component={LabelInput}
                                                    validate={composeValidators(required, userFirstNameLength)}
                                                    placeholder={i18n.firstNamePlaceholder}
                                                />
                                            </div>
                                            <div className="lastname">
                                                <Field
                                                    name="lastname"
                                                    component={LabelInput}
                                                    validate={composeValidators(required, userLastNameLength)}
                                                    placeholder={i18n.lastNamePlaceholder}
                                                />
                                            </div>
                                        </div>
                                        <div className="text">
                                            <Field
                                                name="title"
                                                component={LabelInput}
                                                validate={composeValidators(required, userLastNameLength)}
                                                placeholder={i18n.titlePlaceholder}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </StyledProfileHeader>
                        <StyledProfileContentForm
                            className="ui-profile-content"
                            onSubmit={handleSubmit}
                        >
                            <StyledProfileItem className="ui-profile-content-item">
                                <>
                                    <StyledProfileTitle className="ui-title">
                                        <FormattedMessage
                                            id="profile.fields.bio"
                                            defaultMessage="Biographie"
                                        />
                                    </StyledProfileTitle>
                                    <div className="ui-text">
                                        <Field
                                            name="biography"
                                            component={LabelTextArea}
                                            placeholder={i18n.biographyPlaceholder}
                                            validate={userBioLength}
                                            showCount
                                            maxLength={250}
                                        />
                                    </div>
                                </>
                            </StyledProfileItem>
                            <StyledProfileItem className="ui-profile-content-item">
                                <StyledProfileTitle className="ui-title">
                                    <FormattedMessage
                                        id="profile.fields.job"
                                        defaultMessage="Carrière"
                                    />
                                </StyledProfileTitle>
                                <StyledProfileList className="ui-profile-list">

                                    <div className="item">
                                        <Icon iconName="Briefcase" />
                                        <Field
                                            name="company"
                                            component={LabelInput}
                                            placeholder={i18n.companyPlaceholder}
                                            validate={userCompanyLength}
                                        />
                                    </div>

                                    <div className="item">
                                        <Icon iconName="Award" />
                                        <Field
                                            name="education"
                                            component={LabelInput}
                                            placeholder={i18n.diplomaPlaceholder}
                                            validate={userEducationLength}
                                        />
                                    </div>

                                </StyledProfileList>
                            </StyledProfileItem>
                        </StyledProfileContentForm>
                        <StyledActionsComponent
                            className="ui-profile-action"
                            pristine={pristine}
                            submit={form.submit}
                            submitting={submitting}
                            reset={() => handleReset(form.reset)}
                            valid={valid}
                        />
                    </StyledContainer>
                </StyledPageLayout>
            )}
        </FinalForm>
    );
}

EditableProfile.propTypes = {
    onSubmit: PropTypes.func,
    validators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    switchToReadMode: PropTypes.func.isRequired,
};

export default injectIntl(memo(EditableProfile));
