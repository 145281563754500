import React, { useMemo, ReactNode } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Tooltip, Typography, Space, Col, Row as AntdRow } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { SpaceSize } from 'antd/es/space';
import styled from 'styled-components';
import classNames from 'classnames';

import Timestamp from 'components/Shared/Notification/Timestamp';
import AvatarList from 'components/Shared/Call/AvatarList';
import Avatar from 'components/Shared/User/Avatar';

import { colors } from 'common/themes/Colors';
import { rgba } from 'polished';

const SPACE_SIZE: SpaceSize | [SpaceSize, SpaceSize] = [0,0];
const timeStampStyle = {fontSize: 12};
const { Text } = Typography;
const StyledWrapper = styled(AntdRow)`
    padding: 5px;
    border-radius: 4px;
    transition: all 250ms ease;
    margin: 1px 0;
    width: 100%;
    &:hover{
        background-color: ${props => props.theme.lighten};
    }
    &.missedCall{
        background-color: ${rgba(colors.error, 0.1)};
        .s-recent-call-name{
            color: ${({theme}) => theme.error}!important;
            text-decoration: underline!important;
            &:hover{
                color: ${({theme}) => theme.error}!important;
                text-decoration: none!important;
            }
        }
    }
    .s-recent-call-name{
        color: ${({theme}) => theme.black}!important;
        text-decoration: underline!important;
        &:hover{
            color: ${({theme}) => theme.black}!important;
            text-decoration: none!important;
        }
    }

`;

const WrapperSpace = styled(Space)`
    &.missedCall{
        color: ${colors.error};
    }
`;

const StyledText = styled(Text)`
    &.missedCall{
        color: ${colors.error};
    }
`;

const MAX_AVATAR_COUNT = 2;

type RowProps = {
    onAddParticipant: () => void;
    onRemoveParticipant: () => void;
    label?: string | ReactNode;
    phoneNumber?: string;
    date?: string;
    status?: string | React.ReactElement;
    hasParticipant: boolean;
    children?: ReactNode;
    avatarUri?: string;
    presenceStatus?: number;
    avatarSize?: string;
    avatarMode?: string;
    maxAvatarCount?: number;
    isMissed?: boolean;
    hidePlusButton?: boolean;
    hideStatus?: boolean;
    // eslint-disable-next-line
    members?: any[];
    disablePlusButton?: boolean;
} & WrappedComponentProps;

const Row = ({
    intl,
    onAddParticipant = () => {},
    onRemoveParticipant = () => {},
    label = '',
    phoneNumber = '',
    date = '',
    status = '',
    isMissed = false,
    hasParticipant = false,
    avatarSize = 'default',
    avatarMode = 'square',
    avatarUri,
    maxAvatarCount = MAX_AVATAR_COUNT,
    presenceStatus = undefined,
    hideStatus = false,
    members = [],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    user,
    children: callButtonComponent = null,
    hidePlusButton = false,
    disablePlusButton = false,
}: RowProps): JSX.Element => {
    const hasUsersWithoutMe = useMemo(() => {
        if (!members.length) return false;

        return members.some(member => {
            if (member.isMyUser) {
                return false;
            }

            return true;
        });
    }, [members]);

    const membersWithoutMeExceptIfOnlyMe = useMemo(() => {
        if (members.length === 1 && members[0].isMyUser) {
            return members;
        }

        return members.filter(member => !member.isMyUser);
    }, [members]);

    return (
        <StyledWrapper
            align="middle"
            justify="space-between"
            className={classNames("ui-item-call-builder", { missedCall: isMissed })}
        >
            <Space>
                {(typeof presenceStatus === 'number' || hideStatus || avatarUri) && (
                    <Avatar
                        status={presenceStatus}
                        src={avatarUri}
                        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                        /* @ts-ignore */
                        content={typeof label === 'string' ? label : ''}
                        hasStatus={!hideStatus}
                        size="md"
                    />
                )}
                {avatarMode && (
                    <AvatarList
                        size={avatarSize}
                        mode={avatarMode}
                        members={user ? [user] : membersWithoutMeExceptIfOnlyMe}
                        maxCount={maxAvatarCount}
                    />
                )}
                <Col flex={1}>
                    <WrapperSpace
                        className={classNames({ missedCall: isMissed })}
                        direction="vertical"
                        size={SPACE_SIZE}
                    >
                        <StyledText
                            className={classNames({ missedCall: isMissed })}
                            strong
                            ellipsis
                        >
                            {label}
                        </StyledText>
                        {phoneNumber && <Text type="secondary" ellipsis>{phoneNumber}</Text>}
                        <AntdRow align="middle" className="status-call">
                            <Space>
                                {status}
                            </Space>
                        </AntdRow>
                        {date && <Text 
                            style={timeStampStyle} 
                            type="secondary" 
                            ellipsis>
                            <Timestamp date={date} hasDate hasTime />
                        </Text>}
                        {!avatarMode && (
                            <AvatarList
                                size={avatarSize}
                                mode={avatarMode}
                                members={user ? [user] : membersWithoutMeExceptIfOnlyMe}
                                maxCount={maxAvatarCount}
                            />
                        )}
                    </WrapperSpace>
                </Col>
            </Space>
            <AntdRow align="middle">
                <Space>
                    {callButtonComponent}
                    {!hidePlusButton && (
                        <Tooltip
                            placement="top"
                            title={(
                                <>
                                    {phoneNumber ? <p>{phoneNumber}</p> : null}
                                    {members.map(member => <p key={member.id}>{member.fullname}</p>)}
                                    {user ? <p>{user.firstname} {user.lastname}</p> : null}
                                    {!members.length && !phoneNumber && !user ? (
                                        <p>{intl.formatMessage({ id: 'callBuilder.noMembersOrPhones' })}</p>
                                    ) : null}
                                </>
                            )}
                        >
                            <Button
                                shape="circle"
                                onClick={hasParticipant ? onRemoveParticipant : onAddParticipant}
                                disabled={(!hasUsersWithoutMe && !phoneNumber && !user) || !hasParticipant && disablePlusButton}
                            >
                                {hasParticipant ? <MinusOutlined /> : <PlusOutlined />}
                            </Button>
                        </Tooltip>
                    )}
                </Space>
            </AntdRow>
        </StyledWrapper>
    );
}

export default injectIntl(Row as React.FunctionComponent<RowProps>);
