import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import { injectIntl } from 'react-intl';

import { ChangeBtn, ForwardToLabel } from 'components/Shared/Drawers/Sip/Forwards/ManualForwards/Commons';

const ForwardSummaryLayout = injectIntl(({ data, ContentComponent, onEdit = () => {}, isLoading = false, intl }) => {
    const handleClick = useCallback(() => onEdit(data), [data, onEdit]);
    const delay = useMemo(() => {
        if (data?.delay) {
            return intl.formatMessage({ id: 'call.forward.busy.summary.delay' }, { delay: data.delay });
        }

        return null;
    }, [intl, data]);

    return (
        <Space size="middle">
            <ForwardToLabel />
            <ContentComponent data={data} />
            {delay}
            <ChangeBtn loading={isLoading} onClick={handleClick}/>
        </Space>
    )
});

ForwardSummaryLayout.propTypes = {
    intl: PropTypes.any.isRequired,
    data: PropTypes.any,
    ContentComponent: PropTypes.any,
    onEdit: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default ForwardSummaryLayout;
