import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { notification } from 'antd';

import { copyToClipboard } from 'utils/copyclipboard';
import Action from 'components/Shared/Common/Action';
import Icon from 'components/Shared/Common/Icon';

const openNotificationWithIcon = (type, placement, message, description, icon) => {
    notification[type]({
        message: `${message}`,
        position: 'bottomLeft',
        description: `${description}`,
        bottom: 80,
        className: `${type}`,
        // getContainer: () => document.getElementById('appRoom'),
        placement,
        icon: <Icon iconName={icon} />,
        closeIcon: <Icon iconName="XCircle" />
    });
};

const CopyRoomLinkButton = memo((props) => {
    const {
        onClick,
        intl,
        children
    } = props;

    useWhyDidYouUpdate('components:room:Footer:MoreButton:CopyRoomLinkButton', props);

    const handleLinkCopy = useCallback((e) => {
        const { protocol, host, pathname, search } = window.location;
        const roomLink = `${protocol}//${host}${pathname}${search}`;
        copyToClipboard(roomLink);

        e.stopPropagation();
        openNotificationWithIcon(
            'info',
            'bottomLeft',
            intl.formatMessage({ id: 'global.copiedToClipboard' }),
            intl.formatMessage({ id: 'room.sharelink' }),
            'Copy'
        );
        if (typeof onClick === 'function')
            onClick();
    }, []);

    return (
        <Action
            className="line-item"
            onClick={handleLinkCopy}
        >
            {children}
        </Action>
    );
})

CopyRoomLinkButton.propTypes = {
    onClick: PropTypes.func,
    intl: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired
};

export default injectIntl(CopyRoomLinkButton);
