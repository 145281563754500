import { useEffect, useState } from 'react';
import Logger from 'services/debug/logger';
import DeviceManager from 'components/Room/Helpers/DeviceManager';

const logger = new Logger('component:room:useMediaDevicePermissions');

const useMediaDevicesPermissions = (name) => {
    const [status, setStatus] = useState('granted');

    useEffect(() => {
        if (name) {
            const onChange = () => {
                logger.log('Permissions request:', name);
                return DeviceManager
                    .getPermissionStatus(name)
                    .then(s => {
                        logger.log('Permissions status:', name, s.state);
                        setStatus(s.state);
                        return s;
                    })
                    .catch((e) => {
                        logger.log('Permissions status:', name, e);
                        setStatus(undefined);
                    });
            }

            onChange()
                .then(permissionStatus => {
                    logger.log('Permissions addOnchangeEvent:', name, permissionStatus);
                    if (permissionStatus) {
                        permissionStatus.onchange = onChange;
                    }
                    return permissionStatus;
                });
        }
    }, []);

    return status;
};

export default useMediaDevicesPermissions;
