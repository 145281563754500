import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import config from 'config';
import { AudioSpeakingVisualizer } from 'components/Shared/Common/AudioStrengthVisualizer';
import SoundMeter, { THRESHOLD_TYPES } from 'components/Shared/Call/SoundMeterStrength';
import { notification } from 'antd';

const AudioStrength = injectIntl(memo(({ intl, strength, muted, error, alertOnError, warning }) => {
    const [api, contextHolder] = notification.useNotification();
    useEffect(() => {
        if (alertOnError) {
            const key = `muteError${error || ''}`;
            if (error) {
                api.error({
                    message: intl.formatMessage({ id: 'global.label.error' }),
                    description: error,
                    key,
                    duration: 60
                });
            } else if (warning) {
                api.warning({
                    message: intl.formatMessage({ id: 'global.label.warning' }),
                    description: warning,
                    key,
                    duration: 60
                });
            } else {
                notification.destroy(key);
            }

            return () => notification.destroy(key);
        }
        return undefined;
    }, [error, alertOnError, warning]);

    return (
        <>
            {contextHolder}
            <AudioSpeakingVisualizer
                error={error}
                isError={!!error}
                isMuted={muted ?? strength === null}
                isActive={!!strength}
            />
        </>
    );
}));

const VoiceEqualizer = ({ track, alertOnError, isTrackMuted, isMe, _client }) => (
    <SoundMeter
        track={track}
        Component={AudioStrength}
        thresholdType={THRESHOLD_TYPES.BOOL}
        alertOnError={alertOnError}
        isTrackMuted={isTrackMuted}
        refreshRate={config.speakingMeterRefreshRate}
        isMe={isMe}
        _client={_client}
    />
);

VoiceEqualizer.propTypes = {
    track: PropTypes.shape({
        id: PropTypes.string.isRequired
    }),
    alertOnError: PropTypes.bool,
    isMe: PropTypes.bool,
    isTrackMuted: PropTypes.bool,
    _client: PropTypes.string
};

export default injectIntl(VoiceEqualizer);
