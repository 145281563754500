import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Html = ({ color = '#E77D8B', withBorder = true }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-html" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="file-ai" fill={color} fillRule="nonzero">
                {withBorder && <Border />}
            </g>
            <path
                d="M30.8175823,92.6885771 L17,79.1195669 L30.8175823,65.548574 M63.1824177,65.548574 L77,79.1195669 L63.1824177,92.6885771 M52.1747805,59 L41.9322074,98"
                id="Stroke-175"
                stroke={color}
                strokeWidth="4.03727753"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

Html.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

export default Html;
