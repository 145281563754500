import { getToken } from 'services/token';
import Compressor from 'compressorjs';

import { ResourceError } from 'utils/state/error';
import { FILE_TYPES, FILE_TEMPLATES } from 'const/feed';
import Logger from 'services/debug/logger';
import { callbacks } from '@amplement/backend-connector';

import { createUrl } from './url';

const logger = new Logger('util:file');

export const getFileSource = (
    uri,
    download = false
) => {
    const token = getToken();
    if (!token) return uri;
    return createUrl(uri, `token=${token}&download=${download}`);
};

export const isImage = (file) => file?.type?.startsWith('image/');

export const isHeic = (file) => isImage(file) && file?.type?.endsWith('heic');

export const shouldCompressImage = (file) => isImage(file) && !file?.type?.endsWith('gif');

export const isVideo = (file) => file?.type?.startsWith('video/');

export const getFileType = (file) => {
    if (isHeic(file)) {
        return FILE_TYPES.FILE;
    }
    if (isImage(file)) {
        return FILE_TYPES.IMAGE;
    }
    if (isVideo(file)) {
        return FILE_TYPES.VIDEO;
    }
    return FILE_TYPES.FILE;
};

// const getBase64 = (file) => {
//     if (!file) return Promise.reject(new Error('No file to process in base 64'));

//     return new Promise((resolve, reject) => {
//         logger.log('getBase64:file: ', file);
//         const reader = new FileReader();

//         const timeout = setTimeout(() => {
//             reject(new Error('timeout'));
//         }, 30000);
//         reader.addEventListener('load', () => {
//             if (timeout) clearTimeout(timeout);
//             resolve(reader.result);
//         });
//         reader.readAsDataURL(file);
//     });
// };

const getBlobUrl = (file) => {
    if (!file) return Promise.reject(new Error('No file to process in base 64'));

    const URL = (window.URL || window.webkitURL)
    if (URL) {
        return URL.createObjectURL(file);
    }
    return null;
};

const compressImage = (file, format) => {

    let settings = {};
    switch (format) {
        case FILE_TEMPLATES.THUMB:
            settings = {
                quality: 1,
                maxHeight: 65,
                maxWidth: 65
                // beforeDraw(context) {
                //     context.filter = 'sepia(100%)'; // eslint-disable-line no-param-reassign
                // },
            };
            break;
        case FILE_TEMPLATES.PREVIEW:
            settings = {
                quality: 0.5,
                maxHeight: 500,
                maxWidth: 500
                // beforeDraw(context) {
                //     context.filter = 'sepia(100%)'; // eslint-disable-line no-param-reassign
                // },
            };
            break;
        case FILE_TEMPLATES.COMPRESSED:
            settings = {
                quality: 0.7,
                maxHeight: 3000,
                maxWidth: 3000
                // beforeDraw(context) {
                //     context.filter = 'grayscale(100%)'; // eslint-disable-line no-param-reassign
                // },
            };
            break;
        default:
            break;
    }

    return new Promise((resolve, reject) => {
        new Compressor(file, {
            checkOrientation: true,
            success(result) {
                // resolve(result);
                // result.name = file.name;
                resolve(result);
            },
            error(err) {
                logger.error(err, 'Error in compression file process', file);
                reject(new ResourceError('modal.uploadFile.error.cannotcompress'));
            },
            ...settings
        });
    });
}

const formatImageFile = (output) => ({ output, url: getBlobUrl(output) });

export const processFile = (file, format) => {
    if (!isImage(file)) {
        return Promise.reject(new Error('not an image file, cannot compress'));
    }

    if (file && file.size > callbacks.get('getFileUploadSizeLimit')()) {
        return Promise.reject(new Error('Oversize limit'));
    }

    let p = Promise.resolve(file);
    const shouldCompress = shouldCompressImage(file);
    const handleError = (e) =>{
        // if (e instanceof ResourceError) { // not working, why ???
        if (e instanceof Error && e.name === 'ResourceError') {
            logger.error(e, 'processFile:error');
        }
        return file;
    };

    if (
        format === FILE_TEMPLATES.THUMB
        || ((format === FILE_TEMPLATES.PREVIEW || format === FILE_TEMPLATES.COMPRESS) && shouldCompress)
    ) {
        p = compressImage(file, format).catch(handleError);
    }

    return p.then(formatImageFile);
}

export const getFileSize = async (file) => new Promise(resolve => {
    if (!isImage(file)) {
        resolve({});
    }
    const reader = new FileReader();
    let isResolved = false;
    reader.onload = () => {
        const img = new Image;
        img.onload = () => {
            const { width, height } = img;
            isResolved = true;
            resolve({ width, height });
        };
        img.src = reader.result;
    };
    setTimeout(() => {
        if (!isResolved) {
            logger.error(new Error('Cannot get image dimensions'));
            resolve({ width: undefined, height: undefined });
        }
    }, 5000);
    reader.readAsDataURL(file);
})
