import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import Button from 'components/Shared/Common/Button';
import ArrowFolding from 'components/Shared/Common/ArrowFolding';
import classNames from 'classnames';

const ParticipantListFold = ({ isFold, onFold, intl, isInterfaceDisplayed = true }) => {
    const handleFold = useCallback(() => onFold(!isFold), [onFold, isFold]);

    return (
        <div
            className={classNames(
                'fold-list',
                { active: isFold },
                { 'hide-interface': !isInterfaceDisplayed }
            )}
        >
            <ArrowFolding
                timeout={7000}
                label={intl.formatMessage({ id: 'room.foldingMessage' })}
            />
            <Button
                iconName="ChevronRight"
                color="light-transparency"
                onClick={handleFold}
            />
        </div>
    );
};

ParticipantListFold.propTypes = {
    isFold: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
    onFold: PropTypes.func.isRequired,
    isInterfaceDisplayed: PropTypes.bool
};

export default injectIntl(ParticipantListFold);
