import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Logger from 'services/debug/logger';
import DeviceManager from 'components/Room/Helpers/DeviceManager';
import Button from 'components/Shared/Common/Button';
import soundSample from 'statics/sounds/outgoingRinging.mp3';

const logger = new Logger('component:room:settings:SpeakerTest');

const soundPlayerTest = async ({ deviceId, onError }) => {
    try {
        const audio = new Audio(soundSample);
        if (typeof audio.sinkId !== 'undefined') {
            DeviceManager.attachAudioOutput(deviceId)(audio)
                .catch((e) => {
                    logger.error(e);
                    onError(e);
                });
        }
        audio.play()
        logger.log(`Audio is being played on ${audio.sinkId}`);
    }
    catch (e) {
        if (onError && typeof onError === 'function') {
            logger.error(e);
            onError(e);
        }
    }
};

const SpeakerTest = ({ intl, deviceId }) => {
    const [error, setError] = useState();

    const handleClick = () => {
        soundPlayerTest({ deviceId, onError: setError })
    }
    return (
        <Button
            onClick={handleClick}
            color={error ? 'danger' : 'secondary'}
            size="md"
        >
            {error ? 'Error' : intl.formatMessage({ id: 'test.sound' })}
        </Button>
    );
};

SpeakerTest.propTypes = {
    deviceId: PropTypes.string,
    intl: PropTypes.any.isRequired
};

export default SpeakerTest;
