import { createSelector } from 'reselect';

import { isAdmin } from 'utils/user';
import { getToken } from 'services/token';

const sessionSelector = state => state.session;
export const getTabFocusSelector = state => sessionSelector(state).hasTabFocus;
export const getIsReadySelector = state => sessionSelector(state).isReady;
export const getErrorSelector = state => sessionSelector(state).error;
export const getContentErrorSelector = state => sessionSelector(state).contentError;
export const getIsAuthenticated = state => sessionSelector(state).authenticated && !!getToken();

export const getUserRoleSelector = state =>
    (sessionSelector(state).user ? sessionSelector(state).user.roles : undefined);

export const isUserAdminSelector = createSelector(getUserRoleSelector, isAdmin);
