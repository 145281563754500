import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import DeviceControlButtonMicrophone from 'components/Shared/Call/Buttons/DeviceControlButtonMicrophone';
import DeviceControlButtonCamera from 'components/Shared/Call/Buttons/DeviceControlButtonCamera';
import DeviceControlButtonScreen from 'components/Shared/Call/Buttons/DeviceControlButtonScreen';
import { container as HoldButton } from 'components/Shared/Call/Buttons/Hold';
import { container as ExitButton } from 'components/Shared/Call/Buttons/Exit';
import { container as CallTransferButton } from 'components/Shared/Call/Buttons/CallTransferDropDown';
import Button from 'components/Shared/Common/Button';
import { constants } from '@amplement/backend-connector';
import classNames from 'classnames';
import { isMobile } from "react-device-detect";
import ArrowFolding from 'components/Shared/Common/ArrowFolding';
import { isNativeScreenSharingSupported } from 'services/screenSharing';
import { Grid } from 'antd';
import AutoPatchRoom from 'components/Listeners/AutoPatchRoom/container';
import RecorderButton from 'components/Shared/Recorder/RecorderButton';
import { RECORDER_STATUS } from 'components/Shared/Recorder/constants';
import MoreButton from './MoreButton/ButtonContainer';
import ToggleChatButton from './ToggleChatButton/wrapper';
import InviteUserDrawer from './InviteUserButton';
import { StyleFooterAction, StyleFooterButton } from './styles';
import { useWebgl } from '../Webgl/WebglContext';

const { useBreakpoint } = Grid;

// NE PAS SUPPRIMER sinon ca va virer les trads
// keysUnused - i18nTools - hack
// eslint-disable-next-line
const keepResource = [
    'rtc.error.noAudioStream',
    'rtc.error.noScreenStream',
    'room.call.ringing',
    'rtc.error.devices.all.cannotGetStream',
    'date.today',
    'modal.uploadFile.multicastText',
    'modal.uploadFile.unicastText',
    'call.button.accept.audio',
    'call.button.accept.video',
    'room.error.noActiveClient',
    'notification.room.inviteConflict',
    'error.settings.emailAlreadyUsed',
    'error.settings.passwordRequired',
    'error.settings.invalidPassword',
    'error.settings.newAndActualEmailsAreIdentical',
    'menuLeft.navigation.admin',
    'room.grid.label.tooltip',
    'room.actions.addMembers'
];

export const RoomButton = ({ label, children, size, className, ...props }) => (
    <div className={classNames("button-container", className)}>
        <StyleFooterButton className={size} {...props}>
            {children}
            <div className="label">{label}</div>
        </StyleFooterButton>
    </div>
);

const InviteUserButton = ({ onClick, isActive }) => (
    <Button
        iconName="Users"
        color={isActive ? 'lighten' : 'light-transparency'}
        onClick={onClick}
    />
);

const defaultFeaturesProps = {
    audio: true,
    hold: false,
    video: true,
    dtmf: false,
    persistence: true,
    public: true,
    screenSharing: true,
    editMembers: true,
    transferToPhonenumber: false, // allow the client to transfer the call to a PhoneAccount
    transferToSipUser: false, // allow the client to transfer to a relation with a sip account
    transferToUser: false, // allow the client to transfer to a relation
    transferToRoom: false, // allow the client to transfer to a room
};

const Footer = (props) => {
    const {
        onToggleOwnAudio = () => {},
        onToggleOwnVideo = () => {},
        onToggleOwnScreen = () => {},
        updatedAt,
        intl,
        _room,
        onFold,
        isMeAdmin,
        features = defaultFeaturesProps,
        displayMode,
        _onlineClients = [],
        onDisplayModeChange = () => {},
        hasGalleryMode = true,
        className = '',
        onToggleChat,
        isChatOpen,
        isInterfaceDisplayed = true,
        handleChangeStatus = () => {},
        recorderState = RECORDER_STATUS.STOPPED,
        onStartRecording = () => {},
        onStopRecording = () => {},
    } = props;
    const [avoidSpam, setAvoidSpam] = useState(false);

    useWhyDidYouUpdate('components:room:Footer', props);
    const {
        setOverlayType,
        overlayType,
        isWebglSupported,
        setIsScreenShare,
        setHasVideo,
        initialVideoStream,
    } = useWebgl();

    const screens = useBreakpoint();

    const handleToggleGallery = useCallback(() => {
        onDisplayModeChange(
            displayMode === constants.rooms.ROOM_DISPLAY_MODE.GRID ? undefined : constants.rooms.ROOM_DISPLAY_MODE.GRID
        );
        onFold(false);
    }, [displayMode, onDisplayModeChange, onFold]);

    const isScreenChecked = false;

    const handleSetBlurOverlayType = useCallback(() => {
        setAvoidSpam(true);
        setTimeout(() => setAvoidSpam(false), 200);
        if (overlayType === 'normal') {
            setOverlayType('blur');
        } else {
            setOverlayType('normal');
        }
    }, [setOverlayType, overlayType]);

    const handleCameraClick = useCallback(params => {
        setIsScreenShare(false);
        setHasVideo(hasVid => {
            onToggleOwnVideo(params);
            if (hasVid && params) {
                return !hasVid;
            }

            return hasVid;
        });
    }, [onToggleOwnVideo, setIsScreenShare, setHasVideo]);

    const handleScreenClick = useCallback(params => {
        setIsScreenShare(screen => !screen);
        setHasVideo(false);
        onToggleOwnScreen(params);
    }, [onToggleOwnScreen, setIsScreenShare, setHasVideo]);

    return (
        <StyleFooterAction className={className}>
            <div className="footer-container">
                {!screens?.xs && !isMobile && isNativeScreenSharingSupported && features?.screenSharing && (
                    <RoomButton
                        className="cta_ss"
                        label={intl.formatMessage({ id: 'room.actions.toggleOwnScreen' })}
                    >
                        <DeviceControlButtonScreen
                            _room={_room}
                            intl={intl}
                            isDisabled={isScreenChecked}
                            onClick={handleScreenClick}
                            updatedAt={updatedAt}
                        />
                    </RoomButton>
                )}
                {!screens?.xs && !isMobile && features?.recording && (
                    <RoomButton
                        className="cta_video"
                        label={intl.formatMessage({ id: 'room.actions.record' })}
                    >
                        <RecorderButton
                            handleChangeStatus={handleChangeStatus}
                            recorderState={recorderState}
                            onStartRecording={onStartRecording}
                            onStopRecording={onStopRecording}
                        />
                    </RoomButton>
                )}
                {features?.video && isWebglSupported && (
                    <RoomButton
                        className="cta_camera"
                        label={intl.formatMessage({ id: 'room.actions.toggleOwnCameraGaussianBlur' })}
                    >
                        <Button
                            color={overlayType !== 'normal' ? 'lighten' : 'light-transparency'}
                            iconName="Blur"
                            onClick={handleSetBlurOverlayType}
                            isDisabled={avoidSpam}
                            isLoading={avoidSpam}
                        />
                    </RoomButton>
                )}
                {!screens?.xs && !features?.dtmf && hasGalleryMode && (
                    <div className="button-container">
                        <RoomButton
                            className="cta_mode"
                            label={intl.formatMessage({ id: 'room.actions.toggleGallery' })}
                        >
                            <ArrowFolding
                                timeout={7000}
                                isVisible={displayMode === constants.rooms.ROOM_DISPLAY_MODE.GRID}
                                label={intl.formatMessage({ id : 'room.grid.label.tooltip'})}
                            />
                            <Button
                                color={displayMode === constants.rooms.ROOM_DISPLAY_MODE.GRID ? 'lighten' : 'light-transparency'}
                                iconName="Grid"
                                onClick={handleToggleGallery}
                            />
                        </RoomButton>
                    </div>
                )}
            </div>

            <div className="footer-container centered">
                {features?.audio && (
                    <RoomButton
                        className="cta_micro"
                        label={intl.formatMessage({ id: 'room.actions.toggleOwnMicro' })}
                    >
                        <DeviceControlButtonMicrophone
                            _room={_room}
                            intl={intl}
                            onClick={onToggleOwnAudio}
                            updatedAt={updatedAt}
                        />
                    </RoomButton>
                )}

                {!screens?.xs && features?.hold && (
                    <RoomButton
                        className="cta_hold"
                        label={intl.formatMessage({ id: 'room.actions.toggleHold' })}
                    >
                        <HoldButton _room={_room} />
                    </RoomButton>
                )}

                {!screens?.xs && (features?.transfers || features?.transferToSipUser || features?.transferToPhonenumber || features?.transferToUser || features?.transferToRoom) && (
                    <RoomButton
                        className="cta_transfer"
                        label={intl.formatMessage({ id: 'room.actions.callTransfer' })}
                    >
                        <CallTransferButton
                            _room={_room}
                            _onlineClients={_onlineClients}
                            transferToUser={features?.transferToUser}
                            transferToPhonenumber={features?.transferToPhonenumber}
                            transferToSipUser={!!features?.transferToSipUser}
                            transferToRoom={!!features?.transferToRoom}
                        />
                    </RoomButton>
                )}

                <RoomButton
                    danger
                    className="cta_exit"
                    label={intl.formatMessage({ id: 'room.actions.exit' })}
                >
                    <ExitButton
                        recorderState={recorderState}
                        handleChangeStatus={handleChangeStatus}
                        onStopRecording={onStopRecording}
                        onToggleOwnVideo={onToggleOwnVideo}
                        onToggleOwnScreen={onToggleOwnScreen}
                        videoStream={initialVideoStream}
                        _room={_room}
                    />
                </RoomButton>
                {features?.video && (
                    <RoomButton
                        className="cta_camera"
                        label={intl.formatMessage({ id: 'room.actions.toggleOwnCamera' })}
                    >
                        <DeviceControlButtonCamera
                            _room={_room}
                            intl={intl}
                            isDisabled={isScreenChecked}
                            onClick={handleCameraClick}
                            updatedAt={updatedAt}
                        />
                    </RoomButton>
                )}
                {!screens?.xs && isMeAdmin && features?.editMembers && (
                    <RoomButton
                        className="cta_members"
                        label={intl.formatMessage({ id: 'room.actions.memberList' })}
                    >
                        <InviteUserDrawer
                            _room={_room}
                            Button={InviteUserButton}
                        />
                    </RoomButton>
                )}
            </div>

            <div className="footer-container">
                {!screens?.xs && features?.feed && (
                    <RoomButton
                        className="cta_feed pos-abs-right"
                        label={intl.formatMessage({ id: 'room.actions.chat' })}
                    >
                        <ToggleChatButton
                            intl={intl}
                            _room={_room}
                            active={isChatOpen}
                            onClick={onToggleChat}
                        />
                    </RoomButton>
                )}
                <RoomButton
                    className="cta_more"
                    label={intl.formatMessage({ id: 'room.actions.moreSettings' })}
                >
                    <MoreButton isInterfaceDisplayed={isInterfaceDisplayed} _room={_room} />
                </RoomButton>
            </div>

            <AutoPatchRoom _room={_room} />
        </StyleFooterAction>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    isInterfaceDisplayed: PropTypes.bool,
    user: PropTypes.shape({
        _client: PropTypes.string
    }),
    _room: PropTypes.string.isRequired,
    isChatOpen: PropTypes.bool,
    _onlineClients: PropTypes.array,
    handleChangeStatus: PropTypes.func,
    recorderState: PropTypes.oneOf(Object.values(RECORDER_STATUS)),
    onStartRecording: PropTypes.func,
    onStopRecording: PropTypes.func,
    onToggleChat: PropTypes.func.isRequired,
    onToggleOwnAudio: PropTypes.func.isRequired,
    onToggleOwnVideo: PropTypes.func.isRequired,
    onToggleOwnScreen: PropTypes.func.isRequired,
    onDisplayModeChange: PropTypes.func.isRequired,
    onFold: PropTypes.func.isRequired,
    displayMode: PropTypes.oneOf(Object.values(constants.rooms.ROOM_DISPLAY_MODE)),
    updatedAt: PropTypes.any,
    isMeAdmin: PropTypes.bool,
    hasGalleryMode: PropTypes.bool,
    intl: PropTypes.any.isRequired,
    features: PropTypes.shape({
        audio: PropTypes.bool.isRequired,
        hold: PropTypes.bool.isRequired,
        feed: PropTypes.bool,
        video: PropTypes.bool.isRequired,
        dtmf: PropTypes.bool.isRequired,
        persistence: PropTypes.bool.isRequired,
        public: PropTypes.bool.isRequired,
        screenSharing: PropTypes.bool.isRequired,
        editMembers: PropTypes.bool.isRequired,
        transfers: PropTypes.string,
        transferToPhonenumber: PropTypes.bool,
        transferToSipUser: PropTypes.bool,
        transferToRoom: PropTypes.bool,
        transferToUser: PropTypes.bool
    })
};

export default injectIntl(Footer);
