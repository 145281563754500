import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import RadioGroup from 'components/Shared/Forms/Radio/RadioGroup';
import RoomItem from 'components/Shared/Room/RoomItem';
import { constants } from '@amplement/backend-connector';
import AntdModal from 'components/Shared/Modals';
import CallForwardFooter from 'components/Room/AntdModal/CallForwardFooter';
import { injectIntl } from 'react-intl';
import useRooms from 'hooks/useRooms';
import Spinner from 'components/Shared/Common/Spinner';

const ContentComponent = ({
    onChange,
    rooms,
    // _user,
    _room,
    value
}) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const list = map(rooms, (room) => ({
            // note : condition pour la captation ? a vérifier
            // avec l'aval de Marco, je vire car ca grise le cas ou on ne peut transferer vers un numéro externe, l'entrée est présente mais grisé.
            // avec l'endpoint /transfer, si ca se trouve, plus besoin du tout de cette props
            
            // disabled: room?.caller?._user !== _user,
            name: room?.name,
            members: room?.members?.filter(x => x.status === constants.rooms.USER_IN_ROOM_STATUS.JOINED),
            hasIcon: true,
            value: room?.id
        }))?.filter(room => room?.id !== _room);
        setItems(list);
    }, [rooms, _room, setItems])

    const handleChange = useCallback((e) => {
        onChange(e.target.value);
    }, [onChange]);

    if (!items?.length) {
        return 'No rooms';
    }

    return (
        <RadioGroup
            items={items}
            value={value}
            onChange={handleChange}
            ItemComponent={RoomItem}
        />
    );
}

const RoomSelectorModal = ({
    _room,
    intl,
    onClose,
    onSubmit,
    isOpen = true,
    _user,
    ...props
}) => {
    const { data: rooms, isLoading, error } = useRooms({ _room, canForward: true, transfer: true }); 
    const [value, setValue] = useState('');
    const handleChange = useCallback(n => setValue(n), [setValue]);
    const handleSubmit = useCallback(() => onSubmit({ _destRoom: value }), [onSubmit, value]);

    const footer = useMemo(() =>
        <CallForwardFooter onClose={onClose} onSubmit={handleSubmit} isDisabled={!value} />
    , [onClose, handleSubmit, value]);

    return (
        <AntdModal
            title={intl.formatMessage({ id: 'room.actions.callTransfer' })}
            isOpen={isOpen}
            isAutoScroll
            footer={footer}
            onClose={onClose}
            {...props}
        > 
            {isLoading ? <Spinner /> : (
                error ? intl.formatMessage({ id: 'error.default' }) : (
                    <ContentComponent
                        _room={_room}
                        onChange={handleChange}
                        rooms={rooms}
                        _user={_user}
                        value={value}
                    />
                )
            )}
        </AntdModal>
    );
}

RoomSelectorModal.propTypes = {
    _room: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    // _user: PropTypes.string.isRequired,
    isOpen: PropTypes.bool
};

export default memo(injectIntl(RoomSelectorModal));
