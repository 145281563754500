import React, { useCallback, memo } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Drawer from 'components/Shared/Drawers';
import FeedItemDrawer from 'components/Room/Footer/FeedItemDrawer/container';
import { selectors } from '@amplement/backend-connector';
import { injectIntl } from 'react-intl';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import Icon from 'components/Shared/Common/Icon';
import ToggleChatButton from './Button';

const ToggleChat = (props) => {
    const {
        _feed,
        active,
        onClick,
        _room,
        intl,
    } = props;

    useWhyDidYouUpdate('Component:room:Footer:ToggleChat', props);

    const handleDrawerClose = useCallback(() => onClick(false), []);

    const handleToggleDrawer = useCallback(() => {
        onClick(!active);
    }, [active]);

    return (
        <>
            <ToggleChatButton
                _feed={_feed}
                active={active}
                onClick={handleToggleDrawer}
            />
            <Drawer
                isOpen={active}
                width={400}
                noOverflowBody
                mask={false}
                padding="md"
                onClose={handleDrawerClose}
                title={(
                    <CategoryTitle
                        label={intl.formatMessage({ id: 'room.drawer.feedItem.title' })}
                        icon={<Icon size="xl" iconName="MessageCircle" />}
                    />
                )}
            >
                <FeedItemDrawer
                    _room={_room}
                    _feed={_feed}
                    visible={active}
                />
            </Drawer>
        </>
    );
}

ToggleChat.propTypes = {
    _feed: PropTypes.string,
};

const mapStateToProps = (state, { _room }) => ({
    _feed: selectors.rooms.getFeedIdByRoomIdSelector(state, _room)
});

export default connect(mapStateToProps, null)(injectIntl(memo(ToggleChat)));

