import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';

import SeparatorTemplate from 'components/Shared/Feed/ItemTemplate/SeparatorTemplate';
import UnreadSeparatorTemplate from 'components/Shared/Feed/ItemTemplate/UnreadSeparatorTemplate';
import FeedItemMessageTemplate from "components/Shared/Feed/ItemTemplate/FeedItemMessageTemplate";

const FeedItemRow = forwardRef((props, ref) => {
    const {
        SeparatorComponent = SeparatorTemplate,
        SeparatorUnreadComponent = UnreadSeparatorTemplate,
        updateFeedItem,
        deleteFeedItem,
        replyFeedItem,
        onLoad,
        _customFeedItem,
        deleteLocalFeedItem,
        isSelected = false,
        isEditable = false,
        isRelative = false,
        isAvatarClickable,
        hasStatus,
        hideAvatar,
        hideFullname,
        item,
        style,
        isSending = false,
        isFailed = false,
        failureCode,
        failureMessage,
    } = props;

    return (
        <>
            {item?.hasUnreadFlag && (
                <SeparatorUnreadComponent
                    unreadCount={item.unreadCount}
                    style={style}
                    key={`${item.id}_unread`}
                />
            )}
            {item?.showTimeSeparator && item?.createdAt && (
                <SeparatorComponent
                    isRelative={isRelative}
                    createdAt={item.createdAt}
                    style={style}
                    key={`${item.id}_separator`}
                />
            )}
            <FeedItemMessageTemplate
                ref={ref}
                onLoad={onLoad}
                item={item}
                _customFeedItem={_customFeedItem}
                style={style}
                key={item.id}
                updateFeedItem={updateFeedItem}
                deleteFeedItem={deleteFeedItem}
                replyFeedItem={replyFeedItem}
                deleteLocalFeedItem={deleteLocalFeedItem}
                isSelected={isSelected}
                isEditable={!isFailed && isEditable}
                hideAvatar={hideAvatar}
                isSending={isSending}
                isFailed={isFailed}
                failureCode={failureCode}
                failureMessage={failureMessage}
                hideFullname={hideFullname}
                isAvatarClickable={isAvatarClickable}
                hasStatus={hasStatus}
            />
        </>
    );
});

FeedItemRow.propTypes = {
    SeparatorComponent: PropTypes.any,
    SeparatorUnreadComponent: PropTypes.any,
    _customFeedItem: PropTypes.string,
    item: PropTypes.object.isRequired,
    style: PropTypes.object,
    updateFeedItem: PropTypes.func,
    deleteFeedItem: PropTypes.func,
    replyFeedItem: PropTypes.func,
    isSelected: PropTypes.bool,
    onLoad: PropTypes.func,
    isRelative: PropTypes.bool,
    isSending: PropTypes.bool,
    isFailed: PropTypes.bool,
    failureCode: PropTypes.number,
    failureMessage: PropTypes.string,
    isEditable: PropTypes.bool,
    hasStatus: PropTypes.bool,
    isAvatarClickable: PropTypes.bool,
};

export default memo(FeedItemRow, isEqual);
