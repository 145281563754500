import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import Placeholder from 'components/Shared/Common/Placeholder';
import { Col, Alert } from 'antd';
import { StyledRow } from 'components/Shared/styles';

import ItemLoadings from '../../Contacts/Shared/ItemLoadings';

const ItemContainer = ({ displayMode, children }) => displayMode !== 'list' ? (
    <Col xs={24} lg={12} xl={6} xxl={4}>
        {children}
    </Col>
) : children;

const ListContainer = ({ displayMode, children }) => displayMode !== 'list' ? (
    <StyledRow 
        gutter={[10, 10]} 
        className="s-contact-list"
    >
        {children}
    </StyledRow>
) : children;

const ContactCardList = (props) => {
    const {
        data, isLoading = false, error,
        isFiltered, noData, ItemComponent,
        SkeletonComponent, displayMode, noItemFallback,
        ...rest
    } = props;

    if (isLoading) {
        return (
            <ListContainer displayMode={displayMode}>
                {!SkeletonComponent ? (
                    <ItemLoadings repeat={4} />
                ):(
                    Array(4).fill(0).map((_, i) =>
                        <ItemContainer key={i} displayMode={displayMode}>
                            <SkeletonComponent />
                        </ItemContainer>
                    )
                )}
            </ListContainer>
        );
    }

    if (error) {
        return (
            <Alert message={error} type="error" />
        );
    }

    if (noData) {
        if (noItemFallback !== undefined) {
            return noItemFallback;
        }

        return (
            <Placeholder
                name="placeholderSuggestions"
                position="centered"
                title={
                    isFiltered ?
                        <FormattedMessage
                            id="rightColumn.contacts.searchFallBackMessage"
                            defaultMessage="Aucun résultat ne correspond à votre recherche."
                        /> :
                        <FormattedMessage
                            id="contacts.noRelations"
                            defaultMessage="Vous n'avez pas encore de relations."
                        />
                }
            />
        );
    }

    return (
        <ListContainer displayMode={displayMode}>
            {data.map(item => (
                <ItemContainer key={item?.id || item} displayMode={displayMode}>
                    <ItemComponent
                        {...(typeof item === 'string' ? { id: item } : item)}
                        {...rest}
                    />
                </ItemContainer>
            ))}
        </ListContainer>
    );
}

ContactCardList.propTypes = {
    data: PropTypes.array,
    bookmarks: PropTypes.any,
    isLoading: PropTypes.bool,
    ItemComponent: PropTypes.any.isRequired,
    SkeletonComponent: PropTypes.any
};

export default memo(ContactCardList);
