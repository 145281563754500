import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const useTimeout = (delay = 7000) => {
    const [isTimeout, setIsTimeout] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTimeout(true); // count is 0 here
        }, delay);

        return () => clearTimeout(timer);
    }, [delay]);

    return isTimeout;
};

useTimeout.propTypes = {
    delay: PropTypes.number
};

export default useTimeout;
