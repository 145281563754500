import React, { useCallback, useMemo } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

import ButtonClick from 'components/Shared/Common/Button/ButtonClick';
import Row from 'components/CallBuilder/Row/Row';
import CallButton from 'components/CallBuilder/DefaultResults/style';

import { MAX, NAVIGATION } from 'components/CallBuilder/constants';

import { CommonListProps, ListProps, MaxSipReachedProps } from 'components/CallBuilder/types';

import { PhonebookDb } from 'hooks/usePhonebook';
import uuid from 'utils/state/uuid';

const { Text } = Typography;

type ContactType = {
    phoneNumber: string;
} & PhonebookDb;

type ContactProps = {
    contact: ContactType;
} & WrappedComponentProps & CommonListProps & MaxSipReachedProps;

const Contact = injectIntl(({
    contact,
    inputValue,
    isSipDisconnected,
    onCloseModal,
    onAddParticipant,
    onRemoveParticipant,
    intl,
    maxSipReached,
    showDirectCall,
}: ContactProps): JSX.Element => {
    const translation = useMemo(() => ({ call: intl.formatMessage({ id: 'call.call' }) }), [intl]);

    const member = useMemo(() => ({
        ...contact,
        fullname: `${contact.user_name?.first || ''} ${contact.user_name?.last || ''}`,
    }), [contact]);

    const handleAddUser = useCallback(() => {
        if (inputValue.find((item) => item?.value === member.phoneNumber)) {
            return;
        }

        onAddParticipant?.({
            ...member,
            resultType: NAVIGATION.PHONEBOOK,
            label: member.fullname,
            value: member.phoneNumber,
            phoneNumber: member.phoneNumber,
        });
    }, [onAddParticipant, inputValue, member]);

    const handleRemoveParticipant = useCallback(() => {
        onRemoveParticipant?.({
            ...member,
            label: member.fullname,
            value: member.phoneNumber,
        }, `phoneNumber`);
    }, [onRemoveParticipant, member]);

    const hasParticipant = useMemo(() => !!inputValue.find((item) => item?.value === member?.phoneNumber), [inputValue, member]);

    return (
        <Row
            label={member.fullname}
            disablePlusButton={maxSipReached || !member.phoneNumber}
            onAddParticipant={handleAddUser}
            onRemoveParticipant={handleRemoveParticipant}
            hasParticipant={hasParticipant}
            phoneNumber={member.phoneNumber}
            hideStatus
        >
            {!inputValue.length && !isSipDisconnected && showDirectCall ? (
                <ButtonClick onClick={onCloseModal}>
                    <CallButton
                        disabled={!member.phoneNumber}
                        phoneNumber={member.phoneNumber}
                        customPrefix
                        iconName="Phone"
                    >
                        <p>{translation.call}</p>
                    </CallButton>
                </ButtonClick>
            ) : null}
        </Row>
    );
});

type PhoneBookProps = ListProps & WrappedComponentProps & MaxSipReachedProps;

const PhoneBook = ({
    max = MAX,
    inputValue = [],
    maxSipReached = false,
    showDirectCall = false,
    isSipDisconnected = false,
    isLoading = false,
    searchResults: { [NAVIGATION.PHONEBOOK]: phoneBook } = {},
    onAddParticipant,
    onRemoveParticipant,
    onCloseModal,
    intl,
}: PhoneBookProps): JSX.Element | JSX.Element[] => {
    const { results = [] } = phoneBook || {};

    if (isLoading) {
        return <LoadingOutlined />;
    }

    if (!results || !results.length) {
        return <Text>{intl.formatMessage({ id: 'callbuilder.emptyList' })}</Text>;
    }

    const contacts = useMemo(() => results.reduce((acc: Array<ContactType>, contact: PhonebookDb) => {
        let newAcc = acc;
        const phones = Object.values(contact.user_phone);
        if (phones.length) {
            phones.forEach(phoneNumber => {
                newAcc = [...newAcc, { ...contact, phoneNumber }];
            });
        } else {
            newAcc = [...newAcc, { ...contact, phoneNumber: '' }];
        }

        return newAcc;
    }, []), [results]);

    return (
        contacts.slice(0, max).map(contact => (
            <Contact
                key={`${contact.phoneNumber}-${uuid()}`}
                isSipDisconnected={isSipDisconnected}
                onCloseModal={onCloseModal}
                contact={contact}
                showDirectCall={showDirectCall}
                maxSipReached={maxSipReached}
                inputValue={inputValue}
                onAddParticipant={onAddParticipant}
                onRemoveParticipant={onRemoveParticipant}
            />
        ))
    );
};

export default injectIntl(PhoneBook as React.FunctionComponent<PhoneBookProps>);
