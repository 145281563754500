import React, { memo, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Loader from 'common/icons/loader.gif';

import Logger from 'services/debug/logger';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import useMediaDeviceFinder from 'hooks/useMediaDeviceFinder';
import useMyStreamSelector from 'hooks/useMyStreamSelector';

import DeviceManager from 'components/Room/Helpers/DeviceManager';

import { useWebgl } from 'components/Room/Webgl/WebglContext';

const loggerBase = 'component:room:roomscreen:player';
const logger = new Logger(loggerBase);

const Player = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-size: cover;
    height: 100%;

    &.filled {
        border-radius: 4px;
        object-fit: cover;
    }

    &.hidden {
        display: none;
    }

    &.shown {
        display: block;
    }
`;

const usePlayer = (contextualLoggerName, kind, ref, updatedAt, muted, stream) => {
    useEffect(() => {
        if (ref.current) {
            const getTracks = kind === 'audio' ? 'getAudioTracks' : 'getVideoTracks';
            const track = stream?.[getTracks]?.()?.[0];
            const currTrack = ref.current.srcObject?.[getTracks]?.()?.[0];
            if (
                stream?.id !== ref.current.srcObject?.id
                || track?.id !== currTrack?.id
                || track?.enabled !== currTrack?.enabled
                || track?.muted !== currTrack?.muted
            ) {
                const origLoggerType = loggerBase;
                logger.setName(contextualLoggerName);
                logger.log('UpdateSrcObject',
                    ref.current.srcObject?.id,
                    stream?.id,
                    `[${track?.id}|${track?.kind}|enabled-${track?.enabled}|muted-${track?.muted}]`
                );
                logger.setName(origLoggerType);
                ref.current.srcObject = stream;
            }
        }
    }, [contextualLoggerName, kind, updatedAt, muted, stream]);
}

const VideoPlayer = memo((props) => {
    const { className, stream, muted, updatedAt, id, loader, isMyUser } = props;
    const videoRef = useRef(null);
    const { videoStream } = useWebgl() || {};

    const selectedStream = isMyUser ? videoStream : stream;

    const contextualLoggerName = `${loggerBase}-${id}:VideoPlayer`;
    useWhyDidYouUpdate(contextualLoggerName, props);
    usePlayer(contextualLoggerName, 'video', videoRef, updatedAt, muted, selectedStream);

    return (
        <Player
            ref={videoRef}
            className={className}
            poster={loader}
            autoPlay
        />
    );
});

export const AudioPlayer = memo((props) => {
    const {
        stream,
        muted,
        updatedAt,
        id
    } = props;
    const audioRef = useRef();
    const deviceId = useMediaDeviceFinder('audiooutput');
    const contextualLoggerName = `${loggerBase}-${id}:AudioPlayer`;

    useWhyDidYouUpdate(contextualLoggerName, { ...props, deviceId });
    usePlayer(contextualLoggerName, 'audio', audioRef, updatedAt, muted, stream);

    useEffect(() => {
        if (audioRef.current) {
            logger.log('useEffect:audio:finalSettings', stream, deviceId);
            if (deviceId) {
                DeviceManager.attachAudioOutput(deviceId)(audioRef.current);
            } else {
                logger.warn('Device with id', deviceId, ' not found');
            }
        }
    }, [deviceId, audioRef.current]);

    return (
        <audio
            ref={audioRef}
            muted={muted}
            autoPlay
        />
    );
})

const MainPlayer = (props) => {
    const {
        className = '',
        isVideoOn,
        isAudioOn = false,
        videoStream,
        audioStream,
        updatedAt,
        loader = Loader,
        forceMute = false,
        renderDefault,
        isMyUser,
        _room,
        id
    } = props;
    const { hasVideo, isSharingScreen, hasScreen } = useMyStreamSelector(_room) || {};
    const muted = forceMute || !isAudioOn;
    const hasVideoTrack = videoStream && videoStream.getVideoTracks && videoStream.getVideoTracks()?.length;
    const hasAudioTrack = audioStream && audioStream.getAudioTracks && audioStream.getAudioTracks()?.length;
    const shortId = id?.slice(0, 13);

    useWhyDidYouUpdate(`${loggerBase}-${shortId}:base`, { ...props, muted, hasVideoTrack, hasAudioTrack, updatedAt });

    const isVideoEnabled = useMemo(() =>
        ((isVideoOn && hasVideoTrack) || (isMyUser && (isSharingScreen || hasScreen || hasVideo || hasVideoTrack))),
    [isSharingScreen, hasScreen, isVideoOn, hasVideo, hasVideoTrack]);

    return (
        <>
            {isVideoEnabled ? (
                <VideoPlayer
                    isMyUser={isMyUser}
                    id={shortId}
                    className={className}
                    poster={loader}
                    updatedAt={updatedAt}
                    stream={videoStream}
                    muted
                />
            ) : renderDefault}
            {(!muted && hasAudioTrack && (
                <AudioPlayer
                    id={shortId}
                    stream={audioStream}
                    muted={muted}
                    autoPlay
                />
            ))}
        </>
    );
}

MainPlayer.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    _room: PropTypes.string,
    renderDefault: PropTypes.any,
    videoStream: PropTypes.shape({
        id: PropTypes.string.isRequired
    }),
    audioStream: PropTypes.shape({
        id: PropTypes.string.isRequired
    }),
    isVideoOn: PropTypes.bool,
    isAudioOn: PropTypes.bool,
    loader: PropTypes.string,
    forceMute: PropTypes.bool
};

export default MainPlayer;
