import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Shared/Common/Spinner';

const ItemList = ({ ids, fallbackMessage, isLoading, itemRender }) => {
    if (isLoading) {
        return <Spinner mode="lighten" isActive />;
    }

    return (
        ids && ids.length ? ids.map(itemRender) : fallbackMessage
    );
}

ItemList.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string, PropTypes.object),
    fallbackMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isLoading: PropTypes.bool,
    itemRender: PropTypes.func.isRequired
};

export default ItemList;
