import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import FullscreenLoader from 'components/Shared/Layout/FullscreenLoader';
import FullscreenMessageFormatter from 'components/Shared/Errors/Formatter';
import RoomFullscreenMessage from 'components/Shared/Errors/RoomFullscreenMessage';

import Room from 'components/Room/container';
import Requierements from 'components/Room/Requierements/container';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import RoomEnded from 'components/Room/Layout/RoomEnded';
import { callbacks, constants } from '@amplement/backend-connector';
import AlertOnNewMediaDevices from 'components/Listeners/AlertOnNewMediaDevices/container';
import DeviceManager from 'components/Room/Helpers/DeviceManager';
import TabEventListener from 'components/Room/TabEventListener';
import WebGLProvider from 'components/Room/Webgl/WebglContext';

const RoomScreen = (props) => {
    const {
        _room,
        isLoading,
        addNotification,
        requestPostRoomJoin,
        _client,
        _user,
        isWsOpen,
        isEnded,
        status,
        setOwnDevicesSettings,
        roomSettings,
        intl,
        kind
    } = props;
    const [isJoined, setIsJoined] = useState(false);
    const [requirementDone, setRequirementDone] = useState(false);
    const [error, setError] = useState();
    const [errorValues, setErrorValues] = useState();
    const hasAudio = !!roomSettings?.audioinput;
    const hasVideo= !!roomSettings?.videoinput && kind === constants.rooms.ROOM_TYPE.VIDEO;

    useEffect(() => {
        if (_room) {
            callbacks.set('onRoomNotFound', (id) => {
                if (id === _room) {
                    setError('room.error.notFound');
                }
            }, true);
            callbacks.set('onRoomForbidden', (id) => {
                if (id === _room) {
                    setError('room.error.forbidden');
                }
            }, true);
            callbacks.set('onRoomJoinMaxMembersReached', ({ room } = {}) => {
                if (room?.id === _room) {
                    setError('room.error.maxMemberLimit');
                }
            }, true);
            callbacks.set('onRoomJoinMaxCallsReached', ({ room, maxCalls } = {}) => {
                if (room?.id === _room) {
                    setErrorValues({ maxCalls });
                    setError(intl.formatMessage({ id: 'room.error.maxCallsLimit' }, { maxCalls }));
                }
            }, true);
            callbacks.set('onRoomWSNotConnected', (id) => {
                if (id === _room) {
                    setError('room.error.noWebsocketMounted');
                }
            }, true);
            callbacks.set('onForwardRoomFailed', (id) => {
                if (id === _room) {
                    addNotification({
                        title: 'room.error.transferFailed',
                        referenceError: 'R30'
                    });
                }
            }, true);
        }
    }, [_room, setError]);

    useEffect(() => {
        if (_room) {
            callbacks.set('onRoomJoinFailed', ({ room }) => {
                if (room?.id === _room) {
                    let reason = 'error.default';
                    if (status === constants.rooms.USER_IN_ROOM_STATUS.KICKED) {
                        reason = 'room.error.kicked';
                    }
                    if (status === undefined) {
                        reason = 'room.error.notInvited';
                    }
                    setError(reason);
                }
            }, true);
        }
    }, [_room, setError, status]);

    useEffect(() => {
        if (!isJoined && isWsOpen && !isLoading && requirementDone && _client && _room && status !== constants.rooms.USER_IN_ROOM_STATUS.JOINED) {
            
            DeviceManager.enumerateDevices()
                .then(devices => {
                    const audio = hasAudio && devices?.some(d => d.kind === 'audioinput');
                    const video = hasVideo && devices?.some(d => d.kind === 'videoinput');
                    requestPostRoomJoin(audio, video);
                    setIsJoined(true);
                })
                .catch(() => {
                    requestPostRoomJoin(false, false);
                    setIsJoined(true);
                });
        }
    }, [isWsOpen, isLoading, requirementDone, _client, _room, isJoined, requestPostRoomJoin, setIsJoined, hasAudio, hasVideo, status]);

    useEffect(() => {
        if (isJoined) {
            if (
                ['room.error.kicked', 'room.error.ended'].indexOf(error) !== -1
                && (status === constants.rooms.USER_IN_ROOM_STATUS.JOINED
                || status === constants.rooms.USER_IN_ROOM_STATUS.WAITING)
            ) {
                setError(undefined);
            }
            if (status === constants.rooms.USER_IN_ROOM_STATUS.KICKED) {
                setError('room.error.kicked');
            }
            if (status === constants.rooms.USER_IN_ROOM_STATUS.LEFT) {
                setError('room.error.ended');
            }
        } else if (status === constants.rooms.USER_IN_ROOM_STATUS.JOINED) {
            setIsJoined(true);
        }
    }, [isJoined, status, setIsJoined, setError]);

    useWhyDidYouUpdate('screens:room', { ...props, isJoined, error, requirementDone });

    const handleComplete = useCallback((settings) => {
        setOwnDevicesSettings(settings);
        setRequirementDone(true);
    }, [setRequirementDone, setOwnDevicesSettings]);

    if (isEnded && _room) {
        return <RoomEnded _room={_room} />;
    }

    if (error) {
        return (
            <FullscreenMessageFormatter
                content={intl.formatMessage({ id: error })}
                resValues={errorValues}
                buttonType={BUTTON_TYPE.CLOSE}
                render={RoomFullscreenMessage}
            />
        );
    }

    if (!requirementDone) {
        return (
            <Requierements
                audio
                video={kind === constants.rooms.ROOM_TYPE.VIDEO}
                onComplete={handleComplete}
                initialConfig={roomSettings}
                skipNextTime={roomSettings?.skipNextTime === '1'}
            />
        );
    }

    if (_client && _room && !isLoading && isJoined) {
        return (
            <div>
                <AlertOnNewMediaDevices />
                <TabEventListener />
                <Room
                    _room={_room}
                    _client={_client}
                    _me={_user}
                    onError={addNotification}
                />
            </div>
        );
    }
    
    return <FullscreenLoader />;
}

RoomScreen.propTypes = {
    intl: PropTypes.any.isRequired,
    _room: PropTypes.string,
};

const WrappedRoomScreen = ({ _room, ...props }) => (
    <WebGLProvider _room={_room}>
        <RoomScreen _room={_room} {...props} />
    </WebGLProvider>
);

export default injectIntl(WrappedRoomScreen);
