import Logger from 'services/debug/logger';

const logger = new Logger('utils');

export const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        }
    };
};

export const skipCancelledRequest = e => {
    if (e?.isCanceled) {
        logger.warn('Request canceled');
        return e;
    }
    throw e;
};
