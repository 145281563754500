import React, { memo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Shared/Common/Button';
import styled from 'styled-components';

import { Tooltip, Slider, Typography } from 'antd';
import { injectIntl } from 'react-intl';
import { utils } from '@amplement/backend-connector';
import useInterval from 'hooks/useInterval';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import { PopConfirmDeletion } from 'components/Shared/Common/PopConfirm';
import usePhonenumber from 'hooks/usePhonenumber';

const { Text } = Typography;

const Styled = styled.div`
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: column;

    .action-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        .spacer{
            display: flex;
            flex-direction: row;
        }
    }
    .bar-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .time-container{
        margin-top: 5px;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .current-time,
    .duration {
        font-family: monospace;
        font-size: 10px;
    }
`;

const PLAYER_ERROR_CODES = [
    'MEDIA_ERR_ABORTED',
    'MEDIA_ERR_NETWORK',
    'MEDIA_ERR_DECODE',
    'MEDIA_ERR_SRC_NOT_SUPPORTED',
]

const useAudio = url => {
    const [audio, setAudio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);
    const [error, setError] = useState();

    const toggle = (isPlaying) => setPlaying(isPlaying ?? !playing);

    useEffect(() => playing ? audio.play() : audio.pause(), [playing]);

    useEffect(() => {
        if (audio.src !==  url) {
            audio?.pause?.();
            setAudio(new Audio(url));
        }
    }, [url]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        audio.onerror = () => {
            setPlaying(false)
            if (PLAYER_ERROR_CODES?.[audio.error.code]) {
                setError(`Error: ${  PLAYER_ERROR_CODES[audio.error.code]}`);
            } else {
                setError('Error');
            }
        };
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, [audio]);

    return [playing, toggle, audio, error];
};

    
const sliderProps = {
    min: 0,
    style: { width: '100%' },
    tooltip: { open: false },
};

const ProgressBar =  memo((props) => {
    const {
        autoRefreshDelay,
        audio,
        onTimeChange,
        duration
    } = props;

    const [state, setState] = useState({});
    const { currentTime } = audio || {};
    const hideHoursIfnull = duration > 60 * 1000 * 60;

    useWhyDidYouUpdate('components:voicemails:ProgressBar', { ...props, state });
    useInterval(() => setState({}), autoRefreshDelay);

    const handleChange= useCallback((value) => {
        onTimeChange(value);
        setState({});
    }, [onTimeChange, setState]);

    return (
        <>
            <Slider
                {...sliderProps}
                onChange={handleChange}
                max={duration}
                step={duration / 100}
                value={currentTime}
            />
            <div className="time-container">
                <div className="current-time">{utils.date.convertDurationToString(currentTime * 1000 * 60, { hideHoursIfnull })}</div>
                <div className="duration">{utils.date.convertDurationToString(duration * 1000 * 60, { hideHoursIfnull })}</div>
            </div>
        </>
    );
});

const download = (url) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.download = url;
    a.href = url;
    a.click();
    a.remove();
};

const VoiceMailItemDetails = (props) => {
    const {
        intl,
        id,
        onRemove,
        getMediaByVoiceMailId,
        isActive,
        onRead,
        readState,
        duration,
        caller: { callback, external, hidden }
    } = props;
    const [src, setSrc] = useState();
    const [isPlaying, togglePlayPause, audio, playerError] = useAudio(src);
    const isRead = readState === 'read';
    const { formatProps } = usePhonenumber({ prefix: !!external});

    useWhyDidYouUpdate('components:voicemails:VoiceMailItemDetails', { ...props, src, isPlaying, audio });

    const fetchFile = () => getMediaByVoiceMailId(id)
        .then((response) => {
            setSrc(response.request.responseURL);
            return response;
        });

    const pause = () => togglePlayPause(false);

    const play = () => togglePlayPause(true);

    const handleChangeTime = useCallback((value) => {
        audio.currentTime = value;
    }, [audio]);

    const handleDownload = useCallback((e) => {
        e.preventDefault();
        fetchFile()
            .then((response) => download(response.request.responseURL));
        return false;
    }
    , [id, getMediaByVoiceMailId]);

    const handleTogglePlayPause = useCallback(() => {
        if (!src) {
            fetchFile().then(() => {
                if (!isRead) {
                    onRead();
                }
                setTimeout(play, 0);
            });
        } else {
            togglePlayPause();
        }
    }, [src, setSrc, isRead, togglePlayPause, getMediaByVoiceMailId]);

    useEffect(() => {
        if (!isActive && isPlaying) { // auto stop if another voicemail is played
            pause();
        } else if (isActive && !isRead) {
            handleTogglePlayPause(); // autoplay if unread and visible
        }
    }, [isActive]);

    return (
        <div className="line-container voicemail">
            <Styled>
                <div className="bar-container">
                    <ProgressBar
                        autoRefreshDelay={isPlaying ? 1000 : null}
                        audio={audio}
                        duration={duration}
                        onTimeChange={handleChangeTime}
                    />
                </div>
                {playerError && <Text type="danger">{playerError}</Text>}
                <div className="action-container">
                    <div className="spacer">
                        <Tooltip
                            placement="bottom"
                            title={isPlaying ?
                                intl.formatMessage({ id: 'global.button.pause' })
                                : intl.formatMessage({ id: 'global.button.play' })
                            }
                        >
                            <Button
                                onClick={handleTogglePlayPause}
                                iconName={!isPlaying ? "Play" : "Pause"}
                                size="sm"
                            />
                        </Tooltip>
                    </div>

                    <div className="spacer">
                        <Tooltip
                            placement="bottom"
                            title={intl.formatMessage({ id: 'global.button.download' })}
                        >
                            <Button
                                onClick={handleDownload}
                                iconName="Download"
                                size="sm"
                                download
                            />
                        </Tooltip>
                        {!hidden && callback && (
                            <Tooltip
                                placement="bottom"
                                title={intl.formatMessage({ id: 'global.button.recall' })}
                            >
                                <Button
                                    {...formatProps(callback)}
                                    iconName="Phone"
                                    size="sm"
                                />
                            </Tooltip>
                        )}
                        {onRemove && (

                            <Tooltip
                                placement="bottom"
                                title={intl.formatMessage({ id: 'global.button.remove' })}
                            >
                                <PopConfirmDeletion
                                    title={intl.formatMessage({ id: 'voicemail.askDelete' })}
                                    onConfirm={onRemove}
                                    buttonIconName="Trash2"
                                />
                            </Tooltip>
                        )}
                    </div>
                </div>
            </Styled>
        </div>
    );
}

VoiceMailItemDetails.propTypes = {
    intl: PropTypes.any.isRequired,
};

export default memo(injectIntl(VoiceMailItemDetails));
