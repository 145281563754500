import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Field, Form as FinalForm } from 'react-final-form';
import { Form, Row, Col } from 'antd';

import { LabelInput, LabelInputNumber, LabelPassword } from 'components/Shared/Forms';
import Button from 'components/Shared/Common/Button';
import SipStatus from 'components/Shared/Call/SipStatus';
import SipData from 'components/Shared/Call/SipData';
import Link from 'components/Shared/Common/Link';
import { PopConfirmDeletion } from 'components/Shared/Common/PopConfirm';
import { ButtonActions } from 'components/Shared/styles';

import { composeValidators } from 'utils/formValidator';
import { SIP_ACCOUNT_STATUS } from 'const/call';

import CollapseError from '../CollapseError';

const formatMessages = intl => ({
    confirmRemoveAccount: intl.formatMessage({ id: 'modal.deleteSipAccount.title' }),
    no: intl.formatMessage({ id: 'button.toggle.off' }),
    yes: intl.formatMessage({ id: 'button.toggle.on' }),
    line: intl.formatMessage({ id: 'drawer.sip.line' }),
    parametersTitle: intl.formatMessage({ id: 'drawer.sip.title' }),
    generalParameters: intl.formatMessage({ id: 'drawer.sip.title.general' }),
    advancedParameters: intl.formatMessage({ id: 'drawer.sip.title.advanced' }),
    reset: intl.formatMessage({ id: 'drawer.sip.button.reset' }),
    removeSettings: intl.formatMessage({ id: 'global.button.remove' }),
    saveSettings: intl.formatMessage({ id: 'global.save' }),
    userName: intl.formatMessage({ id: 'drawer.sip.label.username' }),
    fillUserName: intl.formatMessage({ id: 'drawer.sip.placeholder.username' }),
    password: intl.formatMessage({ id: 'drawer.sip.label.password' }),
    fillPassword: intl.formatMessage({ id: 'drawer.sip.placeholder.password' }),
    server: intl.formatMessage({ id: 'drawer.sip.label.server' }),
    fillServer: intl.formatMessage({ id: 'drawer.sip.placeholder.server' }),
    serverPort: intl.formatMessage({ id: 'drawer.sip.label.port' }),
    fillProxyPort: intl.formatMessage({ id: 'drawer.sip.placeholder.proxy' }),
    domainServer: intl.formatMessage({ id: 'drawer.sip.label.domain' }),
    fillDomainServer: intl.formatMessage({ id: 'drawer.sip.placeholder.domain' }),
    prefix: intl.formatMessage({ id: 'drawer.sip.label.prefix' }),
    fillPrefix: intl.formatMessage({ id: 'drawer.sip.placeholder.prefix' }),
    outboundProxyIpaddr: intl.formatMessage({ id: 'drawer.sip.label.outboundProxyServer' }),
    fillOutboundProxyIpaddr: intl.formatMessage({ id: 'drawer.sip.placeholder.outboundProxyServer' }),
    outboundProxyPort: intl.formatMessage({ id: 'drawer.sip.label.outboundProxyPort' }),
    fillOutboundProxyPort: intl.formatMessage({ id: 'drawer.sip.placeholder.outboundProxyPort' })
});

const gutter = [10, 0];
const span = 12;

const DrawerSettings = ({
    intl,
    _account = null,
    onRemove,
    status,
    sipData,
    error,
    errorDetails,
    onSubmit,
    validators,
    getAccounts,
    initialValues = {},
    hasAdvancedSettings
}) => {
    const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState();
    const i18n = useMemo(() => formatMessages(intl), [intl]);

    useEffect(getAccounts, [_account]);

    const initialFormValues = useMemo(() => initialValues, [initialValues.id]);

    const handleToggle = useCallback(
        () => setIsAdvancedSettingsOpen(!isAdvancedSettingsOpen),
        [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen]);

    const handleRemove = useCallback(() => onRemove(_account), [_account]);

    const {
        required,
        number,
        port
    } = validators;

    return (
        <FinalForm onSubmit={onSubmit} initialValues={initialFormValues}>
            {({ handleSubmit, pristine, submitting, form }) => (
                <Form layout="vertical" onSubmit={handleSubmit}>
                    {_account && <SipStatus status={status} />}
                    {
                        error ? (
                            <CollapseError
                                title="Details"
                                content={errorDetails && JSON.stringify(errorDetails)}
                                textButton="details"
                                label={error}
                            />
                        ) : <SipData sipData={sipData} />
                    }
                    <h2>{i18n.generalParameters}</h2>
                    <Row align="top" gutter={gutter}>
                        <Col span={span}>
                            <Field
                                component={LabelInput}
                                placeholder={i18n.fillUserName}
                                validate={required}
                                name="username"
                                label={i18n.userName}
                            />
                        </Col>
                        <Col span={span}>
                            <Field
                                component={LabelPassword}
                                placeholder={i18n.fillPassword}
                                validate={required}
                                name="password"
                                label={i18n.password}
                            />
                        </Col>
                    </Row>
                    <Col span={span}>
                        <Field
                            component={LabelInput}
                            placeholder={i18n.fillServer}
                            validate={required}
                            name="server"
                            label={i18n.server}
                        />
                    </Col>

                    {isAdvancedSettingsOpen || hasAdvancedSettings ?
                        <h2>{i18n.advancedParameters}</h2> : (
                            <Link
                                onClick={handleToggle}
                            >
                                <span>{i18n.advancedParameters} &gt;</span>
                            </Link>
                        )}

                    {(isAdvancedSettingsOpen || hasAdvancedSettings) && (
                        <>
                            <Row align="top" gutter={gutter}>
                                <Col span={span}>
                                    <Field
                                        component={LabelInputNumber}
                                        placeholder={i18n.fillServerPort}
                                        validate={composeValidators(number, port)}
                                        name="serverPort"
                                        label={i18n.serverPort}
                                    />
                                </Col>
                                <Col span={span}>
                                    <Field
                                        component={LabelInput}
                                        placeholder={i18n.fillDomainServer}
                                        name="domainServer"
                                        label={i18n.domainServer}
                                    />
                                </Col>
                            </Row>
                            <Row align="top" gutter={gutter}>
                                <Col span={span}>
                                    <Field
                                        component={LabelInput}
                                        placeholder={i18n.fillOutboundProxyIpaddr}
                                        name="outboundProxyIpaddr"
                                        label={i18n.outboundProxyIpaddr}
                                    />
                                </Col>
                                <Col span={span}>
                                    <Field
                                        component={LabelInputNumber}
                                        placeholder={i18n.fillOutboundProxyPort}
                                        validate={composeValidators(number, port)}
                                        name="outboundProxyPort"
                                        label={i18n.outboundProxyPort}
                                    />
                                </Col>
                            </Row>

                            <Col span={span}>
                                <Field
                                    component={LabelInput}
                                    placeholder={i18n.fillPrefix}
                                    name="prefix"
                                    label={i18n.prefix}
                                />
                            </Col>
                        </>
                    )}
                    <Form.Item shouldUpdate noStyle>
                        {() => (
                            <ButtonActions className={_account ? 'spaced':  ''}>
                                {_account && (
                                    <PopConfirmDeletion
                                        title={i18n.confirmRemoveAccount}
                                        onConfirm={handleRemove}
                                        buttonIconName="Trash2"
                                        buttonColor="danger"
                                        buttonTitle={i18n.removeSettings}
                                        disabled={!!submitting}
                                        buttonSize="md"
                                        isTransparent={false}
                                    />
                                )}

                                <Button
                                    iconName="Check"
                                    onClick={form.submit}
                                    color="primary"
                                    isDisabled={pristine || submitting}
                                >
                                    {i18n.saveSettings}
                                </Button>
                            </ButtonActions>
                        )}
                    </Form.Item>
                </Form>
            )}
        </FinalForm>
    );
}

DrawerSettings.propTypes = {
    initialValues: PropTypes.object,
    _account: PropTypes.string,
    intl: PropTypes.any.isRequired,
    onRemove: PropTypes.func.isRequired,
    getAccounts: PropTypes.func.isRequired,
    status: PropTypes.oneOf(Object.values(SIP_ACCOUNT_STATUS)),
    sipData: PropTypes.shape({
        code: PropTypes.number.isRequired,
        reason: PropTypes.string.isRequired
    }),
    error: PropTypes.string,
    errorDetails: PropTypes.object,
};

export default injectIntl(DrawerSettings);
