import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import { usePrevious } from 'react-use';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

import { resources, selectors } from '@amplement/backend-connector';

import { getCurrentUserIdSelector } from 'selectors/user';

import CustomScroll from 'components/MessageList/CustomScroll';
import FeedItemDrawer from 'components/Room/Footer/FeedItemDrawer/container';
import Header from 'components/Feed/Header/container';

import { FeedItemResult } from 'hooks/useSearch';
import usePreviousFeedItems, { FeedType, REQUEST_LIMIT, useGetNextFeedItems, useGetPreviousFeedItems } from 'hooks/useFeeds';

import { DELETE_FEED_ITEM, POST_FEED_ITEM, PUT_FEED_ITEM } from 'types/feedItem';

const { websocket: { manager: webSocketManager } } = resources;

const StyledCard = styled(Card)`
    margin-bottom: 10px;
    margin-left: 10px;
    cursor: pointer;
    max-height: 65vh;
    overflow: hidden;

    .ant-card-body {
        padding: 0px;
        display: flex;
        flex-direction: column;
        height: 65vh;
        max-height: 65vh;

        .ant-comment {
            margin: 0;
        }
    }
`;

const StyledLoaderContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const StyledLoader = styled(LoadingOutlined)`
    font-size: 34px;
    color: #08c;
    z-index: 2;
`;

type MessageDetailProps = {
    feedItem: FeedItemResult;
    mustScroll: boolean;
    setMustScroll: (value: boolean) => void;
    selectFeedItem: (item?: FeedItemResult) => void;
};

const MessageDetail = ({
    feedItem: { _feed, id, createdAt, feed },
    feedItem,
    mustScroll,
    selectFeedItem,
    setMustScroll,
}: MessageDetailProps): JSX.Element | null => {
    const prevId = usePrevious(id);
    const [items, setItems] = useState<FeedType>([]);
    const [isTopEndReached, setTopEndReached] = useState(false);
    const [isBottomEndReached, setBottomEndReached] = useState(false);
    const [isFetchedOnce, setFetchedOnce] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const isWsOpen = useSelector(selectors.network.getIsWsOpenSelector);
    const _myUser = useSelector(getCurrentUserIdSelector);

    const {
        data: firstData,
        isLoading: isInitLoading
    } = usePreviousFeedItems({
        _feed,
        _lastFeedItem: id,
        lastCreatedAt: createdAt,
        limit: REQUEST_LIMIT,
    });

    const {
        mutate: getNext,
        data: { data: nextData } = {},
        isLoading: nextIsLoading,
        reset: resetNext,
        status: nextStatus,
    } = useGetNextFeedItems({
        _feed,
        limit: REQUEST_LIMIT,
        onSuccess: (data: unknown) => {
            const typedData = data as { data: FeedType };
            setLoading(false);
            if (!typedData?.data?.length || typedData?.data?.length < REQUEST_LIMIT) {
                setBottomEndReached(true);
            }
        },
        onError: () => {
            setLoading(false);
        }
    });

    const {
        mutate: getPrevious,
        data: { data: previousData } = {},
        isLoading: prevIsLoading,
        reset: resetPrevious,
    } = useGetPreviousFeedItems({
        _feed,
        limit: REQUEST_LIMIT,
        onSuccess: (data: unknown) => {
            const typedData = data as { data: FeedType };
            if (!typedData?.data?.length || typedData?.data?.length < REQUEST_LIMIT) {
                setTopEndReached(true);
            }
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
        }
    });

    const feedItems = useMemo(() => {
        const removeDoubleSameId = items.filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index);
        removeDoubleSameId.sort((a, b) => {
            if (a.createdAt && b.createdAt) {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            }
            return 0;
        });

        return removeDoubleSameId.map((item, index) => {
            if (removeDoubleSameId[index - 1] && removeDoubleSameId[index - 1]._user === item?._user) {
                return ({
                    ...item,
                    hideAvatar: true,
                    hideFullname: true,
                });
            }

            return ({
                ...item,
                hideAvatar: false,
                hideFullname: false,
            });
        });
    }, [items]);

    const handleLoadMore = useCallback((isPrevious) => {
        if (isPrevious && !prevIsLoading && !isTopEndReached) {
            return getPrevious({
                _feedItem: feedItems[0]?.id || '',
                feedItemCreationDate: feedItems[0]?.createdAt,
            });
        }

        if (!nextIsLoading && !isBottomEndReached) {
            return getNext({
                _feedItem: feedItems[feedItems.length - 1]?.id || '',
                feedItemCreationDate: feedItems[feedItems.length - 1]?.createdAt,
            });
        }

        return null;
    }, [feedItems, prevIsLoading, nextIsLoading, getPrevious, getNext, isBottomEndReached, isTopEndReached]);

    const handlePostFeedItem = useCallback((payload) => {
        if (payload?._feed !== _feed) {
            return;
        }

        setBottomEndReached((prevIsBottomEndReached) => {
            if (prevIsBottomEndReached) {
                setMustScroll(payload?.id);
                setItems((prevFeedItems) => [...prevFeedItems, payload]);
                return true;
            }

            if (payload?._user === _myUser) {
                setLoading(true);
                resetPrevious();
                resetNext();
                setTopEndReached(false);
                setItems([payload]);
                getPrevious({
                    _feedItem: payload?.id || '',
                    feedItemCreationDate: payload?.createdAt,
                });
                return true;
            }
            return prevIsBottomEndReached;
        });
    }, [getPrevious, resetNext, resetPrevious, _myUser, _feed]);

    const handleCloseChat = useCallback(() => selectFeedItem(undefined), [selectFeedItem]);

    useEffect(() => {
        if (isWsOpen) {
            const websocket = webSocketManager.getWs('api');

            websocket?._primus.on('data', (event) => {
                if (event?.code === POST_FEED_ITEM) {
                    handlePostFeedItem(event?.payload);
                }

                if (event?.code === PUT_FEED_ITEM) {
                    setItems((prevFeedItems) => {
                        const index = prevFeedItems.findIndex((item) => item.id === event?.payload?.id);
                        if (index !== -1) {
                            const newItems = [...prevFeedItems];
                            newItems[index] = event?.payload;
                            return newItems;
                        }
                        return prevFeedItems;
                    });
                }

                if (event?.code === DELETE_FEED_ITEM) {
                    setItems((prevFeedItems) => prevFeedItems.filter((item) => item.id !== event?.payload?.id));
                }
            });
        }
    }, [isWsOpen, handlePostFeedItem]);

    useEffect(() => {
        if (((nextData?.length ?? 0) > 0 || (previousData?.length ?? 0) > 0) && !isLoading) {
            setItems((prevItems) => {
                const previous = previousData ? [...previousData, ...prevItems] : prevItems;
                const next = [...previous, ...(nextData || [])];
                const uniqueItems = next.filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index);
                return uniqueItems;
            });
        }
    }, [previousData, nextData]);

    useEffect(() => {
        const resetItems = typeof mustScroll === 'boolean' && mustScroll && items.findIndex((item) => item.id === id) === -1;
        if (!firstData || (items?.length && id === prevId && !resetItems)) {
            return;
        }

        if (resetItems) {
            setItems([]);
        }

        setTopEndReached(false);
        setBottomEndReached(false);
        setFetchedOnce(false);
        resetNext();
        resetPrevious();

        if (firstData?.length) {
            setItems(firstData);
            if (firstData?.length < REQUEST_LIMIT) {
                setTopEndReached(true);
            }
        } else {
            setItems([feedItem] as FeedType);
            setTopEndReached(true);
        }

        getNext({
            _feedItem: firstData?.[0]?.id || id || '',
            feedItemCreationDate: firstData?.[0]?.createdAt || createdAt,
        });
    }, [firstData, items, getNext, id, mustScroll]);

    useEffect(() => {
        if (feedItems?.length && ['error', 'success'].includes(nextStatus) && !isFetchedOnce) {
            setFetchedOnce(true);
        }
    }, [feedItems, nextStatus]);

    if (!feedItems?.length || !isFetchedOnce || isLoading) {
        return (
            <StyledLoaderContainer>
                <StyledLoader />
            </StyledLoaderContainer>
        )
    }

    return (
        <StyledCard>
            <Header
                _feed={_feed}
                hideActions
                feed={feed}
                handleCloseChat={handleCloseChat}
            />
            {!isLoading ? (
                <FeedItemDrawer
                    isFetching={nextIsLoading || prevIsLoading || isInitLoading}
                    customItems={feedItems}
                    _customFeedItem={id}
                    _feed={_feed}
                    isEndReached={isTopEndReached}
                    // eslint-disable-next-line
                    // @ts-ignore
                    isBottomEndReached={isBottomEndReached}
                    visible
                    mustScroll={mustScroll}
                    setMustScroll={setMustScroll}
                    customScrollComponent={CustomScroll}
                    handleLoadMore={handleLoadMore}
                    _room={undefined}
                />
            ) : (
                <StyledLoaderContainer>
                    <StyledLoader />
                </StyledLoaderContainer>
            )}
        </StyledCard>
    );
}

export default MessageDetail;
