import React from 'react';
import PropTypes from 'prop-types';

import Border from 'common/file/icons/Shared/Border';
import { FileIconProps } from 'components/Shared/Common/Icon.types';

const Video = ({ color = '#F64646', withBorder = true }: FileIconProps) => (
    <svg width="100px" height="124px" viewBox="0 0 100 124">
        <g id="file-video" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={color} fillRule="nonzero">
                <g id="file-ai">
                    {withBorder && <Border />}
                </g>
                <path
                    d="M49.7142857,51.3571429 C53.8352141,51.3571429 57.6356783,52.3725717 61.1157924,54.4034598 C64.5959065,56.434348 67.3513663,59.1898077 69.3822545,62.6699219 C71.4131426,66.150036 72.4285714,69.9505002 72.4285714,74.0714286 C72.4285714,78.1923569 71.4131426,81.9928211 69.3822545,85.4729353 C67.3513663,88.9530494 64.5959065,91.7085092 61.1157924,93.7393973 C57.6356783,95.7702855 53.8352141,96.7857143 49.7142857,96.7857143 C45.5933574,96.7857143 41.7928931,95.7702855 38.312779,93.7393973 C34.8326649,91.7085092 32.0772051,88.9530494 30.046317,85.4729353 C28.0154288,81.9928211 27,78.1923569 27,74.0714286 C27,69.9505002 28.0154288,66.150036 30.046317,62.6699219 C32.0772051,59.1898077 34.8326649,56.434348 38.312779,54.4034598 C41.7928931,52.3725717 45.5933574,51.3571429 49.7142857,51.3571429 Z M61.0714286,75.6981027 C61.7023841,75.3431902 62.0178571,74.8009709 62.0178571,74.0714286 C62.0178571,73.3418862 61.7023841,72.799667 61.0714286,72.4447545 L44.9821429,62.9804688 C44.3709047,62.6058389 43.7399586,62.5959804 43.0892857,62.9508929 C42.4583302,63.3255227 42.1428571,63.8776005 42.1428571,64.6071429 L42.1428571,83.5357143 C42.1428571,84.2652566 42.4583302,84.8173344 43.0892857,85.1919643 C43.4047635,85.3497032 43.7202365,85.4285714 44.0357143,85.4285714 C44.3709094,85.4285714 44.6863824,85.3398446 44.9821429,85.1623884 L61.0714286,75.6981027 Z"
                    id="play-circle---FontAwesome"
                />
            </g>
        </g>
    </svg>
);

Video.propTypes = {
    color: PropTypes.string,
    withBorder: PropTypes.bool
};

export default Video;
