import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Button from 'components/Shared/Common/Button';

const formatMessages = intl => () => ({
    save: intl.formatMessage({
        id: 'global.button.save',
        defaultMessage: 'Sauvegarder'
    }),
    reset: intl.formatMessage({
        id: 'global.button.reset',
        defaultMessage: 'Annuler'
    })
});

const GeneralSettingsForm = ({
    intl,
    pristine,
    reset,
    submitting,
    submit,
    valid = true,
    className = ''
}) => {
    const i18n = formatMessages(intl)();

    return (
        <div className={className}>
            <Button
                onClick={reset}
                isShadow
                color="secondary"
                isDisabled={submitting || pristine}
            >
                {i18n.reset}
            </Button>
            <Button
                onClick={submit}
                color="primary"
                isShadow
                isDisabled={pristine || submitting || !valid}
            >
                {i18n.save}
            </Button>
        </div>
    );
};

GeneralSettingsForm.propTypes = {
    intl: PropTypes.any.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    submit: PropTypes.func.isRequired,
    valid: PropTypes.bool,
    className: PropTypes.string
};

export default injectIntl(GeneralSettingsForm);
