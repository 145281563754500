import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Switch } from 'antd';

const ToggleButton = (props) => {
    const {
        toggleOffText,
        toggleOnText,
        value = false,
        onChange
    } = props;

    const handleChange = useCallback(() => onChange(!value), [value]);
    
    return value !== undefined ? (
        <Switch 
            checkedChildren={toggleOnText} 
            unCheckedChildren={toggleOffText}
            // onClick={handleChange}
            onChange={handleChange}
            checked={value}
            // defaultChecked={false}
        />
    ) :  null;
}

ToggleButton.propTypes = {
    toggleOnText: PropTypes.string.isRequired,
    toggleOffText: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

export default memo(ToggleButton);

const _YesNoToggleButton = ({ intl, ...props }) => (
    <ToggleButton
        toggleOnText={intl.formatMessage({ id: 'button.toggle.on' })}
        toggleOffText={intl.formatMessage({ id: 'button.toggle.off' })}
        {...props}
    />
);

_YesNoToggleButton.propTypes = {
    intl: PropTypes.any.isRequired
};

export const YesNoToggleButton = memo(injectIntl(_YesNoToggleButton));
