import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const LeftTemplate = ({ intl, onLoad }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (onLoad && !isLoaded) {
            onLoad();
            setIsLoaded(true);
        }
    }, [onLoad]);

    return intl.formatMessage({
        id: 'feedItem.leaveStatus',
        defaultMessage: 'a quitté le groupe'
    });
};

LeftTemplate.propTypes = {
    intl: PropTypes.object.isRequired,
    onLoad: PropTypes.func
};

export default memo(injectIntl(LeftTemplate));
