import { Children, isValidElement, cloneElement, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import getStreams from 'components/Shared/Recorder/utils';

const AudioStreamsMerger = ({ members = [], _room, children = null }) => {
    const streams = useMemo(() => getStreams(members, _room), [members, _room]);
    const audioContext = useMemo(() => new AudioContext(), []);
    const destinationNode = useMemo(() => audioContext.createMediaStreamDestination(), [audioContext]);

    useEffect(() => {
        const sourceNodes = streams
            .filter(stream => stream.audioStream?.active)
            .map((stream) => {
                const sourceNode = audioContext.createMediaStreamSource(stream.audioStream);
                sourceNode.connect(destinationNode);
                return sourceNode;
            });

        return () => {
            sourceNodes.forEach((sourceNode) => sourceNode.disconnect());
        };
    }, [audioContext, destinationNode, streams]);

    const childrenWithStream = useMemo(() => Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, { stream: destinationNode.stream });
        }
        return child;
    }), [destinationNode, children]);

    return childrenWithStream || null;
};

AudioStreamsMerger.propTypes = {
    members: PropTypes.arrayOf(PropTypes.shape({
        _client: PropTypes.string
    })),
    _room: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default AudioStreamsMerger;
