import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Radio } from 'antd';
import RadioItem from 'components/Shared/Forms/Radio/RadioItem';

export const StyleIconLabel = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
`;

const Styled = styled(Radio.Group)`
    width: 100%;
`;

const RadioGroup = ({
    onChange,
    items,
    value = '',
    ItemComponent,
    checked = false,
    ...itemProps
}) => (
    <Styled onChange={onChange} checked={checked} value={value} {...itemProps}>
        <StyleIconLabel>
            {items?.length ? items.map(item => (
                <RadioItem
                    key={item?.value || item?.id}
                    {...item}
                    isSpacer
                    ItemComponent={ItemComponent}
                    disabled={item?.disabled}
                    checked={value === item?.value}
                    value={item?.value || item?.id}
                />
            )) : null}
        </StyleIconLabel>
    </Styled>
);

RadioGroup.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    checked: PropTypes.bool,
    ItemComponent: PropTypes.any.isRequired
};

export default memo(RadioGroup);
