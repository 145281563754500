import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Configure } from 'components/Room/Requierements/Screens';

const RoomSettings = ({
    intl,
    onClose = () => {},
    onSubmit,
    showSkip = true,
    footerButtonProps = {}
}) => {
    const handleSubmit = useCallback((settings) => {
        onSubmit(settings);
        onClose();
    }, [onSubmit, onClose]);

    return (
        <div className="content">
            <Configure
                layoutDirection="vertical"
                avatar={undefined}
                onSubmit={handleSubmit}
                showSkip={showSkip}
                footerButtonProps={footerButtonProps}
                submitLabel={intl.formatMessage({
                    id: 'global.button.save',
                })}
            />
        </div>
    );
}

RoomSettings.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
    footerButtonProps: PropTypes.object,
    showSkip: PropTypes.bool,
    onClose: PropTypes.func
};

export default injectIntl(RoomSettings);
